import React from 'react';
import {
  CheckFilled, Truck, Waiting,
} from './icons';
import LoginBg1 from './img/loginBg.png';
import LoginBg2 from './img/loginBg2.png';

export const Colors = {
  // lightGreen: '#67C9BB',
  darkGreen: '#19AD95',
  white: '#ffffff',
  yellow: '#FFCE00',
  red: '#F53C56',
  green: '#ADD328',
  // blue: '#00539F', // eski
  blue: '#004F91',
  blueBlack2: '#002E58',
  blueLight: '#88CCFB',
  purple: '#8B52CA',
  lightPurple: '#7764E4',
  islamicGreen: '#00953B',
  orange: '#F68121',
  // black: '#474A5C',
  navy: '#01203D',
  error: '#f44336',
  darkGrey: '#737B8B',
  lightGrey: '#ECEFF2',
  grey: '#96A0AF',
  lightBlack: '#474A5C',
  silver: '#919DC0',
  lightOrange: '#FFBB54',
  lightWhite: '#FBFBFE',
  burgundy: '#B00F0F',
  blueLight2: '#1779D3',
  semiLightBlue: '#20A4FF',
  pink: '#FF9090',
  mint: '#ADD328',
  freshGrey: '#ECECEC',
  redBlack: '#630C0C',
  greenBlack: '#013E2B',
  brown: '#885509',
  black: '#000',
};

// 1    Admin
// 2    Müşteri Az Admini
// 3    Alt Kullanıcı
// 4    Müşteri Temsilcisi
// 5    Müşteri Ana Admin

// Auth
// auth_1: Sipariş yetkisi
// auth_2: Rapor yetkisi
// auth_3: Kullanıcı yetkisi
// auth_4: Müşteri yetkisi
// auth_5: Ürün yetkisi
// auth_6: Ayar yetkisi
// auth_7: Log yetkisi
// auth_8: Duyuru yetkisi
// auth_9: Ödeme yetkisi
// auth_10: Müşteri oluşturma
// auth_11: Müşteri güncelleme
// auth_12: Müşteri aktif-pasif
// auth_13: Kullanıcı oluşturma
// auth_14: Kullanıcı güncelleme
// auth_15: Kullanıcı aktif
// auth_16: Müşteri temsilcisi aktarma
// auth_17: Müşteri yönetme
// auth_18: Blokaj tanımlama
// auth_19: Blokaj silme
// auth_20: SAP sipariş yaratma
// auth_21: Duyuru oluşturma
// auth_22: Faturalar
// auth_23: Teminat
// auth_24: Bütün Müşterileri Görebilir
// auth_25: Tüm Şantiye
// auth_26: Müşteri Temsilcisi Yetkisi
// auth_27: Sadece Müşteri Oluşturabilir
// auth_28: Kredi Kontrol Ekranı
// auth_29: Rol Ekranı
// auth_30: Net Tahsilat Ödeme Yapabilme
// auth_31: Tonaj Artırımı-İptali
// auth_32: Müşteri Alt Kullanıcı Oluşturabilir
// auth_33: Müşteri Kullanıcı Ekranı
// auth_34: All User/Tüm Kullanıcıları Görebilir
// auth_35: Ana Sayfa Ekranı(Dashboard)
// auth_36: Tüm Kredi Kontrol
// auth_37: Şirket Kodları

export const languageData = [
  { id: 1, name: 'T', text: 'Türkçe' },
  { id: 2, name: 'E', text: 'English' },
  { id: 3, name: 'S', text: 'Español' },
];

export const tablePagination = [
  { id: 1, value: 5 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

export const orderItemStatus = [
  { id: 1, text: 'Beklemede', icon: (<Waiting />) },
  { id: 2, text: 'İçeri Girdi', icon: (<Truck />) },
  { id: 3, text: 'Dolumda', icon: (<Truck />) },
  { id: 4, text: 'Çıkış Yaptı', icon: (<CheckFilled />) },
];

// export const locations = [
//   { id: 1, text: 'Fabrika' },
//   { id: 2, text: 'Şantiye' },
// ];

export const invalidMail = ['ğ', 'Ğ', 'ş', 'Ş', 'ü', 'Ü', 'ö', 'Ö', 'ç', 'Ç', 'ı', 'İ'];

// !(invalidMail.some(a => mail.includes(a)))

// export const productId = [
//   {
//     id: 1, name: 'Gri Çimento', alternative_name: 'Gri Çimento', sap_product_no: '1234566',
//   },
//   {
//     id: 2, name: 'Beyaz Çimento', alternative_name: 'Beyaz Çimento', sap_product_no: '12345667',
//   },
//   {
//     id: 3, name: 'Mavi Çimento', alternative_name: 'Mavi Çimento', sap_product_no: '12345663',
//   },
// ];

export const sliderData = [LoginBg1, LoginBg2];

// export const avaibleHours = [
//   { id: 9, name: '09:00' },
//   { id: 10, name: '10:00' },
//   { id: 11, name: '11:00' },
//   { id: 12, name: '12:00' },
//   { id: 13, name: '13:00' },
//   { id: 14, name: '14:00' },
//   { id: 15, name: '15:00' },
//   { id: 16, name: '16:00' },
//   { id: 17, name: '17:00' },
//   { id: 18, name: '18:00' },
// ];

// export const months = [
//   'Jan',
//   'Feb',
//   'Mar',
//   'Apr',
//   'May',
//   'Jun',
//   'Jul',
//   'Aug',
//   'Sep',
//   'Oct',
//   'Nov',
//   'Dec',
// ];

export const monthsLong = [
  'January',
  'February',
  'March',
  'April',
  'Mayy',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
