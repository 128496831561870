import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ClickAwayListener, Grid, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { tr } from 'date-fns/locale';
import {
  addDays,
  format,
  isValid,
  setHours,
} from 'date-fns';
import {
  CustomButton,
  CustomTextField,
  OrderCard,
  PageWrapper,
  SearchBar,
  SimpleTable,
  TabItem,
  TabWrapper,
} from '../assets/styled';
import Table from '../components/Table';
import {
  CheckFilledCircle,
  CrossFilledCircle,
  Excel,
  NoOrder,
  WarningCircleFilled,
} from '../assets/icons';

import {
  getReports,
  getReportsOrder,
  getNotSapOrder,
  getOrders,
  getExcel,
} from '../store/actions';
import {
  selectNotSapOrder,
  selectOrderDetail,
  selectReports,
  selectReportsOrder,
} from '../store/selectors';
import { tra } from '../commons/utils';
import CustomFilter from '../components/CustomFilter';
import DetailOrder from '../components/Modals/DetailOrder';
import { Colors } from '../assets/statics';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerTabValue: 0,
      startDate: setHours(new Date(), 12),
      endDate: addDays(setHours(new Date(), 12), 1),
      filter: [],
    };
    props.getReports(
      format?.(setHours?.(new Date(), 12), 'yyyy-MM-dd'),
      format?.(setHours?.(new Date(), 12), 'yyyy-MM-dd'),
    );
  }

  getReports = () => {
    const { startDate, endDate } = this.state;
    if (isValid(startDate) && isValid(endDate)) {
      this.props.getReports(
        format(setHours(startDate, 12), 'yyyy-MM-dd'),
        format(setHours(endDate, 12), 'yyyy-MM-dd'),
      );
    }
  }

  getReportsOrder = () => {
    const { startDate, endDate } = this.state;
    if (isValid(startDate) && isValid(endDate)) {
      this.props.getReportsOrder(
        format(setHours(startDate, 12), 'yyyy-MM-dd'),
        format(setHours(endDate, 12), 'yyyy-MM-dd'),
      );
    }
  }

  innerTabChange = (event, newValue) => {
    const { startDate, endDate } = this.state;
    this.setState({
      innerTabValue: newValue,
      startDate: new Date(),
      endDate: new Date(),
      filter: [],
    });
    switch (newValue) {
      case 0:
        if (isValid(startDate) && isValid(endDate)) {
          this.props.getReports(
            format(setHours(startDate, 12), 'yyyy-MM-dd'),
            format(setHours(endDate, 12), 'yyyy-MM-dd'),
          );
        }
        break;
      case 1:
        if (isValid(startDate) && isValid(endDate)) {
          this.props.getReportsOrder(
            format(setHours(startDate, 12), 'yyyy-MM-dd'),
            format(setHours(endDate, 12), 'yyyy-MM-dd'),
          );
        }
        break;
      case 2:
        this.props.getNotSapOrder();
        break;
      default:
        break;
    }
  }

  // tabData = () => {
  //   const { innerTabValue } = this.state;
  //   const { reports, reportsOrder, notSapOrder } = this.props;
  //   this.setState({ filter: [] });
  //   switch (innerTabValue) {
  //     case 0:
  //       return reports;
  //     case 1:
  //       return reportsOrder;
  //     case 2:
  //       return notSapOrder;
  //     default:
  //       return reports;
  //   }
  // }

  render() {
    const { reports, reportsOrder, notSapOrder } = this.props;
    const {
      searchFilter, filterDrawer, filter, innerTabValue, openDetailModal, startDate, endDate,
    } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? ((innerTabValue === 0 ? reports : reportsOrder)?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : (innerTabValue === 0 ? reports : reportsOrder);
    const columns = [
      // {
      //   name: 'ebeln',
      //   text: tra('Type'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     <Tooltip title={value === '1' ? tra('Aktive') : value === '2' ? tra('Pending') : value === '3' ? tra('Passive') : 'yanlış value'}>
      //       <div style={{
      //         width: 15,
      //         height: 15,
      //         borderRadius: '100%',
      //         background: value === '1' ? Colors.blue : value === '2' ? Colors.grey : value === '3' ? Colors.burgundy : 'red',
      //         margin: '0 auto',
      //       }}
      //       />
      //     </Tooltip>
      //   ),
      // },
      {
        name: 'deliveryDate',
        text: tra('Delivery_Date'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'date',
        customBody: (value) => value && (new Date(value).toLocaleDateString() || '-'),
      },
      {
        name: 'distributionChannel',
        text: tra('Delivery_Type'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'externalDeliveryId',
        text: tra('eWayBillNo'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'trackingNo',
        text: tra('SAP_delivery_No'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'creditControlText',
        text: tra('Credit_Control'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'soldToParty',
        text: tra('Construction_Site_Number'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'concretePouringAddress',
        text: tra('Construction_Site_Name'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'material',
        text: tra('Product_No'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'materialText',
        text: tra('Product_Name'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'amount',
        text: tra('Quanta'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'unit',
        text: tra('Unit'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value) => (value === 'TO' ? 'TON' : value),
      },
      // {
      //   name: 'orderNo',
      //   text: tra('Order_Number'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      // {
      //   name: 'orderDate',
      //   text: tra('Order_Date'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'date',
      //   customBody: (value) => value && (new Date(value).toLocaleDateString() || '-'),
      // },
      {
        name: 'paymentTermText',
        text: tra('Maturity_Description'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'plaque',
        text: tra('Plaque'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'pumpPlaque',
        text: tra('Pump_Plaque'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'subPackaging',
      //   text: tra('Sub_Packaging'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      // {
      //   name: 'unitPrice',
      //   text: tra('Currency'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      // {
      //   name: 'salesManager',
      //   text: tra('Sales_Representative),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     value ? (
      //       <TableNameBox>
      //         {/* <img src={row.img} alt="salesManager" /> */}
      //         <span>{value}</span>
      //       </TableNameBox>

      //     ) : (
      //       <TableNameBox notExist={!value}>
      //         {/* <img src={row.img} alt="salesManager" /> */}
      //         <span>{tra('Must_be_created)}</span>
      //       </TableNameBox>
      //     )
      //   ),
      // },
      // {
      //   name: 'Action',
      //   text: tra('Action'),
      //   filterable: false,
      //   align: 'left',
      //   sortable: false,
      //   type: 'action',
      //   disableSetting: false,
      //   customBody: (value, columnName, index, row) => (
      //     <div className="tableButton">
      //       <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
      //         <MobileTripleIcon />
      //       </CustomIconButton>
      //       {
      //         this.state[`anchorMobileTable${row.id}`] && (
      //           <MobileMoreMenu
      //             className="mobileMoreMenu"
      //             anchorEl={this.state[`anchorMobileTable${row.id}`]}
      //             keepMounted
      //             open={Boolean(this.state[`anchorMobileTable${row.id}`])}
      //             onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
      //             // placement="left"
      //             anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      //             transformOrigin={{ horizontal: 'right', vertical: 'center' }}
      //           >
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ openNewCustomer: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <EditMenu />
      //               {tra('Edit')}
      //             </MobileMoreMenuItem>
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ detailModalOpen: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <ManageMenu />
      //               {tra('Manage)}
      //             </MobileMoreMenuItem>
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ detailModalOpen: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <DeactiveMenu />
      //               {tra('Make_Passive')}
      //             </MobileMoreMenuItem>
      //           </MobileMoreMenu>
      //         )
      //       }
      //     </div>
      //   ),
      // },
    ];
    const columnsOrder = [
      // {
      //   name: 'ebeln',
      //   text: tra('Type'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     <Tooltip title={value === '1' ? tra('Aktive') : value === '2' ? tra('Pending') : value === '3' ? tra('Passive') : 'yanlış value'}>
      //       <div style={{
      //         width: 15,
      //         height: 15,
      //         borderRadius: '100%',
      //         background: value === '1' ? Colors.blue : value === '2' ? Colors.grey : value === '3' ? Colors.burgundy : 'red',
      //         margin: '0 auto',
      //       }}
      //       />
      //     </Tooltip>
      //   ),
      // },
      {
        name: 'documentNo',
        text: tra('Order_Number'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'creditControl',
        text: tra('Credit_Control'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'customerNo',
        text: tra('Customer_No'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'customerName',
        text: tra('Customer_Name'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'materialNo',
        text: tra('Material_No'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'materialName',
        text: tra('Material'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'distributionChannel',
        text: tra('Delivery_Method'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
        customBody: (value) => value && (
          value === 'FB' ? tra('Factory') : value === 'SN' ? tra('Building_Site') : 'wrong value'
        ),
      },
      {
        name: 'createDate',
        text: tra('Delivery_Date'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'date',
        customBody: (value) => value && (new Date(value).toLocaleDateString() || '-'),
      },
      {
        name: 'amount',
        text: tra('Quanta'),
        filterable: true,
        align: 'right',
        sortable: true,
        type: 'text',
        customBody: (value) => value && new Intl.NumberFormat('tr-TR').format(value),
      },
      {
        name: 'usedAmount',
        text: tra('Used_Amount'),
        filterable: true,
        align: 'right',
        sortable: true,
        type: 'text',
        customBody: (value) => value && new Intl.NumberFormat('tr-TR').format(value),
      },
      {
        name: 'unit',
        text: tra('Unit'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'orderStatus',
        text: tra('Order_Status'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'paymentTerm',
        text: tra('Payment_Type'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'salesManager',
      //   text: tra('Sales_Representative),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     value ? (
      //       <TableNameBox>
      //         {/* <img src={row.img} alt="salesManager" /> */}
      //         <span>{value}</span>
      //       </TableNameBox>

      //     ) : (
      //       <TableNameBox notExist={!value}>
      //         {/* <img src={row.img} alt="salesManager" /> */}
      //         <span>{tra('Must_be_created)}</span>
      //       </TableNameBox>
      //     )
      //   ),
      // },
      // {
      //   name: 'Action',
      //   text: tra('Action'),
      //   filterable: false,
      //   align: 'left',
      //   sortable: false,
      //   type: 'action',
      //   disableSetting: false,
      //   customBody: (value, columnName, index, row) => (
      //     <div className="tableButton">
      //       <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
      //         <MobileTripleIcon />
      //       </CustomIconButton>
      //       {
      //         this.state[`anchorMobileTable${row.id}`] && (
      //           <MobileMoreMenu
      //             className="mobileMoreMenu"
      //             anchorEl={this.state[`anchorMobileTable${row.id}`]}
      //             keepMounted
      //             open={Boolean(this.state[`anchorMobileTable${row.id}`])}
      //             onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
      //             // placement="left"
      //             anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      //             transformOrigin={{ horizontal: 'right', vertical: 'center' }}
      //           >
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ openNewCustomer: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <EditMenu />
      //               {tra('Edit')}
      //             </MobileMoreMenuItem>
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ detailModalOpen: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <ManageMenu />
      //               {tra('Manage)}
      //             </MobileMoreMenuItem>
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ detailModalOpen: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <DeactiveMenu />
      //               {tra('Make_Passive')}
      //             </MobileMoreMenuItem>
      //           </MobileMoreMenu>
      //         )
      //       }
      //     </div>
      //   ),
      // },
    ];
    // const currentColumn = () => {
    //   switch (innerTabValue) {
    //     case 0:
    //       return columns;
    //     case 1:
    //       return columnsOrder;
    //     default:
    //       return columns;
    //   }
    // };
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            // columns={currentColumn()}
            columns={innerTabValue === 1 ? columnsOrder : columns}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar style={{ paddingBottom: 10 }}>
              <TabWrapper value={this.state.innerTabValue} innerTab style={{ width: 'unset' }} onChange={(event, newValue) => this.innerTabChange(event, newValue)} aria-label="simple tabs example">
                <TabItem value={0} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Shipment_Report')}</p>)} />
                <TabItem value={1} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Orders_Report')}</p>)} />
                <TabItem value={2} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Orders_Opened_from_the_Portal')}</p>)} />
              </TabWrapper>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                {
                  (innerTabValue === 0 || innerTabValue === 1) && (
                    <CustomButton onClick={() => this.setState((prevState) => ({ filterDrawer: !prevState.filterDrawer }))}>{tra('Filter')}</CustomButton>
                  )
                }
              </div>
              {
                (innerTabValue === 1 || innerTabValue === 0) && (
                  <div className="rightButtons">
                    <CustomButton
                      lightBlue
                      startIcon={<Excel />}
                      onClick={() => {
                        this.props.getExcel(
                          'deliveryOrOrder',
                          format?.(setHours?.(isValid(startDate) ? startDate : new Date(), 12), 'yyyy-MM-dd'),
                          format?.(setHours?.(isValid(endDate) ? endDate : new Date(), 12), 'yyyy-MM-dd'),
                          innerTabValue === 0 ? 'delivery_report' : 'order_report',
                        );
                      }}
                    >
                      {tra('Download_Excel')}
                    </CustomButton>
                  </div>
                )
              }
              {(innerTabValue === 0 || innerTabValue === 1) && (
                <div className="datePickerWrapper">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      label={tra('Starting_Date')}
                      value={startDate}
                      onChange={(e) => isValid(e) && this.setState({ startDate: e }, () => (innerTabValue === 0 ? this.getReports() : this.getReportsOrder()))}
                      autoOk
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      maxDate={endDate}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      label={tra('End_Date')}
                      value={endDate}
                      onChange={(e) => isValid(e) && this.setState({ endDate: e }, () => (innerTabValue === 0 ? this.getReports() : this.getReportsOrder()))}
                      autoOk
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      minDate={startDate}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )}
            </SearchBar>
          </Grid>
          {
            (innerTabValue === 1 || innerTabValue === 0) ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Table
                  data={filteredData || []}
                  columns={innerTabValue === 1 ? columnsOrder : columns}
                  id="reportsTable"
                  options={{
                    selectableRows: 'none',
                    header: {
                      visible: true,
                      columnSetting: true,
                    },
                    filter: {
                      show: true,
                      filters: filter,
                      onChange: (state, value, text, type) => this.setState({
                        deleteFilter: {
                          state, value, text, type,
                        },
                      }),
                      drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                    },
                    pagination: {
                      active: true,
                      perPage: 20,
                    },
                  }}
                  // rowClick={(row) => console.log(row)}
                />
              </Grid>
            ) : innerTabValue === 2 ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {
                  notSapOrder?.length > 0 ? notSapOrder.map((order) => (
                    <OrderCard>
                      <div className="cardHeader">
                        {/* <div>
                          <TableNameBox style={{ margin: 'auto' }}>
                            <WarningCircleFilled />
                            <span>Hatalı Sipariş</span>
                          </TableNameBox>
                        </div> */}
                        <div>
                          <p>{tra('Order_Status')}</p>
                          <span>{order.order_status_name}</span>
                        </div>
                        <div>
                          <p>{tra('Order_Date')}</p>
                          <span>{new Date(order?.createdAt).toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                        </div>
                        {/* <div>
                          <p>Sipariş Özeti</p>
                          <span>{order.Amounts && order.Amounts.length} Teslimat, 1 Ürün</span>
                        </div> */}
                        <div>
                          <p>{tra('Total')}</p>
                          <span>{order?.total_amount} {tra('Ton')}</span>
                        </div>
                        <div>
                          <p>{tra('Delivery_Place')}</p>
                          <span>{order.delivery_point_name}</span>
                        </div>
                        <div>
                          <p>{tra('Building_Site')}</p>
                          <Tooltip title={order?.Delivery?.name}>
                            <span>{order?.Delivery?.name}</span>
                          </Tooltip>
                        </div>
                        <div className="buttonWrapper">
                          <CustomButton lined onClick={() => { this.setState({ openDetailModal: true }); this.props.getOrders(order.id); }}>{tra('Order_Detail')}</CustomButton>
                          {/* <CustomButton red disabled={order?.cancel_request} onClick={() => this.setState({ questionModal2: true, selectedId: order?.id })}>
                            {tra('Cancel_Order')}
                          </CustomButton> */}
                        </div>
                      </div>
                      <div className="cardContent">
                        {
                          (order?.message || order?.tonnage_update_message || order?.cancel_message) && (
                            <>
                              <div className="message">
                                {
                                  order?.message && (
                                    <div className="messageText">
                                      <WarningCircleFilled color={Colors.orange} />
                                      <span>{order?.message}</span>
                                    </div>
                                  )
                                }
                                {
                                  order?.cancel_message && (
                                    <Tooltip title={tra('Order_Cancellation')}>
                                      <div className="messageText">
                                        {order.cancel_confirm ? <CheckFilledCircle color={Colors.green} /> : <CrossFilledCircle color={Colors.error} />}
                                        <span style={{ color: order.cancel_confirm ? Colors.green : Colors.error }}>{order?.cancel_message}</span>
                                      </div>
                                    </Tooltip>
                                  )
                                }
                                {
                                  order?.tonnage_update_message && (
                                    <Tooltip title={tra('Tonnage_Update')}>
                                      <div className="messageText">
                                        {order.tonnage_update_confirm ? <CheckFilledCircle color={Colors.green} /> : <CrossFilledCircle color={Colors.error} />}
                                        <span style={{ color: order.tonnage_update_confirm ? Colors.green : Colors.error }}>{order?.tonnage_update_message}</span>
                                      </div>
                                    </Tooltip>
                                  )
                                }
                                {/* <WarningCircleFilled color={Colors.orange} />
                                <span>{order?.cancel_message}</span> */}
                              </div>
                            </>
                          )
                        }
                        <SimpleTable>
                          <thead>
                            {/* <th>{tra('Order_Stage')}</th> */}
                            <th>{tra('Order_Ref_ID')}</th>
                            <th>{tra('Quanta')}</th>
                            <th>{tra('Product')}</th>
                            <th>{tra('Delivery_Time')}</th>
                          </thead>
                          <tbody>
                            {
                              order.Amounts && order.Amounts.map((item) => (
                                <tr>
                                  {/* <td>
                                    {
                                      item.stage_id && (
                                        orderItemStatus.map((d) => (
                                          d.id === item.stage_id && (
                                            <TableNameBox style={{ margin: 'auto' }}>
                                              {d.icon}
                                              <span>{d.text}</span>
                                            </TableNameBox>
                                          )
                                        ))
                                      )
                                    }
                                  </td> */}
                                  <td align="center">{item.order_id}</td>
                                  <td>{item.amount} {tra('Ton')}</td>
                                  <td>
                                    <Tooltip title={order.product_name}>
                                      <span>{order.product_name}</span>
                                    </Tooltip>
                                  </td>
                                  <td align="center">{item.hour}{item.hour !== '-' && ':00'}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </SimpleTable>
                      </div>
                    </OrderCard>
                  )) : (this.props.notSapOrder?.length !== 0 && filteredData?.length === 0 ? (
                    <div className="noData">
                      <NoOrder />
                      <p>{tra('There_were_no_results')}</p>
                    </div>
                  ) : '')
                }
                {
                  this.props.notSapOrder && this.props.notSapOrder.length === 0 && (
                    <div className="noData">
                      <NoOrder />
                      <p>{tra('There_is_no_order')}</p>
                    </div>
                  )
                }
              </Grid>
            ) : 'wrong'
          }
        </Grid>
        {
          openDetailModal && (
            <DetailOrder
              open={openDetailModal}
              onClose={() => this.setState({ openDetailModal: false })}
              orderDetail={this.props.orderDetail}
            />
          )
        }
      </PageWrapper>
    );
  }
}

Reports.propTypes = {
  // getDashboard: PropTypes.func.isRequired,
  // dashboardData: PropTypes.object.isRequired,
  getReports: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  getReportsOrder: PropTypes.func.isRequired,
  reportsOrder: PropTypes.array.isRequired,
  getNotSapOrder: PropTypes.func.isRequired,
  notSapOrder: PropTypes.array.isRequired,
  orderDetail: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  getExcel: PropTypes.func.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  // dashboardData: selectDashboard(),
  reports: selectReports(),
  reportsOrder: selectReportsOrder(),
  notSapOrder: selectNotSapOrder(),
  orderDetail: selectOrderDetail(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getReports,
      getReportsOrder,
      getNotSapOrder,
      getOrders,
      getExcel,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
