import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomButton,
  CustomFade,
  CustomIconButton,
} from '../../assets/styled';
import Input from '../Input';
import GenerateModal from './Info';
import { tra } from '../../commons/utils';

export default class CompanyCreditControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCreditControls: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rowData?.CreditControlAreas !== prevState.selectedCreditControls && prevState.selectedCreditControls === null) {
      const selectedCreditControls = nextProps.rowData?.CreditControlAreas;
      return {
        selectedCreditControls,
      };
    }
    return null;
  }

  clearAllandClose = () => {
    this.setState({
      questionModal: false,
      selectedCreditControls: null,
    });
    this.props.onClose();
  }

  saveFunc = () => {
    const {
      selectedCreditControls, questionModal,
    } = this.state;
    const newCreditControls = [];
    if (selectedCreditControls && this.props.rowData?.id) {
      if (questionModal) {
        const data = { credit_controls: newCreditControls, company_code_id: this.props.rowData?.id };
        const x = selectedCreditControls?.map((c) => newCreditControls.push(c.id));
        this.props.runService(data);
        this.clearAllandClose();
      } else {
        this.setState({ questionModal: true });
      }
    } else {
      // this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning'); // temp
    }
    // this.setState({ questionModal: false });
  }

  close = () => {
    const { selectedCreditControls } = this.state;
    if (selectedCreditControls !== this.props.rowData?.CreditControlAreas) {
      this.setState({
        sureModal: true,
      });
    } else {
      this.clearAllandClose();
    }
  }

  render() {
    const {
      open,
    } = this.props;
    const { questionModal } = this.state;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{tra('Defining_Credit_Control_to_the_Company')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content">
                <div className="formWrapper" style={{ width: '100%' }}>
                  <p>{this.props.rowData?.code} - {this.props.rowData?.name}</p>
                  {/* <Input
                    type="autoComplete"
                    label={tra('Company_Name')}
                    variant="standard"
                    data={this.props.companyCodes || []}
                    fullWidth
                    value={this.props.rowData || this.state.companyCode}
                    // onChange={(e, value) => this.setState({ companyCode: value })}
                    disabled
                  /> */}
                  <Input
                    type="autoComplete"
                    label={tra('Credit_Control')}
                    variant="standard"
                    data={this.props.creditControls || []}
                    fullWidth
                    disableCloseOnSelect
                    multiple
                    value={this.state.selectedCreditControls}
                    onChange={(e, value) => this.setState({ selectedCreditControls: value })}
                  />
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <CustomButton onClick={() => this.saveFunc()}>
                    {tra('Save_Edit')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          questionModal && (
            <GenerateModal
              open={questionModal}
              type="Warning"
              title={tra('Defining_Credit_Control_to_the_Company')}
              text={tra('Changing_credit_controls_defined_for_the_company')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.saveFunc()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.clearAllandClose()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

CompanyCreditControl.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rowData: PropTypes.array.isRequired,
  creditControls: PropTypes.array.isRequired,
  runService: PropTypes.func.isRequired,
};

CompanyCreditControl.defaultProps = {
};
