import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Fade, IconButton, Paper,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
  ArrowDown,
  Companies,
  Danger,
  Hamburger,
  Location,
  Logout,
  LogoutFilled,
  NoAnnouncement,
  Notifications,
  Setting,
  World,
} from '../assets/icons';
import {
  AnnouncementBox,
  AnnouncementWrapper,
  CustomButton,
  CustomIconButton,
  CustomPopper,
  CustomPopperUser,
  HeaderWrapper,
  MobileMoreMenu,
  MobileMoreMenuItem,
  Simplelink,
} from '../assets/styled';
import { setActiveUser, getWarnings, appConfig } from '../store/actions';
import {
 selectWarnings, selectCompanies, selectActiveUser, selectAuth, selectAppConfig,
} from '../store/selectors';
import { tra } from '../commons/utils';
import { Colors, languageData } from '../assets/statics';

const pageList = [
  { text: tra('Order'), hash: '/Order' },
  { text: tra('Invoices_Screen'), hash: '/Invoice' },
  { text: tra('Payments_Screen'), hash: '/Payment' },
  { text: tra('Coverage_Information'), hash: '/Guarantee' },
  { text: tra('Add_Sub_User'), hash: '/SubUserAdd' },
  { text: tra('Reports'), hash: '/Reports' },
  { text: tra('Customers'), hash: '/Customers' },
  { text: tra('Products'), hash: '/Products' },
  { text: tra('Order_Transactions'), hash: '/OrderTransactions' },
  { text: tra('Create_Announcement'), hash: '/Announcement' },
  { text: tra('Management_Screen'), hash: '/Admin' },
  { text: tra('Log'), hash: '/Log' },
  { text: tra('Customer_Management'), hash: '/CustomersManagement' },
  { text: tra('Announcements'), hash: '/AllAnnouncement' },
  { text: tra('Overview'), hash: '/' },
];
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annoAnchor: null,
      userAnchor: null,
      changeLanguage: null,
    };
    if (localStorage.getItem('token')) props.getWarnings();
  }

  logOut = () => {
    localStorage.removeItem('token');
    // window.location.replace('#/Login');
    this.props.setActiveUser({});
    this.props.appConfig('godMode', false);
    this.props.appConfig('userIsAdmin', false);
    localStorage.removeItem('godModeCustomerId');
    localStorage.removeItem('userIsAdmin');
    // window.location.href = '#/Login';
    window.location.replace('#/Login');
  }

  render() {
    const { annoAnchor, userAnchor } = this.state;
    const { activeUser } = this.props;
    return (
      <HeaderWrapper visible={this.props.visible}>
        <div className="left">
          <div className="mobileButton">
            <CustomIconButton onClick={() => this.props.appConfig('mobileMenuOpen', true)}>
              <Hamburger />
            </CustomIconButton>
          </div>
          {/* <h1>{tra('Overview')}</h1> */}
          <div>
            <Switch>
              {
                pageList?.map((page) => (
                  <Route path={page.hash}>
                    <h1>{page.text}</h1>
                    {
                      page.hash === '/' && (
                        <p><span style={{ fontWeight: 'bold' }}>{activeUser && `${activeUser.name} `}</span>{tra('Welcome_to_Çimsa_Customer_Portal')}</p>
                      )
                    }
                    {
                      page.hash === '/' && (
                        <p>{tra('Click_here_to_download_the_user_manual_of_the_Customer_Portal')} <a style={{ fontWeight: 600 }} href={tra('manual_link')} target="_blank" rel="noreferrer noopener">{tra('click_here')}</a></p>
                      )
                    }
                    {
                      // page.hash === '/' && (
                      //   <p><span style={{ fontWeight: 'bold' }}>{activeUser && `${activeUser.name} `}</span>{this.props.auth?.auth17 ? tra('Welcome_to_Cimsa_Admin_Screen') : tra('Welcome_to_Cimsa_Customer_Screen')}</p>
                      // )
                    }
                  </Route>
                ))
              }
            </Switch>
          </div>
        </div>
        <div className="right">
          {
            this.props.companies?.length > 0 && (this.props.activeUser?.sap_customer_no !== '0000000000' || this.props.appConfigs?.godMode) && (
              <IconButton simple disabled={this.props.companies?.length === 1} className="selectedCompany" onClick={() => { this.props.appConfig('changeCompany', true); this.setState({ userAnchor: false }); }}>
                <Location />
                <div>
                  <p>{tra('Company')}</p>
                  <span>{this.props.companies?.find((c) => c?.code === localStorage.getItem('company'))?.name || tra('Company_Not_Selected')}</span>
                </div>
              </IconButton>
            )
          }
          <IconButton className="changeLanguage" size="medium" style={{ border: '1px solid #EFF0F3' }} onClick={(e) => this.setState({ changeLanguage: e.currentTarget })}>
            <World color={Colors.lightBlack} />
          </IconButton>
          {
            this.state.changeLanguage && (
              <MobileMoreMenu
                className="mobileMoreMenu"
                anchorEl={this.state.changeLanguage}
                keepMounted
                open={Boolean(this.state.changeLanguage)}
                onClose={() => this.setState({ changeLanguage: null })}
                // placement="left"
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                modifiers={{
                  flip: {
                    enabled: false,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                  },
                  arrow: {
                    enabled: true,
                    element: this.state.changeLanguage,
                  },
                }}
              >
                {
                  languageData.map((l) => (
                    <MobileMoreMenuItem
                      value={l.name}
                      // active={!this.state.changeLanguage}
                      onClick={() => { this.setState({ changeLanguage: null }); localStorage.setItem('language', l.name); window.location.reload(); }}
                    >
                      {l.text}
                    </MobileMoreMenuItem>
                  ))
                }
              </MobileMoreMenu>
            )
          }
          <IconButton className="announceButton" size="medium" style={{ border: '1px solid #EFF0F3' }} onClick={(e) => this.setState({ annoAnchor: e.currentTarget })}>
            {/* <span className="count">2</span> */}
            <Notifications />
          </IconButton>
          <IconButton size="medium" className="disableRipple" disableRipple style={{ padding: 0, borderRadius: '25px' }} onClick={(e) => this.setState({ userAnchor: e.currentTarget })}>
            {/* <img src="https://thispersondoesnotexist.com/image" alt="Name Surname" /> */}
            <div className="iconWrapper">
              <div className="nameIcon">
                <p>{activeUser.name?.split(' ')[0]?.charAt()}{activeUser.name?.split(' ')[1]?.charAt()}</p>
              </div>
              <ArrowDown />
            </div>
          </IconButton>
          {
            annoAnchor && (
              <CustomPopper
                open={Boolean(annoAnchor)}
                anchorEl={annoAnchor}
                placement="bottom-end"
                transition
              >
                {({ TransitionProps }) => (
                  <ClickAwayListener onClickAway={() => this.setState({ annoAnchor: false })}>
                    <Fade {...TransitionProps} timeout={500}>
                      <Paper>
                        <h2 className="dashTitle">{tra('Announcements')}<Simplelink to="/AllAnnouncement" onClick={() => this.setState({ annoAnchor: false })}>{tra('See_All')}</Simplelink></h2>
                        <AnnouncementWrapper>
                          {
                            this.props.warnings && this.props.warnings?.slice(0, 10)?.map((warn) => (
                              <AnnouncementBox>
                                <i>{warn.type === 'WARNING' ? <Logout /> : <Danger />}</i>
                                <div className="content">
                                  <p>{warn.type}</p>
                                  <span>{warn.message}</span>
                                  <h5>22.07.2021</h5>
                                </div>
                              </AnnouncementBox>
                            ))
                          }
                          {
                            this.props.warnings && this.props.warnings.length === 0 && (
                              <div className="noData">
                                <NoAnnouncement width={190} />
                                <p>{tra('No_Announcement')}</p>
                              </div>
                            )
                          }
                          {/* <AnnouncementBox>
                            <i><Logout /></i>
                            <div className="content">
                              <p>Sipariş Teslimatı</p>
                              <span>05 Temmuz 2015’te vermiş olduğunuz sipariş teslim edilmek üzere yola çıkmıştır.</span>
                              <h5>22.07.2021</h5>
                            </div>
                          </AnnouncementBox>
                          <AnnouncementBox>
                            <i><Danger /></i>
                            <div className="content">
                              <p>Dikkat! Limit</p>
                              <span>Limitiniz işlem yapmak için düşük seviyededir.</span>
                              <div className="buttonWrapper">
                                <CustomButton white>Detaylar</CustomButton>
                                <CustomButton>Yükselt</CustomButton>
                              </div>
                              <h5>22.07.2021</h5>
                            </div>
                          </AnnouncementBox>
                          <AnnouncementBox>
                            <i><Logout /></i>
                            <div className="content">
                              <p>Sipariş Teslimatı</p>
                              <span>05 Temmuz 2015’te vermiş olduğunuz sipariş teslim edilmek üzere yola çıkmıştır.</span>
                              <h5>22.07.2021</h5>
                            </div>
                          </AnnouncementBox> */}
                        </AnnouncementWrapper>
                        <div className="triangle" />
                      </Paper>
                    </Fade>
                  </ClickAwayListener>
                )}
              </CustomPopper>
            )
          }
          {
            userAnchor && (
              <CustomPopperUser open={Boolean(userAnchor)} anchorEl={userAnchor} placement="bottom-end" transition>
                {({ TransitionProps }) => (
                  <ClickAwayListener onClickAway={() => this.setState({ userAnchor: false })}>
                    <Fade {...TransitionProps} timeout={500}>
                      <Paper style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                          this.props.auth?.auth6 && (
                            <CustomButton simple startIcon={<Setting />}><Simplelink to="/Admin" onClick={() => this.setState({ userAnchor: false })}>{tra('Settings')}</Simplelink></CustomButton>
                          )
                        }
                        {
                          window.innerWidth <= 425 && (
                            <CustomButton simple startIcon={<Notifications color="#6F7490" width={20} />}><Simplelink to="/AllAnnouncement" onClick={() => this.setState({ userAnchor: false })}>{tra('Announcements')}</Simplelink></CustomButton>
                          )
                        }
                        {
                          this.props.companies?.length > 1 && (
                            <CustomButton onClick={() => { this.props.appConfig('changeCompany', true); this.setState({ userAnchor: false }); }} simple startIcon={<Companies />}>{tra('Change_Company')}</CustomButton>
                          )
                        }
                        <CustomButton simple startIcon={<LogoutFilled />} onClick={() => { this.logOut(); this.setState({ userAnchor: false }); }}>{tra('Exit')}</CustomButton>
                        <div className="triangle" />
                      </Paper>
                    </Fade>
                  </ClickAwayListener>
                )}
              </CustomPopperUser>
            )
          }
        </div>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  visible: PropTypes.bool.isRequired,
  activeUser: PropTypes.object.isRequired,
  setActiveUser: PropTypes.func.isRequired,
  warnings: PropTypes.array.isRequired,
  getWarnings: PropTypes.func.isRequired,
  appConfig: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  appConfigs: PropTypes.object.isRequired,
};

Header.defaultProps = {
  // isBread: true,
  // timeAgo: 'zaman hatalı',
};

const mapStateToProps = createStructuredSelector({
  activeUser: selectActiveUser(),
  // notifications: selectNotifications(),
  warnings: selectWarnings(),
  companies: selectCompanies(),
  auth: selectAuth(),
  appConfigs: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setActiveUser,
      getWarnings,
      appConfig,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
