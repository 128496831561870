import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Tooltip, ClickAwayListener } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  CustomButton,
  CustomTextField,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  SearchBar,
  TableNameBox,
  CustomIconButton,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  ActiveMenu,
  DeactiveMenu,
  EditMenu,
  Excel,
  ManageMenu,
  MobileTripleIcon,
  PlusButton,
  UploadFilled,
} from '../assets/icons';
import NewCustomer from '../components/Modals/NewCustomer';
import { formatPhoneNumber, tra } from '../commons/utils';

import {
  getCustomers,
  getSapCustomer,
  createCustomer,
  editCustomer,
  appConfig,
  init,
  getExcel,
  updateSapCustomer,
} from '../store/actions';
import {
  selectCustomers,
  selectSapCustomer,
  selectRepresentatives,
  selectFactories,
} from '../store/selectors';
import Info from '../components/Modals/Info';
import CustomFilter from '../components/CustomFilter';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: [],
    };
    this.props.getCustomers();
  }

  godMode = () => {
    const { selectedCustomer } = this.state;
    localStorage.removeItem('company');
    localStorage.setItem('godModeCustomerId', selectedCustomer.id);
    this.props.appConfig('godMode', true);
    this.props.appConfig('godModeCustomer', selectedCustomer);
    localStorage.setItem('userIsAdmin', parseInt(selectedCustomer?.sap_customer_no, 10) === 0);
    this.props.appConfig('userIsAdmin', parseInt(selectedCustomer?.sap_customer_no, 10) === 0);
    this.props.appConfig('godMode', true);
    setTimeout(() => {
      this.props.init();
    }, 1000);
    window.location.replace('#/');
  }

  render() {
    const {
      searchFilter, openNewCustomer, filterDrawer, filter, selectedCustomer,
    } = this.state;
    // const { customers } = this.props;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (this.props.customers && this.props.customers?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : this.props.customers;
    const columns = [
      {
        name: 'is_removed',
        text: tra('Active'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'bool',
        filterArrayText: [tra('Passive'), tra('Active')],
        customBody: (value) => (
          <Tooltip title={!value ? tra('Active') : tra('Passive')} placement="top">
            <div style={{
              width: 15,
              height: 15,
              borderRadius: '100%',
              background: !value ? Colors.blue : Colors.burgundy,
              margin: '0 auto',
            }}
            />
          </Tooltip>
        ),
      },
      {
        name: 'sap_customer_no',
        text: tra('Customer_No'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'name',
        text: tra('Customer'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'authorized_name',
        text: tra('Authorized_Person'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value, column, index, row) => `${value} ${row.authorized_surname}`,
      },
      {
        name: 'authorized_phone',
        text: tra('Authorized_Phone'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value) => formatPhoneNumber(value),
      },
      {
        name: 'email',
        text: tra('Authorized_Email'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'phone',
        text: tra('Contact_Info'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'Representatives',
        text: tra('Sales_Representative'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value && value?.length > 0 ? (
            <Tooltip
              title={value?.length > 1 ? (
                value?.map((t) => (
                  <p>{t.name}</p>
                ))
              ) : ''}
            >
              <TableNameBox>
                <span>{value[0]?.name}</span>{value.length > 1 && <i> +{value.length - 1}</i>}
              </TableNameBox>
            </Tooltip>
            // <TableNameBox>
            //   {/* <img src={row.img} alt="salesManager" /> */}
            //   <span>{value[0].name} {value[0].surname}</span>
            // </TableNameBox>
          ) : (
            <TableNameBox notExist>
              {/* <img src={row.img} alt="salesManager" /> */}
              <span>{tra('Must_be_created')}</span>
            </TableNameBox>
          )
        ),
      },
      {
        name: 'CreditControlAreas',
        text: tra('Credit_Control'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value && value.length > 0 ? (
            <Tooltip
              title={value?.length > 1 ? (
                value?.map((t) => (
                  <p>{t.alternative_name}</p>
                ))
              ) : ''}
            >
              <p>{value[0]?.alternative_name}{value.length > 1 && `, +${value.length - 1}`}</p>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'Action',
        text: tra('Action'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: false,
        type: 'action',
        disableSetting: false,
        customBody: (value, columnName, index, row) => (
          <div className="tableButton">
            <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
              <MobileTripleIcon />
            </CustomIconButton>
            {
              this.state[`anchorMobileTable${row.id}`] && (
                <MobileMoreMenu
                  className="mobileMoreMenu"
                  anchorEl={this.state[`anchorMobileTable${row.id}`]}
                  keepMounted
                  open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                  onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                  // placement="left"
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                >
                  {
                    !row.is_removed && (
                      <>
                        <MobileMoreMenuItem
                          onClick={() => {
                            this.setState({
                              openNewCustomer: true, selectedCustomerId: row.id, [`anchorMobileTable${row.id}`]: null, edit: true,
                            }); this.props.getCustomers(row.id);
                          }}
                        >
                          <EditMenu />
                          {tra('Edit')}
                        </MobileMoreMenuItem>
                        <MobileMoreMenuItem
                          onClick={() => {
                            if (row.is_removed) {
                              this.props.appConfig(
                                'infoModal', {
                                  open: true,
                                  type: 'Info',
                                  title: 'Bu Müşteriyi Yönetemezsiniz.',
                                  text: 'Bu Müşteri Pasif Durumda Olduğu için Yönetemezsiniz. Müşteriyi Yönetebilmek için önce aktif durumuna getiriniz.',
                                  cancelButton: (<CustomButton grey onClick={() => this.props.appConfig('infoModal', false)}>{tra('Done')}</CustomButton>),
                                },
                              );
                            } else {
                              this.setState({
                                selectedCustomerId: row.id,
                                [`anchorMobileTable${row.id}`]: null,
                                selectedCustomer: row,
                                questionModal: true,
                              });
                            }
                          }}
                        >
                          <ManageMenu />
                          {tra('Manage')}
                        </MobileMoreMenuItem>
                      </>
                    )
                  }
                  <MobileMoreMenuItem
                    onClick={() => this.setState({
                      infoDelete: true, selectedCustomerId: row.id, [`anchorMobileTable${row.id}`]: null, selectedCustomerRemoved: row.is_removed,
                    })}
                  >
                    {
                      row.is_removed ? (
                        <>
                          <ActiveMenu />
                          {tra('Make_Active')}
                        </>
                      ) : (
                        <>
                          <DeactiveMenu />
                          {tra('Make_Passive')}
                        </>
                      )
                    }
                  </MobileMoreMenuItem>
                  <MobileMoreMenuItem
                    onClick={() => {
                      this.props.updateSapCustomer(row.sap_customer_no);
                      this.setState({ [`anchorMobileTable${row.id}`]: null });
                    }}
                  >
                    <UploadFilled />
                    {tra('Update_from_SAP')}
                  </MobileMoreMenuItem>
                </MobileMoreMenu>
              )
            }
          </div>
        ),
      },
    ];
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={columns}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState((prevState) => ({ filterDrawer: !prevState.filterDrawer }))}>{tra('Filter')}</CustomButton>
              </div>
              <div className="rightButtons">
                <CustomButton
                  lightBlue
                  startIcon={<Excel />}
                  onClick={() => this.props.getExcel('customers')}
                >
                  {tra('Download_Excel')}
                </CustomButton>
                <CustomButton
                  onClick={() => this.setState({ openNewCustomer: true, edit: null })}
                  startIcon={<PlusButton />}
                >
                  {tra('Create_Customer')}
                </CustomButton>
                {/* <CustomButton>
                  Son Eklenilen
                </CustomButton> */}
              </div>
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              columns={columns || []}
              id="customerTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={() => {}}
            />
          </Grid>
        </Grid>
        {
          openNewCustomer && (
            <NewCustomer
              open={openNewCustomer}
              onClose={() => this.setState({ openNewCustomer: false, edit: false })}
              edit={this.state.edit}
              getSapCustomer={(id) => this.props.getSapCustomer(id)}
              sapCustomer={this.props.sapCustomer}
              representatives={this.props.representatives}
              factories={this.props.factories}
              createCustomer={(data) => this.props.createCustomer(data)}
              selectedCustomerId={this.state.selectedCustomerId}
            />
          )
        }
        {
          this.state.infoDelete && (
            <Info
              open={this.state.infoDelete}
              type="Question"
              title={tra('Confirmation')}
              text={this.state.selectedCustomerRemoved ? tra('Customer_will_be_active_Do_you_confirm') : tra('Customer_will_be_inactive_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ infoDelete: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => { this.setState({ infoDelete: false }); this.props.editCustomer(this.state.selectedCustomerId); }}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
        {
          this.state.questionModal && selectedCustomer && (
            <Info
              open
              type="Warning"
              title={tra('Confirmation')}
              text={tra('The_transactions_you_will_do_after_that_will_be_done_on_behalf_of_x_Do_you_confirm', [selectedCustomer.name])}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false, selectedCustomer: null })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.setState({ questionModal: false }, () => this.godMode())}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
      </PageWrapper>
    );
  }
}

Customers.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  getSapCustomer: PropTypes.func.isRequired,
  editCustomer: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  sapCustomer: PropTypes.object.isRequired,
  representatives: PropTypes.array.isRequired,
  factories: PropTypes.array.isRequired,
  createCustomer: PropTypes.array.isRequired,
  init: PropTypes.func.isRequired,
  appConfig: PropTypes.func.isRequired,
  getExcel: PropTypes.func.isRequired,
  updateSapCustomer: PropTypes.func.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  customers: selectCustomers(),
  sapCustomer: selectSapCustomer(),
  representatives: selectRepresentatives(),
  factories: selectFactories(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getCustomers,
      getSapCustomer,
      createCustomer,
      editCustomer,
      init,
      appConfig,
      getExcel,
      updateSapCustomer,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
