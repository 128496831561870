import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomButton, CustomFade, CustomIconButton, ErrorLine,
} from '../../assets/styled';
import Input from '../Input';
import Info from './Info';
import { tra } from '../../commons/utils';

export default class NewPersonnel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      isChangedState: [],
      // userAuthAdd: [{ id: 1, name: tra('Admin') }, { id: 4, name: tra('Customer_Representative') }],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.editPersonnel && nextProps.sapUser && !prevState.isChangedState.includes('phone')) {
      return {
        phone: nextProps.sapUser?.phone,
      };
    }
    if (nextProps.editPersonnel && nextProps.userDetail?.id !== prevState.userId) {
      return {
        email: nextProps.userDetail.email,
        name: nextProps.userDetail.name,
        surname: nextProps.userDetail.surname,
        phone: nextProps.userDetail.phone,
        representatives: nextProps.userDetail.representatives || [],
        personnelType: nextProps.userDetail?.Roles ? nextProps.userDetail?.Roles[0].id : '',
        userId: nextProps.userDetail.id,
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.setState({
      email: '',
      name: '',
      surname: '',
      phone: '',
      representatives: [],
      personnelType: '',
      userId: '',
    });
  }

  isChanged = (stateName) => {
    // const { isChangedState } = this.state;
    // if (!isChangedState.includes(stateName)) {
    //   isChangedState.push(stateName);
    // }
    // this.setState({ isChangedState });
    const state = this.state[stateName];
    const current = this.props.userDetail[stateName];
    if (state !== current) {
      const { isChangedState } = this.state;
      if (!isChangedState.includes(stateName)) {
        isChangedState.push(stateName);
      }
      this.setState({ isChangedState });
    }
  }

  clearAll = () => {
    this.setState({
      personnelType: '',
      name: '',
      surname: '',
      email: '',
      phone: '',
      sapUserName: '',
      isChangedState: [],
    }, () => this.props.onClose());
  }

  createPersonnel = () => {
    const { sapUser } = this.props;
    const {
      personnelType,
      representatives,
      phone,
    } = this.state;
    const newrepresentatives = [];
    (representatives || []).map((k) => (newrepresentatives.push({ id: k.id })));
    const data = {
      ...sapUser,
      // user_type_id: personnelType,
      customers: newrepresentatives,
      roles: [{ id: personnelType }],
    };
    if (personnelType && phone) {
      data.phone = phone?.toString();
      this.props.createUser(data);
      this.setState({ confirmModal: false });
      this.clearAll();
    } else {
      this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning');
    }
  }

  editPersonnel = () => {
    const {
      personnelType,
      name,
      surname,
      email,
      phone,
      isChangedState,
      representatives,
    } = this.state;
    if (personnelType && phone.toString().length > 0) {
      const updatedData = {};
      const newrepresentatives = [];
      if (isChangedState.includes('representatives')) {
        representatives.map((k) => newrepresentatives.push({ id: k.id }));
      }
      const data = {
        name: isChangedState.includes('name') && name,
        surname: isChangedState.includes('surname') && surname,
        email: isChangedState.includes('email') && email,
        phone: isChangedState.includes('phone') && phone.toString(),
        // user_type_id: isChangedState.includes('personnelType') && personnelType,
        customers: isChangedState.includes('representatives') && newrepresentatives,
        roles: isChangedState.includes('personnelType') && [{ id: personnelType }],
      };
      for (let i = 0; i < Object.keys(data).length; ++i) {
        const keysArray = Object.keys(data);
        if (data[keysArray[i]]) {
          updatedData[keysArray[i]] = data[keysArray[i]];
        }
      }
      this.props.editUser(updatedData);
      this.clearAll();
    } else {
      this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning');
    }
  }

  saveFunc = () => {
    const {
      personnelType,
      phone,
      confirmModal,
    } = this.state;
    if (personnelType && phone?.toString().length === 10) {
      if (confirmModal) {
        if (this.props.editPersonnel) {
          this.editPersonnel();
        } else {
          this.createPersonnel();
        }
      } else {
        this.setState({ confirmModal: true });
      }
    } else {
      // this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning');
      this.setState({ isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random() });
    }
  }

  enterSapNumber = (e) => {
    if (this.state.sapUserName && e.key === 'Enter') {
      this.props.getSapUser(this.state.sapUserName);
    }
  }

  close = () => {
    if (this.state.sapUserName) {
      this.setState({
        sureModal: true,
      });
    } else {
      this.clearAll();
    }
  }

  render() {
    const { isError, errorMessage } = this.state;
    const { open, editPersonnel } = this.props;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{editPersonnel ? tra('Edit_Employee') : tra('Create_New_Employee')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content">
                {errorMessage && errorMessage?.length && (
                  <ErrorLine key={this.state.reRender}>
                    {errorMessage}!
                  </ErrorLine>
                )}
                {
                  !this.props.editPersonnel && !Object.keys(this.props.sapUser).length ? (
                    <div className="bringCustomer">
                      <Input
                        type="text"
                        label={tra('SAP_Username')}
                        variant="standard"
                        value={this.state.sapUserName}
                        fullWidth
                        autoFocus
                        onChange={(e) => this.setState({ sapUserName: e.target.value })}
                        onKeyDown={(e) => this.enterSapNumber(e)}
                      />
                      <CustomButton onClick={() => this.state.sapUserName && this.props.getSapUser(this.state.sapUserName)}>{tra('Bring')}</CustomButton>
                    </div>
                  ) : (
                    <div className="formWrapper">
                      <Input
                        type="text"
                        variant="standard"
                        label={tra('SAP_Username')}
                        value={this.props.sapUser.username}
                        disabled
                        // onChange={(e) => { this.setState({ personnelType: e.target.value }); if (!this.state.isChangedState.includes('personnelType') && editPersonnel) { this.isChanged('personnelType'); } }}
                      />
                      <Input
                        type="text"
                        label={tra('Employee_Name')}
                        variant="standard"
                        value={this.props.sapUser.name || this.state.name}
                        disabled={this.props.sapUser}
                        onChange={(e) => { this.setState({ name: e.target.value }); if (!this.state.isChangedState.includes('name') && editPersonnel) { this.isChanged('name'); } }}
                      />
                      <Input
                        type="text"
                        label={tra('Employee_Surame')}
                        variant="standard"
                        value={this.props.sapUser.surname || this.state.surname}
                        disabled={this.props.sapUser}
                        onChange={(e) => { this.setState({ surname: e.target.value }); if (!this.state.isChangedState.includes('surname') && editPersonnel) { this.isChanged('surname'); } }}
                      />
                      <Input
                        type="tel"
                        label={tra('Telephone')}
                        variant="standard"
                        value={this.state.phone}
                        onChange={(e) => { this.setState({ phone: e.floatValue }); if (!this.state.isChangedState.includes('phone')) { this.isChanged('phone'); } }}
                        error={isError && (!this.state.phone || !(this.state.phone?.toString()?.length === 10))}
                      />
                      <Input
                        type="text"
                        label={tra('Email')}
                        fullWidth
                        variant="standard"
                        value={this.props.sapUser.email || this.state.email}
                        disabled={this.props.sapUser}
                        onChange={(e) => { this.setState({ email: e.target.value }); if (!this.state.isChangedState.includes('email') && editPersonnel) { this.isChanged('email'); } }}
                      />
                      <Input
                        type="select"
                        variant="standard"
                        label={tra('Employee_Type')}
                        fullWidth
                        data={this.props.userRoleByType}
                        value={this.state.personnelType}
                        onChange={(e) => { this.setState({ personnelType: e.target.value, representatives: [] }); if (!this.state.isChangedState.includes('personnelType') && editPersonnel) { this.isChanged('personnelType'); } }}
                        error={isError && !this.state.personnelType}
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Represented_Customers')}
                        variant="standard"
                        multiple
                        selectAll
                        fullWidth
                        // disabled={this.state.personnelType === undefined || this.state.personnelType !== 4}
                        disabled={!(this.props.userRoleByType?.find((r) => r.id === this.state.personnelType)?.Permissions?.some((p) => p.id === 26))}
                        data={this.props.customers}
                        value={this.state.representatives || []}
                        onChange={(e, value) => { this.setState({ representatives: value }); if (!this.state.isChangedState.includes('representatives') && editPersonnel) { this.isChanged('representatives'); } }}
                      />
                      <CustomButton simple onClick={() => this.clearAll()} style={{ marginLeft: 0 }}>{tra('Cancel')}</CustomButton>
                      <CustomButton onClick={() => this.saveFunc()}>{tra('Save')}</CustomButton>
                    </div>
                  )
                }
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          this.state.confirmModal && (
            <Info
              open={this.state.confirmModal}
              type="Question"
              title={tra('Confirmation')}
              text={editPersonnel ? tra('Employee_will_be_arranged_Do_you_confirm') : tra('A_New_Employee_Will_Be_Created_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ confirmModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.saveFunc()}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <Info
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.clearAll()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

NewPersonnel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editPersonnel: PropTypes.bool,
  setToast: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  userDetail: PropTypes.object,
  sapUser: PropTypes.object.isRequired,
  getSapUser: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  userRoleByType: PropTypes.array.isRequired,
};

NewPersonnel.defaultProps = {
  userDetail: {},
  editPersonnel: false,
};
