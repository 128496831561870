import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ClickAwayListener, Grid, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { tr } from 'date-fns/locale';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
 addDays, format, isValid, setHours,
} from 'date-fns';
import {
  CustomButton,
  CustomTextField,
  PageWrapper,
  SearchBar,
  // ChartWrapper,
  TabWrapper,
  TabItem,
  CustomIconButton,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  Danger,
  Excel,
  Printer,
  View,
} from '../assets/icons';
// import BarChart from '../components/charts/Bar';

import {
  getInvoices,
  getInvoiceLink,
  setToast,
  getExcel,
} from '../store/actions';
import {
  selectOpenInvoices,
  selectInvoices,
} from '../store/selectors';
import { tra } from '../commons/utils';
import CustomFilter from '../components/CustomFilter';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerTabValue: 0,
      startDate: setHours(new Date(), 12),
      endDate: addDays(setHours(new Date(), 12), 1),
      // invoiceLink: '',
    };
    this.props.getInvoices();
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   let invoiceLink = nextProps.invoiceLink || '';
  //   if (nextProps.invoiceLink !== prevState.invoiceLink && nextProps.invoiceLink !== '') {
  //     invoiceLink = nextProps.invoiceLink || '';
  //     // window.open(`${invoiceLink}`);
  //     return {
  //       invoiceLink,
  //     };
  //   }
  //   return null;
  // }

  innerTabChange = (event, newValue) => {
    this.setState({
      innerTabValue: newValue,
      startDate: setHours(new Date(), 12),
      endDate: addDays(setHours(new Date(), 12), 1),
      filter: [],
    });
    this.getInvoices(newValue);
  }

  getInvoices = (tabVal) => {
    const { startDate, endDate } = this.state;
    if (tabVal === 0) {
      this.props.getInvoices();
    } else if (isValid(startDate) && isValid(endDate)) {
      this.props.getInvoices({
        startDate: format(setHours(this.state.startDate, 12), 'yyyy-MM-dd'),
        endDate: format(setHours(this.state.endDate, 12), 'yyyy-MM-dd'),
      });
    }
  }

  filterCurrentData = () => {
    const {
      openInvoices,
      invoices,
    } = this.props;
    switch (this.state.innerTabValue) {
      case 0:
        return openInvoices;
      case 1:
        return invoices;
      default:
    }
    return [];
  }

  render() {
    const {
      searchFilter, innerTabValue, filterDrawer, filter,
    } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (this.filterCurrentData() && this.filterCurrentData()?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : this.filterCurrentData();
    const columns = [
      {
        name: 'view',
        text: '',
        filterable: false,
        editable: false,
        align: 'center',
        sortable: false,
        type: 'text',
        hidden: !(this.state.innerTabValue === 1),
        customBody: (value, a, ab, row) => (
          <CustomIconButton column onClick={() => (row.einvoiceNo ? this.props.getInvoiceLink(row.einvoiceNo) : this.props.setToast(true, tra('There_is_no_invoice'), 'warning'))}>
            <View />
            <span style={{ paddingTop: 5, color: Colors.blue }}>{tra('View')}</span>
          </CustomIconButton>
        ),
      },
      {
        name: 'print',
        text: '',
        filterable: false,
        editable: false,
        align: 'center',
        sortable: false,
        type: 'text',
        hidden: !(this.state.innerTabValue === 1),
        customBody: () => (
          <CustomIconButton column>
            <Printer />
            <span style={{ paddingTop: 5, color: Colors.burgundy }}>{tra('Print')}</span>
          </CustomIconButton>
        ),
      },
      // {
      //   name: 'ebeln',
      //   text: tra('Type'),
      //   filterable: true,
      //   editable: false,
      //   align: 'center',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     <Tooltip title={value === '1' ? tra('Aktive') : value === '2' ? tra('Pending') : value === '3' ? tra('Passive') : 'yanlış value'}>
      //       <div style={{
      //         width: 15,
      //         height: 15,
      //         borderRadius: '100%',
      //         background: value === '1' ? Colors.blue : value === '2' ? Colors.grey : value === '3' ? Colors.burgundy : 'red',
      //         margin: '0 auto',
      //       }}
      //       />
      //     </Tooltip>
      //   ),
      // },
      {
        name: 'dueDateStatu',
        text: tra('Payment_Status'),
        filterable: !(innerTabValue === 1),
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
        filterArrayText: [tra('Overdue'), tra('Undue')],
        hidden: innerTabValue === 1,
        customBody: (value) => (
          <Tooltip title={value === '1' ? tra('Undue') : value === '0' ? tra('Overdue') : 'yanlış value'}>
            <div style={{
              width: 15,
              height: 15,
              borderRadius: '100%',
              background: value === '1' ? Colors.mint : value === '0' ? Colors.burgundy : 'red',
              margin: '0 auto',
            }}
            />
          </Tooltip>
        ),
      },
      // {
      //   name: 'documentNo',
      //   text: tra('File number'),
      //   filterable: false,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      {
        name: 'invoiceDocumentNo',
        text: tra('Invoice_No'),
        filterable: !(innerTabValue === 1),
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        hidden: innerTabValue === 1,
      },
      {
        name: 'documentNo',
        text: tra('File_No'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'reference',
        text: tra('Reference'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'documentType',
        text: tra('File_Type'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'dueDate2',
      //   // text: tra('ölçütlerin_tanımı_ belge açıklaması'),
      //   text: tra('File_Description'),
      //   filterable: false,
      //   editable: false,
      //   align: 'center',
      //   sortable: true,
      //   type: 'text',
      // },
      {
        name: 'documentDate',
        text: tra('Invoice_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && new Date(value).toLocaleDateString()) || '-',
      },
      {
        name: 'dueDate',
        text: tra('Net_Due_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && new Date(value).toLocaleDateString()) || '-',
      },
      {
        name: 'paymentTermText',
        text: tra('Maturity_Description'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'criterionDescription',
        text: tra('Invoice_Description'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'businessAreaText',
        text: tra('Business_Area'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'creditControlText',
        text: tra('Credit_Control'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'dueDateStatu',
      //   text: tra('Payment_Status'),
      //   filterable: false,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      {
        name: 'localAmount',
        text: tra('Total_Amount'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value) => value && (
          <span style={{ color: value < 0 && Colors.darkGreen, fontWeight: value < 0 && 700 }}>{new Intl.NumberFormat('tr-TR').format(value)}</span>
        ),
      },
      {
        name: 'localCurrency',
        text: tra('Currency'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'text',
        text: tra('Explanation'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'Action',
      //   text: tra('Action'),
      //   filterable: !(innerTabValue === 1),
      //   editable: false,
      //   align: 'left',
      //   sortable: false,
      //   type: 'action',
      //   disableSetting: false,
      //   hidden: innerTabValue === 1,
      //   customBody: () => (
      //     <CustomButton>{tra('Pay')}</CustomButton>
      //   ),
      // },
    ];
    // const barData = [
    //   {
    //     months: 'Aralık',
    //     invoice: 200,
    //   },
    //   {
    //     months: 'Ocak',
    //     invoice: 300,
    //   },
    //   {
    //     months: 'Şubat',
    //     invoice: 400,
    //   },
    //   {
    //     months: 'Mart',
    //     invoice: 150,
    //   },
    //   {
    //     months: 'Nisan',
    //     invoice: 200,
    //   },
    //   {
    //     months: 'Mayıs',
    //     invoice: 140,
    //   },
    //   {
    //     months: 'Haziran',
    //     invoice: 400,
    //   },
    //   {
    //     months: 'Temmuz',
    //     invoice: 250,
    //   },
    //   {
    //     months: 'Ağustos',
    //     invoice: 320,
    //   },
    // ];
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={columns || []}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <h3>{tra('Expense_Statistics')}</h3> */}
            <h5 style={{ display: 'flex', alignItems: 'center' }}>
              <Danger />
              <span style={{ padding: '10px 0 10px 10px' }}>
                {tra('It_is_for_informational_purposes_only_It_has_no_financial_value')}
              </span>
            </h5>
            {/* <ChartWrapper style={{ boxShadow: 'none', height: 300 }}>
              <BarChart direction="vertical" theme="white" dataProp={barData} keyProp="invoice" titleTooltip={tra('Total_Invoice')} />
            </ChartWrapper> */}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <TabWrapper value={this.state.innerTabValue} innerTab style={{ width: 'unset' }} onChange={(event, newValue) => this.innerTabChange(event, newValue)}>
                <TabItem value={0} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Open_Account')}</p>)} />
                <TabItem value={1} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Invoices')}</p>)} />
              </TabWrapper>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('search_for_Invoice_number_product')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState({ filterDrawer: !filterDrawer })}>{tra('Filter')}</CustomButton>
              </div>
              <div className="rightButtons">
                {
                  innerTabValue === 0 && (
                    <CustomButton lightBlue onClick={() => this.props.getExcel('openInvoice')} startIcon={<Excel />}>{tra('Download_Excel')}</CustomButton>
                  )
                }
                {
                  innerTabValue === 1 && (
                    <CustomButton
                      lightBlue
                      onClick={() => {
                        this.props.getExcel(
                          'allInvoice',
                          format(setHours(this.state.startDate, 12), 'yyyy-MM-dd'),
                          format(setHours(this.state.endDate, 12), 'yyyy-MM-dd'),
                        );
                      }}
                      startIcon={<Excel />}
                    >
                      {tra('Download_Excel')}
                    </CustomButton>
                  )
                }
              </div>
              {
                innerTabValue === 1 && (
                  <div className="datePickerWrapper">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        label={tra('Starting_Date')}
                        value={this.state.startDate}
                        onChange={(e) => isValid(e) && this.setState({ startDate: e }, () => this.getInvoices(1))}
                        autoOk
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        maxDate={this.state.endDate}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        label={tra('End_Date')}
                        value={this.state.endDate}
                        onChange={(e) => isValid(e) && this.setState({ endDate: e }, () => this.getInvoices(1))}
                        autoOk
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDate={this.state.startDate}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                )
              }
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              columns={columns.filter((c) => !c.hidden)}
              id="invoiceTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

Invoice.propTypes = {
  getInvoices: PropTypes.func.isRequired,
  openInvoices: PropTypes.array.isRequired,
  invoices: PropTypes.array.isRequired,
  setToast: PropTypes.func.isRequired,
  getInvoiceLink: PropTypes.func.isRequired,
  getExcel: PropTypes.func.isRequired,
};

// Invoice.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  openInvoices: selectOpenInvoices(),
  invoices: selectInvoices(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getInvoices,
      getInvoiceLink,
      setToast,
      getExcel,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
