import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  ClickAwayListener,
  Dialog,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  CustomButton,
  CustomTextField,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  SearchBar,
  TableNameBox,
  CustomIconButton,
  TabWrapper,
  TabItem,
  CustomDialogContent,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  ActiveMenu,
  DeactiveMenu,
  EditMenu,
  Excel,
  MobileTripleIcon,
  PlusButton,
  Reload,
  Setting,
  Swap,
} from '../assets/icons';
import {
  createSubuser,
  getSubusers,
  editSubuser,
  deleteSubuser,
  getSapUser,
  getCustomers,
  setSapUser,
  setToast,
  swapCustomers,
  workAreaAlternative,
  getUserRoleByType,
  getAllPermissions,
  getAllRoles,
  createRole,
  getCreditControl,
  removeCreditControl,
  getCompanyCodes,
  toggleCreditLimit,
  editMatchCreditControl,
  companySettingUpdate,
  getPaymentTerms,
  updateSapPaymentTerms,
  updateSapCreditControls,
  getAllUsers,
  getExcel,
} from '../store/actions';
import {
  selectSubusers,
  selectSubuserDetail,
  selectSapCustomer,
  selectSapUser,
  selectCustomers,
  selectRepresentatives,
  selectUserRoleByType,
  selectAllPermissions,
  selectAllRoles,
  selectAuth,
  selectCreditControl,
  selectCompanyCodes,
  selectPaymentTerms,
  selectAllUsers,
} from '../store/selectors';
import NewPersonnel from '../components/Modals/NewPersonnel';
import Info from '../components/Modals/Info';
import { formatPhoneNumber, tra } from '../commons/utils';
import SwapPersonnel from '../components/Modals/SwapPersonnel';
import Input from '../components/Input';
import NewRole from '../components/Modals/NewRole';
import CustomFilter from '../components/CustomFilter';
import CompanyCreditControl from '../components/Modals/CompanyCreditControl';
import CompanySetting from '../components/Modals/CompanySetting';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerTabValue: 0,
      currentData: [],
      currentColumn: [
        {
          name: 'is_removed',
          text: tra('Type'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'bool',
          filterArrayText: [tra('Passive'), tra('Active')],
          customBody: (value) => (
            <Tooltip title={value === true ? tra('Passive') : tra('Active')}>
              <div style={{
                width: 15,
                height: 15,
                borderRadius: '100%',
                background: value === true ? Colors.burgundy : Colors.blue,
                margin: '0 auto',
              }}
              />
            </Tooltip>
          ),
        },
        {
          name: 'main_role',
          text: tra('Employee_Type'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          // customBody: (value) => (
          //   <div>
          //     {value && value[0]?.name}
          //   </div>
          // ),
        },
        {
          name: 'full_name',
          text: tra('Name_Surname'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          // customBody: (value, columnName, index, row) => (
          //   <div>
          //     {row.name} {row.surname}
          //   </div>
          // ),
        },
        {
          name: 'phone',
          text: tra('Contact_Info'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value, columnName, index, row) => (
            <div>
              {row.phone || '-'}
              <br />
              {row.email || '-'}
            </div>
          ),
        },
        {
          name: 'representatives',
          text: tra('Represented_Customers'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'array',
          customBody: (value, columnName, index, row) => (
            value && value.length > 0 ? (
              <Tooltip
                title={value && value.map((item) => (
                  <p>{item.name}</p>
                ))}
              >
                <TableNameBox style={{ maxWidth: '200px' }}>
                  <span>
                    {value[0].name}
                  </span>
                  {
                    value.length > 1 && (
                      <i>
                        {` +${value.length - 1}`}
                      </i>
                    )
                  }
                </TableNameBox>
              </Tooltip>
            ) : row?.Roles?.[0]?.Permissions?.some((u) => u.id === 26) ? (
              <TableNameBox notExist>
                <span>{tra('Must_be_created')}</span>
              </TableNameBox>
            ) : (
              <TableNameBox success>
                {row?.Roles?.map((u) => <span style={{ margin: 'auto' }}>{u?.name}</span>)}
              </TableNameBox>
            )
          ),
        },
        {
          name: 'Action',
          text: tra('Action'),
          filterable: false,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'action',
          disableSetting: false,
          customBody: (value, columnName, index, row) => (
            <div className="tableButton">
              <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
                <MobileTripleIcon />
              </CustomIconButton>
              {
                this.state[`anchorMobileTable${row.id}`] && (
                  <MobileMoreMenu
                    className="mobileMoreMenu"
                    anchorEl={this.state[`anchorMobileTable${row.id}`]}
                    keepMounted
                    open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                    onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                    // placement="left"
                    anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                    disablePortal={false}
                    modifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                      arrow: {
                        enabled: true,
                        element: this.state[`anchorMobileTable${row.id}`],
                      },
                    }}
                  >
                    {
                      !row.is_removed && (
                        <>
                          <MobileMoreMenuItem
                            // active={!this.state[`columnVisibility${tabValue}`]}
                            onClick={() => {
                              this.setState({
                                openNewPersonnel: true, selectedUserId: row.id, editPersonnel: true, [`anchorMobileTable${row.id}`]: null,
                              }); this.props.getSubusers(row.id);
                            }}
                          >
                            <EditMenu />
                            {tra('Edit')}
                          </MobileMoreMenuItem>
                          <MobileMoreMenuItem
                            // active={!this.state[`columnVisibility${tabValue}`]}
                            disabled={!(row?.representatives?.length > 0)}
                            onClick={() => {
                              this.setState({
                                openSwapPersonnel: true, selectedUserId: row.id, [`anchorMobileTable${row.id}`]: null,
                              }); this.props.getSubusers(row.id);
                            }}
                          >
                            <Swap />
                            {tra('Transfer_Customers')}
                          </MobileMoreMenuItem>
                        </>
                      )
                    }
                    <MobileMoreMenuItem
                      // active={!this.state[`columnVisibility${tabValue}`]}
                      onClick={() => this.setState({ infoDelete: true, selectedUserId: row.id, [`anchorMobileTable${row.id}`]: null })}
                      disabled={row?.Roles?.some((u) => u.id === 1)}
                    >
                      {row.is_removed ? <ActiveMenu /> : <DeactiveMenu />}
                      {row.is_removed ? tra('Make_Active') : tra('Make_Passive')}
                    </MobileMoreMenuItem>
                  </MobileMoreMenu>
                )
              }
            </div>
          ),
        },
      ],
      userColumns: [
        {
          name: 'is_removed',
          text: tra('Type'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'bool',
          filterArrayText: [tra('Passive'), tra('Active')],
          customBody: (value) => (
            <Tooltip title={value === true ? tra('Passive') : tra('Active')}>
              <div style={{
                width: 15,
                height: 15,
                borderRadius: '100%',
                background: value === true ? Colors.burgundy : Colors.blue,
                margin: '0 auto',
              }}
              />
            </Tooltip>
          ),
        },
        {
          name: 'main_role',
          text: tra('Employee_Type'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          // customBody: (value) => (
          //   <div>
          //     {value && value[0]?.name}
          //   </div>
          // ),
        },
        {
          name: 'full_name',
          text: tra('Name_Surname'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          // customBody: (value, columnName, index, row) => (
          //   <div>
          //     {row.name} {row.surname}
          //   </div>
          // ),
        },
        {
          name: 'phone',
          text: tra('Contact_Info'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value, columnName, index, row) => (
            <div>
              {row.phone || '-'}
              <br />
              {row.email || '-'}
            </div>
          ),
        },
        {
          name: 'representatives',
          text: tra('Represented_Customers'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'array',
          customBody: (value, columnName, index, row) => (
            value && value.length > 0 ? (
              <Tooltip
                title={value && value.map((item) => (
                  <p>{item.name}</p>
                ))}
              >
                <TableNameBox style={{ maxWidth: '200px' }}>
                  <span>
                    {value[0].name}
                  </span>
                  {
                    value.length > 1 && (
                      <i>
                        {` +${value.length - 1}`}
                      </i>
                    )
                  }
                </TableNameBox>
              </Tooltip>
            ) : row?.Roles?.[0]?.Permissions?.some((u) => u.id === 26) ? (
              <TableNameBox notExist>
                <span>{tra('Must_be_created')}</span>
              </TableNameBox>
            ) : (
              <TableNameBox success>
                {row?.Roles?.map((u) => <span style={{ margin: 'auto' }}>{u?.name}</span>)}
              </TableNameBox>
            )
          ),
        },
        {
          name: 'Action',
          text: tra('Action'),
          filterable: false,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'action',
          disableSetting: false,
          customBody: (value, columnName, index, row) => (
            <div className="tableButton">
              <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
                <MobileTripleIcon />
              </CustomIconButton>
              {
                this.state[`anchorMobileTable${row.id}`] && (
                  <MobileMoreMenu
                    className="mobileMoreMenu"
                    anchorEl={this.state[`anchorMobileTable${row.id}`]}
                    keepMounted
                    open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                    onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                    // placement="left"
                    anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                    disablePortal={false}
                    modifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                      arrow: {
                        enabled: true,
                        element: this.state[`anchorMobileTable${row.id}`],
                      },
                    }}
                  >
                    {
                      !row.is_removed && (
                        <>
                          <MobileMoreMenuItem
                            // active={!this.state[`columnVisibility${tabValue}`]}
                            onClick={() => {
                              this.setState({
                                openNewPersonnel: true, selectedUserId: row.id, editPersonnel: true, [`anchorMobileTable${row.id}`]: null,
                              }); this.props.getSubusers(row.id);
                            }}
                          >
                            <EditMenu />
                            {tra('Edit')}
                          </MobileMoreMenuItem>
                          <MobileMoreMenuItem
                            // active={!this.state[`columnVisibility${tabValue}`]}
                            disabled={!(row?.representatives?.length > 0)}
                            onClick={() => {
                              this.setState({
                                openSwapPersonnel: true, selectedUserId: row.id, [`anchorMobileTable${row.id}`]: null,
                              }); this.props.getSubusers(row.id);
                            }}
                          >
                            <Swap />
                            {tra('Transfer_Customers')}
                          </MobileMoreMenuItem>
                        </>
                      )
                    }
                    <MobileMoreMenuItem
                      // active={!this.state[`columnVisibility${tabValue}`]}
                      onClick={() => this.setState({ infoDelete: true, selectedUserId: row.id, [`anchorMobileTable${row.id}`]: null })}
                      disabled={row?.Roles?.some((u) => u.id === 1)}
                    >
                      {row.is_removed ? <ActiveMenu /> : <DeactiveMenu />}
                      {row.is_removed ? tra('Make_Active') : tra('Make_Passive')}
                    </MobileMoreMenuItem>
                  </MobileMoreMenu>
                )
              }
            </div>
          ),
        },
      ],
      creditColumns: [
        {
          name: 'is_removed',
          text: tra('Status'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'bool',
          filterArrayText: [tra('Passive'), tra('Active')],
          customBody: (value) => (
            <Tooltip title={!value ? tra('Active') : tra('Passive')} placement="top">
              <div style={{
                width: 15,
                height: 15,
                borderRadius: '100%',
                background: !value ? Colors.blue : Colors.burgundy,
                margin: '0 auto',
              }}
              />
            </Tooltip>
          ),
        },
        {
          name: 'sap_credit_control_no',
          text: tra('Credit_Control_No'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'text',
        },
        {
          name: 'name',
          text: tra('Credit_Control'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'alternative_name',
          text: tra('Credit_Control_Description'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            value || '-'
          ),
        },
        {
          name: 'factory_name',
          text: tra('Factory_Description'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            value || '-'
          ),
        },
        {
          name: 'updatedAt',
          text: tra('Update_Date'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        {
          name: 'Action',
          text: tra('Action'),
          filterable: false,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'action',
          disableSetting: false,
          customBody: (value, columnName, index, row) => (
            <div className="tableButton">
              <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
                <MobileTripleIcon />
              </CustomIconButton>
              {
                this.state[`anchorMobileTable${row.id}`] && (
                  <MobileMoreMenu
                    className="mobileMoreMenu"
                    anchorEl={this.state[`anchorMobileTable${row.id}`]}
                    keepMounted
                    open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                    onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                    // placement="left"
                    anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                    disablePortal={false}
                    modifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                      arrow: {
                        enabled: true,
                        element: this.state[`anchorMobileTable${row.id}`],
                      },
                    }}
                  >
                    <MobileMoreMenuItem
                      // active={!this.state[`columnVisibility${tabValue}`]}
                      onClick={() => {
                        this.setState({
                          changeAlternativeName: true,
                          changingName: row.name,
                          alternativeName: row.alternative_name,
                          factoryName: row.factory_name,
                          creditId: row.id,
                          [`anchorMobileTable${row.id}`]: null,
                        });
                      }}
                    >
                      <EditMenu />
                      {tra('Change_Credit_Description')}
                    </MobileMoreMenuItem>
                    {
                      this.props.auth.auth36 && (
                        <MobileMoreMenuItem
                          // active={!this.state[`columnVisibility${tabValue}`]}
                          onClick={() => {
                            this.props.removeCreditControl(row.id);
                            this.setState({
                              [`anchorMobileTable${row.id}`]: null,
                            });
                          }}
                        >
                          {
                            row.is_removed ? (
                              <>
                                <ActiveMenu />
                                {tra('Make_Active')}
                              </>
                            ) : (
                              <>
                                <DeactiveMenu />
                                {tra('Make_Passive')}
                              </>
                            )
                          }
                        </MobileMoreMenuItem>
                      )
                    }
                  </MobileMoreMenu>
                )
              }
            </div>
          ),
        },
      ],
      paymentColumns: [
        {
          name: 'sap_payment_term_no',
          text: tra('Payment_Term_No'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'text',
        },
        {
          name: 'name',
          text: tra('Payment_Condition'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        // {
        //   name: 'alternative_name',
        //   text: tra('Credit_Control_Description'),
        //   filterable: true,
        //   editable: false,
        //   align: 'left',
        //   sortable: true,
        //   type: 'text',
        //   customBody: (value) => (
        //     value || '-'
        //   ),
        // },
        {
          name: 'updatedAt',
          text: tra('Update_Date'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        // {
        //   name: 'Action',
        //   text: tra('Action'),
        //   filterable: false,
        //   editable: false,
        //   align: 'left',
        //   sortable: false,
        //   type: 'action',
        //   disableSetting: false,
        //   customBody: (value, columnName, index, row) => (
        //     <div className="tableButton">
        //       <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
        //         <MobileTripleIcon />
        //       </CustomIconButton>
        //       {
        //         this.state[`anchorMobileTable${row.id}`] && (
        //           <MobileMoreMenu
        //             className="mobileMoreMenu"
        //             anchorEl={this.state[`anchorMobileTable${row.id}`]}
        //             keepMounted
        //             open={Boolean(this.state[`anchorMobileTable${row.id}`])}
        //             onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
        //             // placement="left"
        //             anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
        //             transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        //             disablePortal={false}
        //             modifiers={{
        //               flip: {
        //                 enabled: false,
        //               },
        //               preventOverflow: {
        //                 enabled: true,
        //                 boundariesElement: 'scrollParent',
        //               },
        //               arrow: {
        //                 enabled: true,
        //                 element: this.state[`anchorMobileTable${row.id}`],
        //               },
        //             }}
        //           >
        //             <MobileMoreMenuItem
        //               // active={!this.state[`columnVisibility${tabValue}`]}
        //               onClick={() => {
        //                 this.setState({
        //                   changeAlternativeName: true,
        //                   changingName: row.name,
        //                   alternativeName: row.alternative_name,
        //                   factoryName: row.factory_name,
        //                   creditId: row.id,
        //                   [`anchorMobileTable${row.id}`]: null,
        //                 });
        //               }}
        //             >
        //               <EditMenu />
        //               {tra('Change_Credit_Description')}
        //             </MobileMoreMenuItem>
        //             {
        //               this.props.auth.auth36 && (
        //                 <MobileMoreMenuItem
        //                   // active={!this.state[`columnVisibility${tabValue}`]}
        //                   onClick={() => {
        //                     this.props.removeCreditControl(row.id);
        //                     this.setState({
        //                       [`anchorMobileTable${row.id}`]: null,
        //                     });
        //                   }}
        //                 >
        //                   {
        //                     row.is_removed ? (
        //                       <>
        //                         <ActiveMenu />
        //                         {tra('Make_Active')}
        //                       </>
        //                     ) : (
        //                       <>
        //                         <DeactiveMenu />
        //                         {tra('Make_Passive')}
        //                       </>
        //                     )
        //                   }
        //                 </MobileMoreMenuItem>
        //               )
        //             }
        //           </MobileMoreMenu>
        //         )
        //       }
        //     </div>
        //   ),
        // },
      ],
      rolesColumns: [
        {
          name: 'name',
          text: tra('Name'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'Permissions',
          text: tra('Permissions'),
          filterable: false,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            value && value.length > 0 ? (
              <Tooltip
                title={value?.map((item) => (
                  <p>{item?.name}</p>
                ))}
              >
                <TableNameBox style={{ maxWidth: '200px' }}>
                  <span>
                    {value && value?.[0]?.name}
                  </span>
                  {
                    value?.length > 1 && (
                      <i>
                        {` +${value?.length - 1}`}
                      </i>
                    )
                  }
                </TableNameBox>
              </Tooltip>
            ) : (
              <TableNameBox notExist style={{ maxWidth: '200px' }}>
                <span>
                  {tra('No_Role')}
                </span>
              </TableNameBox>
            )
          ),
        },
        {
          name: 'createdAt',
          text: tra('Create_Date'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        {
          name: 'updatedAt',
          text: tra('Update_Date'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        {
          name: 'Action',
          text: tra('Action'),
          filterable: false,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'action',
          disableSetting: false,
          customBody: (value, columnName, index, row) => (
            <div className="tableButton">
              <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
                <MobileTripleIcon />
              </CustomIconButton>
              {
                this.state[`anchorMobileTable${row.id}`] && (
                  <MobileMoreMenu
                    className="mobileMoreMenu"
                    anchorEl={this.state[`anchorMobileTable${row.id}`]}
                    keepMounted
                    open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                    onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                    // placement="left"
                    anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                    disablePortal={false}
                    modifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                      arrow: {
                        enabled: true,
                        element: this.state[`anchorMobileTable${row.id}`],
                      },
                    }}
                  >
                    <MobileMoreMenuItem
                      // active={!this.state[`columnVisibility${tabValue}`]}
                      onClick={() => {
                        this.setState({
                          openNewRole: true,
                          selectedRole: row.id,
                          [`anchorMobileTable${row.id}`]: null,
                          editRole: true,
                        });
                      }}
                    >
                      <EditMenu />
                      {tra('Edit_Roles')}
                    </MobileMoreMenuItem>
                  </MobileMoreMenu>
                )
              }
            </div>
          ),
        },
      ],
      companyColumns: [
        {
          name: 'check_limit',
          text: tra('Limit_Control'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'bool',
          filterArrayText: [tra('Check_limit'), tra('Do_not_Check_limit')],
          customBody: (value) => (
            <Tooltip title={value ? tra('Check_limit') : tra('Do_not_Check_limit')} placement="top">
              <div style={{
                width: 15,
                height: 15,
                borderRadius: '100%',
                background: value ? Colors.blue : Colors.burgundy,
                margin: '0 auto',
              }}
              />
            </Tooltip>
          ),
        },
        {
          name: 'code',
          text: tra('Company_Code'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'text',
        },
        {
          name: 'name',
          text: tra('Company_Name'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'CreditControlAreas',
          text: tra('Credit_Control_Description'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'array',
          customBody: (value) => (
            value && value.length > 0 ? (
              <Tooltip
                title={value && value.map((item) => (
                  <p style={{ fontSize: 12, lineHeight: '15px' }}>{item.alternative_name}</p>
                ))}
              >
                <TableNameBox style={{ maxWidth: '250px' }}>
                  <span>
                    {value[0].alternative_name}
                  </span>
                  {
                    value.length > 1 && (
                      <i>
                        {` +${value.length - 1}`}
                      </i>
                    )
                  }
                </TableNameBox>
              </Tooltip>
            ) : (
              <TableNameBox notExist style={{ maxWidth: '250px' }}>
                <span style={{ margin: 'auto' }}>{tra('Not_Defined')}</span>
              </TableNameBox>
            )
          ),
        },
        {
          name: 'low_limit',
          text: tra('Low_Limit'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            (value || value === 0) && (
              <span>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value)}</span>
            )
          ),
        },
        {
          name: 'insufficient_limit',
          text: tra('Insufficient_Limit'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            (value || value === 0) && (
              <span>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(value)}</span>
            )
          ),
        },
        {
          name: 'time_range',
          text: tra('Time_Range'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            value && (
              <span>{value} {tra('Time')}</span>
            )
          ),
        },
        {
          name: 'kvkk_validity',
          text: tra('KVKK_Validity'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            value && (
              <span>{value} {tra('Months')}</span>
            )
          ),
        },
        {
          name: 'kvkk_text',
          text: tra('KVKK_Text'),
          filterable: false,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'text',
          customBody: (value) => (
            <span>{value?.length > 0 ? tra('Avaible') : '-'}</span>
          ),
        },
        {
          name: 'from_name',
          text: tra('Email_Sender_Name'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'from_email',
          text: tra('Email_Sender_Address'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'smtp_port',
          text: tra('SMTP_Port'),
          filterable: true,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'text',
        },
        {
          name: 'smtp_server',
          text: tra('SMTP_Server'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'createdAt',
          text: tra('Create_Date'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        {
          name: 'updatedAt',
          text: tra('Update_Date'),
          filterable: true,
          editable: false,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        {
          name: 'Action',
          text: tra('Action'),
          filterable: false,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'action',
          disableSetting: false,
          customBody: (value, columnName, index, row) => (
            <div className="tableButton">
              <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
                <MobileTripleIcon />
              </CustomIconButton>
              {
                this.state[`anchorMobileTable${row.id}`] && (
                  <MobileMoreMenu
                    className="mobileMoreMenu"
                    anchorEl={this.state[`anchorMobileTable${row.id}`]}
                    keepMounted
                    open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                    onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                    // placement="left"
                    anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                    disablePortal={false}
                    modifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                      arrow: {
                        enabled: true,
                        element: this.state[`anchorMobileTable${row.id}`],
                      },
                    }}
                  >
                    <MobileMoreMenuItem
                      // active={!this.state[`columnVisibility${tabValue}`]}
                      onClick={() => {
                        this.setState({
                          companySettingModal: true,
                          rowData: row,
                          [`anchorMobileTable${row.id}`]: null,
                        });
                      }}
                    >
                      <Setting color={Colors.darkGrey} />
                      {tra('Update_Company_Settings')}
                    </MobileMoreMenuItem>
                    <MobileMoreMenuItem
                      // active={!this.state[`columnVisibility${tabValue}`]}
                      onClick={() => {
                        this.setState({
                          companyCreditControlModal: true,
                          rowData: row,
                          [`anchorMobileTable${row.id}`]: null,
                        });
                      }}
                    >
                      <EditMenu />
                      {tra('Change_Defined_Credits')}
                    </MobileMoreMenuItem>
                    {
                      this.props.auth.auth36 && (
                        <MobileMoreMenuItem
                          // active={!this.state[`columnVisibility${tabValue}`]}
                          onClick={() => {
                            this.props.toggleCreditLimit(row.id);
                            this.setState({
                              [`anchorMobileTable${row.id}`]: null,
                            });
                          }}
                        >
                          {
                            row.is_removed ? (
                              <>
                                <ActiveMenu />
                                {tra('Check_limit')}
                              </>
                            ) : (
                              <>
                                <DeactiveMenu />
                                {tra('Do_not_Check_limit')}
                              </>
                            )
                          }
                        </MobileMoreMenuItem>
                      )
                    }
                  </MobileMoreMenu>
                )
              }
            </div>
          ),
        },
      ],
      usersColumns: [
        {
          name: 'is_removed',
          text: tra('Active'),
          filterable: true,
          align: 'center',
          sortable: true,
          type: 'bool',
          filterArrayText: [tra('Passive'), tra('Active')],
          customBody: (value) => (
            <Tooltip title={!value ? tra('Active') : tra('Passive')} placement="top">
              <div style={{
                width: 15,
                height: 15,
                borderRadius: '100%',
                background: !value ? Colors.blue : Colors.burgundy,
                margin: '0 auto',
              }}
              />
            </Tooltip>
          ),
        },
        {
          name: 'customer_name',
          text: tra('Customer'),
          filterable: true,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'name',
          text: tra('Name'),
          filterable: true,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value, column, index, row) => `${value} ${row.surname}`,
        },
        {
          name: 'phone',
          text: tra('Telephone'),
          filterable: false,
          align: 'left',
          sortable: true,
          type: 'text',
          customBody: (value) => formatPhoneNumber(value),
        },
        {
          name: 'email',
          text: tra('Authorized_Email'),
          filterable: false,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'role_name',
          text: tra('Role'),
          filterable: true,
          align: 'left',
          sortable: true,
          type: 'text',
        },
        {
          name: 'kvkk',
          text: tra('KVKK'),
          filterable: true,
          align: 'center',
          sortable: true,
          type: 'bool',
          filterArrayText: [tra('Passive'), tra('Active')],
          customBody: (value) => (
            <Tooltip title={!value ? tra('Active') : tra('Passive')} placement="top">
              <div style={{
                width: 15,
                height: 15,
                borderRadius: '100%',
                background: !value ? Colors.blue : Colors.burgundy,
                margin: '0 auto',
              }}
              />
            </Tooltip>
          ),
        },
        {
          name: 'kvkk_end_date',
          text: tra('KVKK_End_Date'),
          filterable: true,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        {
          name: 'updatedAt',
          text: tra('Update_Date'),
          filterable: true,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        {
          name: 'createdAt',
          text: tra('Create_Date'),
          filterable: true,
          align: 'left',
          sortable: true,
          type: 'date',
        },
        // {
        //   name: 'CreditControlAreas',
        //   text: tra('Credit_Control'),
        //   filterable: true,
        //   align: 'left',
        //   sortable: true,
        //   type: 'array',
        //   customBody: (value) => (
        //     value && value.length > 0 ? (
        //       <Tooltip
        //         title={value?.length > 1 ? (
        //           value?.map((t) => (
        //             <p>{t.alternative_name}</p>
        //           ))
        //         ) : ''}
        //       >
        //         <p>{value[0]?.alternative_name}{value.length > 1 && `, +${value.length - 1}`}</p>
        //       </Tooltip>
        //     ) : (
        //       '-'
        //     )
        //   ),
        // },
        {
          name: 'Action',
          text: tra('Action'),
          filterable: false,
          editable: false,
          align: 'left',
          sortable: false,
          type: 'action',
          disableSetting: false,
          customBody: (value, columnName, index, row) => (
            <div className="tableButton">
              <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
                <MobileTripleIcon />
              </CustomIconButton>
              {
                this.state[`anchorMobileTable${row.id}`] && (
                  <MobileMoreMenu
                    className="mobileMoreMenu"
                    anchorEl={this.state[`anchorMobileTable${row.id}`]}
                    keepMounted
                    open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                    onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                    // placement="left"
                    anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                  >
                    <MobileMoreMenuItem
                      // active={!this.state[`columnVisibility${tabValue}`]}
                      onClick={() => {
                        this.setState({
                          openEditUser: true, selectedUser: row, [`anchorMobileTable${row.id}`]: null, userMail: row.email, userPhone: row.phone,
                        });
                      }}
                    >
                      <EditMenu />
                      {tra('Edit')}
                    </MobileMoreMenuItem>
                  </MobileMoreMenu>
                )
              }
            </div>
          ),
        },
      ],
    };
    props.getSubusers();
    props.getUserRoleByType();
    props.getCustomers();
    // props.getAllPermissions();
    // props.getAllRoles();
    // this.innerTabChange();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.innerTabValue === 0 && nextProps.subusers !== prevState.currentData) {
      const currentData = nextProps.subusers;
      // const pageLoaded = true;
      return {
        currentData,
        // pageLoaded,
      };
    }
    if (prevState.innerTabValue === 1 && (nextProps.creditControl?.length > 0 ? nextProps.creditControl !== prevState.currentData : prevState.currentData?.length !== 0)) {
      const currentData = nextProps.creditControl;
      return {
        currentData,
      };
    }
    if (prevState.innerTabValue === 2 && (nextProps.paymentTerms?.length > 0 ? nextProps.paymentTerms !== prevState.currentData : prevState.currentData?.length !== 0)) {
      const currentData = nextProps.paymentTerms;
      return {
        currentData,
      };
    }
    if (prevState.innerTabValue === 3 && (nextProps.allRoles?.length > 0 ? nextProps.allRoles !== prevState.currentData : prevState.currentData?.length !== 0)) {
      const currentData = nextProps.allRoles;
      return {
        currentData,
      };
    }
    if (prevState.innerTabValue === 4 && (nextProps.companyCodes?.length > 0 ? nextProps.companyCodes !== prevState.currentData : prevState.currentData?.length !== 0)) {
      const currentData = nextProps.companyCodes;
      return {
        currentData,
      };
    }
    if (prevState.innerTabValue === 5 && (nextProps.allUsers?.length > 0 ? nextProps.allUsers !== prevState.currentData : prevState.currentData?.length !== 0)) {
      const currentData = nextProps.allUsers;
      return {
        currentData,
      };
    }
    return null;
  }

  innerTabChange = (event, newValue) => {
    const {
      userColumns, creditColumns, paymentColumns, rolesColumns, companyColumns, usersColumns,
    } = this.state;
    const {
      subusers, allRoles, creditControl, companyCodes, allUsers,
    } = this.props;
    this.setState({
      searchFilter: '', filter: [], innerTabValue: newValue, filterDrawer: false,
    });
    switch (newValue) {
      case 0:
        this.props.getSubusers();
        this.props.getUserRoleByType();
        this.props.getCustomers();
        this.setState({ currentData: subusers, currentColumn: userColumns });
        break;
      case 1:
        this.setState({ currentData: creditControl, currentColumn: creditColumns });
        this.props.getCreditControl();
        break;
      case 2:
        this.setState({ currentData: creditControl, currentColumn: paymentColumns });
        this.props.getPaymentTerms();
        break;
      case 3:
        this.props.getAllPermissions();
        this.props.getAllRoles();
        this.setState({ currentData: allRoles, currentColumn: rolesColumns });
        break;
      case 4:
        this.props.getCompanyCodes();
        this.props.getCreditControl();
        this.setState({ currentData: companyCodes, currentColumn: companyColumns });
        break;
      case 5:
        this.props.getAllUsers();
        this.setState({ currentData: allUsers, currentColumn: usersColumns });
        break;
      default:
        this.setState({ currentData: [], currentColumn: [] });
        break;
    }
  }

  render() {
    const {
      searchFilter, openNewPersonnel, openSwapPersonnel, innerTabValue, openNewRole, filterDrawer, filter, currentData, currentColumn,
    } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (currentData?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : currentData;
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={currentColumn || []}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <TabWrapper value={innerTabValue} innerTab style={{ width: 'unset' }} onChange={(event, newValue) => this.innerTabChange(event, newValue)} aria-label="simple tabs example">
                <TabItem value={0} label={(<p style={{ textTransform: 'capitalize' }}>{tra('User_Operations')}</p>)} />
                {
                  this.props.auth?.auth28 && (
                    <TabItem value={1} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Credit_Control')}</p>)} />
                  )
                }
                {
                  this.props.auth?.auth28 && (
                    <TabItem value={2} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Payment_Terms')}</p>)} />
                  )
                }
                {
                  this.props.auth?.auth29 && (
                    <TabItem value={3} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Roles')}</p>)} />
                  )
                }
                {
                  this.props.auth?.auth37 && (
                    <TabItem value={4} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Company_Codes')}</p>)} />
                  )
                }
                {
                  this.props.auth?.auth34 && (
                    <TabItem value={5} label={(<p style={{ textTransform: 'capitalize' }}>{tra('All_Users')}</p>)} />
                  )
                }
              </TabWrapper>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState({ filterDrawer: !filterDrawer })}>{tra('Filter')}</CustomButton>
              </div>
              {
                innerTabValue === 0 && (
                  <div className="rightButtons">
                    <CustomButton
                      onClick={() => this.setState({ openNewPersonnel: true })}
                      startIcon={<PlusButton />}
                    >
                      {tra('Create_New_Employee')}
                    </CustomButton>
                  </div>
                )
              }
              {
                innerTabValue === 1 && (
                  <div className="rightButtons">
                    <CustomButton
                      onClick={() => this.props.updateSapCreditControls()}
                      startIcon={<Reload color={Colors.white} />}
                    >
                      {tra('Update_from_SAP')}
                    </CustomButton>
                  </div>
                )
              }
              {
                innerTabValue === 2 && (
                  <div className="rightButtons">
                    <CustomButton
                      onClick={() => this.props.updateSapPaymentTerms()}
                      startIcon={<Reload color={Colors.white} />}
                    >
                      {tra('Update_from_SAP')}
                    </CustomButton>
                  </div>
                )
              }
              {
                innerTabValue === 3 && (
                  <div className="rightButtons">
                    <CustomButton
                      onClick={() => this.setState({ openNewRole: true })}
                      startIcon={<PlusButton />}
                    >
                      {tra('Create_New_Role')}
                    </CustomButton>
                  </div>
                )
              }
              {
                innerTabValue === 5 && (
                  <div className="rightButtons">
                    <CustomButton lightBlue onClick={() => this.props.getExcel('users')} startIcon={<Excel />}>{tra('Download_Excel')}</CustomButton>
                  </div>
                )
              }
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              columns={currentColumn || []}
              id="adminTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
        {
          openNewPersonnel && (
            <NewPersonnel
              open={openNewPersonnel}
              onClose={() => this.setState({ openNewPersonnel: false, editPersonnel: false }, () => this.props.setSapUser({}))}
              editPersonnel={this.state.editPersonnel}
              createUser={(data0) => this.props.createSubuser(data0)}
              editUser={(data1) => this.props.editSubuser(this.state.selectedUserId, data1)}
              getSapUser={(sapName) => this.props.getSapUser(sapName)}
              userDetail={this.props.subuserDetail}
              sapUser={this.props.sapUser}
              customers={this.props.customers}
              userRoleByType={this.props.userRoleByType}
              setToast={(msg, variant) => this.props.setToast(true, msg, variant)}
            />
          )
        }
        {
          openSwapPersonnel && (
            <SwapPersonnel
              open={openSwapPersonnel}
              onClose={() => this.setState({ openSwapPersonnel: false })}
              representatives={this.props.representatives.filter((r) => r?.id !== this.state.selectedUserId)}
              selectedUserId
              setToast={(msg, variant) => this.props.setToast(true, msg, variant)}
              userDetail={this.props.subuserDetail}
              swapCustomers={(id) => this.props.swapCustomers(id, this.state.selectedUserId)}
            />
          )
        }
        {
          openNewRole && (
            <NewRole
              open={openNewRole}
              onClose={() => this.setState({ openNewRole: false, editRole: false })}
              selectedRole
              edit={this.state.editRole}
              setToast={(msg, variant) => this.props.setToast(true, msg, variant)}
              permissions={this.props.allPermissions}
              roleDetail={this.props.allRoles.find((r) => r?.id === this.state.selectedRole)}
              saveRole={(data, id) => this.props.createRole(data, id)}
            />
          )
        }
        {
          this.state.infoDelete && (
            <Info
              open={this.state.infoDelete}
              type="Question"
              title={tra('Confirmation')}
              text={tra('The_Employee_Will_Be_Passive_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ infoDelete: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => { this.setState({ infoDelete: false }); this.props.deleteSubuser(this.state.selectedUserId); }}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
        {
          this.state.changeAlternativeName && (
            <Dialog open={this.state.changeAlternativeName} onClose={() => this.setState({ changeAlternativeName: false, factoryName: false })} maxWidth="sm" fullWidth="true" scroll="body">
              <CustomDialogContent>
                <div className="header">
                  <p><span>{this.state.changingName}</span> {tra('the_description_to_be_changed')}</p>
                </div>
                <div className="content">
                  <Input
                    type="text"
                    variant="standard"
                    label={tra('Change_Description')}
                    fullWidth
                    value={this.state.alternativeName}
                    onChange={(e) => this.setState({ alternativeName: e.target.value })}
                    autoFocus
                  />
                  <Input
                    type="text"
                    variant="standard"
                    label={tra('Change_Fabric_Description')}
                    fullWidth
                    value={this.state.factoryName}
                    onChange={(e) => this.setState({ factoryName: e.target.value })}
                  />
                </div>
                <div className="buttonWrapper">
                  <CustomButton lined onClick={() => this.setState({ changeAlternativeName: false })}>
                    {tra('Cancel')}
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      this.props.workAreaAlternative({ credit_control_id: this.state.creditId, alternative_name: this.state.alternativeName, factory_name: this.state.factoryName });
                      this.setState({ changeAlternativeName: false });
                    }}
                  >
                    {tra('Save')}
                  </CustomButton>
                </div>
              </CustomDialogContent>
            </Dialog>
          )
        }
        {
          this.state.openEditUser && (
            <Dialog open={this.state.openEditUser} onClose={() => this.setState({ openEditUser: false, factoryName: false })} maxWidth="sm" fullWidth="true" scroll="body">
              <CustomDialogContent>
                <div className="header">
                  <p><span>{this.state.selectedUser?.name} {this.state.selectedUser?.surname}</span> {tra('updating')}</p>
                </div>
                <div className="content">
                  <Input
                    type="text"
                    variant="standard"
                    label={tra('Email')}
                    fullWidth
                    value={this.state.userMail}
                    onChange={(e) => this.setState({ userMail: e.target.value })}
                    autoFocus
                  />
                  <Input
                    type="text"
                    variant="standard"
                    label={tra('Telephone')}
                    fullWidth
                    value={this.state.userPhone}
                    onChange={(e) => this.setState({ userPhone: e.target.value })}
                  />
                </div>
                <div className="buttonWrapper">
                  <CustomButton lined onClick={() => this.setState({ openEditUser: false })}>
                    {tra('Cancel')}
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      this.props.editSubuser(this.state.selectedUser?.id, { email: this.state.userMail, phone: this.state.userPhone }, 'all');
                      this.setState({ openEditUser: false });
                    }}
                  >
                    {tra('Save')}
                  </CustomButton>
                </div>
              </CustomDialogContent>
            </Dialog>
          )
        }
        {
          this.state.companyCreditControlModal && (
            <CompanyCreditControl
              open={this.state.companyCreditControlModal}
              onClose={() => this.setState({ companyCreditControlModal: false, rowData: null })}
              rowData={this.state.rowData}
              companyCodes={this.props.companyCodes}
              creditControls={this.props.creditControl}
              runService={(data) => this.props.editMatchCreditControl(data)}
            />
          )
        }
        {
          this.state.companySettingModal && (
            <CompanySetting
              open={this.state.companySettingModal}
              onClose={() => this.setState({ companySettingModal: false, rowData: null })}
              rowData={this.state.rowData}
              runService={(data) => this.props.companySettingUpdate(data)}
            />
          )
        }
      </PageWrapper>
    );
  }
}

Admin.propTypes = {
  getSubusers: PropTypes.func.isRequired,
  createSubuser: PropTypes.func.isRequired,
  subusers: PropTypes.array.isRequired,
  editSubuser: PropTypes.func.isRequired,
  deleteSubuser: PropTypes.func.isRequired,
  subuserDetail: PropTypes.object.isRequired,
  sapUser: PropTypes.object.isRequired,
  getSapUser: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  getCustomers: PropTypes.func.isRequired,
  setSapUser: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  representatives: PropTypes.array.isRequired,
  swapCustomers: PropTypes.func.isRequired,
  workAreaAlternative: PropTypes.func.isRequired,
  getUserRoleByType: PropTypes.func.isRequired,
  userRoleByType: PropTypes.array.isRequired,
  getAllPermissions: PropTypes.func.isRequired,
  allPermissions: PropTypes.array.isRequired,
  getAllRoles: PropTypes.func.isRequired,
  allRoles: PropTypes.array.isRequired,
  createRole: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getCreditControl: PropTypes.func.isRequired,
  creditControl: PropTypes.array.isRequired,
  removeCreditControl: PropTypes.func.isRequired,
  getCompanyCodes: PropTypes.func.isRequired,
  companyCodes: PropTypes.array.isRequired,
  toggleCreditLimit: PropTypes.func.isRequired,
  editMatchCreditControl: PropTypes.func.isRequired,
  companySettingUpdate: PropTypes.func.isRequired,
  getPaymentTerms: PropTypes.func.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  updateSapPaymentTerms: PropTypes.func.isRequired,
  updateSapCreditControls: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  allUsers: PropTypes.array.isRequired,
  getExcel: PropTypes.func.isRequired,
};

// Admin.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  subusers: selectSubusers(),
  subuserDetail: selectSubuserDetail(),
  sapCustomer: selectSapCustomer(),
  sapUser: selectSapUser(),
  customers: selectCustomers(),
  representatives: selectRepresentatives(),
  userRoleByType: selectUserRoleByType(),
  allRoles: selectAllRoles(),
  allPermissions: selectAllPermissions(),
  auth: selectAuth(),
  creditControl: selectCreditControl(),
  companyCodes: selectCompanyCodes(),
  paymentTerms: selectPaymentTerms(),
  allUsers: selectAllUsers(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getSubusers,
      createSubuser,
      editSubuser,
      deleteSubuser,
      getSapUser,
      getCustomers,
      setSapUser,
      setToast,
      swapCustomers,
      workAreaAlternative,
      getUserRoleByType,
      getAllPermissions,
      getAllRoles,
      createRole,
      getCreditControl,
      removeCreditControl,
      getCompanyCodes,
      toggleCreditLimit,
      editMatchCreditControl,
      companySettingUpdate,
      getPaymentTerms,
      updateSapPaymentTerms,
      updateSapCreditControls,
      getAllUsers,
      getExcel,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
