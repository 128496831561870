import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { Colors } from '../../assets/statics';
import { tra } from '../../commons/utils';

// const data2 = [
//   {
//     id: 'blue',
//     color: 'hsl(209, 100%, 31%)',
//     data: [
//       {
//         x: 'Ocak',
//         y: Math.floor(Math.random() * 40) + 1,
//       },
//       {
//         x: 'Şub',
//         y: Math.floor(Math.random() * 40) + 1,
//       },
//     ],
//   },
//   {
//     id: 'green',
//     color: 'hsl(231, 13%, 32%)',
//     data: [
//       {
//         x: 'Ocak',
//         y: Math.floor(Math.random() * 40) + 1,
//       },
//       {
//         x: 'Şub',
//         y: Math.floor(Math.random() * 40) + 1,
//       },
//     ],
//   },
// ];

const LineChart = props => (
  <ResponsiveLine
    data={props.data?.length > 0 ? props.data : []}
    colors={props.colors || [Colors.blueLight, Colors.mint, Colors.lightOrange, Colors.lightPurple]}
    margin={{
      top: 25, right: 25, bottom: 50, left: 70,
    }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false,
    }}
    theme={{
      textColor: Colors.lightBlack,
      grid: {
        line: {
          stroke: Colors.freshGrey,
        },
      },
    }}
    lineWidth={6}
    curve="catmullRom"
  // axisBottom={{
  //   orient: 'bottom',
  //   tickSize: 5,
  //   tickPadding: 5,
  //   tickRotation: 0,
  //   legend: 'transportation',
  //   legendOffset: 36,
  //   legendPosition: 'middle',
  // }}
  // axisLeft={{
  //   orient: 'left',
  //   tickSize: 5,
  //   tickPadding: 5,
  //   tickRotation: 0,
  //   legend: 'count',
  //   legendOffset: -40,
  //   legendPosition: 'middle',
  // }}
    pointSize={0}
    // pointColor={{ theme: 'color' }}
    pointBorderWidth={2}
    // pointBorderColor={{ from: 'color' }}
    pointLabelYOffset={-12}
    // colors={themeContext.graphLine}
    useMesh
    axisBottom={{ format: value => `${tra(value)}` }}
    axisLeft={{ format: value => `${value} ${tra('Ton')}` }}
    // legendLabel={datum => `${datum.id} (${datum.value})`}
    legends={[
      {
        anchor: 'bottom-left',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 45,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 160,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    // tooltip={({ id, value, color }) => (
    //   <div className="chartTooltip">
    //     <h6>
    //       asd
    //     </h6>
    //     123
    //   </div>
    // )}
    enableSlices="x"
  />
);

LineChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array,
};

LineChart.defaultProps = {
  colors: [Colors.blueLight, Colors.mint, Colors.lightOrange, Colors.lightPurple],
};

export default LineChart;
