import * as constants from './constants';
// import * as index from '../index';
// import {
//   init,
// } from './actions';

const initialState = {
  toast: { open: false, message: '', variant: 'success' },
  activeUser: {},
  appConfig: {
    successCustomer: false,
    createOrder: {},
    godMode: false,
    infoModal: {
      open: false,
      type: '',
      title: '',
      text: '',
    },
    survey: false,
  },
  customers: [],
  products: [],
  orders: [],
  deliveryPoints: [],
  productGroups: [],
  buildingSites: [],
  sapCustomer: {},
  orderDetail: {},
  subusers: [],
  subuserDetail: {},
  sapUser: {},
  customerDetail: {},
  openInvoices: [],
  invoices: [],
  payments: [],
  warnings: [],
  // creditControlAreas: [],
  orderProduct: false,
  reports: [],
  reportsOrder: [],
  workAreas: [],
  sapProduct: {},
  creditLimit: false,
  deliveries: false,
  companies: [],
  blockagesList: [],
  shippingCost: false,
  allBuildingSites: [],
  log: [],
  notSapOrder: [],
  customerByCredit: [],
  auth: {},
  userRoleByType: [],
  allRoles: [],
  allPermissions: [],
  dashboardData: {},
  showPayment: false,
  invoiceLink: '',
  announcements: [],
  dashboardChart: [],
  tonnageUpdates: [],
  creditControl: [],
  companyCodes: [],
  initCompanyCodes: [],
  paymentTerms: [],
  dashboardByCredit: {},
  excelFile: null,
  allUsers: [],
  serviceConfig: {},
  dashboardAdminData: {},
  dashboardAdminCharts: [],
  dashProductCharts: [],
  reportAdminOrder: [],
  reportAdminMonth: [],
};

export default function store(state = initialState, action) {
  const actions = action;
  switch (actions.type) {
    case constants.APP_CONFIG: {
      const appConfig = { ...state.appConfig };
      appConfig[action.key] = action.value;
      return { ...state, appConfig };
    }
    case constants.SET_TOAST: {
      const oldToast = state.toast;
      const toast = { open: action.value, message: action.message, variant: action.variant };
      if (!action.value) {
        toast.message = '';
        toast.variant = oldToast.variant;
      }
      return { ...state, toast };
    }
    case constants.SET_INIT: {
      return {
        ...state,
        cities: action.data.cities,
        countries: actions.data.countries,
        deliveryPoints: actions.data.deliveryPoints,
        factories: actions.data.factories,
        representatives: actions.data.representatives,
        activeUser: actions.data.user,
        productGroups: actions.data.productGroups,
        allBuildingSites: actions.data.buildingSites,
        // creditControlAreas: actions.data.creditControlAreas,
        workAreas: actions.data.workAreas,
        // creditControls: actions.data.creditControls,
        companies: actions.data.companyCodes,
        allProductGroup: actions.data.allProductGroup,
        auth: actions.data.auth,
        showPayment: actions.data.show_payment,
        initCompanyCodes: actions.data.companyCodes,
        kvkkText: actions.data.kvkk_text,
        orderStatus: actions.data.orderStatus,
      };
    }
    case constants.SET_ACTIVE_USER: {
      return { ...state, activeUser: action.data };
    }
    case constants.SET_CUSTOMERS: {
      return { ...state, customers: action.data };
    }
    case constants.SET_CUSTOMER_DETAIL: {
      return { ...state, customerDetail: action.data };
    }
    case constants.SET_PRODUCTS: {
      return { ...state, products: action.data };
    }
    case constants.SET_SAPCUSTOMER: {
      return { ...state, sapCustomer: action.data };
    }
    case constants.SET_ORDERS: {
      return { ...state, orders: action.data };
    }
    case constants.SET_ORDER_DETAIL: {
      return { ...state, orderDetail: action.data };
    }
    case constants.SET_ORDER_PRODUCT: {
      return { ...state, orderProduct: action.data };
    }
    case constants.SET_SUBUSERS: {
      return { ...state, subusers: action.data };
    }
    case constants.SET_SUBUSER_DETAIL: {
      return { ...state, subuserDetail: action.data };
    }
    case constants.SET_SAPUSER: {
      return { ...state, sapUser: action.name };
    }
    case constants.SET_OPEN_INVOICES: {
      return { ...state, openInvoices: action.data };
    }
    case constants.SET_INVOICES: {
      return { ...state, invoices: action.data };
    }
    case constants.SET_PAYMENTS: {
      return { ...state, payments: action.data };
    }
    case constants.SET_WARNINGS: {
      return { ...state, warnings: action.data };
    }
    case constants.SET_REPORTS: {
      return { ...state, reports: action.data };
    }
    case constants.SET_ORDER_REPORTS: {
      return { ...state, reportsOrder: action.data };
    }
    case constants.SET_SAPPRODUCT: {
      return { ...state, sapProduct: action.data };
    }
    case constants.SET_CREDIT_LIMIT: {
      return { ...state, creditLimit: action.data };
    }
    case constants.SET_DEVLIVERIES: {
      return { ...state, deliveries: action.data };
    }
    case constants.SET_BLOCKAGES: {
      return { ...state, blockagesList: action.data };
    }
    case constants.SET_BUNILDING_SITES: {
      return { ...state, buildingSites: action.data };
    }
    case constants.SET_SHIPPING_COST: {
      return { ...state, shippingCost: action.data };
    }
    case constants.SET_LOG: {
      return { ...state, log: action.data };
    }
    case constants.SET_NOT_SAP_ORDER: {
      return { ...state, notSapOrder: action.data };
    }
    case constants.SET_CUSTOMER_BY_CREDIT: {
      return { ...state, customerByCredit: action.data };
    }
    case constants.SET_USER_ROLE_BY_TYPE: {
      return { ...state, userRoleByType: action.data };
    }
    case constants.SET_ALL_ROLES: {
      return { ...state, allRoles: action.data };
    }
    case constants.SET_ALL_PERMISSIONS: {
      return { ...state, allPermissions: action.data };
    }
    case constants.SET_DASHBOARD: {
      return { ...state, dashboardData: action.data };
    }
    case constants.SET_INVOICELINK: {
      return { ...state, invoiceLink: action.data };
    }
    case constants.SET_ANNOUNCEMENT: {
      return { ...state, announcements: action.data };
    }
    case constants.SET_DASHBOARD_CHART: {
      return { ...state, dashboardChart: action.data };
    }
    case constants.SET_TONNAGE_UPDATES: {
      return { ...state, tonnageUpdates: action.data };
    }
    case constants.SET_CREDIT_CONTROL: {
      return { ...state, creditControl: action.data };
    }
    case constants.SET_COMPANY_CODES: {
      return { ...state, companyCodes: action.data };
    }
    case constants.SET_PAYMENT_TERMS: {
      return { ...state, paymentTerms: action.data };
    }
    case constants.SET_DASHBOARD_BY_CREDIT_CONTROL: {
      return { ...state, dashboardByCredit: action.data };
    }
    case constants.SET_ALL_USERS: {
      return { ...state, allUsers: action.data };
    }
    case constants.SERVICE_CONFIG: {
      return { ...state, serviceConfig: action.data };
    }
    case constants.SET_DASHBOARD_ADMIN: {
      return { ...state, dashboardAdminData: action.data };
    }
    case constants.SET_DASHBOARD_ADMIN_CHARTS: {
      return { ...state, dashboardAdminCharts: action.data };
    }
    case constants.SET_DASHBOARD_ADMIN_PRODUCT_CHARTS: {
      return { ...state, dashProductCharts: action.data };
    }
    case constants.SET_REPORT_ADMIN_ORDER: {
      return { ...state, reportAdminOrder: action.data };
    }
    case constants.SET_REPORT_ADMIN_MONTH: {
      return { ...state, reportAdminMonth: action.data };
    }
    case constants.SET_REPORT_ADMIN_BUILDING_SITES: {
      return { ...state, reportAdminBuildingSites: action.data };
    }
    default: {
      return state;
    }
  }
}
