import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { tr } from 'date-fns/esm/locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  CustomButton, CustomFade, CustomIconButton, ErrorLine,
} from '../../assets/styled';
import Input from '../Input';
import Info from './Info';
import { tra } from '../../commons/utils';
import { getCustomerByCreditControl } from '../../store/actions';
import { selectCustomerByCredit } from '../../store/selectors';

class Blockage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedWorkAreas: [],
      selectedCreditControl: [],
      selectedProduct: [],
      selectedCustomer: [],
      selectedBuildinSite: [],
      selectedStartingDate: null,
      selectedEndDate: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currentProduct && !prevState.selectedProduct.some((p) => p.sap_product_no === nextProps.currentProduct)) {
      const curData = prevState.selectedProduct;
      const data = nextProps.products.find((p) => p.sap_product_no === nextProps.currentProduct);
      curData.push(data);
      return {
        selectedProduct: curData,
      };
    }
    return null;
    // this.setState({  });
  }

  clearAll = () => {
    this.setState({
      // selectedWorkAreas: [],
      selectedCreditControl: [],
      selectedProduct: [],
      selectedCustomer: [],
      selectedStartingDate: null,
      selectedEndDate: null,
      except_this: null,
    });
  }

  blockage = () => {
    const {
      // selectedWorkAreas,
      selectedCreditControl,
      selectedProduct,
      selectedCustomer,
      selectedStartingDate,
      selectedEndDate,
      except_this,
      confirmModal,
      selectedBuildinSite,
    } = this.state;
    const startDate = selectedStartingDate;
    const endDate = selectedEndDate;
    // const x = startDate?.setHours(0, 0, 0, 0);
    const y = endDate?.setHours(23, 59, 59, 0);
    const data = {
      credit_controls: selectedCreditControl,
      products: selectedProduct,
      customers: selectedCustomer,
      block_start_date: startDate,
      block_end_date: endDate,
      building_sites: selectedBuildinSite,
      except_this,
    };
    if (selectedCreditControl?.length > 0 && selectedProduct.length > 0 && selectedStartingDate instanceof Date && selectedEndDate instanceof Date && this.props.customerByCredit?.length > 0) {
      if (confirmModal) {
        this.props.blockageProducts(data);
        this.setState({ confirmModal: false });
        this.clearAll();
        this.props.onClose();
      } else {
        this.setState({ confirmModal: true });
      }
    } else if (this.state.selectedCreditControl?.length > 0 && !(this.props.customerByCredit?.length > 0)) {
      // this.props.setToast(tra('Please_select_a_credit_control_area_with_a_customer'), 'warning');
      this.setState({ errorMessage: tra('Please_select_a_credit_control_area_with_a_customer'), isError: true, reRender: Math.random() });
    } else {
      // this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning');
      this.setState({ errorMessage: tra('Please_Fill_in_the_mandatory_fields'), isError: true, reRender: Math.random() });
    }
  }

  getCustomerWithCredit = (newValue) => {
    const creditIdList = [];
    const x = newValue?.map((c) => creditIdList?.push(c.id));
    this.props.getCustomerByCreditControl({ ids: creditIdList });
  }

  close = () => {
    const {
      selectedCreditControl,
      selectedProduct,
      selectedCustomer,
      customerByCredit,
      selectedStartingDate,
      selectedEndDate,
    } = this.state;
    if (selectedCreditControl?.length > 0 || selectedProduct?.length > 0 || selectedCustomer?.length > 0 || customerByCredit?.length > 0 || selectedStartingDate || selectedEndDate) {
      this.setState({ sureModal: true });
    } else {
      this.clearAll();
      this.props.onClose();
    }
  }

  render() {
    const {
      open, products, customerByCredit,
    } = this.props;
    const {
      selectedStartingDate, selectedEndDate, except_this, errorMessage, isError,
    } = this.state;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{tra('Define_Blockage')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content blokage">
                {errorMessage && errorMessage?.length && (
                  <ErrorLine key={this.state.reRender}>
                    {errorMessage}!
                  </ErrorLine>
                )}
                <div className="formWrapper">
                  <Input
                    type="autoComplete"
                    placeholder={`${tra('Select_Credit_Control')} *`}
                    variant="standard"
                    data={this.props.creditControls}
                    fullWidth
                    value={this.state.selectedCreditControl || []}
                    selectAll
                    multiple
                    onChange={(e, newValue) => {
                      this.setState({ selectedCreditControl: newValue, selectedCustomer: null });
                      this.getCustomerWithCredit(newValue);
                    }}
                    error={isError && !(this.state.selectedCreditControl?.length > 0)}
                  />
                  <Input
                    type="autoComplete"
                    placeholder={tra('Choose_Customer_Number')}
                    variant="standard"
                    // data={customers}
                    data={customerByCredit}
                    fullWidth
                    value={this.state.selectedCustomer || []}
                    selectAll
                    multiple
                    onChange={(e, newValue) => {
                      this.setState({ selectedCustomer: newValue });
                      if (newValue?.length === 0) {
                        this.setState({ except_this: false });
                      }
                      if (newValue?.length > 0) {
                        this.setState({ selectedBuildinSite: [] });
                      }
                    }}
                    error={isError && !(this.props.customerByCredit?.length > 0)}
                  />
                  <Input
                    type="checkbox"
                    title={tra('All_Except_Selected_Customers')}
                    variant="standard"
                    fullWidth
                    value={except_this || false}
                    disabled={!(this.state.selectedCustomer?.length > 0) || this.state.selectedBuildinSite?.length > 0}
                    onChange={(e) => this.setState({ except_this: e.target.checked })}
                  />
                  {
                    this.state.selectedCustomer?.length === 1 && (
                      <Input
                        type="autoComplete"
                        placeholder={tra('Choose_BuildingSite')}
                        variant="standard"
                        // data={customers}
                        data={this.state.selectedCustomer?.[0]?.BuildingSites || []}
                        fullWidth
                        value={this.state.selectedBuildinSite || []}
                        selectAll
                        multiple
                        onChange={(e, newValue) => {
                          this.setState({ selectedBuildinSite: newValue }); if (newValue?.length > 0 && except_this) this.setState({ except_this: false });
                        }}
                      />
                    )
                  }
                  <Input
                    type="autoComplete"
                    placeholder={`${tra('Select_Product_Code')} *`}
                    variant="standard"
                    data={products}
                    fullWidth
                    value={this.state.selectedProduct || []}
                    selectAll
                    multiple
                    onChange={(e, newValue) => this.setState({ selectedProduct: newValue })}
                    error={isError && !(this.state.selectedProduct?.length > 0)}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label={`${tra('Block_Start_Date')} *`}
                      value={selectedStartingDate}
                      onChange={(e) => this.setState({ selectedStartingDate: e })}
                      autoOk
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      // style={{ paddingTop: 10 }}
                      minDate={new Date()}
                      maxDate={this.state.selectedEndDate}
                      error={isError && !(this.state.selectedStartingDate)}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label={`${tra('Block_End_Date')} *`}
                      value={selectedEndDate}
                      onChange={(e) => this.setState({ selectedEndDate: e })}
                      autoOk
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      // style={{ paddingTop: 10 }}
                      minDate={this.state.selectedStartingDate}
                      error={isError && !(this.state.selectedEndDate)}
                    />
                  </MuiPickersUtilsProvider>
                  <CustomButton simple onClick={() => this.close()} style={{ marginLeft: 0 }}>{tra('Cancel')}</CustomButton>
                  <CustomButton onClick={() => this.blockage()}>{tra('Complete')}</CustomButton>
                </div>
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          this.state.confirmModal && (
            <Info
              open={this.state.confirmModal}
              type="Question"
              title={tra('Confirmation')}
              text={tra('Selected_products_will_be_blocked_for_the_Customers_you_have_selected_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ confirmModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.blockage()}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <Info
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => { this.clearAll(); this.props.onClose(); }}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

Blockage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  // customers: PropTypes.array.isRequired,
  blockageProducts: PropTypes.object.isRequired,
  // setToast: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  currentProduct: PropTypes.string.isRequired, // livecycle
  // workAreas: PropTypes.array.isRequired,
  creditControls: PropTypes.array.isRequired,
  getCustomerByCreditControl: PropTypes.func.isRequired,
  customerByCredit: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({
  customerByCredit: selectCustomerByCredit(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getCustomerByCreditControl,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Blockage);
