import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  ClickAwayListener, Dialog, Grid, IconButton,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import {
  CustomButton,
  CustomTextField,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  SearchBar,
  CustomIconButton,
  TabWrapper,
  TabItem,
  CustomDialogContent,
  ErrorLine,
} from '../assets/styled';
import Table from '../components/Table';
import {
  CheckFilledCircle,
  CrossFilledCircle,
  MobileTripleIcon,
} from '../assets/icons';
import {
  getTonnageUpdates,
  confirmDeclineOrder,
} from '../store/actions';
import {
  selectAuth,
  selectTonnageUpdates,
} from '../store/selectors';
// import Info from '../components/Modals/Info';
import { tra } from '../commons/utils';
import CustomFilter from '../components/CustomFilter';
import Input from '../components/Input';
import Info from '../components/Modals/Info';
import { Colors } from '../assets/statics';

class OrderTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerTabValue: 0,
      statusType: 1,
    };
    props.getTonnageUpdates();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.innerTabValue === 0 && nextProps.tonnageUpdates !== prevState.currentData) {
      const currentData = nextProps.tonnageUpdates?.filter((item) => item.tonnage_update === true);
      return {
        currentData,
      };
    }
    if (prevState.innerTabValue === 1 && nextProps.tonnageUpdates !== prevState.currentData) {
      const currentData = nextProps.tonnageUpdates?.filter((item) => item.cancel_request === true);
      return {
        currentData,
      };
    }
    return null;
  }

  innerTabChange = (event, newValue) => {
    const { tonnageUpdates } = this.props;
    // this.setState({ filter: '' });
    this.setState({ innerTabValue: newValue, filter: [] });
    // localStorage.setItem('Dashboard', newValue);
    switch (newValue) {
      case 0:
        this.setState({ currentData: tonnageUpdates?.filter((item) => item.tonnage_update === true) });
        break;
      case 1:
        this.setState({ currentData: tonnageUpdates?.filter((item) => item.cancel_request === true) });
        break;
      default:
        this.setState({ currentData: [] });
        break;
    }
  }

  setClear = () => {
    this.setState({
      isError: false,
      errorMessage: '',
      orderData: null,
      declaration: null,
      transactionStatus: null,
      confirmModal: false,
      approveModal: false,
    });
  }

  saveFunc = () => {
    const {
      transactionStatus, orderData, declaration, confirmModal, innerTabValue,
    } = this.state;
    const data = {
      type: innerTabValue + 1,
      confirmation: (transactionStatus === 1 ? true : transactionStatus === 2 ? false : null),
      message: declaration,
    };
    if ((transactionStatus === 1 || transactionStatus === 2) && orderData?.id && declaration) {
      if (confirmModal) {
        this.props.confirmDeclineOrder(orderData?.id, data);
        this.setClear();
        setTimeout(() => {
          this.props.getTonnageUpdates();
          if (this.state.innerTabValue === 0) {
            this.setState({ currentData: this.props.tonnageUpdates?.filter((item) => item.tonnage_update === true) });
          } else {
            this.setState({ currentData: this.props.tonnageUpdates?.filter((item) => item.cancel_request === true) });
          }
        }, 500);
      } else {
        this.setState({ confirmModal: true });
      }
    } else if ((!orderData?.id || !(transactionStatus === 1 || transactionStatus === 2)) && transactionStatus && declaration) {
      this.setState({ isError: true, errorMessage: tra('An_error_has_occurred') });
    } else {
      this.setState({ isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields') });
    }
  }

  render() {
    const {
      searchFilter, filterDrawer, filter, innerTabValue, currentData,
    } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (currentData?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : currentData;
    const tonnagesColumns = [
      {
        name: 'order_status_name',
        text: tra('Status'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'id',
        text: tra('Order_Number'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'sap_order_no',
        text: tra('SAP_Order_Number'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value) => (
          value || '-'
        ),
      },
      {
        name: 'customer',
        text: tra('Customer_Name'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'created_user_name',
        text: tra('Order_Creator'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'product_name',
        text: tra('Product_Name'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'delivery_point_name',
        text: tra('Delivery_Place'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'total_amount',
        text: `${tra('Total_Quantity')}(${tra('Ton')})`,
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value) => (
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            suffix={` ${tra('Ton')}`}
            renderText={(value1, props) => <div {...props}>{value1}</div>}
          />
        ),
      },
      {
        name: 'tonnage_update_amount',
        text: tra('Tonnage_Increase_Amount'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        hidden: innerTabValue === 1,
        customBody: (value) => (
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            suffix={` ${tra('Ton')}`}
            renderText={(value1, props) => <div {...props}>{value1}</div>}
          />
        ),
      },
      {
        name: 'payment_condition_name',
        text: tra('Payment_Condition'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'date',
        text: tra('Shipping_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
      },
      {
        name: 'createdAt',
        text: tra('Create_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
      },
      {
        name: 'Action',
        text: tra('Action'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: false,
        type: 'action',
        disableSetting: false,
        customBody: (value, columnName, index, row) => (
          <div className="tableButton">
            <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
              <MobileTripleIcon />
            </CustomIconButton>
            {
              this.state[`anchorMobileTable${row.id}`] && (
                <MobileMoreMenu
                  className="mobileMoreMenu"
                  anchorEl={this.state[`anchorMobileTable${row.id}`]}
                  keepMounted
                  open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                  onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                  // placement="left"
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                  disablePortal={false}
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'scrollParent',
                    },
                    arrow: {
                      enabled: true,
                      element: this.state[`anchorMobileTable${row.id}`],
                    },
                  }}
                >
                  <MobileMoreMenuItem
                    // active={!this.state[`columnVisibility${tabValue}`]}
                    onClick={() => {
                      this.setState({
                        transactionStatus: 1,
                        orderData: row,
                        approveModal: true,
                        [`anchorMobileTable${row.id}`]: null,
                      });
                    }}
                  >
                    <CheckFilledCircle />
                    {tra('Approve')}
                  </MobileMoreMenuItem>
                  <MobileMoreMenuItem
                    // active={!this.state[`columnVisibility${tabValue}`]}
                    onClick={() => {
                      this.setState({
                        transactionStatus: 2,
                        orderData: row,
                        approveModal: true,
                        [`anchorMobileTable${row.id}`]: null,
                      });
                    }}
                  >
                    <CrossFilledCircle />
                    {tra('Reject')}
                  </MobileMoreMenuItem>
                </MobileMoreMenu>
              )
            }
          </div>
        ),
      },
    ];
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={tonnagesColumns || []}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <TabWrapper value={innerTabValue} innerTab style={{ width: 'unset' }} onChange={(event, newValue) => this.innerTabChange(event, newValue)} aria-label="simple tabs example">
                <TabItem value={0} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Tonnage_Update')}</p>)} />
                <TabItem value={1} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Order_Cancellation')}</p>)} />
                {
                  // this.props.auth?.auth29 && <TabItem label={(<p style={{ textTransform: 'capitalize' }}>{tra('Tonnage_Increase')}</p>)} />
                }
              </TabWrapper>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState({ filterDrawer: !filterDrawer })}>{tra('Filter')}</CustomButton>
              </div>
              {/* <div className="rightButtons">
                <CustomButton
                  onClick={() => this.setState({ openNewPersonnel: true })}
                  startIcon={<PlusButton />}
                >
                  {tra('Create_New_Employee')}
                </CustomButton>
              </div> */}
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              columns={tonnagesColumns.filter((c) => !c.hidden) || []}
              id="reportsTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
        {
          this.state.approveModal && (
            <Dialog open={this.state.approveModal} onClose={() => this.setClear()} maxWidth="sm" fullWidth="true" scroll="body">
              <CustomDialogContent style={{ overflow: 'hidden', position: 'relative' }}>
                <div className="header" style={{ paddingTop: '15px' }}>
                  <p>{`${tra('Order')} ${innerTabValue === 0 ? tra('Tonnage_Update') : tra('Order_Cancellation')}`}</p>
                  <IconButton size="medium" style={{ border: 'none', borderRadius: '6px' }} onClick={(e) => this.setState({ changeLanguage: e.currentTarget })}>
                    <Close color={Colors.lightBlack} onClick={() => this.setClear()} />
                  </IconButton>
                </div>
                <div className="content">
                  {this.state.errorMessage && this.state.errorMessage?.length && (
                    <ErrorLine key={this.state.reRender}>
                      {this.state.errorMessage}!
                    </ErrorLine>
                  )}
                  <Input
                    type="select"
                    variant="standard"
                    label={tra('Status')}
                    fullWidth
                    data={[{ id: 1, name: tra('Approve') }, { id: 2, name: tra('Reject') }]}
                    value={this.state.transactionStatus}
                    onChange={(e) => this.setState({ transactionStatus: e.target.value })}
                    error={this.state.isError && !this.state.transactionStatus}
                    // success={this.state.transactionStatus === 1}
                  />
                  {
                    // this.state.transactionStatus === 1 && innerTabValue === 0 && (
                    //   <Input
                    //     type="number"
                    //     variant="standard"
                    //     label={tra('Quanta')}
                    //     fullWidth
                    //     value={this.state.quanta}
                    //     onChange={(e) => this.setState({ quanta: e.value })}
                    //     error={this.state.isError && !this.state.quanta && innerTabValue === 0 && this.state.transactionStatus === 1}
                    //     autoFocus={this.state.transactionStatus === 1}
                    //   />
                    // )
                  }
                  <Input
                    type="text"
                    variant="standard"
                    label={tra('Description')}
                    fullWidth
                    multiline
                    row={4}
                    value={this.state.declaration}
                    onChange={(e) => this.setState({ declaration: e.target.value })}
                    error={this.state.isError && !this.state.declaration}
                    autoFocus={this.state.transactionStatus === 2}
                  />
                </div>
                <div className="buttonWrapper">
                  <CustomButton lined onClick={() => this.setClear()}>
                    {tra('Cancel')}
                  </CustomButton>
                  <CustomButton onClick={() => { this.saveFunc(); this.setState({ reRender: Math.random() }); }}>
                    {tra('Save')}
                  </CustomButton>
                </div>
              </CustomDialogContent>
            </Dialog>
          )
        }
        {
          this.state.confirmModal && (
            <Info
              open={this.state.confirmModal}
              type={this.state.transactionStatus === 1 ? 'Success' : 'Error'}
              title={this.state.transactionStatus === 1 ? tra('Confirmation') : tra('Rejection')}
              text={this.state.transactionStatus === 1 ? tra('The_order_update_request_will_be_approved_Do_you_confirm') : tra('Order_update_request_will_be_rejected_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ confirmModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.saveFunc()}>{tra('Save')}</CustomButton>)}
            />
          )
        }
      </PageWrapper>
    );
  }
}

OrderTransactions.propTypes = {
  getTonnageUpdates: PropTypes.func.isRequired,
  tonnageUpdates: PropTypes.array.isRequired,
  confirmDeclineOrder: PropTypes.func.isRequired,
};

// OrderTransactions.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  // dashboardData: selectDashboard(),
  auth: selectAuth(),
  tonnageUpdates: selectTonnageUpdates(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getTonnageUpdates,
      confirmDeclineOrder,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderTransactions);
