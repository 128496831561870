import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Danger, Logout, NoAnnouncement } from '../assets/icons';
import {
  AnnouncementBox,
  PageWrapper,
} from '../assets/styled';
import { selectWarnings } from '../store/selectors';
import { getWarnings } from '../store/actions';
import { tra } from '../commons/utils';

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.getWarnings();
  }

  render() {
    return (
      <PageWrapper>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <>
              {
                this.props.warnings && this.props.warnings.map((warn) => (
                  <AnnouncementBox seeAll>
                    <i>{warn.type === 'WARNING' ? <Logout /> : <Danger />}</i>
                    <div className="content">
                      <p>{warn.type}</p>
                      <span>{warn.message}</span>
                      <h5>22.07.2021</h5>
                    </div>
                  </AnnouncementBox>
                ))
              }
              {
                this.props.warnings && this.props.warnings.length === 0 && (
                  <div className="noData">
                    <NoAnnouncement />
                    <p>{tra('No_Announcement')}</p>
                  </div>
                )
              }
              {/* <AnnouncementBox seeAll>
                <i><Logout /></i>
                <div className="content">
                  <p>Sipariş Teslimatı</p>
                  <span>05 Temmuz 2015’te vermiş olduğunuz sipariş teslim edilmek üzere yola çıkmıştır.</span>
                  <h5>22.07.2021</h5>
                </div>
              </AnnouncementBox>
              <AnnouncementBox seeAll>
                <i><Danger /></i>
                <div className="content">
                  <p>Dikkat! Limit</p>
                  <span>Limitiniz işlem yapmak için düşük seviyededir.</span>
                  <div className="buttonWrapper">
                    <CustomButton white>Detaylar</CustomButton>
                    <CustomButton>Yükselt</CustomButton>
                  </div>
                  <h5>22.07.2021</h5>
                </div>
              </AnnouncementBox>
              <AnnouncementBox seeAll>
                <i><Logout /></i>
                <div className="content">
                  <p>Sipariş Teslimatı</p>
                  <span>05 Temmuz 2015’te vermiş olduğunuz sipariş teslim edilmek üzere yola çıkmıştır.</span>
                  <h5>22.07.2021</h5>
                </div>
              </AnnouncementBox> */}
            </>
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

Announcement.propTypes = {
  // getDashboard: PropTypes.func.isRequired,
  // dashboardData: PropTypes.object.isRequired,
  warnings: PropTypes.array.isRequired,
  getWarnings: PropTypes.func.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  // dashboardData: selectDashboard(),
  warnings: selectWarnings(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // getDashboard,
      getWarnings,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
