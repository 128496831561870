export default {
  Ortak: {
    Filter: 'Filter',
    Confirmation: 'Confirmation',
    The_Employee_Will_Be_Passive_Do_you_confirm: 'The Employee Will Be Passive. Do you confirm?',
    Customer_will_be_inactive_Do_you_confirm: 'Customer will be inactive. Do you confirm?',
    Cancel: 'Cancel',
    Accept: 'Accept',
    Cimsa_Customer_Portal: 'Fercim Customer Portal',
    Welcome_to_Çimsa_Customer_Portal: 'Welcome to Fercim Customer Portal',
  },
  Header: {
    Overview: 'Overview',
    Welcome_to_Cimsa_Admin_Screen: 'Welcome to Fercim Admin Screen',
    Announcements: 'Announcements',
    No_Announcement: 'No Announcement',
    Settings: 'Settings',
  },
  Widgets: {
    Total_Customers: 'Total Customers',
    Total_Order_Quantity: 'Total Order Quantity',
    Orders_in_Delivery: 'Orders in Delivery',
    Compared_to_last_month: 'Compared to last month',
    Orders_Report: 'Orders Report',
  },
  Admin: {
    Create_New_Employee: 'Create New Employee',
  },
  Customers: {
    Must_be_created: 'Must be created',
    Download_Excel: 'Download Excel',
    Create_Customer: 'Create Customer',
  },
  Dash: {
    Overdue_Debt: 'Overdue Debt',
    Collection_Average_Maturity: 'Collection Average Maturity',
    available_Limits: 'Available Limits',
    Todays_Orders: 'Todays Orders',
    Piece: 'Piece',
    Day: 'Day',
    Balance: 'Balance',
    Low_Limit: 'Low Limit',
    Insufficient_Limit: 'Insufficient Limit',
    Payment_Information: 'Payment Information',
    Expected_Transactions: 'Expected Transactions',
    See_All: 'See All',
    Current_Account_Information: 'Current Account Information',
    Code: 'Code',
    Account_Name: 'Account Name',
    Group: 'Group',
    District: 'District',
    Province: 'Province',
    Recent_Transactions: 'Recent Transactions',
  },
  Login: {
    Password_Reset: 'Reset Password',
    Please_enter_your_email_address_to_process: 'Please enter your email address to process',
    Email: 'Email',
    Send_Reset_Email: 'Send Reset Email',
    Please_enter_the_new_password_you_want_to_create: 'Please enter the new password you want to create',
    Password: 'Password',
    Password_Again: 'Password Again',
    Save: 'Save',
    I_accept_the_Personal_Data_Protection_Text: 'I accept the Personal Data Protection Text',
    Please_enter_a_valid_email_address: 'Please enter a valid email address',
    Your_Password_Does_Not_Match: 'Your password does not match',
    Your_password_must_be_at_least_8_digits: 'Your password must be at least 8 digits',
    Please_log_in_to_the_system_by_entering_the_username_given_to_you: 'Please log in to the system by entering the username given to you',
    Enter_the_code_sent_to_your_phone_number_or_email: 'Enter the code sent to your phone number or email',
    Login: 'Login',
    Forget_Password: 'Forget Password?',
    Send_Code_Again: 'Send Code Again',
  },
  Invoices: {
    Expense_Statistics: 'Expense Statistics',
    Total_Invoice: 'Total Invoice',
    Guarantee: 'Guarantee',
    Hypothec: 'Hypothec',
    search_for_Invoice_number_product: 'Search invoice and number product',
    Starting_Date: 'Starting Date',
    End_Date: 'End Date',
    It_is_for_informational_purposes_only_It_has_no_financial_value: 'It is for informational purposes only It has no financial value',
    Open_Invoices: 'Open Invoices',
    Paid: 'Paid',
    Cash_Collection: 'Cash Collection',
    Credit_Card: 'Credit Card',
    Check_Information: 'Check Information',
    DDS_Information: 'DDS Information',
    Transfers: 'Transfers',
  },
  Order: {
    Order_Now: 'Order Now',
    Order_Status: 'Order Status',
    Order_Date: 'Order Date',
    Tonnage_Request_Sent: 'Tonnage Request Sent',
    Request_Tonnage_Increase: 'Request Tonnage Increase',
    Order_Detail: 'Order Detail',
    Repeat_Order: 'Repeat Order',
    Cancel_Order: 'Cancel Order',
    Order_Stage: 'Order Stage',
    Order_Ref_ID: 'Order Ref ID',
    Product: 'Product',
    Delivery_Time: 'Delivery Time',
    Ton: 'Ton',
    There_is_no_order: 'There is no order',
    Tonnage_Increase: 'Tonnage Increase',
    Your_tonnage_increase_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm: 'Your tonnage increase request will be forwarded to the sales operation team. Do you confirm?',
    Continue: 'Continue',
    Order_Will_Be_Canceled: 'Order Will Be Canceled',
    Your_order_cancellation_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm: 'Your order cancellation request will be forwarded to the sales operation team. Do you confirm?',
    Approved: 'Approved',
    On_Its_Way: 'On the way',
    Was_Delivered: 'Delivered',
    Delivery_Address: 'Delivery address',
    Tonnage_Increase_Will_Be_Requested: 'Tonnage increase will be requested',
  },
  Product: {
    Product_No: 'Product No.',
    Product_Group: 'Product group',
    Product_Type: 'Product type',
    Product_Name: 'Product name',
    Product_Description: 'Product description',
    Change_Product_Description: 'Change Product Description',
    Define_Blockage: 'Define Blockage',
    Search_Product: 'Search Product',
    New_Product: 'New Product',
    the_description_to_be_changed: 'The description of the product will be changed',
    Change_Description: 'Change description',
  },
  SideBar: {
    Order: 'Order',
    Invoices_Screen: 'Invoices screen',
    Payments_Screen: 'Payments screen',
    Coverage_Information: 'Coverage information',
    Add_Sub_User: 'Add sub user',
    Reports: 'Reports',
    Orders_Report: 'Orders report',
    Tonnage_Report: 'Tonnage report',
    Transaction_Logs_Report: 'Transaction logs report',
    Cus_Rep_Match_Report: 'Customer representative matching reports',
    Payments_Report: 'Payments report',
    Products: 'Products',
    Cement: 'Cement',
    Ready_Mixed_Concrete: 'Ready Mixed Concrete',
    Create_Announcement: 'Create Announcement',
    Management_Screen: 'Management Screen',
    Customer_Management: 'Customer Management',
    Çimsa_Admin_Screen: 'Cimsa Admin Screen',
    y2021_All_Rights_Reserved: '2021 All Rights Reserved',
    Made_with: 'Made with',
    by_MDP_Group: 'By MDP Group',
    Made_with_by_MDP_Group: 'Made with by MDP Group',
  },
  Columns: {
    Order_Number: 'Order Number',
    Customer: 'Customer',
    Customers: 'Customers',
    Customer_No: 'Customer No',
    Date: 'Date',
    Quanta: 'Quanta',
    Situation: 'Situation',
    Cancelled: 'Cancelled',
    Action: 'Action',
    Edit: 'Edit',
    Manage: 'Manage',
    Make_Active: 'Make Active',
    Make_Passive: 'Make Passive',
    Active: 'Active',
    Passive: 'Passive',
    Authorized_Person: 'Authorized Person',
    Authorized_Phone: 'Authorized Phone',
    Authorized_Email: 'Authorized Email',
    Contact_Info: 'Contact Info',
    Business_Area: 'Business Area',
    Sales_Representative: 'Sales Representative',
    Type: 'Type',
    Pending: 'Pending',
    Amount: 'Amount',
    Status: 'Status',
    Pay: 'Pay',
    View: 'View',
    Print: 'Print',
    Invoice_No: 'Invoice No',
    Reference: 'Reference',
    Total_Amount: 'Total Amount',
    Currency: 'Currency',
    Invoice_Date: 'Invoice Date',
    Posting_Date: 'Posting Date',
    Explanation: 'Explanation',
    File_Number: 'File number',
    Credit_Control: 'Credit check',
    Payment_Status: 'Payment Status',
    Due_Date: 'Due Date',
    Clearing_Document: 'Clearing Document',
    Document_Type: 'Document Type',
    Tracking_No: 'Tracking no.',
    External_Delivery_Id: 'Foreign Delivery No.',
    Material: 'Material',
    Material_Text: 'Material Information',
    Plaque: 'Plaque',
    Sub_Packaging: 'Prepackaged',
    User_Deleted: 'User deleted',
    User_Type: 'User type',
    User_Name: 'User name',
    User_Surname: 'User surname',
    Telephone: 'Phone',
    Transactions: 'Transactions',
    Authorize: 'Authorize',
    Add_New_User: 'Add new user',
  },
  charts: {
    chart_date: 'Chart date',
  },
  Table: {
    Cancel: 'Cancel',
    Delete_Row: 'Delete row',
    Edit_Row: 'Edit row',
    End: 'End',
    found: 'found.',
    Next: 'Next',
    No_Data: 'No data...',
    Prev: 'Previous',
    Search_Data: 'Search data',
    Save_Row: 'Save row',
    Starting: 'Start',
    table_pagination: 'displaying [var1] and [var2] from document [var0]...',
    Clear: 'Clear',
  },
  Modals: {
    Block_Create_Date: 'Block Create Date',
    Please_Fill_in_the_mandatory_fields: 'Please fill in the required fields.',
    Select_Business_Areas: 'Select Business Areas',
    Select_Product_Code: 'Select Product Code',
    Choose_Customer_Number: 'Choose Customer Number',
    All_Except_Selected_Customers: 'All Except Selected Customers',
    Block_Start_Date: 'Block Start Date',
    Block_End_Date: 'Block End Date',
    Complete: 'Complete',
    Selected_products_will_be_blocked_for_the_Customers_you_have_selected_Do_you_confirm: 'Selected products will be blocked for the Customers you have selected. Do you confirm?',
    Enter_hours_and_quantities: 'Enter hours and quantities!',
    Available_Credit: 'Available Credit',
    Delivery_Method: 'Delivery method',
    Goods_Delivery_Place: 'Goods Delivery Place',
    Payment_Condition: 'Payment condition',
    Select_the_requested_quantity_and_shipping_date_and_time: 'Select the requested quantity and shipping date and time.',
    Time: 'Time',
    Add_new_quantity_shipping_time: 'Add new quantity, shipping time',
    Select_Time: 'Select Time',
    Complete_Order: 'Complete Order',
    Check_Credit: 'Check Credit',
    An_order_will_be_created_for_the_product_you_selected_Do_you_confirm: 'An order will be created for the product you selected. Do you confirm?',
    Done: 'Done',
    Shipping_Date: 'Shipping Date',
    Order_Summary: 'Order Summary',
    Delivery_1_Product: 'Delivery, 1 Product',
    Building_Site: 'Building site',
    Do_you_confirm: 'Do you confirm?',
    Tonnage_Increase_Request_Sent: 'Tonnage Increase Request Sent',
    PDPA_Approval: 'PDPA Approval',
    PDPA_Text: 'PDPA Text',
    Candidate_Clarification_Text_on_the_Protection_of_Your_Personal_Data: 'Candidate clarification text on the protection of your personal data',
    I_have_read_and_I_approve: 'I have read and I approve',
    Edit_Customer: 'Edit customer',
    SAP_Customer_Number: 'SAP customer number',
    Bring: 'Bring',
    Customer_Name: 'Customer name',
    Address: 'Address',
    Authorized_Person_Name: 'Authorized person name',
    Authorized_Person_Surname: 'Authorized person surname',
    Authorized_Person_Email: 'Authorized person email',
    Authorized_Person_Telephone: 'Authorized person telephone',
    Customer_Representative: 'Customer representative',
    Verification_Method: 'Verification method',
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    EMAIL_AND_SMS: 'SMS AND EMAIL',
    Add_Customer: 'Add Customer',
    Save_Edit: 'Save Edit',
    Create_New_Customer: 'Create New Customer',
    Customer_will_be_registered_Do_you_confirm: 'Customer will be registered. Do you confirm?',
    Attention: 'Attention!',
    If_you_exit_without_saving_your_data_will_be_lost: 'If you exit without saving, your data will be lost.',
    Admin: 'Admin',
    Edit_Employee: 'Edit Employee',
    Create_New_Employee: 'Create New Employee',
    SAP_Username: 'SAP Username',
    Employee_Name: 'Employee Name',
    Employee_Surame: 'Employee Surname',
    Employee_Type: 'Employee Type',
    Represented_Customers: 'Represented Customers',
    Employee_will_be_arranged_Do_you_confirm: 'Employee will be arranged. Do you confirm?',
    A_New_Employee_Will_Be_Created_Do_you_confirm: 'A new employee will be created. Do you confirm?',
    SAP_Product_Number: 'SAP Product Number',
    A_new_product_will_be_created_Do_you_confirm: 'A new product will be created. Do you confirm?',
    Customer_Admin: 'Customer Admin',
    Master_Account_Cannot_Be_Changed: 'Master account cannot be changed',
    Edit_Subser: 'Edit Subser',
    Create_Subuser: 'Create Subuser',
    Subuser_Type: 'Subuser Type',
    Subuser: 'Subuser',
    Deactivate_User: 'Deactivate User',
    Activate_User: 'Activate User',
    Add_User: 'Add User',
    Subuser_Will_Be_Active: 'Subuser will be active',
    Subuser_To_Be_Edited: 'Subuser to be edited',
    Create_New_Subuser: 'Create new subuser.',
    Subuser_will_be_activated_Do_you_confirm: 'Subuser will be activated. Do you confirm?',
    Subuser_Will_Be_Registered_Do_You_Confirm: 'Subuser will be registered. Do you confirm?',
    Subuser_To_Be_Passive_Do_You_Confirm: 'Subuser to be passive. Do you confirm?',
    Subuser_To_Be_Edited_Do_you_Confirm: 'Subuser to be edited. Do you confirm?',
  },
  Saga: {
    Could_not_establish_a_connection_with_the_server: 'Could not establish a connection with the server',
    Preparing_for_download: 'Preparing for download',
    Customer_Edited: 'Customer edited',
    Failed_to_Edit_Customer: 'Failed to edit customer',
    Error: 'Error',
    Successful: 'Successful',
    Failed_to_Retrieve_Customer_Information: 'Failed to retrieve customer information',
    Failed_to_Retrieve_User_Information: 'Failed to retrieve user information',
    Order_Created: 'Order created',
    Your_password_has_been_successfully_sent_to_your_email_Please_check_your_mail_to_login: 'Your password has been successfully sent to your email. Please check your mail to login.',
    Your_Password_Has_Been_Changed_Successfully: 'Your password has been changed successfully',
    There_was_a_problem_during_the_password_process: 'There was a problem during the password process',
    User_Created: 'User created',
    Problem_Occurred_While_Creating_User: 'Problem occurred while creating user',
    User_Edited: 'User edited',
    Failed_to_User_Edit: 'Failed to user edit',
    User_Status_Changed: 'User status changed',
    Failed_to_User_Status_Changed: 'Failed to user status changed',
    Failed_to_Product_Fetch_from_SAP_Service: 'Failed to product fetch from SAP service',
    An_error_occurred_while_fetching_Credit_Limit: 'An error occurred while fetching credit limit',
  },
  Utils: {
    now: 'now',
    minute_ago: 'minute ago',
    hours_ago: 'hours ago',
    day_ago: 'day ago',
    week_ago: 'week ago',
    month_ago: 'month ago',
    years_ago: 'years ago',
    minutes_left: 'minutes left',
    minutes: 'minutes',
    hours: 'hours',
    gün: 'day',
    week: 'week',
    month: 'month',
    years: 'years',
    left: 'left',
    Invalid_token: 'Invalid token',
    File_Downloaded_Successfully: 'Files downloaded successfully',
  },
  new: {
    Blockage_List: 'Blockage List',
    Close: 'Close',
    write: 'write',
    Search: 'Search',
    Exit: 'Exit',
    Total: 'Total',
    Delivery_Place: 'Place of Delivery',
    New_Order: 'New Order',
    Previous_Orders: 'Previous Orders',
    Change_Company: 'Change Company',
    Block_Removed: 'Block Removed',
    Failed_to_Block_Removing: '  Unable to remove blockage!',
    Order_Creation: 'Order Creation',
    Back: 'Back',
    Remove: 'Remove',
    Choose_File: 'Select file',
    Name_Surname: 'Name Surname',
    Blockage_will_be_delete_Do_you_confirm: 'The blockage will be deleted. Do you confirm?',
    Search_Blockage: 'Search Blockage',
    Enter_a_value_greater_than_zero: 'Enter a value greater than zero.',
    You_must_enter_the_amount_first: 'You must enter the amount first.',
    Please_make_sure_that_the_sum_of_their_tonnages_equals: 'Please make sure that the sum of their tonnage equals [var0].',
    Customer_will_be_active_Do_you_confirm: 'Customer will be activated. Do you confirm?',
    Year: 'Year',
    Please_contact_your_customer_representative_to_place_an_order: 'Please contact your customer representative to place an order.',
    Choose_a_Time: 'Select Time',
    Please_enter_valid_date: 'Please enter valid date.',
    Date_should_not_be_before_minimum_date: 'The date must not be earlier than the minimum date.',
    Time_not_selected: 'No time selected',
    Time_selected: 'Time selected',
    Order_Creator: 'Order Creator',
    The_transactions_you_will_do_after_that_will_be_done_on_behalf_of_x_Do_you_confirm: 'The actions you will take from now on will be done on behalf of [var0]. Do you confirm?',
    CAUTION: 'CAUTION',
    YOU_ARE_TRANSACTING_FOR_CUSTOMER_TO_SIGN_OUT: 'YOU ARE TAKING TRANSACTIONS FOR [var0] CUSTOMER.',
    CLICK_HERE: 'CLICK HERE',
    You_Cannot_Manage_This_Customer: 'You Cannot Manage This Customer.',
    You_Cannot_Manage_This_Customer_Because_It_Is_Inactive_In_order_to_Manage_the_Customer_first_activate_it: 'You Cannot Manage This Customer Because It Is Inactive. In order to Manage the Customer, first set it to active state.',
    Company_Selection: 'Company Selection',
    Unpaid: 'Unpaid',
    Shipment_Report: 'Shipment Report',
    Create_Date: 'Creation Date',
    Material_No: 'Material No',
    Material_Name: 'Material Name',
    Unit: 'O.B.',
    Used_Amount: 'Used Quantity',
    Delivery_Date: 'Delivery Date',
    Logs: 'Logs',
    Message: 'Message',
    Remote_Address: 'Remote Address',
    True: 'True',
    False: 'False',
    Last_5_Orders: 'Last 20 Orders',
    Select_Credit_Control: 'Select Credit Control',
    Action_Pending_Orders: 'Action Pending Orders',
    Company: 'Company',
    User: 'User',
    Please_enter_the_6_digit_code_correctly: 'Please enter the 6 digit code correctly',
    Transfer_Customers: 'Transfer Customers',
    Replacing_the_agent_representative: 'Replacing the agent',
    Represented_customers_will_be_transferred_Do_you_confirm: 'Represented customers will be transferred. Do you confirm?',
    User_Operations: 'User operations',
    Credit_Control_No: 'Credit Control Num.',
    Change_Credit_Description: 'Change Credit Definition',
    Update_Date: 'Date of Update',
    Company_Not_Selected: 'No Company Selected',
    Times_up_Sign_in_again: "Time's up! Sign in again",
    Credit_Control_Description: 'Credit Control Definition',
    Produced_by_MDP_Group: 'Produced by MDP Group.',
    You_do_not_have_sufficient_credits: 'You do not have enough credits.',
    Key: 'Key',
    File_No: 'Document no',
    File_Type: 'Document type',
    File_Description: 'Document Description',
    Net_Due_Date: 'Net Due Date',
    Maturity_Description: 'Term Definition',
    Open_Account: 'Open Account',
    Invoices: 'Bills',
    Document_Date: 'Document Date',
    ODK: 'ÖDK',
    Reference_No: 'Reference No',
    Debit_Credit_Indicator: 'Debit-Credit Indicator',
    Overdue: 'Overdue',
    Undue: 'Not Due',
    Payment_Type: 'Payment Type',
    Delivery_Type: 'Delivery Type',
    eWayBillNo: 'E Waybill No',
    SAP_delivery_No: 'SAP Delivery Number',
    Construction_Site_Number: 'Site Number',
    Construction_Site_Name: 'Site Name',
    Product_Name: 'Product Name',
    Roles: 'Roles',
    Edit_Roles: 'Edit Role',
    Create_New_Role: 'Create New Role',
    New_Roles: 'New Role',
    Permissions: 'Permissions',
    Cimsa: 'Ferçim',
    No_Role: 'No Role',
    Select_All: 'Select all',
    Name: 'Name',
    Please_select_a_credit_control_area_with_a_customer: 'Please select a credit control area with a customer!',
    There_were_no_results: 'There were no results',
    To_edit_the_passive_user_you_need_to_activate_it: 'To edit the passive user, you need to activate it.',
    The_role_is_being_created: 'The role is being created.',
    Creating_a_new_role_Do_you_confirm: 'Creating a new role. Do you confirm?',
    Choose_Permission: 'Choose Permission',
    Invoice_Description: 'Invoice Definition',
    Orders_Opened_from_the_Portal: 'Orders Opened from the Portal',
    Pump_Plaque: 'Pump Plate',
    There_is_no_invoice: 'Invoice not found.',
    Factory_Description: 'Factory Description',
    Change_Fabric_Description: 'Change Factory Description',
    Title: 'Title',
    Text: 'Text',
    Last: 'End',
    Order_Cancellation: 'Order Cancellation',
    Tonnage_Update: 'Tonnage Update',
    Order_Transactions: 'Order Transactions',

    Total_Quantity: 'Total Quantity',
    Reject: 'Reject',
    Approve: 'Approve',
    An_error_has_occurred: 'An error has occurred',
    The_order_update_request_will_be_approved_Do_you_confirm: 'The order update request will be rejected. Do you confirm?',
    Order_update_request_will_be_rejected_Do_you_confirm: 'The order update request will be approved. Do you confirm?',
    Rejection: 'Rejection',
    Company_Codes: 'Company Codes',
    Company_Code: 'Company Code',
    Limit_Control: 'Limit Control',
    Company_Name: 'Company Name',
    Not_Defined: 'Not Defined',
    Confirmation_Code: 'Confirmation Code',
    Change_Defined_Credits: 'Change Defined Credits',
    Defining_Credit_Control_to_the_Company: 'Defining Credit Control to the Company',
    Changing_credit_controls_defined_for_the_company: 'The credit controls defined for the company are being changed.',
    Send: 'Send',
    Draft: 'Draft',
    Announcement_will_be_published_Do_you_confirm: 'Announcement will be published. Do you confirm?',
    Check_limit: 'Do limit check',
    Do_not_Check_limit: 'Do not limit check',
    Time_Range: 'Time Range',
    Update_Company_Settings: 'Update Company Settings',
    Enter_a_valid_time_range: 'Enter a valid time range.',
    The_insufficient_limit_cannot_be_higher_than_the_low_limit: 'The insufficient limit cannot be higher than the low limit.',
    Payment_Terms: 'Payment Terms',
    Update_from_SAP: 'Update from SAP',
    Payment_Term_No: 'Payment Term number',
    Updated_from_SAP: 'Updated from SAP',
    Tonnage_Increase_Amount: 'Tonnage Increase Amount',
    Tonnage_Increase_Request: 'Tonnage Increase Request',
    Repeat: 'Repeat',
    Repeat_Last_Order: 'Repeat Last Order',
    File_Downloaded_Successfully: 'File Downloaded Successfully',
    All_Users: 'All Users',
    KVKK_End_Date: 'GDPR End Date',
    Role: 'Role',
    Announcement_has_been_published: 'Announcement has been published',
    Last_Announcement_Drafts: 'Last Announcement Drafts',
    Please_chance_password_by_entering_the_password_given_to_you: 'Please change your password by entering the password given to you.',
    New_Password: 'New Password',
    New_Password_Again: 'New Password(again)',
    New_passwords_are_not_the_same: 'New passwords are not the same',
    Password_must_be_between_6_32_characters_long: 'Password must be between 8-32 characters long',
    The_password_will_change_Do_you_confirm: 'The password will be changed. Do you confirm?',
    Open: 'Open',
    Previous_Announcements: 'Previous Announcements',
    Drafts: 'Drafts',
    Publish: 'Publish',
    Template: 'Template',
    Templates: 'Templates',
    The_template_will_be_edit_Do_you_confirm: 'The template will be edited. Do you confirm?',
    It_will_be_saved_as_a_template_Do_you_confirm: 'It will be saved as a template. Do you confirm?',
    Today_Debt: 'Todays Dept',

    KVKK_Validity: 'GDPR Validity',
    KVKK_Text: 'GDPR Validity',
    Email_Sender_Name: 'Email Senders Name',
    Email_Sender_Address: 'Email Senders Address',
    SMTP_Port: 'SMTP Port',
    SMTP_Server: 'SMTP Server',
    Avaible: 'Available',
    Order_Settings: 'Order Settings',
    Mail_Settings: 'Mail Settings',
    KVKK_Settings: 'GDPR Settings',
    Make_Payment: 'Do Payment',
    The_Settings_Updated: 'Settings are updated.',
    Months: 'Months',
    Changing_company_settings: 'Companys settings are being changed.',
    The_role_is_being_edited: 'The role is being edited',
    Explicit_Consent_Statement_Form: 'Explicit Consent Statements Form',
    Total_Debt: 'Totals Debt',
    Editing_the_role_Do_you_confirm: 'Editing the role. Do you confirm?',
    An_error_occurred_while_fetching_Blockages_data: 'An error occurred while fetching Blockages data!',
    updating: 'updating.',
    Password_must_be_between_8_32_characters_long: 'Password must be between 8 and 32 characters long.',
    Welcome_to_Cimsa_Customer_Screen: 'Welcome to Fercim Customers Screen',
    SAP_Order_Number: 'SAP Order Number',
    Click_to_add: 'Click to add',
    Click_here_to_download_the_user_manual_of_the_Customer_Portal: 'Click here to download the user manual of the Customers Portal',
    click_here: 'click here.',
    manual_link: 'https://www.fercim.com.tr/bayi/Bayi_Portal_EN.pdf',
    Please_Create_a_New_8_character_Password: 'Please create a new 8 characters long password.',
    Shipping_cost_not_found_Contact_your_customer_representative: 'Shipping cost not found. Contact your customer representative.',
  },
  Months: {
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    Mayy: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  DashboardCharts: {
    Avg: 'Avg.',
    Users: 'Users',
    Monthly_Reports: 'Monthly Reports',
    Order_Reports: 'Order Reports',
    Please_Choose: 'Please Choose',
    Select_Year: 'Select Year',
    Log: 'Log',
    Choose_BuildingSite: 'Choose Building-site',
  },
};
