import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Dialog, Grid, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import {
  CustomButton,
  CustomDialogContent,
  CustomTextField,
  DashboardBox,
  OrderCard,
  PageWrapper,
  SearchBar,
  SimpleTable,
  // TableNameBox,
} from '../assets/styled';
import {
  CheckFilledCircle,
  CrossFilledCircle,
  NoOrder,
  PlusButton,
  ShoppingBasket,
  WarningCircleFilled,
} from '../assets/icons';
import { Colors } from '../assets/statics';
import CreateOrder from '../components/Modals/CreateOrder';
import DetailOrder from '../components/Modals/DetailOrder';
import GenerateModal from '../components/Modals/Info';

import {
  getOrders,
  orderTonnageUpdate,
  cancelOrder,
  // getBuildingsites,
} from '../store/actions';
import {
  selectOrders, selectOrderDetail, selectProductGroups, selectAllProductGroup,
} from '../store/selectors';
import { tra } from '../commons/utils';
import RepeatOrder from '../components/Modals/RepeatOrder';
import Input from '../components/Input';

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreateModal: false,
      openDetailModal: false,
      openRepeatModal: false,
      selectedProduct: 0,
    };
    this.props.getOrders();
    // this.props.getBuildingsites();
  }

  render() {
    const {
      searchFilter, openCreateModal, openDetailModal, openRepeatModal,
    } = this.state;
    const {
      orders, orderDetail, productGroups, allProductGroup,
    } = this.props;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (this.props.orders && this.props.orders?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : this.props.orders;
    return (
      <PageWrapper>
        <Grid container spacing={3} style={{ paddingBottom: 20 }}>
          {
            productGroups ? (
              <>
                <Grid item lg={10} md={10} sm={12} xs={12}>
                  <Grid container spacing={3} alignItems="stretch">
                    {
                      allProductGroup?.map((pg, i) => (
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <DashboardBox
                            style={{ cursor: (productGroups?.some((p) => p.id === allProductGroup[i]?.id)) && 'pointer' }}
                            disable={!(productGroups?.some((p) => p.id === allProductGroup[i]?.id))}
                            onClick={() => productGroups?.some((p) => p.id === allProductGroup[i]?.id) && this.setState({ selectedProduct: pg.id, openCreateModal: true })}
                          >
                            <div className="content">
                              <h3 style={{ fontSize: 24 }}>{pg.name}</h3>
                              <p style={{ maxWidth: '90%' }}>{!(productGroups?.some((p) => p.id === allProductGroup[i]?.id)) ? tra('Please_contact_your_customer_representative_to_place_an_order') : tra('Order_Now')}</p>
                            </div>
                            <div className="icon">
                              <ShoppingBasket color={Colors.blueLight} />
                            </div>
                          </DashboardBox>
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12} style={{ display: 'flex' }}>
                  <CustomButton
                    lightBlue
                    onClick={() => this.setState({ openCreateModal: true })}
                    startIcon={<PlusButton color={Colors.blue} />}
                    style={{ margin: 'auto' }}
                  >
                    {tra('New_Order')}
                  </CustomButton>
                </Grid>
              </>
            ) : (
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingBottom: 20 }}>
                <h3>{tra('Please_contact_your_customer_representative_to_place_an_order')}</h3>
              </Grid>
            )
          }
          {
            orders && orders.length > 0 && (
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingBottom: 20 }}>
                <h3>{tra('Last_5_Orders')}</h3>
                <SearchBar>
                  <div className="find">
                    <div className="inputWrapper">
                      <CustomTextField
                        search
                        fullWidth
                        placeholder={tra('Search')}
                        value={searchFilter}
                        onChange={(event) => this.setState({ searchFilter: event.target.value })}
                        variant="outlined"
                      />
                      <Search />
                    </div>
                    <CustomButton>{tra('Filter')}</CustomButton>
                  </div>
                </SearchBar>
              </Grid>
            )
          }
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {
              filteredData?.length > 0 ? filteredData.map((order) => (
                <OrderCard>
                  <div className="cardHeader">
                    <div>
                      <p>{tra('Order_Status')}</p>
                      <span>{order.order_status_name}</span>
                    </div>
                    <div>
                      <p>{tra('Shipping_Date')}</p>
                      <span>{new Date(order?.date).toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' })}</span>
                    </div>
                    {/* <div>
                      <p>Sipariş Özeti</p>
                      <span>{order.Amounts && order.Amounts.length} Teslimat, 1 Ürün</span>
                    </div> */}
                    <div>
                      <p>{tra('Total')}</p>
                      {/* <span>{order?.total_amount} {tra('Ton')}</span> */}
                      <span>
                        {/* {order?.total_amount} {tra('Ton')} */}
                        <NumberFormat
                          value={order?.total_amount}
                          displayType="text"
                          thousandSeparator
                          suffix={` ${tra('Ton')}`}
                          renderText={(value, props) => <div {...props}>{value}</div>}
                        />
                      </span>
                    </div>
                    <div>
                      <p>{tra('Delivery_Type')}</p>
                      <span>{order.delivery_point_name}</span>
                    </div>
                    <div>
                      <p>{tra('Building_Site')}</p>
                      <Tooltip title={order?.Delivery?.name}>
                        <span>{order?.Delivery?.name}</span>
                      </Tooltip>
                    </div>
                    <div className="buttonWrapper">
                      <CustomButton onClick={() => { this.setState({ openDetailModal: true }); this.props.getOrders(order.id); }}>{tra('Order_Detail')}</CustomButton>
                      <CustomButton lightBlue disabled={order?.tonnage_update || order?.cancel_request} onClick={() => this.setState({ tonnageDialog: true, selectedId: order?.id })}>
                        {order?.tonnage_update ? tra('Tonnage_Request_Sent') : tra('Request_Tonnage_Increase')}
                      </CustomButton>
                      <CustomButton lined onClick={() => { this.setState({ openRepeatModal: true }); this.props.getOrders(order.id, true); }}>{tra('Repeat_Order')}</CustomButton>
                      <CustomButton darkGrey disabled={order?.cancel_request} onClick={() => this.setState({ cancelModal: true, selectedId: order?.id })}>
                        {tra('Cancel_Order')}
                      </CustomButton>
                    </div>
                  </div>
                  <div className="cardContent">
                    {
                      (order?.message || order?.tonnage_update_message || order?.cancel_message) && (
                        <>
                          <div className="message">
                            {
                              order?.message && (
                                <div className="messageText">
                                  <WarningCircleFilled color={Colors.orange} />
                                  <span>{order?.message}</span>
                                </div>
                              )
                            }
                            {
                              order?.cancel_message && (
                                <Tooltip title={tra('Order_Cancellation')}>
                                  <div className="messageText">
                                    {order.cancel_confirm ? <CheckFilledCircle color={Colors.green} /> : <CrossFilledCircle color={Colors.error} />}
                                    <span style={{ color: order.cancel_confirm ? Colors.green : Colors.error }}>{order?.cancel_message}</span>
                                  </div>
                                </Tooltip>
                              )
                            }
                            {
                              order?.tonnage_update_message && (
                                <Tooltip title={tra('Tonnage_Update')}>
                                  <div className="messageText">
                                    {order.tonnage_update_confirm ? <CheckFilledCircle color={Colors.green} /> : <CrossFilledCircle color={Colors.error} />}
                                    <span style={{ color: order.tonnage_update_confirm ? Colors.green : Colors.error }}>{order?.tonnage_update_message}</span>
                                  </div>
                                </Tooltip>
                              )
                            }
                            {/* <WarningCircleFilled color={Colors.orange} />
                            <span>{order?.cancel_message}</span> */}
                          </div>
                        </>
                      )
                    }
                    <SimpleTable>
                      <thead>
                        <th>{tra('Order_Stage')}</th>
                        <th>{tra('Order_Ref_ID')}</th>
                        <th>{tra('Quanta')}</th>
                        <th>{tra('Product')}</th>
                        <th>{tra('Delivery_Time')}</th>
                      </thead>
                      <tbody>
                        {
                          order?.Amounts?.map((item) => (
                            <tr>
                              <td>
                                {/* {
                                  item.stage_id && (
                                    orderItemStatus.map((d) => (
                                      d.id === item.stage_id && (
                                        <TableNameBox style={{ margin: 'auto' }}>
                                          {d.icon}
                                          <span>{item.amount_stage_name}</span>
                                        </TableNameBox>
                                      )
                                    ))
                                  )
                                } */}
                                <span>{order.order_status_name}</span>
                              </td>
                              <td align="center">{item.order_id}</td>
                              {/* <td>{item.amount} {tra('Ton')}</td> */}
                              <td>
                                <NumberFormat
                                  value={item.amount}
                                  displayType="text"
                                  thousandSeparator
                                  suffix={` ${tra('Ton')}`}
                                  renderText={(value, props) => <div {...props}>{value}</div>}
                                />
                              </td>
                              <td>
                                <Tooltip title={order.product_name}>
                                  <span>{order.product_name}</span>
                                </Tooltip>
                              </td>
                              <td align="center">{item.hour}{item.hour !== '-' && ':00'}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </SimpleTable>
                  </div>
                </OrderCard>
              )) : (orders?.length !== 0 && filteredData?.length === 0 ? (
                <div className="noData">
                  <NoOrder />
                  <p>{tra('There_were_no_results')}</p>
                </div>
              ) : '')
            }
            {
              orders && orders.length === 0 && (
                <div className="noData">
                  <NoOrder />
                  <p>{tra('There_is_no_order')}</p>
                </div>
              )
            }
          </Grid>
        </Grid>
        {
          openCreateModal && (
            <CreateOrder
              open={openCreateModal}
              onClose={() => this.setState({ openCreateModal: false, selectedProduct: 0 })}
              selectedProduct={this.state.selectedProduct}
            />
          )
        }
        {
          openRepeatModal && (
            <RepeatOrder
              open={openRepeatModal}
              onClose={() => this.setState({ openRepeatModal: false, selectedProduct: 0 })}
              selectedProduct={this.state.selectedProduct}
              orderDetail={orderDetail}
            />
          )
        }
        {
          openDetailModal && (
            <DetailOrder
              open={openDetailModal}
              onClose={() => this.setState({ openDetailModal: false })}
              orderDetail={orderDetail}
            />
          )
        }
        {
          this.state.tonnageModal && (
            <GenerateModal
              open={this.state.tonnageModal}
              type="Question"
              title={tra('Tonnage_Increase')}
              text={tra('Your_tonnage_increase_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ tonnageModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => { this.props.orderTonnageUpdate(this.state.selectedId, { tonnage_update_amount: this.state.tonnageUpdateAmount }); this.setState({ tonnageModal: false, tonnageDialog: false }); }}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
        {
          this.state.cancelModal && (
            <GenerateModal
              open={this.state.cancelModal}
              type="Warning"
              title={tra('Order_Will_Be_Canceled')}
              text={tra('Your_order_cancellation_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ cancelModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => { this.props.cancelOrder(this.state.selectedId); this.setState({ cancelModal: false }); }}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
        {
          this.state.tonnageDialog && (
            <Dialog open={this.state.tonnageDialog} onClose={() => this.setState({ tonnageDialog: false })} maxWidth="sm" fullWidth="true" scroll="body">
              <CustomDialogContent>
                <div className="header">
                  <p>
                    {/* <span>{this.state.selectedId}</span>  */}
                    {tra('Tonnage_Increase_Request')}
                  </p>
                </div>
                <div className="content">
                  <Input
                    type="number"
                    variant="standard"
                    label={tra('Tonnage_Increase')}
                    fullWidth
                    value={this.state.tonnageUpdateAmount}
                    onChange={(e) => this.setState({ tonnageUpdateAmount: e.value })}
                    autoFocus
                  />
                </div>
                <div className="buttonWrapper">
                  <CustomButton lined onClick={() => this.setState({ tonnageDialog: false })}>
                    {tra('Cancel')}
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      if (this.state.tonnageUpdateAmount > 0) this.setState({ tonnageModal: true });
                    }}
                  >
                    {tra('Save')}
                  </CustomButton>
                </div>
              </CustomDialogContent>
            </Dialog>
          )
        }
      </PageWrapper>
    );
  }
}

Order.propTypes = {
  getOrders: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  orderDetail: PropTypes.object.isRequired,
  orderTonnageUpdate: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  productGroups: PropTypes.array.isRequired,
  allProductGroup: PropTypes.array.isRequired,
  // getBuildingsites: PropTypes.func.isRequired,
};

// Order.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  orders: selectOrders(),
  orderDetail: selectOrderDetail(),
  productGroups: selectProductGroups(),
  allProductGroup: selectAllProductGroup(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getOrders,
      orderTonnageUpdate,
      cancelOrder,
      // getBuildingsites,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Order);
