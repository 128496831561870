import React, { Component } from 'react';
import {
 Checkbox, FormControlLabel, Grid, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  AnnounceDraftCard,
  AnnounceHeader,
  AnnounceWrapper,
  CustomButton,
  PageWrapper,
} from '../assets/styled';
import {
  selectWarnings,
  selectAnnouncements,
  selectCustomers,
} from '../store/selectors';
import {
  getWarnings,
  getAnnouncement,
  announce,
  getCustomers,
  setToast,
} from '../store/actions';
import { tra } from '../commons/utils';
import Input from '../components/Input';
import Info from '../components/Modals/Info';
import { Colors } from '../assets/statics';
import { Trash, Send, BookMark } from '../assets/icons';

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: 1,
      selectedCustomers: [],
      title: '',
      text: '',
      mail: false,
      sms: false,
      isDraft: false,
      selectedDraft: null,
      annoId: '',
    };
    // props.getWarnings();
    props.getAnnouncement();
    props.getCustomers();
  }

  announce = () => {
    const {
      selectedCustomers, title, text, mail, sms, isDraft,
    } = this.state;
    const newcustomers = [];
    (selectedCustomers || []).map((k) => (newcustomers.push({ customer_id: k.id })));
    const data = {
      customers: newcustomers,
      header: title,
      description: text,
      sms,
      mail,
      is_draft: isDraft,
    };
    this.props.announce(data);
  }

  editAnnounce = () => {
    const {
      selectedCustomers, title, text, mail, sms, isDraft, annoId,
    } = this.state;
    const newcustomers = [];
    (selectedCustomers || []).map((k) => (newcustomers.push({ customer_id: k.id })));
    const data = {
      customers: newcustomers,
      header: title,
      description: text,
      sms,
      mail,
      is_draft: isDraft,
    };
    this.props.announce(data, annoId);
  }

  saveFunc = () => {
    const {
      selectedCustomers, title, text, questionModal, annoDraft,
    } = this.state;
    if (selectedCustomers?.length > 0 && text && title) {
      if (questionModal) {
        if (annoDraft) {
          this.editAnnounce();
        } else {
          this.announce();
        }
        this.setState({ questionModal: false });
        this.clearAll();
      } else {
        this.setState({ questionModal: true });
      }
    } else {
      this.props.setToast(true, tra('Please_Fill_in_the_mandatory_fields'), 'warning');
    }
  }

  clearAll = () => {
    this.setState({
      selectedCustomers: [],
      title: '',
      text: '',
      mail: false,
      sms: false,
      isDraft: false,
      annoDraft: false,
      selectedDraft: null,
      annoId: '',
    });
  }

  viewDraft = (anno, index) => {
    if (this.state.annoId !== anno?.id) {
      this.setState({
        selectedDraft: index,
        selectedCustomers: anno?.CustomerAnnouncement,
        title: anno?.header,
        text: anno?.description,
        mail: anno?.mail,
        sms: anno?.sms,
        annoId: anno?.id,
        annoDraft: anno?.is_draft,
        isDraft: false,
      });
    }
  }

  render() {
    const {
      selectedCustomers, title, text, mail, sms, isDraft, showContent, annoDraft,
    } = this.state;
    const draftData = this.props.announcements?.filter((ann) => ann.is_draft);
    const annoData = this.props.announcements?.filter((ann) => !ann.is_draft);
    return (
      <PageWrapper>
        <Grid container spacing={3}>
          <Grid item lg={4} md={5} sm={12} xs={12}>
            {/* <h2 style={{ fontSize: '18px', color: Colors.navy, paddingBottom: '30px' }}>{tra('Last_Announcement_Drafts')}</h2> */}
            <AnnounceHeader>
              <h2>{tra('Previous_Announcements')}</h2>
              <Input
                type="select"
                data={[{ id: 1, name: tra('Announcements') }, { id: 2, name: tra('Templates') }]}
                startAdornment={showContent === 1 ? <Send /> : <BookMark />}
                value={showContent}
                onChange={(event) => this.setState({ showContent: event.target.value }, () => this.clearAll())}
              />
            </AnnounceHeader>
            <AnnounceWrapper>
              {(showContent === 1 ? annoData : draftData)?.map((anno, index) => (
                <AnnounceDraftCard active={this.state.selectedDraft === index} onClick={() => this.viewDraft(anno, index)}>
                  <Tooltip title={(<p style={{ fontSize: '14px', lineHeight: '18px' }}>{anno?.description}</p>) || ''}>
                    <div className="content">
                      <h5>
                        {
                          anno?.CustomerAnnouncement?.map((customer, i) => (
                            anno?.CustomerAnnouncement?.length > 1 ? (
                              i === anno?.CustomerAnnouncement?.length - 1 ? (
                                `${customer?.name?.replace('.', ' ')?.split?.(' ')?.[0]} ${customer?.name?.replace('.', ' ')?.split?.(' ')?.[1]}`
                                ) : `${customer?.name?.replace('.', ' ')?.split?.(' ')?.[0]} ${customer?.name?.replace('.', ' ')?.split?.(' ')?.[1]}, `
                              ) : `${customer?.name?.replace('.', ' ')?.split?.(' ')?.[0]} ${customer?.name?.replace('.', ' ')?.split?.(' ')?.[1]}`
                          ))
                        }
                      </h5>
                      <p>{anno?.header}</p>
                      {/* <p>{anno?.description}</p> */}
                    </div>
                  </Tooltip>
                </AnnounceDraftCard>
              ))}
            </AnnounceWrapper>
          </Grid>
          <Grid item lg={8} md={7} sm={12} xs={12}>
            <div className="announceForm">
              <Input
                type="autoComplete"
                label={tra('Customers')}
                variant="standard"
                data={this.props.customers || []}
                fullWidth
                multiple
                selectAll
                disableCloseOnSelect
                value={selectedCustomers || []}
                onChange={(e, value) => this.setState({ selectedCustomers: value })}
              />
              <Input
                type="text"
                label={tra('Title')}
                variant="standard"
                fullWidth
                value={title}
                onChange={(e) => this.setState({ title: e.target.value })}
              />
              <Input
                type="text"
                label={tra('Text')}
                variant="standard"
                fullWidth
                multiline
                row={5}
                value={text}
                onChange={(e) => this.setState({ text: e.target.value })}
              />
              <div className="checkingArea">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={mail || false}
                      onChange={() => this.setState({ mail: !mail })}
                      name="mail"
                      color="primary"
                    />
                  )}
                  label={tra('Email')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={sms || false}
                      onChange={() => this.setState({ sms: !sms })}
                      name="sms"
                      color="primary"
                    />
                  )}
                  label={tra('SMS')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isDraft || false}
                      onChange={() => this.setState({ isDraft: !isDraft })}
                      name="isDraft"
                      color="primary"
                    />
                  )}
                  label={annoDraft ? tra('Edit') : tra('Template')}
                />
              </div>
            </div>
            <div className="buttonWrapperAnnounce">
              {
                isDraft ? (
                  <CustomButton startIcon={<BookMark width={14} color={Colors.white} />} onClick={() => this.saveFunc()}>
                    {tra('Save')}
                  </CustomButton>
                ) : (
                  <CustomButton startIcon={<Send color={Colors.white} width={16} />} onClick={() => this.saveFunc()}>
                    {tra('Publish')}
                  </CustomButton>
                )
              }
              {
                (selectedCustomers?.length > 0 || title || mail || sms || isDraft) && (
                  <CustomButton lined startIcon={<Trash width={16} />} onClick={() => this.clearAll()}>
                    {tra('Clear')}
                  </CustomButton>
                )
              }
            </div>
          </Grid>
        </Grid>
        {
          this.state.questionModal && (
            <Info
              open={this.state.questionModal}
              type={isDraft ? 'Warning' : 'Question'}
              title={tra('Confirmation')}
              text={isDraft ? (annoDraft ? tra('The_template_will_be_edit_Do_you_confirm') : tra('It_will_be_saved_as_a_template_Do_you_confirm')) : tra('Announcement_will_be_published_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue={!isDraft} orange={isDraft} onClick={() => this.saveFunc()}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
      </PageWrapper>
    );
  }
}

Announcement.propTypes = {
  // getDashboard: PropTypes.func.isRequired,
  // dashboardData: PropTypes.object.isRequired,
  warnings: PropTypes.array.isRequired,
  getWarnings: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  getAnnouncement: PropTypes.func.isRequired,
  announce: PropTypes.func.isRequired,
  announcements: PropTypes.array.isRequired,
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  // dashboardData: selectDashboard(),
  warnings: selectWarnings(),
  announcements: selectAnnouncements(),
  customers: selectCustomers(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // getDashboard,
      getWarnings,
      getAnnouncement,
      announce,
      getCustomers,
      setToast,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
