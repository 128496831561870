import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  TextField,
  InputAdornment,
  // IconButton,
} from '@material-ui/core';
import {
  LockOutlined,
  MailOutlineOutlined,
} from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import {
  Colors,
  languageData,
  sliderData,
} from '../assets/statics';
import {
  LoginWrapper,
  LoginBox,
  CustomButton,
  Simplelink,
  // MobileMoreMenu,
  // MobileMoreMenuItem,
  CustomMenuItem,
} from '../assets/styled';
import Logo from '../assets/img/logo.png';

import { doLogin, enterCode, setToast } from '../store/actions';
import { selectActiveUser, selectAppConfig } from '../store/selectors';
import { getLangCode, tra } from '../commons/utils';
import { Refresh, World } from '../assets/icons';
import CountDownTimer from '../components/CountDownTimer';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginContent: true,
      email: '',
      password: '',
      code: '',
      acitveBg: 0,
      languageData: [{ id: 1, name: 'T' }, { id: 2, name: 'E' }, { id: 3, name: 'S' }],
    };
    setInterval(() => this.changeBg(), 5000); // slider change
  }

  changeBg = () => {
    const { acitveBg } = this.state;
    let newActive = acitveBg;
    if (acitveBg < (sliderData.length - 1)) {
      newActive = acitveBg + 1;
    } else if (acitveBg === (sliderData.length - 1)) {
      newActive = 0;
    }
    this.setState({ acitveBg: newActive });
  }

  enterLogin = (e) => {
    if (this.props.appConfig?.code) {
      if (e.key === 'Enter') {
        this.enterCode();
      }
    } else if (e.key === 'Enter') {
      this.doLogin();
    }
  }

  doLogin = () => {
    const { email, password } = this.state;
    if ((/\S+@\S+\.\S+/.test(email)) && password?.length > 5) {
      this.props.doLogin(email, password);
    } else {
      this.props.setToast(true, tra('Please_enter_a_valid_email_address'), 'warning');
    }
  }

  enterCode = (codes) => {
    // const { code } = this.state;
    if (codes?.length === 6 && parseInt(codes, 10) > 100000) {
      this.props.enterCode(codes);
      this.setState({ loginContent: false });
    } else {
      this.props.setToast(true, tra('Please_enter_the_6_digit_code_correctly'), 'warning');
    }
  }

  onChangeCode = (e) => {
    const value = e?.target?.value || '';
    const { code } = this.state;
    if (value?.length === 6 && parseInt(value, 10) > 100000) {
      this.setState({ code: value });
      this.enterCode(value);
    } else if (value?.length < 7) {
        this.setState({ code: value });
      } else if (this.state.code?.length < 7) {
        this.setState({ code });
      } else {
        this.setState({ code: '' });
      }
  }

  render() {
    const {
      code, password, email, acitveBg, expired,
    } = this.state;
    const {
      activeUser, appConfig,
    } = this.props;

    return (
      <LoginWrapper onKeyDown={(e) => this.enterLogin(e)}>
        {
          (activeUser && localStorage.getItem('token')) && Object.keys(activeUser).length > 0 && (
            <Redirect to="/" />
          )
        }
        <div className="slider">
          <div className="wrapper" style={{ transform: `translate(-${acitveBg}00%, 0)` }}>
            {
              sliderData && sliderData.map((slide) => (
                <div style={{ backgroundImage: `url(${slide})` }}>
                  {/* <img src={slide} alt="slideImg" /> */}
                </div>
              ))
            }
          </div>
        </div>
        <LoginBox>
          <div>
            <div className="logo">
              <img src={Logo} alt={tra('Cimsa_Customer_Portal')} />
              {
                (!appConfig?.code && this.state.loginContent) && (
                  <div className="changeLanguage">
                    <TextField
                      select
                      value={languageData?.find((lang) => lang?.name === getLangCode())?.name}
                      onChange={(e) => {
                        localStorage.setItem('language', this.state.languageData?.find((lang) => lang?.name === e.target.value)?.name);
                        window.location.reload();
                      }}
                      fullWidth
                      variant="outlined"
                      style={{ margin: '10px 0' }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <World width={22} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {
                        languageData?.map((item) => (
                          <CustomMenuItem value={item.name}>
                            {item.text}
                          </CustomMenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                )
              }
            </div>
            <>
              <h1>{tra('Welcome_to_Çimsa_Customer_Portal')}</h1>
              {
                (!appConfig?.code && this.state.loginContent) ? (
                  <>
                    <p>{tra('Please_log_in_to_the_system_by_entering_the_username_given_to_you')}</p>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label={tra('Email')}
                      autoFocus
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineOutlined style={{ color: Colors.grey }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type="password"
                      variant="outlined"
                      fullWidth
                      label={tra('Password')}
                      value={password}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined style={{ color: Colors.grey }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                ) : (
                  <>
                    <CountDownTimer initialMinute={3} initialSeconds={0} key={this.state.resetCode} reset={appConfig?.code && this.state.resetCode} expired={() => this.setState({ expired: true, code: '' })} />
                    {!expired && <p>{tra('Enter_the_code_sent_to_your_phone_number_or_email')}</p>}
                    {
                      !expired && (
                        <TextField
                          type="text"
                          variant="outlined"
                          fullWidth
                          label={tra('Confirmation_Code')}
                          // key={code}
                          autoFocus
                          key={this.state.loginContent}
                          value={code}
                          // onChange={(e) => this.setState({ code: e.target.value?.length < 7 ? e.target.value : (code?.length < 7 ? code : '') })}
                          onChange={(e) => this.onChangeCode(e)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOutlined style={{ color: Colors.grey }} />
                              </InputAdornment>
                            ),
                          }}
                          error={code?.length > 6}
                        />
                      )
                    }
                  </>
                )
              }
              {/* <FormControlLabel control={<Checkbox name="checkedC" />} label={tra('I_accept_the_Personal_Data_Protection_Text')} /> */}
              {/* <CustomButton fullWidth onClick={() => this.setState({ code: true })}>{tra('Login')}</CustomButton> */}
              {
                appConfig?.code ? (
                  <CustomButton fullWidth grey={!(code?.length === 6 && parseInt(code, 10) > 100000)} disabled={!(code?.length === 6 && parseInt(code, 10) > 100000) || expired} onClick={() => this.enterCode()}>{tra('Login')}</CustomButton>
                ) : (
                  <CustomButton fullWidth onClick={() => this.doLogin()}>{tra('Login')}</CustomButton>
                )
              }
              {
                (!appConfig?.code && this.state.loginContent) ? (
                  <Simplelink to="/ForgetPassword/Forget">{tra('Forget_Password')}</Simplelink>
                ) : expired && (
                  <CustomButton fullWidth lined onClick={() => { this.doLogin(); this.setState({ resetCode: Math.random(1, 10), expired: false }); }} endIcon={<Refresh />}>{tra('Send_Code_Again')}</CustomButton>
                )
              }
            </>
          </div>
        </LoginBox>
      </LoginWrapper>
    );
  }
}

Login.propTypes = {
  doLogin: PropTypes.func.isRequired,
  enterCode: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
  // LoginData: PropTypes.object.isRequired,
  appConfig: PropTypes.object.isRequired,
};

// Login.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  activeUser: selectActiveUser(),
  appConfig: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      doLogin,
      enterCode,
      setToast,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
