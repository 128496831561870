import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Backdrop, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomButton, CustomFade, CustomIconButton, ErrorLine,
} from '../../assets/styled';
import Input from '../Input';
import GenerateModal from './Info';

import {
  setSapCustomer,
  editCustomer,
  setToast,
  setCustomerDetail,
  appConfig,
} from '../../store/actions';
import {
  selectCustomerDetail,
  // selectWorkAreas,
  selectRepresentatives,
  selectAppConfig,
} from '../../store/selectors';
import { tra } from '../../commons/utils';
import { invalidMail } from '../../assets/statics';

class NewCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // content: 0,
      verify: 3,
      customerId: '',
      isChangedState: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.subEdit && nextProps.subuserDetail && nextProps.subuserDetail.id !== prevState.customerId) {
    //   return {
    //     mail: nextProps.subuserDetail.email,
    //     adminName: nextProps.subuserDetail.name,
    //     adminSurname: nextProps.subuserDetail.surname,
    //     phone: nextProps.subuserDetail.phone,
    //     credit_controls: nextProps.subuserDetail.BuildingSites || [],
    //     subuserType: (prevState.subuserAuth.filter((i) => i.id === nextProps.subuserDetail.user_type_id))[0] || {},
    //     customerId: nextProps.subuserDetail.id,
    //   };
    // }
    if (!nextProps.edit && !prevState.customerId) {
      return {
        customerId: nextProps.sapCustomer.sap_customer_no,
        mail: nextProps.sapCustomer.email || '',
      };
    }
    if (nextProps.edit && !prevState.customerId) {
      return {
        adminName: nextProps.customerDetail.authorized_name,
        adminSurname: nextProps.customerDetail.authorized_surname,
        phone: nextProps.customerDetail.authorized_phone,
        customerId: nextProps.customerDetail.sap_customer_no,
        mail: nextProps.customerDetail.email,
        representatives: nextProps.customerDetail.Representatives || [],
        credit_controls: nextProps.customerDetail?.CreditControlAreas || [],
        verify: nextProps.customerDetail.verification_method_id || 0,
      };
    }
    if (nextProps.appConfigs?.successCustomer) {
      nextProps.appConfig('successCustomer', false);
      nextProps.onClose();
    }
    return null;
  }

  componentWillUnmount() {
    this.props.setSapCustomer({});
    this.props.setCustomerDetail({});
  }

  close = () => {
    if ((this.props.sapCustomer && this.props.sapCustomer.sap_customer_no) || this.props.edit) {
      this.setState({
        sureModal: true,
      });
    } else {
      this.setClear();
    }
  }

  setClear = () => {
    this.setState({
      sapNumber: '',
      adminName: '',
      adminSurname: '',
      mail: '',
      phone: '',
      customerId: '',
      isChangedState: [],
      credit_controls: null,
    });
    this.props.onClose();
  }

  getSapCustomer = () => {
    // this.setState({ content: 1 });
    this.props.getSapCustomer(this.state.sapNumber);
  }

  getValid = () => {
    const {
      adminName,
      adminSurname,
      phone,
      mail,
    } = this.state;
    if (adminName?.length > 2 && adminSurname?.length > 1 && phone?.toString()?.length === 10 && /\S+@\S+\.\S+/.test(mail)) {
      return true;
    }
    // this.props.setToast(true, tra('Please_Fill_in_the_mandatory_fields'), 'warning');
    this.setState({ isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random() });
    return false;
  }

  saveCustomer = () => {
    const {
      adminName,
      adminSurname,
      // businessArea,
      representatives,
      verify,
      phone,
      mail,
    } = this.state;
    const { sapCustomer } = this.props;
    // const newBusinessAreaArray = [];
    // businessArea?.length && businessArea.map((k) => newBusinessAreaArray.push({ workarea_id: k.id }));
    const newRepresentativesArray = [];
    representatives?.length && representatives.map((k) => newRepresentativesArray.push({ representative_id: k.id }));
    if (this.getValid()) {
      delete sapCustomer?.credit_controls;
      const data = {
        ...sapCustomer,
        verification_method_id: verify,
        user_name: adminName,
        user_surname: adminSurname,
        user_phone: phone?.toString(),
        email: mail?.toLowerCase(),
        customer_representative: newRepresentativesArray,
        // customer_workareas: newBusinessAreaArray,
      };
      this.props.createCustomer(data);
      this.setState({ questionModal: false });
      // this.props.onClose();
    }
  }

  editCustomer = () => {
    const {
      adminName,
      adminSurname,
      verify,
      phone,
      isChangedState,
      representatives,
      credit_controls,
      mail,
    } = this.state;
    const data = {
      verification_method_id: isChangedState.includes('verify') && verify,
      authorized_name: isChangedState.includes('adminName') && adminName,
      authorized_surname: isChangedState.includes('adminSurname') && adminSurname,
      authorized_phone: isChangedState.includes('phone') && phone.toString(),
      customer_representative: isChangedState.includes('representatives') && representatives,
      customer_workareas: isChangedState.includes('credit_controls') && credit_controls,
      email: isChangedState.includes('mail') && mail !== this.props.customerDetail.email && mail?.toLowerCase(),
    };
    if (this.getValid()) {
      const updatedData = {};
      for (let i = 0; i < Object.keys(data).length; ++i) {
        const keysArray = Object.keys(data);
        if (data[keysArray[i]]) {
          updatedData[keysArray[i]] = data[keysArray[i]];
        }
      }
      this.props.editCustomer(this.props.selectedCustomerId, updatedData);
      this.setState({ questionModal: false });
      // this.props.onClose();
    }
  }

  saveFunc = () => {
    const {
      mail,
      adminName,
      adminSurname,
      phone,
      // subuserType,
      representatives,
      verify,
    } = this.state;
    if (adminName && adminSurname && verify && phone?.toString()?.length === 10 && mail && /\S+@\S+\.\S+/.test(mail) && !(invalidMail.some((a) => mail.includes(a)))) {
      this.setState({ isError: false, errorMessage: tra('') });
      if (this.state.questionModal) {
        if (this.props.edit) {
          this.editCustomer();
        } else {
          this.saveCustomer();
        }
      } else {
        this.setState({ questionModal: true });
      }
    } else if (adminName && adminSurname && verify && phone?.toString()?.length === 10 && mail) {
      // this.props.setToast(true, tra('Please_enter_a_valid_email_address'), 'warning');
      this.setState({
        isError: true, errorMessage: tra('Please_enter_a_valid_email_address'), reRender: Math.random(), questionModal: false,
      });
    } else {
      // this.props.setToast(true, tra('Please_Fill_in_the_mandatory_fields'), 'warning');
      this.setState({
        isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random(), questionModal: false,
      });
    }
    // if (!this.props.edit && adminName && adminSurname && verify && phone && mail && representatives?.length > 0) { // müşteri ekleme
    //   this.saveCustomer();
    // } else if (this.props.edit && adminName && adminSurname && verify && phone && mail && representatives?.length > 0) {
    //   this.editCustomer();
    // } else {
    //   this.props.setToast(true, tra('Please_Fill_in_the_mandatory_fields'), 'warning');
    //   this.setState({ isError: true });
    // }
  }

  isChanged = (stateName) => { // değişim yapılan inputları tespit etme
    const { isChangedState } = this.state;
    if (!isChangedState.includes(stateName)) {
      if (stateName === 'phone') {
        if (this.props.customerDetail.authorized_phone !== this.state.phone) {
          isChangedState.push(stateName);
        }
      } else {
        isChangedState.push(stateName);
      }
    }
    this.setState({ isChangedState });
  }

  enterSapNumber = (e) => {
    if (this.state.sapNumber && e.key === 'Enter') {
      this.getSapCustomer();
    }
  }

  render() {
    const { isError, errorMessage } = this.state;
    const { open, edit, representatives } = this.props;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          // onExit={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{this.props.edit ? tra('Edit_Customer') : tra('Create_Customer')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content">
                {errorMessage && errorMessage?.length && (
                  <ErrorLine key={this.state.reRender}>
                    {errorMessage}!
                  </ErrorLine>
                )}
                {
                  !this.props.sapCustomer.sap_customer_no && !this.props.edit ? (
                    <div className="bringCustomer">
                      <Input
                        type="number"
                        justNumber
                        label={tra('SAP_Customer_Number')}
                        variant="standard"
                        value={this.state.sapNumber}
                        fullWidth
                        autoFocus={open}
                        onChange={(e) => { this.setState({ sapNumber: e.value }); }}
                        onKeyDown={(e) => this.enterSapNumber(e)}
                      />
                      <CustomButton onClick={() => this.state.sapNumber && this.getSapCustomer()}>{tra('Bring')}</CustomButton>
                    </div>
                  ) : ((this.props.sapCustomer && this.props.sapCustomer.sap_customer_no) || this.props.edit) && (
                    <>
                      <div className="formWrapper">
                        <Input
                          type="text"
                          label={tra('SAP_Customer_Number')}
                          variant="standard"
                          disabled
                          value={edit ? (this.props.customerDetail && this.props.customerDetail.sap_customer_no) : (this.props.sapCustomer && this.props.sapCustomer.sap_customer_no)}
                          // onChange={(e) => this.setState({ sapNumber: e.target.value })}
                        />
                        <Input
                          type="text"
                          label={tra('Customer_No')}
                          variant="standard"
                          value={edit ? (this.props.customerDetail && this.props.customerDetail.company_code) : (this.props.sapCustomer && this.props.sapCustomer.company_code)}
                          disabled
                          // onChange={(e) => this.setState({ customerNo: e.target.value })}
                        />
                        <Input
                          type="text"
                          label={tra('Customer_Name')}
                          variant="standard"
                          fullWidth
                          value={edit ? (this.props.customerDetail && this.props.customerDetail.name) : (this.props.sapCustomer && this.props.sapCustomer.name)}
                          disabled
                          // onChange={(e) => this.setState({ customerName: e.target.value })}
                        />
                        <Input
                          type="text"
                          label={tra('Telephone')}
                          variant="standard"
                          value={edit ? (this.props.customerDetail && this.props.customerDetail.phone) : (this.props.sapCustomer && this.props.sapCustomer.phone)}
                          disabled
                          // onChange={(e) => this.setState({ telephone: e.target.value })}
                        />
                        <Input
                          type="text"
                          label={tra('Address')}
                          variant="standard"
                          value={edit ? (this.props.customerDetail && this.props.customerDetail.city) : (this.props.sapCustomer && this.props.sapCustomer.city)}
                          // onChange={(e) => this.setState({ city: e.target.value })}
                          disabled
                        />
                        <Input
                          type="text"
                          label={tra('Address')}
                          variant="standard"
                          value={edit ? (this.props.customerDetail && this.props.customerDetail.address) : (this.props.sapCustomer && this.props.sapCustomer.address)}
                          disabled
                          fullWidth
                        />
                        <Input
                          type="text"
                          label={`${tra('Authorized_Person_Name')}*`}
                          variant="standard"
                          autoFocus
                          value={this.state.adminName}
                          onChange={(e) => { this.setState({ adminName: e.target.value }); if (!this.state.isChangedState.includes('adminName') && edit) { this.isChanged('adminName'); } }}
                          error={isError && !this.state.adminName}
                        />
                        <Input
                          type="text"
                          label={`${tra('Authorized_Person_Surname')}*`}
                          variant="standard"
                          value={this.state.adminSurname}
                          onChange={(e) => { this.setState({ adminSurname: e.target.value }); if (!this.state.isChangedState.includes('adminSurname') && edit) { this.isChanged('adminSurname'); } }}
                          error={isError && !this.state.adminSurname}
                        />
                        <Input
                          type="text"
                          label={`${tra('Authorized_Person_Email')}*`}
                          variant="standard"
                          value={this.state.mail}
                          onChange={(e) => { this.setState({ mail: e.target.value }); if (!this.state.isChangedState.includes('mail') && edit) { this.isChanged('mail'); } }}
                          // disabled
                          error={isError && (!this.state.mail || invalidMail.some((a) => this.state.mail.includes(a)) || !(/\S+@\S+\.\S+/.test(this.state.mail)))}
                        />
                        <Input
                          type="tel"
                          label={`${tra('Authorized_Person_Telephone')}*`}
                          variant="standard"
                          value={this.state.phone}
                          onChange={(e) => { this.setState({ phone: e.floatValue }); if (!this.state.isChangedState.includes('phone') && edit) { this.isChanged('phone'); } }}
                          error={isError && (!this.state.phone || !(this.state.phone?.toString()?.length === 10))}
                        />
                        <Input
                          type="autoComplete"
                          data={representatives}
                          label={tra('Customer_Representative')}
                          variant="standard"
                          multiple
                          fullWidth
                          value={this.state.representatives}
                          onChange={(e, newValue) => { this.setState({ representatives: newValue }); if (!this.state.isChangedState.includes('representatives') && edit) { this.isChanged('representatives'); } }}
                          // error={isError && !(this.state.representatives?.length > 0)}
                        />
                        <Input
                          type="autoComplete"
                          label={tra('Credit_Control')}
                          variant="standard"
                          data={this.props.sapCustomer?.credit_controls || []}
                          // data={this.props.workAreas}
                          multiple
                          fullWidth
                          disabled
                          value={this.props.sapCustomer?.credit_controls || this.state.credit_controls || []}
                          onChange={(e, value) => { this.setState({ credit_controls: value }); if (!this.state.isChangedState.includes('credit_controls') && edit) { this.isChanged('credit_controls'); } }}
                        />
                      </div>
                      <div className="bringCustomer">
                        <Input
                          type="select"
                          variant="standard"
                          label={tra('Verification_Method')}
                          fullWidth
                          data={[{ id: 1, name: tra('SMS') }, { id: 2, name: tra('EMAIL') }, { id: 3, name: tra('EMAIL_AND_SMS') }]}
                          value={this.state.verify}
                          onChange={(e) => { this.setState({ verify: e.target.value }); if (!this.state.isChangedState.includes('verify') && edit) { this.isChanged('verify'); } }}
                        />
                        <CustomButton onClick={() => this.saveFunc()} style={{ marginLeft: this.props.edit && 'auto' }}>
                          {this.props.edit ? tra('Save_Edit') : tra('Add_Customer')}
                        </CustomButton>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          this.state.questionModal && (
            <GenerateModal
              open={this.state.questionModal}
              type="Question"
              title={this.props.edit ? tra('Edit_Customer') : tra('Create_New_Customer')}
              text={tra('Customer_will_be_registered_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.saveFunc()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.setClear()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

NewCustomer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  getSapCustomer: PropTypes.func.isRequired,
  sapCustomer: PropTypes.object.isRequired,
  representatives: PropTypes.array.isRequired,
  // workAreas: PropTypes.array.isRequired,
  createCustomer: PropTypes.func.isRequired,
  setSapCustomer: PropTypes.func.isRequired,
  editCustomer: PropTypes.func.isRequired,
  selectedCustomerId: PropTypes.number,
  customerDetail: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  setCustomerDetail: PropTypes.func.isRequired,
  appConfig: PropTypes.func.isRequired,
  appConfigs: PropTypes.object.isRequired,
};

NewCustomer.defaultProps = {
  edit: false,
  selectedCustomerId: 0,
};

const mapStateToProps = createStructuredSelector({
  // LoginData: selectLogin(),
  representatives: selectRepresentatives(),
  // workAreas: selectWorkAreas(),
  customerDetail: selectCustomerDetail(),
  appConfigs: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      setSapCustomer,
      editCustomer,
      setCustomerDetail,
      setToast,
      appConfig,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomer);
