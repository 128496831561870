import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomButton, CustomFade, CustomIconButton, ErrorLine,
} from '../../assets/styled';
import Input from '../Input';
import GenerateModal from './Info';
import { isMailValid, tra } from '../../commons/utils';
import { invalidMail } from '../../assets/statics';

export default class NewSubuser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subUserId: '',
      isChangedState: [],
      subuserAuth: [{ id: 2, name: tra('Customer_Admin') }, { id: 3, name: tra('Subuser') }, { id: 5, name: tra('Master_Account_Cannot_Be_Changed') }],
      subBeingDeleted: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.subEdit && nextProps.subuserDetail && nextProps.subuserDetail.id !== prevState.subUserId) {
      return {
        mail: nextProps.subuserDetail.email,
        adminName: nextProps.subuserDetail.name,
        adminSurname: nextProps.subuserDetail.surname,
        phone: nextProps.subuserDetail.phone,
        businessArea: nextProps.subuserDetail.BuildingSites || [],
        // subuserType: (prevState.subuserAuth.filter(i => i.id === nextProps.subuserDetail.user_type_id))[0] || {},
        subuserType: nextProps.subuserDetail?.Roles ? nextProps.subuserDetail?.Roles[0] : {},
        subUserId: nextProps.subuserDetail.id,
      };
    }
    return null;
  }

  clearAllandClose = () => {
    this.setState({
      adminName: '',
      adminSurname: '',
      mail: '',
      phone: '',
      subUserId: '',
      isChangedState: [],
      subBeingDeleted: false,
      businessArea: null,
      isError: false,
      errorMessage: '',
    });
    this.props.onClose();
  }

  saveSubuser = () => {
    const {
      mail,
      adminName,
      adminSurname,
      phone,
      businessArea,
      subuserType,
    } = this.state;
    const newBusinessAreaArray = [];
    if (businessArea?.length > 0) {
      businessArea.map((k) => (newBusinessAreaArray.push({ id: k.id })));
    }
    const data = {
      name: adminName,
      surname: adminSurname,
      email: mail?.toLowerCase(),
      phone: phone.toString(),
      roles: [{ id: subuserType.id }],
      user_building_sites: newBusinessAreaArray,
    };
    this.props.createSubuser(data);
  }

  editSubuser = () => {
    const {
      mail,
      adminName,
      adminSurname,
      phone,
      businessArea,
      subuserType,
      isChangedState,
    } = this.state;
    const newBusinessAreaArray = [];
    if (isChangedState.includes('businessArea') && businessArea?.length > 0) {
      businessArea.map((k) => (newBusinessAreaArray.push({ id: k.id })));
    }
    const updatedData = {};
    const data = {
      name: isChangedState.includes('adminName') && adminName,
      surname: isChangedState.includes('adminSurname') && adminSurname,
      email: isChangedState.includes('mail') && mail?.toLowerCase(),
      phone: isChangedState.includes('phone') && phone.toString(),
      roles: isChangedState.includes('subuserType') && [{ id: subuserType.id }],
      user_building_sites: isChangedState.includes('businessArea') && newBusinessAreaArray,
    };
    for (let i = 0; i < Object.keys(data)?.length; ++i) {
      const keysArray = Object.keys(data);
      if (data[keysArray[i]]) {
        updatedData[keysArray[i]] = data[keysArray[i]];
      }
    }
    this.props.editSubuser(updatedData);
  }

  saveFunc = () => {
    const {
      questionModal,
      mail,
      adminName,
      adminSurname,
      phone,
      businessArea,
      subuserType,
    } = this.state;
    if (!this.props.subuserDetail.is_removed) {
      if (/\S+@\S+\.\S+/.test(mail) && isMailValid(mail) && adminName && adminSurname && phone?.toString()?.length === 10 && (this.props.userRoleByType?.find((a) => a.id === this.state.subuserType?.id)?.Permissions?.some((a) => a.id === 25) || this.state.businessArea?.length > 0) && subuserType && !(invalidMail.some((a) => mail.includes(a)))) {
        if (questionModal) {
          if (this.props.subEdit) {
            this.editSubuser();
            this.clearAllandClose();
          } else {
            this.saveSubuser();
            this.clearAllandClose();
          }
        } else {
          this.setState({ questionModal: true });
        }
      } else if (mail && adminName && adminSurname && phone?.toString()?.length === 10 && (this.props.userRoleByType?.find((a) => a.id === this.state.subuserType?.id)?.Permissions?.some((a) => a.id === 25) || businessArea?.length > 0) && subuserType) {
        // this.props.setToast(tra('Please_enter_a_valid_email_address'), 'warning'); // temp
        this.setState({ isError: true, errorMessage: tra('Please_enter_a_valid_email_address'), reRender: Math.random() });
      } else {
        // this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning'); // temp
        this.setState({ isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random() });
      }
    } else {
      this.setState({ errorMessage: tra('To_edit_the_passive_user_you_need_to_activate_it'), reRender: Math.random() });
    }

    // if (this.props.subUser && !this.props.subEdit && !this.state.subBeingDeleted) { // alt kullanıcı ekleme
    //   if (/\S+@\S+\.\S+/.test(mail) && isMailValid(mail) && adminName && adminSurname && phone?.toString()?.length === 10 && businessArea && subuserType && !(invalidMail.some((a) => mail.includes(a)))) {
    //     if (questionModal) {
    //       this.saveSubuser();
    //       this.clearAllandClose();
    //     } else {
    //       this.setState({ questionModal: true });
    //     }
    //   } else if (isMailValid(mail) && adminName && adminSurname && phone?.toString()?.length === 10 && businessArea?.length > 0 && subuserType) {
    //     this.props.setToast(tra('Please_enter_a_valid_email_address'), 'warning'); // temp
    //     this.setState({ isError: true, errorMessage: tra('Please_enter_a_valid_email_address'), reRender: Math.random() });
    //   } else {
    //     this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning'); // temp
    //     this.setState({ isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random() });
    //   }
    // } else if (this.state.subBeingDeleted) { // alt kullanıcı sil
    //   this.props.deleteSubuser();
    //   this.clearAllandClose();
    // } else if (this.props.subEdit && /[a-z0-9]+@\S+\.\S+/.test(mail) && isMailValid(mail) && adminName && adminSurname && phone?.toString()?.length === 10 && businessArea?.length > 0 && subuserType && !(invalidMail.some((a) => mail.includes(a)))) { // alt kullanıcı edit
    //   if (questionModal) {
    //     this.editSubuser();
    //     this.clearAllandClose();
    //   } else {
    //     this.setState({ questionModal: true });
    //   }
    // } else if (mail && isMailValid(mail) && adminName && adminSurname && phone?.toString()?.length === 10 && businessArea?.length > 0 && subuserType) {
    //   this.setState({ isError: true, errorMessage: tra('Please_enter_a_valid_email_address'), reRender: Math.random() });
    //   // this.props.setToast(tra('Please_enter_a_valid_email_address'), 'warning'); // temp
    // } else {
    //   this.setState({ isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random() });
    //   // this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning'); // temp
    // }
    // // this.setState({ questionModal: false });
  }

  deleteUser = () => {
    if (this.state.subBeingDeleted && this.state.questionModal) {
      this.props.deleteSubuser();
      this.setState({ questionModal: false, subBeingDeleted: false });
      this.close(true);
    } else {
      this.setState({ questionModal: true });
    }
  }

  isChanged = (stateName) => { // değişim yapılan inputları tespit etme
    const state = this.state[stateName];
    const current = this.props.subuserDetail[stateName];
    if (state !== current) {
      const { isChangedState } = this.state;
      if (!isChangedState.includes(stateName)) {
        isChangedState.push(stateName);
      }
      this.setState({ isChangedState });
    }
  }

  close = (isSure) => {
    const {
      adminName, adminSurname, phone, mail, businessArea, subuserType,
    } = this.state;
    if ((adminName || adminSurname || phone || mail || businessArea?.length > 0 || subuserType || this.props.subEdit) && !isSure) {
      this.setState({
        sureModal: true,
      });
    } else {
      this.clearAllandClose();
    }
  }

  render() {
    const { isError, errorMessage, reRender } = this.state;
    const { open, subEdit, subuserDetail } = this.props;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          // onExit={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{this.props.subEdit ? tra('Edit_Subser') : tra('Create_Subuser')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content">
                {errorMessage && errorMessage?.length && (
                  <ErrorLine key={reRender}>
                    {errorMessage}!
                  </ErrorLine>
                )}
                <div className="formWrapper">
                  <Input
                    type="text"
                    label={tra('Authorized_Person_Name')}
                    variant="standard"
                    autoFocus
                    value={this.state.adminName}
                    onChange={(e) => { this.setState({ adminName: e.target.value }); if (!this.state.isChangedState.includes('adminName')) { this.isChanged('adminName'); } }}
                    error={isError && !this.state.adminName}
                    disabled={this.props.subuserDetail?.is_removed}
                  />
                  <Input
                    type="text"
                    label={tra('Authorized_Person_Surname')}
                    variant="standard"
                    value={this.state.adminSurname}
                    onChange={(e) => { this.setState({ adminSurname: e.target.value }); if (!this.state.isChangedState.includes('adminSurname')) { this.isChanged('adminSurname'); } }}
                    error={isError && !this.state.adminSurname}
                    disabled={this.props.subuserDetail?.is_removed}
                  />
                  <Input
                    type="tel"
                    label={tra('Telephone')}
                    variant="standard"
                    value={this.state.phone}
                    onChange={(e) => { this.setState({ phone: e.floatValue }); if (!this.state.isChangedState.includes('phone')) { this.isChanged('phone'); } }}
                    error={isError && (!this.state.phone || !(this.state.phone?.toString()?.length === 10))}
                    disabled={this.props.subuserDetail?.is_removed}
                  />
                  <Input
                    type="text"
                    label={tra('Email')}
                    variant="standard"
                    value={this.state.mail}
                    onChange={(e) => { this.setState({ mail: e.target.value }); if (!this.state.isChangedState.includes('mail') && subEdit) { this.isChanged('mail'); } }}
                    error={isError && (!this.state.mail || invalidMail.some((a) => this.state.mail.includes(a)) || !(/\S+@\S+\.\S+/.test(this.state.mail)))}
                    disabled={this.props.subuserDetail?.is_removed}
                  />
                  <Input
                    type="autoComplete"
                    label={tra('Subuser_Type')}
                    variant="standard"
                    data={this.props.userRoleByType}
                    fullWidth
                    value={this.state.subuserType}
                    onChange={(e, value) => { this.setState({ subuserType: value, businessArea: null }); if (!this.state.isChangedState.includes('subuserType') && subEdit) { this.isChanged('subuserType'); } }}
                    disabled={(this.props.subuserDetail && this.props.subuserDetail.id === 5) || this.props.subuserDetail?.is_removed} // fix
                    error={isError && !this.state.subuserType}
                  />
                  {
                    (this.state.subuserType && this.props.userRoleByType && !(this.props.userRoleByType?.find((a) => a.id === this.state.subuserType?.id)?.Permissions?.some((a) => a.id === 25))) && (
                      <Input
                        type="autoComplete"
                        label={tra('Building_Site')}
                        variant="standard"
                        data={this.props.allBuildingSites || []}
                        defaultVal={this.props.subuserDetail.BuildingSites}
                        multiple
                        fullWidth
                        selectAll
                        value={this.state.businessArea || []}
                        onChange={(e, value) => { this.setState({ businessArea: value }); if (!this.state.isChangedState.includes('businessArea') && subEdit) { this.isChanged('businessArea'); } }}
                        disabled={(this.props.subuserDetail && this.props.subuserDetail.id === 5) || this.props.subuserDetail?.is_removed} // fix
                        error={isError && !(this.state.businessArea?.length > 0)}
                      />
                    )
                  }
                </div>
                <div className="bringCustomer">
                  {
                    // !this.props.subUser && (
                    //   <Input
                    //     type="select"
                    //     variant="standard"
                    //     label={tra('Verification_Method')}
                    //     fullWidth
                    //     data={[{ id: 1, name: 'SMS' }, { id: 2, name: 'EMAIL' }, { id: 3, name: 'EMAIL VE SMS' }]}
                    //     value={this.state.verify}
                    //     onChange={(e) => this.setState({ verify: e.target.value })}
                    //   />
                    // )
                  }
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  {
                    this.props.subEdit && this.props.subuserDetail && this.props.subuserDetail.user_type_id !== 5 && (
                      <CustomButton pink={!subuserDetail.is_removed} green={subuserDetail.is_removed} onClick={() => this.setState({ subBeingDeleted: true }, () => this.deleteUser())}>
                        {!subuserDetail.is_removed ? tra('Deactivate_User') : tra('Activate_User')}
                      </CustomButton>
                    )
                  }
                  <CustomButton onClick={() => this.saveFunc()}>
                    {this.props.subEdit ? tra('Save_Edit') : tra('Add_User')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          this.state.questionModal && (
            <GenerateModal
              open={this.state.questionModal}
              type={this.state.subBeingDeleted ? (subuserDetail.is_removed ? 'Warning' : 'Warning') : 'Question'}
              title={this.state.subBeingDeleted ? (subuserDetail.is_removed ? tra('Subuser_Will_Be_Active') : tra('Deactivate_User')) : this.props.subEdit ? tra('Subuser_To_Be_Edited') : this.props.subUser ? tra('Create_New_Subuser') : tra('Create_New_Customer')}
              text={this.state.subBeingDeleted ? (subuserDetail.is_removed ? tra('Subuser_will_be_activated_Do_you_confirm') : tra('Subuser_To_Be_Passive_Do_You_Confirm')) : this.props.subEdit ? tra('Subuser_To_Be_Edited_Do_you_Confirm') : this.props.subUser ? tra('Subuser_Will_Be_Registered_Do_You_Confirm') : tra('Customer_will_be_registered_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ subBeingDeleted: false, questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => (this.state.subBeingDeleted ? this.deleteUser() : this.saveFunc())}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.clearAllandClose()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

NewSubuser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subEdit: PropTypes.bool,
  subUser: PropTypes.bool,
  allBuildingSites: PropTypes.array.isRequired,
  createSubuser: PropTypes.func.isRequired,
  editSubuser: PropTypes.func.isRequired,
  subuserDetail: PropTypes.object,
  deleteSubuser: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  userRoleByType: PropTypes.array.isRequired,
};

NewSubuser.defaultProps = {
  subUser: false,
  subEdit: false,
  subuserDetail: {},
};
