// GENERAL
export const SET_TOAST = 'SET_TOAST';
export const DO_LOGIN = 'DO_LOGIN';
export const ENTER_CODE = 'ENTER_CODE';
export const APP_CONFIG = 'APP_CONFIG';
export const SERVICE_CONFIG = 'SERVICE_CONFIG';
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// CUSTOMER
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMER_DETAIL = 'SET_CUSTOMER_DETAIL';
export const GET_SAPCUSTOMER = 'GET_SAPCUSTOMER';
export const SET_SAPCUSTOMER = 'SET_SAPCUSTOMER';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';

// PRODUCTS
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const BLOCKAGE_PRODUCTS = 'BLOCKAGE_PRODUCTS';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const GET_SAPPRODUCT = 'GET_SAPPRODUCT';
export const SET_SAPPRODUCT = 'SET_SAPPRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const GET_BLOCKAGES = 'GET_BLOCKAGES';
export const SET_BLOCKAGES = 'SET_BLOCKAGES';
export const DELETE_BLOCKAGES = 'DELETE_BLOCKAGES';
export const GET_CUSTOMER_BY_CREDIT = 'GET_CUSTOMER_BY_CREDIT';
export const SET_CUSTOMER_BY_CREDIT = 'SET_CUSTOMER_BY_CREDIT';

// ORDERS
export const GET_ORDERS = 'GET_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDER_DETAIL = 'SET_ORDER_DETAIL';
export const CREATE_ORDERS = 'CREATE_ORDERS';
export const GET_ORDER_PRODUCT = 'GET_ORDER_PRODUCT';
export const SET_ORDER_PRODUCT = 'SET_ORDER_PRODUCT';
export const ORDER_TONNAGE_UPDATE = 'ORDER_TONNAGE_UPDATE';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const GET_CREDIT_LIMIT = 'GET_CREDIT_LIMIT';
export const SET_CREDIT_LIMIT = 'SET_CREDIT_LIMIT';
export const GET_DEVLIVERIES = 'GET_DEVLIVERIES';
export const SET_DEVLIVERIES = 'SET_DEVLIVERIES';
export const GET_NOT_SAP_ORDER = 'GET_NOT_SAP_ORDER';
export const SET_NOT_SAP_ORDER = 'SET_NOT_SAP_ORDER';
export const GET_TONNAGE_UPDATES = 'GET_TONNAGE_UPDATES';
export const SET_TONNAGE_UPDATES = 'SET_TONNAGE_UPDATES';
export const CONFIRMDECLINE = 'CONFIRMDECLINE';

// INIT
export const INIT = 'INIT';
export const SET_INIT = 'SET_INIT';
export const KVKK_APROVE = 'KVKK_APROVE';
export const SURVEY_APROVE = 'SURVEY_APROVE';

// USERS
export const GET_SUBUSERS = 'GET_SUBUSERS';
export const SET_SUBUSERS = 'SET_SUBUSERS';
export const CREATE_SUBUSER = 'CREATE_SUBUSER';
export const SET_SUBUSER_DETAIL = 'SET_SUBUSER_DETAIL';
export const EDIT_SUBUSER = 'EDIT_SUBUSER';
export const DELETE_SUBUSER = 'DELETE_SUBUSER';
export const GET_SAPUSER = 'GET_SAPUSER';
export const SET_SAPUSER = 'SET_SAPUSER';
export const SWAP_CUSTOMERS = 'SWAP_CUSTOMERS';
export const GET_USER_ROLE_BY_TYPE = 'GET_USER_ROLE_BY_TYPE';
export const SET_USER_ROLE_BY_TYPE = 'SET_USER_ROLE_BY_TYPE';

// INVOICES
export const GET_INVOICES = 'GET_INVOICES';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_OPEN_INVOICES = 'SET_OPEN_INVOICES';
export const GET_INVOICELINK = 'GET_INVOICELINK';
export const SET_INVOICELINK = 'SET_INVOICELINK';

// PAYMENTS
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const SET_PAYMENTS = 'SET_PAYMENTS';

// DASHBOARD
export const GET_WARNINGS = 'GET_WARNINGS';
export const SET_WARNINGS = 'SET_WARNINGS';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const GET_DASHBOARD_CHART = 'GET_DASHBOARD_CHART';
export const SET_DASHBOARD_CHART = 'SET_DASHBOARD_CHART';
export const GET_DASHBOARD_BY_CREDIT_CONTROL = 'GET_DASHBOARD_BY_CREDIT_CONTROL';
export const SET_DASHBOARD_BY_CREDIT_CONTROL = 'SET_DASHBOARD_BY_CREDIT_CONTROL';

// REPORTS
export const GET_REPORTS = 'GET_REPORTS';
export const SET_REPORTS = 'SET_REPORTS';

export const GET_ORDER_REPORTS = 'GET_ORDER_REPORTS';
export const SET_ORDER_REPORTS = 'SET_ORDER_REPORTS';

// BUNILDING SITES
export const GET_BUNILDING_SITES = 'GET_BUNILDING_SITES';
export const SET_BUNILDING_SITES = 'SET_BUNILDING_SITES';

// SHIPPING COST
export const GET_SHIPPING_COST = 'GET_SHIPPING_COST';
export const SET_SHIPPING_COST = 'SET_SHIPPING_COST';

export const CONDITIONAL_SERVICE = 'CONDITIONAL_SERVICE';

// LOG
export const GET_LOG = 'GET_LOG';
export const SET_LOG = 'SET_LOG';

// ADMIN
export const SET_WORKAREA_ALTERNATIVE = 'SET_WORKAREA_ALTERNATIVE';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const SET_ALL_ROLES = 'SET_ALL_ROLES';
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS';
export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS';
export const CREATE_ROLE = 'CREATE_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';
export const GET_CREDIT_CONTROL = 'GET_CREDIT_CONTROL';
export const SET_CREDIT_CONTROL = 'SET_CREDIT_CONTROL';
export const REMOVE_CREDIT_CONTROL = 'REMOVE_CREDIT_CONTROL';
export const TOGGLE_CREDIT_LIMIT = 'TOGGLE_CREDIT_LIMIT';
export const GET_COMPANY_CODES = 'GET_COMPANY_CODES';
export const SET_COMPANY_CODES = 'SET_COMPANY_CODES';
export const EDIT_MATCH_CREDIT_CONTROL = 'EDIT_MATCH_CREDIT_CONTROL';
export const COMPANY_SETTING_UPDATE = 'COMPANY_SETTING_UPDATE';
export const GET_PAYMENT_TERMS = 'GET_PAYMENT_TERMS';
export const SET_PAYMENT_TERMS = 'SET_PAYMENT_TERMS';
export const UPDATE_SAP_PAYMNET_TERMS = 'UPDATE_SAP_PAYMNET_TERMS';
export const UPDATE_SAP_CREDIT_CONTROLS = 'UPDATE_SAP_CREDIT_CONTROLS';
export const UPDATE_SAP_PRODUCTS = 'UPDATE_SAP_PRODUCTS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const GET_DASHBOARD_ADMIN = 'GET_DASHBOARD_ADMIN';
export const SET_DASHBOARD_ADMIN = 'SET_DASHBOARD_ADMIN';
export const GET_DASHBOARD_ADMIN_CHARTS = 'GET_DASHBOARD_ADMIN_CHARTS';
export const SET_DASHBOARD_ADMIN_CHARTS = 'SET_DASHBOARD_ADMIN_CHARTS';
export const GET_DASHBOARD_ADMIN_PRODUCT_CHARTS = 'GET_DASHBOARD_ADMIN_PRODUCT_CHARTS';
export const SET_DASHBOARD_ADMIN_PRODUCT_CHARTS = 'SET_DASHBOARD_ADMIN_PRODUCT_CHARTS';
export const GET_REPORT_ADMIN = 'GET_REPORT_ADMIN';
export const SET_REPORT_ADMIN_ORDER = 'SET_REPORT_ADMIN_ORDER';
export const SET_REPORT_ADMIN_MONTH = 'SET_REPORT_ADMIN_MONTH';
export const SET_REPORT_ADMIN_BUILDING_SITES = 'SET_REPORT_ADMIN_BUILDING_SITES';
export const UPDATE_SAP_CUSTOMER = 'UPDATE_SAP_CUSTOMER';

// ANNOUNCEMENT
export const ANNOUNCE = 'ANNOUNCE';
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';

// EXCEL
export const GET_EXCEL = 'GET_EXCEL';
export const SET_EXCEL = 'SET_EXCEL';
