import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { InputAdornment, TextField } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { ChangePasswordWrapper, CustomButton, ErrorLine } from '../assets/styled';
import { tra } from '../commons/utils';
import { Colors } from '../assets/statics';
import Info from '../components/Modals/Info';
import { changePassword } from '../store/actions';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // oldPassword: '',
      newPassword: '',
      newPasswordAgain: '',
      confirmModal: false,
      errorMessage: '',
      reRender: 0,
    };
  }

  saveFunc = () => {
    const { newPassword, newPasswordAgain, confirmModal } = this.state;
    this.setState({ errorMessage: null, reRender: Math.random() });
    if (newPassword && newPasswordAgain && (newPassword === newPasswordAgain) && newPassword?.length > 7 && newPassword?.length < 33) {
      this.setState({ errorMessage: null, reRender: Math.random() });
      if (confirmModal) {
        this.props.changePassword('reset', { password: newPassword, type: 2 });
      } else {
        this.setState({ confirmModal: true });
      }
    } else if (newPassword && newPasswordAgain && newPassword !== newPasswordAgain) {
      this.setState({ errorMessage: tra('New_passwords_are_not_the_same'), reRender: Math.random() });
    } else if (newPassword && newPasswordAgain && !(newPassword?.length > 7 && newPassword?.length < 33)) {
      this.setState({ errorMessage: tra('Password_must_be_between_8_32_characters_long'), reRender: Math.random() });
    } else {
      this.setState({ errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random() });
    }
  }

  render() {
    const {
      newPassword, newPasswordAgain, errorMessage,
    } = this.state;
    return (
      <ChangePasswordWrapper>
        <div className="content">
          {errorMessage && errorMessage?.length && (
            <ErrorLine key={this.state.reRender}>
              {errorMessage}!
            </ErrorLine>
          )}
          {/* <p>{tra('Please_chance_password_by_entering_the_password_given_to_you')}</p> */}
          <p>{tra('Please_Create_a_New_8_character_Password')}</p>
          {/* <TextField
            type="password"
            variant="outlined"
            fullWidth
            label={tra('Password')}
            autoFocus
            value={oldPassword}
            onChange={(e) => this.setState({ oldPassword: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined style={{ color: Colors.grey }} />
                </InputAdornment>
              ),
            }}
          /> */}
          <TextField
            type="password"
            variant="outlined"
            fullWidth
            label={tra('New_Password')}
            value={newPassword}
            onChange={(e) => this.setState({ newPassword: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined style={{ color: Colors.grey }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="password"
            variant="outlined"
            fullWidth
            label={tra('New_Password_Again')}
            value={newPasswordAgain}
            onChange={(e) => this.setState({ newPasswordAgain: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined style={{ color: Colors.grey }} />
                </InputAdornment>
              ),
            }}
          />
          <CustomButton blue style={{ float: 'right' }} onClick={() => this.saveFunc()}>{tra('Save')}</CustomButton>
        </div>
        {
          this.state.confirmModal && (
            <Info
              open={this.state.confirmModal}
              type="Question"
              title={tra('Confirmation')}
              text={tra('The_password_will_change_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ confirmModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.saveFunc()}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
      </ChangePasswordWrapper>
    );
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  // dashboardData: PropTypes.object.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  // dashboardData: selectDashboard(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      changePassword,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
