import { Backdrop, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  CustomButton, CustomFade, CustomIconButton, ErrorLine,
} from '../../assets/styled';
import { tra } from '../../commons/utils';
import Input from '../Input';
import Info from './Info';

export default function NewProduct(props) {
  const [sapNo, setSapNo] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sapProductName, setSapProductName] = useState(null);
  const [productAlternativeName, setProductAlternativeName] = useState(null);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (props?.sapProduct?.sap_product_no) {
      setSapNo(props?.sapProduct?.sap_product_no);
    }
  }, [props.sapProduct.sap_product_no, sapNo]);

  function saveProduct() {
    const { sapProduct } = props;
    const data = {
      ...sapProduct,
      alternative_name: productAlternativeName,
    };
    if (productAlternativeName) {
      if (confirmModal) {
        props.createProduct(data);
        setConfirmModal(false);
        props.onClose();
      } else {
        setConfirmModal(true);
      }
    } else {
      // this.setState({ isError: true, errorMessage: tra('Please_Fill_in_the_mandatory_fields'), reRender: Math.random() });
      setIsError(true);
      setErrorMessage(tra('Please_Fill_in_the_mandatory_fields'));
      props.setToast(true, tra('Please_Fill_in_the_mandatory_fields'), 'warning');
    }
  }

  function enterSapNumber(e) {
    if (sapProductName?.length > 0 && e.key === 'Enter') {
      props.getSapProduct(sapProductName);
    }
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFade in={props.open}>
          <div>
            <div className="header">
              <h1>Yeni Ürün Ekle</h1>
              <CustomIconButton onClick={() => props.onClose()}><Close /></CustomIconButton>
            </div>
            <div className="content">
              {errorMessage && errorMessage?.length && (
                <ErrorLine key={errorMessage}>
                  {errorMessage}!
                </ErrorLine>
              )}
              {
                !sapNo ? (
                  <div className="bringCustomer">
                    <Input
                      type="text"
                      label={tra('SAP_Product_Number')}
                      variant="standard"
                      value={sapProductName}
                      fullWidth
                      autoFocus
                      onChange={(e) => setSapProductName(e.target.value)}
                      onKeyDown={(e) => enterSapNumber(e)}
                    />
                    <CustomButton onClick={() => { props.getSapProduct(sapProductName); setSapNo(123); }}>{tra('Bring')}</CustomButton>
                  </div>
                ) : (
                  <div className="formWrapper">
                    <Input
                      type="text"
                      variant="standard"
                      label={tra('SAP_Product_Number')}
                      value={props?.sapProduct?.sap_product_no}
                      disabled
                    />
                    <Input
                      type="text"
                      variant="standard"
                      label={tra('Product_Name')}
                      value={props?.sapProduct?.name}
                      disabled
                    />
                    <Input
                      type="text"
                      variant="standard"
                      label={tra('Product_Group')}
                      value={props?.sapProduct?.product_group_id}
                      disabled
                    />
                    <Input
                      type="text"
                      variant="standard"
                      label={tra('Product_Description')}
                      value={productAlternativeName}
                      autoFocus
                      onChange={(e) => setProductAlternativeName(e.target.value)}
                      error={isError && !productAlternativeName}
                    />
                    <CustomButton simple onClick={() => props.onClose()} style={{ marginLeft: 0 }}>{tra('Cancel')}</CustomButton>
                    <CustomButton onClick={() => saveProduct()}>{tra('Save')}</CustomButton>
                  </div>
                )
              }
            </div>
          </div>
        </CustomFade>
      </Modal>
      {
        confirmModal && (
          <Info
            open={confirmModal}
            type="Question"
            title={tra('Confirmation')}
            text={tra('A_new_product_will_be_created_Do_you_confirm')}
            cancelButton={(<CustomButton grey onClick={() => setConfirmModal(false)}>{tra('Cancel')}</CustomButton>)}
            confirmButton={(<CustomButton blue onClick={() => saveProduct()}>{tra('Save')}</CustomButton>)}
          />
        )
      }
    </>
  );
}

NewProduct.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  sapProduct: PropTypes.object.isRequired,
  getSapProduct: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
};

NewProduct.defaultProps = {
};
