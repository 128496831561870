import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { tra } from '../commons/utils';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return null;
  }, [delay]);
}

function Timer(props) {
  const [seconds, setSeconds] = useState(props.initialSeconds);
  const [minutes, setMinutes] = useState(props.initialMinute);
  const [status, setStatus] = useState(true);

  const resetSec = useCallback(() => setSeconds(props.initialSeconds), []);
  const resetMin = useCallback(() => setMinutes(props.initialMinute), []);
  const resetStatus = useCallback(() => setStatus(true), []);

  useEffect(() => {
    if (props.reset) {
      // setSeconds(props.initialSeconds);
      // setMinutes(props.initialMinute);
      resetSec();
      resetMin();
      if (!status) setStatus(true);
    }
  }, [props.initialMinute, props.initialSeconds, props.reset, resetMin, resetSec, resetStatus, status]);

  useInterval(
    () => {
      // if (seconds > 0) {
      //   setSeconds(seconds - 1);
      // } else {
      //   setStatus(false);
      // }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // setStatus(false);
          props.expired();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    },
    status === true ? 1000 : null,
    // passing null stops the interval
  );
  return (
    <div>
      <div style={{ padding: 20 }}>
        {
          minutes === 0 && seconds === 0 ? (
            <h2>{tra('Times_up_Sign_in_again')}</h2>
          ) : (
            <h2 style={{ textAlign: 'center', fontSize: '32px' }}> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h2>
          )
        }
      </div>
    </div>
  );
}

Timer.propTypes = {
  initialMinute: PropTypes.number.isRequired,
  initialSeconds: PropTypes.number.isRequired,
  reset: PropTypes.bool.isRequired,
  expired: PropTypes.func.isRequired,
};

Timer.defaultProps = {
};

export default Timer;
