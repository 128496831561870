import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop, Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomButton, CustomFade, CustomIconButton, ErrorLine,
} from '../../assets/styled';
import Input from '../Input';
import GenerateModal from './Info';
import { tra } from '../../commons/utils';

export default class NewRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionData: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.edit && nextProps.roleDetail && !prevState.selectedPermissions && !prevState.name && !prevState.type) {
      return {
        name: nextProps.roleDetail?.name,
        type: nextProps.roleDetail?.type,
        selectedPermissions: nextProps.roleDetail?.Permissions,
        permissionData: nextProps.permissions,
      };
    }
    if (nextProps.permissions && !prevState.permissionData) {
      return {
        permissionData: nextProps.permissions,
      };
    }
    return null;
  }

  clearAllandClose = () => {
    this.setState({
      questionModal: false,
      selectedPermissions: null,
      name: null,
    });
    this.props.onClose();
  }

  saveFunc = () => {
    const {
      selectedPermissions, name, questionModal, type,
    } = this.state;
    const newselectedPermissions = [];
    (selectedPermissions || []).map((k) => (newselectedPermissions.push({ permission_id: k.id })));
    const data = {
      name,
      type,
      RolePermissions: newselectedPermissions,
    };
    if (selectedPermissions?.length > 0 && name && type) { // yetki ekleme
      if (questionModal) {
        if (this.props.edit) {
          this.props.saveRole(data, this.props.roleDetail?.id);
          this.clearAllandClose();
        } else {
          this.props.saveRole(data);
          this.clearAllandClose();
        }
      } else {
        this.setState({ questionModal: true });
      }
    } else {
      this.setState({ errorMessage: tra('Please_Fill_in_the_mandatory_fields'), isError: true, reRender: Math.random() });
    }
    // this.setState({ questionModal: false });
  }

  close = () => {
    const { name, type, selectedPermissions } = this.state;
    if (name || type || selectedPermissions?.length > 0 || type || type || type || type || this.props.edit) {
      this.setState({
        sureModal: true,
      });
    } else {
      this.clearAllandClose();
    }
  }

  render() {
    const {
      open, permissions,
    } = this.props;
    const {
      questionModal, name, selectedPermissions, type, errorMessage, isError, permissionData,
    } = this.state;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{this.props.edit ? tra('Edit_Roles') : tra('New_Roles')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content">
                {errorMessage && errorMessage?.length && (
                  <ErrorLine key={this.state.reRender}>
                    {errorMessage}!
                  </ErrorLine>
                )}
                <div className="formWrapper" style={{ width: '100%' }}>
                  <Input
                    type="text"
                    label={tra('Name')}
                    variant="standard"
                    fullWidth
                    value={name || ''}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    error={isError && !name}
                  />
                  <Input
                    type="select"
                    label={tra('Type')}
                    variant="standard"
                    fullWidth
                    data={[{ id: 1, name: tra('Cimsa') }, { id: 2, name: tra('Customer') }]}
                    value={type || null}
                    onChange={(e) => {
                      this.setState({ type: e.target.value, selectedPermissions: [] });
                      if (e.target.value === 1) this.setState({ permissionData: permissions });
                      if (e.target.value === 2) this.setState({ permissionData: permissions?.filter((p) => p?.is_customer) });
                    }}
                    error={isError && !type}
                  />
                  <Input
                    type="autoComplete"
                    label={tra('Permissions')}
                    variant="standard"
                    data={permissionData || []}
                    fullWidth
                    multiple
                    value={selectedPermissions || []}
                    onChange={(e, value) => this.setState({ selectedPermissions: value })}
                    disableCloseOnSelect
                    error={isError && !(selectedPermissions?.length > 0)}
                  />
                  {/* <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={Permissions}
                    fullWidth
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name}
                    style={{ width: '100%' }}
                    onChange={(e, value) => this.setState({ selectedPermissions: value })}
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank color="primary" />}
                          checkedIcon={<CheckBox color="primary" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.name}
                      </>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="standard" label={tra('Permissions')} placeholder={tra('Choose_Permission')} />
                    )}
                  /> */}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <CustomButton onClick={() => this.saveFunc()}>
                    {this.props.edit ? tra('Save_Edit') : tra('Save')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          questionModal && (
            <GenerateModal
              open={questionModal}
              type="Info"
              title={this.props.edit ? tra('The_role_is_being_edited') : tra('The_role_is_being_created')}
              text={this.props.edit ? tra('Editing_the_role_Do_you_confirm') : tra('Creating_a_new_role_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.saveFunc()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.clearAllandClose()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

NewRole.propTypes = {
  open: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  roleDetail: PropTypes.object.isRequired,
  saveRole: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
};

NewRole.defaultProps = {
};
