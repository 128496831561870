import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import {
  // CustomButton,
  CustomFadeInfo,
} from '../../assets/styled';
import {
  ConfirmInfo,
  DeleteInfo,
  InfoInfo,
  QuestionInfo,
  WarningInfo,
} from '../../assets/icons';
import { Colors } from '../../assets/statics';

const types = {
  Success: {
    icon: (<ConfirmInfo />),
    color: Colors.green,
  },
  Warning: {
    icon: (<WarningInfo />),
    color: Colors.orange,
  },
  Info: {
    icon: (<InfoInfo />),
    color: Colors.blue,
  },
  Error: {
    icon: (<DeleteInfo />),
    color: Colors.burgundy,
  },
  Question: {
    icon: (<QuestionInfo />),
    color: Colors.blueLight2,
  },
};

export default class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      open, type, text, title,
    } = this.props;
    return (
      <Modal
        open={open}
        onClose={() => {}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CustomFadeInfo in={open}>
          <div>
            <div className="content">
              {
                type && (
                  <>
                    {types[type].icon}
                    <h2 style={{ color: types[type].color, textAlign: 'center' }}>{title}</h2>
                    <p>{text}</p>
                    <div className="buttonWrapper">
                      {this.props.cancelButton}
                      {this.props.confirmButton}
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </CustomFadeInfo>
      </Modal>
    );
  }
}

Info.propTypes = {
  open: PropTypes.bool.isRequired,
  // onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cancelButton: PropTypes.any.isRequired,
  confirmButton: PropTypes.any,
};

Info.defaultProps = {
  confirmButton: null,
};
