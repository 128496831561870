import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  ClickAwayListener, Grid, Tooltip, Zoom,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  CustomButton,
  CustomTextField,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  SearchBar,
  // TableNameBox,
  CustomIconButton,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  // DeactiveMenu,
  // EditMenu,
  Excel,
  ManageMenu,
  MobileTripleIcon,
} from '../assets/icons';
import { formatPhoneNumber, tra } from '../commons/utils';

import { getCustomers, init, appConfig } from '../store/actions';
import { selectCustomers } from '../store/selectors';
import Info from '../components/Modals/Info';
import CustomFilter from '../components/CustomFilter';

class CustomersManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    props.getCustomers();
  }

  godMode = () => {
    const { selectedCustomer } = this.state;
    localStorage.removeItem('company');
    localStorage.setItem('godModeCustomerId', selectedCustomer.id);
    localStorage.setItem('customer', selectedCustomer.id);
    localStorage.setItem('userIsAdmin', parseInt(selectedCustomer?.sap_customer_no, 10) === 0);
    this.props.appConfig('userIsAdmin', parseInt(selectedCustomer?.sap_customer_no, 10) === 0);
    this.props.appConfig('godMode', true);
    this.props.appConfig('godModeCustomer', selectedCustomer);
    this.props.init();
  }

  render() {
    const {
      searchFilter, selectedCustomer, filterDrawer, filter,
    } = this.state;
    const { customers } = this.props;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (customers && customers?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : customers;
    const columns = [
      {
        name: 'is_removed',
        text: tra('Active'),
        filterable: false,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
        customBody: (value) => (
          <div style={{
            width: 15,
            height: 15,
            borderRadius: '100%',
            background: value ? Colors.burgundy : Colors.blue,
            margin: '0 auto',
          }}
          />
        ),
      },
      {
        name: 'sap_customer_no',
        text: tra('Customer_No'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'name',
        text: tra('Customer'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'authorized_name',
        text: tra('Authorized_Person'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value, column, index, row) => `${value} ${row.authorized_surname}`,
      },
      {
        name: 'authorized_phone',
        text: tra('Authorized_Phone'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value) => formatPhoneNumber(value),
      },
      {
        name: 'email',
        text: tra('Authorized_Email'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'phone',
        text: tra('Contact_Info'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'CreditControlAreas',
        text: tra('Credit_Control'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value?.length > 1 ? value?.map((c) => (<p>{c.name}</p>)) : ''} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.name} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'Action',
        text: tra('Action'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: false,
        type: 'action',
        disableSetting: false,
        customBody: (value, columnName, index, row) => (
          <div className="tableButton">
            <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
              <MobileTripleIcon />
            </CustomIconButton>
            {
              this.state[`anchorMobileTable${row.id}`] && (
                <MobileMoreMenu
                  className="mobileMoreMenu"
                  anchorEl={this.state[`anchorMobileTable${row.id}`]}
                  keepMounted
                  open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                  onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                  // placement="left"
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                >
                  {/* <MobileMoreMenuItem
                    // active={!this.state[`columnVisibility${tabValue}`]}
                    onClick={() => { this.setState({ openNewCustomer: true, [`anchorMobileTable${row.id}`]: null }); }}
                  >
                    <EditMenu />
                    {tra('Edit')}
                  </MobileMoreMenuItem> */}
                  <MobileMoreMenuItem
                    // active={!this.state[`columnVisibility${tabValue}`]}
                    onClick={() => {
                      if (row.is_removed) {
                        this.props.appConfig(
                          'infoModal', {
                            open: true,
                            type: 'Info',
                            title: tra('You_Cannot_Manage_This_Customer'),
                            text: tra('You_Cannot_Manage_This_Customer_Because_It_Is_Inactive_In_order_to_Manage_the_Customer_first_activate_it'),
                            cancelButton: (<CustomButton grey onClick={() => this.props.appConfig('infoModal', false)}>{tra('Done')}</CustomButton>),
                          },
                        );
                      } else {
                        this.setState({
                          [`anchorMobileTable${row.id}`]: null,
                          selectedCustomer: row,
                          questionModal: true,
                        });
                      }
                    }}
                  >
                    <ManageMenu />
                    {tra('Manage')}
                  </MobileMoreMenuItem>
                  {/* <MobileMoreMenuItem
                    // active={!this.state[`columnVisibility${tabValue}`]}
                    onClick={() => { this.setState({ detailModalOpen: true, [`anchorMobileTable${row.id}`]: null }); }}
                  >
                    <DeactiveMenu />
                    {tra('Make_Passive')}
                  </MobileMoreMenuItem> */}
                </MobileMoreMenu>
              )
            }
          </div>
        ),
      },
    ];
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={columns}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState({ filterDrawer: !filterDrawer })}>{tra('Filter')}</CustomButton>
              </div>
              <div className="rightButtons">
                <CustomButton
                  lightBlue
                  startIcon={<Excel />}
                >
                  {tra('Download_Excel')}
                </CustomButton>
              </div>
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData}
              columns={columns || []}
              id="CustomersManagementTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                // filter: {
                //   show: true,
                //   filters: [],
                //   onChange: (state, value, text, type) => console.log(state, value, text, type),
                //   drawerChange: () => this.setState(prevstate => ({ filterDrawer: !prevstate.filterDrawer })),
                // },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              rowClick={() => {}}
            />
          </Grid>
        </Grid>
        {
          this.state.questionModal && selectedCustomer && (
            <Info
              open
              type="Warning"
              title={tra('Confirmation')}
              // text={`Bundan sonra yapacağınız işlemler ${selectedCustomer.name} adına yapılacaktır. Onaylıyor musunuz?`}
              text={tra('The_transactions_you_will_do_after_that_will_be_done_on_behalf_of_x_Do_you_confirm', [selectedCustomer.name])}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false, selectedCustomer: null })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.setState({ questionModal: false }, () => this.godMode())}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
      </PageWrapper>
    );
  }
}

CustomersManagement.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
  appConfig: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  customers: selectCustomers(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      appConfig,
      getCustomers,
      init,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(CustomersManagement);
