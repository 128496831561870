import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomButton, CustomFade, CustomIconButton,
} from '../../assets/styled';
import Input from '../Input';
import GenerateModal from './Info';
import { tra } from '../../commons/utils';

export default class SwapPersonnel extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  clearAllandClose = () => {
    this.setState({
      questionModal: false,
      selectedRepresentative: null,
    });
    this.props.onClose();
  }

  saveFunc = () => {
    const {
      selectedRepresentative,
    } = this.state;
    if (selectedRepresentative) { // alt kullanıcı ekleme
      this.setState({ questionModal: true });
    } else {
      this.props.setToast(tra('Please_Fill_in_the_mandatory_fields'), 'warning'); // temp
    }
    // this.setState({ questionModal: false });
  }

  close = () => {
    const { name, type, selectedPermissions } = this.state;
    if (name || type || selectedPermissions?.length > 0 || type || type || type || type || this.props.edit) {
      this.setState({
        sureModal: true,
      });
    } else {
      this.clearAllandClose();
    }
  }

  render() {
    const {
      open, userDetail, representatives,
    } = this.props;
    const { questionModal, selectedRepresentative } = this.state;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{tra('Transfer_Customers')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content">
                <div className="formWrapper" style={{ width: '100%' }}>
                  <div style={{ width: '100%', maxHeight: '300px', overflow: 'auto' }}>
                    {
                      userDetail.representatives?.map((c) => (
                        <p style={{ maxWidth: '100%', overflow: 'hidden' }}>{c.name}</p>
                      ))
                    }
                  </div>
                  <Input
                    type="autoComplete"
                    label={tra('Customer_Representative')}
                    variant="standard"
                    data={representatives}
                    fullWidth
                    value={selectedRepresentative}
                    onChange={(e, value) => this.setState({ selectedRepresentative: value })}
                  />
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <CustomButton onClick={() => this.saveFunc()}>
                    {tra('Save_Edit')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          questionModal && (
            <GenerateModal
              open={questionModal}
              type="Warning"
              title={tra('Replacing_the_agent_representative')}
              text={tra('Represented_customers_will_be_transferred_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => { this.props.swapCustomers(selectedRepresentative?.id); this.clearAllandClose(); }}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.clearAllandClose()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

SwapPersonnel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  representatives: PropTypes.array.isRequired,
  userDetail: PropTypes.object.isRequired,
  swapCustomers: PropTypes.func.isRequired,
};

SwapPersonnel.defaultProps = {
};
