import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Skeleton } from '@material-ui/lab';
import { Colors } from '../assets/statics';
import {
  PageWrapper,
  DashboardBox,
  // TableNameBox,
  CustomIconButton,
  TabWrapper,
  TabItem,
  Simplelink,
  LimitCard,
  // AccountInformation,
  AnnouncementBox,
  AnnouncementWrapper,
  CustomButton,
  ChartWrapper,
} from '../assets/styled';
import {
  CalendarFilled,
  Danger,
  DangerFilled,
  DownloadFilled,
  Logout,
  NoAnnouncement,
  TrippleVertical,
  Wallet,
  WalletFilled,
} from '../assets/icons';
import Table from '../components/Table';

import {
  getWarnings,
  getDashboard,
  getCreditLimit,
  getOrders,
  getDashboardChart,
  getDashboardByCreditControl,
  setCreditLimit,
} from '../store/actions';
import {
  selectAppConfig,
  selectCreditLimit,
  selectDashboardByCredit,
  selectDashboardChart,
  selectDashboardData,
  selectInitCompanyCodes,
  selectOrderDetail,
  selectWarnings,
} from '../store/selectors';
import { tra } from '../commons/utils';
import RepeatOrder from '../components/Modals/RepeatOrder';
import LineChart from '../components/charts/Line';

class DashboardCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 99,
      dashboardChart: [],
      counter: 0,
      // dashboardData: false,
    };
    this.boxes = React.createRef();
    window.addEventListener('resize', this.setSize);
    this.setSize();
    props.getDashboard();
    props.getWarnings();
    props.getDashboardChart();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dashboardData?.credit_controls?.length > 1 && prevState.counter === 0) {
      nextProps.getDashboardByCreditControl('all');
      return {
        counter: 1,
      };
    }
    if (nextProps.dashboardData?.credit_controls?.length === 1 && prevState.tabValue !== 0) {
      nextProps.getDashboardByCreditControl(nextProps.dashboardData?.credit_controls?.[0]?.id);
      nextProps.getCreditLimit({ credit_control_id: nextProps.dashboardData?.credit_controls?.[0]?.id });
      return {
        tabValue: 0,
      };
    }
    // if (!(nextProps.dashboardData?.credit_controls?.length === 1) && localStorage.getItem('company') && JSON.stringify(nextProps.dashboardData) !== JSON.stringify(prevState.dashboardData)) {
    //   nextProps.getDashboardByCreditControl('all');
    //   return {
    //     dashboardData: nextProps.dashboardData,
    //   };
    // }
    if (nextProps.dashboardChart?.length > 0 && nextProps.dashboardChart !== prevState.dashboardChart && localStorage.getItem('company')) {
      // nextProps.getOrders(nextProps.dashboardChart);
      // nextProps.getDashboardByCreditControl(nextProps.dashboardData?.credit_controls?.[0]?.id);
      return {
        dashboardChart: nextProps.dashboardChart,
      };
    }
    return null;
  }

  componentDidMount() {
    this.setSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize);
  }

  setSize = () => {
    const { height } = this.state;
    const newHeight = this.boxes?.current?.clientHeight;
    if (height !== newHeight) {
      this.setState({ height: newHeight || 100 });
    }
  }

  tabChange = (event, newValue) => {
    if (newValue !== this.state.tabValue) {
      const id = this.props.dashboardData?.credit_controls?.[newValue]?.id || null;
      this.setState({
        tabValue: newValue,
      });
      this.props.setCreditLimit(false);
      // id && this.props.getCreditLimit({ credit_control_id: id });
      if (newValue === 99) {
        this.props.getDashboardByCreditControl('all');
      } else if (id) {
        this.props.getCreditLimit({ credit_control_id: id });
        this.props.getDashboardByCreditControl(id);
        // this.props.getDashboardByCreditControl('all');
      }
      // localStorage.setItem('Dashboard', newValue);
      // this.props.getOrders(newValue + 1);
    }
  }

  render() {
    const companyInfo = this.props.initCompanyCodes?.find(code => code.code === this.props.appConfig.company);
    const { openCreateModal, tabValue } = this.state;
    const { creditLimit, dashboardData, dashboardByCredit } = this.props;
    const limitStatu = (creditLimit?.limit || creditLimit?.limit === 0) ? (
      creditLimit?.limit > companyInfo?.insufficient_limit ? (creditLimit?.limit >= companyInfo?.low_limit ? 2 : 1) : 0
    ) : (dashboardData?.credit_controls?.[this.state.tabValue]?.limit > companyInfo?.insufficient_limit ? (
      dashboardData?.credit_controls?.[this.state.tabValue]?.limit >= companyInfo?.low_limit ? 2 : 1
    ) : false);
    const columns = [
      {
        name: 'sap_order_no',
        text: tra('Order_Number'),
        filterable: false,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
        customBody: value => (
          value || '-'
        ),
      },
      {
        name: 'date',
        text: tra('Shipping_Date'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
      },
      {
        name: 'Delivery',
        text: tra('Goods_Delivery_Place'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: value => (
          value?.name
        ),
      },
      {
        name: 'product_name',
        text: tra('Product_Name'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'total_amount',
        text: tra('Total_Quantity'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: value => (
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            suffix={` ${tra('Ton')}`}
            renderText={(value1, props) => <div {...props}>{value1}</div>}
          />
        ),
      },
      {
        name: 'created_user_name',
        text: tra('Order_Creator'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
    ];
    return (
      <PageWrapper style={{ paddingBottom: 30 }}>
        <TabWrapper value={tabValue} onChange={(event, newValue) => this.tabChange(event, newValue)} aria-label="simple tabs example">
          {/* <TabItem label={(<p style={{ textTransform: 'capitalize' }}>Eskişehir</p>)} />
          <TabItem label={(<p style={{ textTransform: 'capitalize' }}>Ankara</p>)} />
          <TabItem label={(<p style={{ textTransform: 'capitalize' }}>Kütahya</p>)} />
          <TabItem label={(<p style={{ textTransform: 'capitalize' }}>Konya</p>)} /> */}
          {
            // (this.props.dashboardData?.credit_controls?.length > 1 || this.props.dashboardData?.credit_controls?.length === 0) && (
            this.props.dashboardData?.credit_controls?.length !== 1 && (
              <TabItem value={99} disabled={this.props.dashboardData?.credit_controls?.length !== 0 && false} label={(<p style={{ textTransform: 'capitalize' }}>Genel</p>)} />
            )
          }
          {
            this.props.dashboardData?.credit_controls?.map((credit, i) => (
              <TabItem
                value={i}
                label={(
                  <p style={{
                    whiteSpace: 'nowrap', maxWidth: '180px', textOverflow: 'ellipsis', overflow: 'hidden',
                  }}
                  >
                    {credit?.alternative_name}
                  </p>
                )}
              />
            ))
          }
        </TabWrapper>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={3} style={{ height: tabValue === 99 && '100%' }}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox>
                  <div className="content">
                    <h3>{dashboardData ? dashboardData?.order_count : '-'} {tra('Piece')}</h3>
                    <p>{tra('Todays_Orders')}</p>
                  </div>
                  <div className="icon">
                    <DangerFilled />
                  </div>
                </DashboardBox>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox
                  style={{ cursor: dashboardData?.last_order && 'pointer' }}
                  onClick={() => dashboardData?.last_order && this.setState({ openCreateModal: true }, () => this.props.getOrders(dashboardData?.last_order, true))}
                >
                  <div className="content">
                    <h3>{tra('Repeat')}</h3>
                    <p>{tra('Repeat_Last_Order')}</p>
                  </div>
                  <div className="icon">
                    <CalendarFilled />
                  </div>
                </DashboardBox>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox>
                  <div className="content">
                    <Tooltip title={dashboardByCredit?.dashboard?.overdueDebt > 1000000 ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(dashboardByCredit?.dashboard?.overdueDebt) : ''}>
                      <h3>{dashboardByCredit?.dashboard?.overdueDebt ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(dashboardByCredit?.dashboard?.overdueDebt) : '-'}</h3>
                    </Tooltip>
                    <p>{tra('Total_Debt')}</p>
                  </div>
                  <div className="icon">
                    <WalletFilled />
                  </div>
                </DashboardBox>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox>
                  <div className="content">
                    <h3>
                      {
                        (dashboardByCredit?.dashboard?.todayDebt && dashboardByCredit?.dashboard?.todayDebt > 0) ? (
                          new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(dashboardByCredit?.dashboard?.todayDebt)
                        ) : '-'
                      }
                    </h3>
                    <p>{tra('Today_Debt')}</p>
                  </div>
                  <div className="icon">
                    <DownloadFilled />
                  </div>
                </DashboardBox>
              </Grid>
            </Grid>
          </Grid>
          {
            tabValue === 99 ? (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <h2 className="dashTitle">{tra('Announcements')}<Simplelink to="/AllAnnouncement">{tra('See_All')} ({this.props.warnings?.length})</Simplelink></h2>
                <AnnouncementWrapper style={{ maxHeight: '185px' }}>
                  {
                    this.props.warnings && this.props.warnings?.slice(0, 10)?.map(warn => (
                      <AnnouncementBox>
                        <i>{warn.type === 'WARNING' ? <Logout /> : <Danger />}</i>
                        <div className="content">
                          <p>{warn.type}</p>
                          <span>{warn.message}</span>
                          <h5>{warn.createdAt ? new Date(warn.createdAt).toLocaleDateString() : '-'}</h5>
                        </div>
                      </AnnouncementBox>
                    ))
                  }
                  {
                    this.props.warnings && this.props.warnings.length === 0 && (
                      <div className="noData" style={{ padding: 0 }}>
                        <NoAnnouncement width="144" />
                        <p style={{ fontSize: '18px', paddingTop: 15 }}>{tra('No_Announcement')}</p>
                      </div>
                    )
                  }
                </AnnouncementWrapper>
              </Grid>
            ) : (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <LimitCard statu={(limitStatu || limitStatu === 0) ? limitStatu : false}>
                  <CustomIconButton><TrippleVertical /></CustomIconButton>
                  <h5>
                    {
                      (limitStatu || limitStatu === 0) ? (
                        limitStatu === 1 ? tra('Low_Limit') : limitStatu === 0 ? tra('Insufficient_Limit') : 'Limit'
                      ) : <Skeleton variant="rect" animation="pulse" width={100} height={20} />
                    }
                  </h5>
                  <p>
                    {
                      Object.keys(creditLimit).length ? (
                        creditLimit?.limit ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(creditLimit?.limit) : '-'
                      ) : (
                        tabValue === 0 ? (
                          !isNaN(dashboardData?.credit_controls?.[0]?.limit) ? (
                            new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(dashboardData?.credit_controls?.[0]?.limit)
                          ) : '-'
                        ) : (
                          <CircularProgress />
                        )
                      )
                    }
                  </p>
                  {/* <span>Çarşamba, 22 Mart 2021, 9:24</span> */}
                  <CustomButton><Wallet />{tra('Payment_Information')}</CustomButton>
                </LimitCard>
              </Grid>
            )
          }
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ChartWrapper style={{ height: 300, borderRadius: '6px' }}>
              {
                this.state.dashboardChart?.length > 0 ? (
                  <LineChart data={this.state.dashboardChart?.length > 1 && [this.state.dashboardChart?.[1], this.state.dashboardChart?.[2]]} colors={[Colors.mint, Colors.blueLight]} />
                ) : (
                  <Skeleton variant="rect" animation="pulse" width="100%" height={300} />
                )
              }
            </ChartWrapper>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ChartWrapper style={{ height: 300, borderRadius: '6px' }}>
              {
                this.state.dashboardChart?.length > 0 ? (
                  <LineChart data={this.state.dashboardChart?.length > 0 && [this.state.dashboardChart?.[0]]} colors={[Colors.blueLight]} />
                ) : (
                  <Skeleton variant="rect" animation="pulse" width="100%" height={300} />
                )
              }
            </ChartWrapper>
          </Grid>
          {/* <Grid item lg={3} md={6} sm={6} xs={12}>
            <h2 className="dashTitle">{tra('Current_Account_Information')}</h2>
            <AccountInformation>
              <p>{tra('Code')}<span>F-002</span></p>
              <p>{tra('Account_Name')}:<span>Furkan İnşaat</span></p>
              <p>{tra('Group')}:<span>{tra('Customer')}</span></p>
              <p>{tra('District')}:<span>Büyükçekmece</span></p>
              <p>{tra('Province')}:<span>İstanbul</span></p>
            </AccountInformation>
          </Grid> */}
          <Grid item lg={tabValue === 99 ? 12 : 9} md={12} sm={12} xs={12}>
            <h2 className="dashTitle">{tra('Recent_Transactions')}</h2>
            <Table
              data={this.props.dashboardData?.last_ten_order || []}
              columns={columns}
              id="orderTable"
              detailedTable
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
            // rowClick={(row) => console.log(row)}
            />
          </Grid>
          {
            tabValue !== 99 && (
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <h2 className="dashTitle">{tra('Announcements')}<Simplelink to="/AllAnnouncement">{tra('See_All')} ({this.props.warnings?.length})</Simplelink></h2>
                <AnnouncementWrapper>
                  {
                    this.props.warnings && this.props.warnings?.slice(0, 10)?.map(warn => (
                      <AnnouncementBox>
                        <i>{warn.type === 'WARNING' ? <Logout /> : <Danger />}</i>
                        <div className="content">
                          <p>{warn.type}</p>
                          <span>{warn.message}</span>
                          <h5>{warn.createdAt ? new Date(warn.createdAt).toLocaleDateString() : '-'}</h5>
                        </div>
                      </AnnouncementBox>
                    ))
                  }
                  {
                    this.props.warnings && this.props.warnings.length === 0 && (
                      <div className="noData">
                        <NoAnnouncement width="144" />
                        <p style={{ fontSize: '18px' }}>{tra('No_Announcement')}</p>
                      </div>
                    )
                  }
                </AnnouncementWrapper>
              </Grid>
            )
          }
        </Grid>
        {
          openCreateModal && (
            <RepeatOrder
              open={openCreateModal}
              onClose={() => {
                this.setState({
                  openCreateModal: false,
                  selectedProduct: 0,
                }, () => this.props.dashboardData?.credit_controls?.[tabValue]?.id && this.props.getCreditLimit({ credit_control_id: this.props.dashboardData?.credit_controls?.[tabValue]?.id }));
              }}
              selectedProduct={this.state.selectedProduct}
              orderDetail={this.props.orderDetail}
            />
          )
        }
      </PageWrapper>
    );
  }
}

DashboardCustomer.propTypes = {
  // getDashboard: PropTypes.func.isRequired,
  // dashboardData: PropTypes.object.isRequired,
  getWarnings: PropTypes.func.isRequired,
  getDashboard: PropTypes.func.isRequired,
  warnings: PropTypes.array.isRequired,
  dashboardData: PropTypes.array.isRequired,
  dashboardChart: PropTypes.array.isRequired,
  getCreditLimit: PropTypes.func.isRequired,
  creditLimit: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  orderDetail: PropTypes.object.isRequired,
  getDashboardChart: PropTypes.func.isRequired,
  initCompanyCodes: PropTypes.array.isRequired,
  appConfig: PropTypes.object.isRequired,
  getDashboardByCreditControl: PropTypes.func.isRequired,
  dashboardByCredit: PropTypes.object.isRequired,
  setCreditLimit: PropTypes.func.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  // dashboardData: selectDashboard(),
  warnings: selectWarnings(),
  dashboardData: selectDashboardData(),
  creditLimit: selectCreditLimit(),
  orderDetail: selectOrderDetail(),
  dashboardChart: selectDashboardChart(),
  initCompanyCodes: selectInitCompanyCodes(),
  appConfig: selectAppConfig(),
  dashboardByCredit: selectDashboardByCredit(),
});

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators({
      // getDashboard,
      getWarnings,
      getDashboard,
      getCreditLimit,
      getOrders,
      getDashboardChart,
      getDashboardByCreditControl,
      setCreditLimit,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCustomer);
