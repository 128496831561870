import React from 'react';
import { Backdrop, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { CustomFade, Company } from '../../assets/styled';
import { tra } from '../../commons/utils';

export default function SelectCompany(props) {

  return (
    <Modal
      open
      onClose={() => localStorage.getItem('company') && props.appConfig('changeCompany', false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <CustomFade in style={{ maxWidth: '100%', minWidth: '100px', padding: 0 }}>
        <div>
          <h3 style={{ textAlign: 'center', padding: '20px 0' }}>{tra('Company_Selection')}</h3>
          <div style={{ display: 'flex' }}>
            {
              props.companies?.map((c) => (
                <Company onClick={() => {
                  localStorage.setItem('company', c.code);
                  props.appConfig('company', c.code);
                  setTimeout(() => { props.init(); }, 1000);
                  props.appConfig('changeCompany', false);
                  window.location.reload();
                }}
                >
                  <div className="head">
                    <p>{c.name?.split(' ')[0]?.charAt()}{c.name?.split(' ')[1]?.charAt()}</p>
                  </div>
                  <div className="content">
                    <p>{c.name}</p>
                  </div>
                </Company>
              ))
            }
          </div>
        </div>
      </CustomFade>
    </Modal>
  );
}

SelectCompany.propTypes = {
  companies: PropTypes.array.isRequired,
  init: PropTypes.func.isRequired,
  appConfig: PropTypes.func.isRequired,
};
