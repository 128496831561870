import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  TextField, InputAdornment,
} from '@material-ui/core';
import { LockOutlined, MailOutlineOutlined } from '@material-ui/icons';
import { Colors, languageData } from '../assets/statics';
import {
  LoginWrapper,
  LoginBox,
  CustomButton,
  CustomMenuItem,
} from '../assets/styled';

import Logo from '../assets/img/logo.png';
import LoginBg from '../assets/img/loginBg1.png';
import { World } from '../assets/icons';

import { changePassword, setToast } from '../store/actions';
import { getLangCode, tra } from '../commons/utils';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // stage: 1, // stage: 1 => email, 2 => reset Password
      // resent: false,
      languageData: [{ id: 1, name: 'T' }, { id: 2, name: 'E' }, { id: 3, name: 'S' }],
    };
  }

  sendEmail = () => {
    const { email } = this.state;
    if (/\S+@\S+\.\S+/.test(email)) {
      this.props.changePassword('forget', { email });
    } else {
      this.props.setToast(true, tra('Please_enter_a_valid_email_address'), 'warning');
    }
  }

  changePass = () => {
    const { pass, passAgain } = this.state;
    if (pass.length > 7) {
      if (pass === passAgain) {
        this.props.changePassword('reset', { password: pass, type: 1 });
      } else {
        this.props.setToast(true, tra('Your_Password_Does_Not_Match'), 'warning');
      }
    } else {
      this.props.setToast(true, tra('Your_password_must_be_at_least_8_digits'), 'warning');
    }
  }

  render() {
    const { email, pass, passAgain } = this.state;
    return (
      <LoginWrapper style={{ backgroundImage: `url(${LoginBg})` }}>
        <LoginBox>
          <div>
            <div className="logo">
              <img src={Logo} alt={tra('Cimsa_Customer_Portal')} />
              <div className="changeLanguage">
                <TextField
                  select
                  value={languageData?.find((lang) => lang?.name === getLangCode())?.name}
                  onChange={(e) => {
                    localStorage.setItem('language', this.state.languageData?.find((lang) => lang?.name === e.target.value)?.name);
                    window.location.reload();
                  }}
                  fullWidth
                  variant="outlined"
                  style={{ margin: '10px 0' }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <World width={22} />
                      </InputAdornment>
                    ),
                  }}
                >
                  {
                    languageData?.map((item) => (
                      <CustomMenuItem value={item.name}>
                        {item.text}
                        <span style={{
                          padding: '3px 5px',
                          backgroundColor: '#fde8e3',
                          borderRadius: '10px',
                          marginLeft: 5,
                          color: '#8C172D',
                          minWidth: 25,
                          textAlign: 'center',
                          display: !item.count && 'none',
                        }}
                        > {item.text && item.text}
                        </span>
                      </CustomMenuItem>
                    ))
                  }
                </TextField>
              </div>
            </div>
            <>
              <h1>{tra('Password_Reset')}</h1>
              {
                this.props.match.params.token.length < 10 ? (
                  <>
                    <p>{tra('Please_enter_your_email_address_to_process')}</p>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      label={tra('Email')}
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineOutlined style={{ color: Colors.grey }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomButton fullWidth onClick={() => this.sendEmail()}>{tra('Send_Reset_Email')}</CustomButton>
                  </>
                ) : (
                  <>
                    {localStorage.setItem('token', this.props.match.params.token)}
                    <p>{tra('Please_enter_the_new_password_you_want_to_create')}</p>
                    <TextField
                      type="password"
                      variant="outlined"
                      fullWidth
                      label={tra('Password')}
                      value={pass}
                      onChange={(e) => this.setState({ pass: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined style={{ color: Colors.grey }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type="password"
                      variant="outlined"
                      fullWidth
                      label={tra('Password_Again')}
                      value={passAgain}
                      onChange={(e) => this.setState({ passAgain: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineOutlined style={{ color: Colors.grey }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomButton fullWidth onClick={() => this.changePass()}>{tra('Save')}</CustomButton>
                  </>
                )
              }
              {/* <FormControlLabel control={<Checkbox name="checkedC" />} label={tra('I_accept_the_Personal_Data_Protection_Text)} /> */}
            </>
          </div>
        </LoginBox>
      </LoginWrapper>
    );
  }
}

ForgetPassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  match: PropTypes.any.isRequired,
};

const mapStateToProps = createStructuredSelector({
  // LoginData: selectLogin(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      changePassword,
      setToast,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
