/* eslint-disable arrow-parens */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Backdrop,
  InputAdornment,
  Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { tr } from 'date-fns/esm/locale';
import {
  add, isTomorrow, setHours, subDays,
} from 'date-fns';
import {
  CustomButton,
  CustomFadeOrder,
  CustomIconButton,
  ErrorLine,
} from '../../assets/styled';
import GenerateModal from './Info';
import {
  ArrowLeft,
  CheckCircle,
  PlusButton,
  Waiting,
  WarningCircle,
} from '../../assets/icons';
import Input from '../Input';
import { Colors } from '../../assets/statics';
import {
  createOrders,
  getOrderProduct,
  getCreditLimit,
  setCreditLimit,
  getDeliveries,
  appConfig,
  setOrderProduct,
  setDeliveries,
} from '../../store/actions';
import {
  selectProductGroups,
  selectOrderProduct,
  selectCreditLimit,
  selectDeliveries,
  selectShippingCost,
  selectAppConfig,
  selectInitCompanyCodes,
  selectServiceConfig,
} from '../../store/selectors';
import { tra } from '../../commons/utils';

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 0,
      item: [{
        amount: null,
        hour: null,
      }],
      date: null,
      newCredit: null,
      hourSelected: false,
      mta: null,
      productTypeInput: null,
      creditControl: null,
      deliveryMethod: null,
      productInput: null,
      payment: null,
    };
    props.setCreditLimit(false);
    props.appConfig('checkLimit', props.initCompanyCodes?.find((item) => item?.code === props.appConfigs?.company)?.check_limit); // reduxa alınacak
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const productTypeInput = nextProps.selectedProduct ? nextProps.productGroups.find((d) => d.id === nextProps.selectedProduct) : prevState.productTypeInput;
    const creditControl = productTypeInput?.CreditControlAreas?.length === 1 ? productTypeInput?.CreditControlAreas[0] : prevState.creditControl || null;
    const mta = nextProps.deliveries?.length === 1 ? nextProps.deliveries[0] : prevState.mta || null;
    const deliveryMethod = mta?.DeliveryPoints?.length === 1 ? mta?.DeliveryPoints[0] : prevState.deliveryMethod || null;
    const productInput = nextProps.orderProduct?.length === 1 ? nextProps.orderProduct[0] : (nextProps.orderProduct?.some?.(p => p.id === prevState.productInput?.id) ? prevState.productInput : null) || null;
    const payment = productInput?.Payment?.length === 1 ? productInput?.Payment[0] : prevState.payment || null;
    if (creditControl?.id && ((!nextProps.creditLimit && !nextProps.creditLimit?.limit && nextProps.creditLimit?.limit !== 0) || prevState.creditControl !== creditControl) && nextProps.serviceConfigs?.getCreditLimit !== false) {
      nextProps.getCreditLimit({ credit_control_id: creditControl.id });
    }
    if (creditControl?.id && !mta && !nextProps.deliveries && nextProps.creditLimit?.limit !== 0 && nextProps.serviceConfigs?.getDeliveries !== false) {
      nextProps.getDeliveries(creditControl.id);
    }
    if (mta?.id && deliveryMethod?.id && !nextProps.orderProduct) {
      nextProps.getOrderProduct(creditControl?.id, (nextProps.selectedProduct || productTypeInput?.id), deliveryMethod?.id, mta?.id);
    }
    return {
      productTypeInput,
      creditControl,
      mta,
      deliveryMethod,
      productInput,
      payment,
    };
    // return null;
  }

  componentWillUnmount() {
    this.setState({
      productTypeInput: null,
      creditControl: null,
      mta: null,
      deliveryMethod: null,
      productInput: null,
      payment: null,
    });
    this.props.setOrderProduct(false);
    this.props.setDeliveries(false);
  }

  itemProcess = (type, i) => {
    const item = [...this.state.item];
    if (type === 0) { // decrease
      item.splice(i, 1);
    } else if (type === 1 && item?.length < 5) { // increase
      item.push({ amount: null, hour: null });
    }
    this.setState({ item });
  };

  changeData = (val, i, name) => {
    const { item } = this.state;
    item[i][name] = val;
    this.setState({ item });
  }

  checkInputs = () => {
    const { orderProduct } = this.props;
    const {
      date, productInput, mta, payment, amount, newCredit,
    } = this.state;
    this.setState({ errorMessage: null, reRender: Math.random() });
    if (
      (date >= subDays(new Date(), 1))
      && productInput
      && mta
      && payment
      && amount > 0
      && (this.props.appConfigs?.checkLimit === false ? true : (newCredit === 0 || newCredit > 0))
      && orderProduct?.length > 0
      && orderProduct?.some((or) => or.id === productInput?.id)
    ) {
      this.setState({ questionModal: true }); // Sipariş Sorunsuz
    } else if (parseInt(amount, 10) === 0) {
      this.setState({ errorMessage: tra('Enter_a_value_greater_than_zero'), isError: true });
    } else if (newCredit < 0) {
      this.setState({ errorMessage: tra('Insufficient_Limit'), isError: true });
    } else if (!(date >= subDays(new Date(), 1)) && date) {
      this.setState({ errorMessage: tra('Please_enter_valid_date'), isError: true });
    } else if (date && amount && !(newCredit === 0 || newCredit > 0)) {
      this.setState({ errorMessage: tra('You_do_not_have_sufficient_credits'), isError: true });
    } else {
      this.setState({ errorMessage: tra('Please_Fill_in_the_mandatory_fields'), isError: true });
    }
  }

  createOrder = () => {
    const {
      item, date, productInput, mta, payment, amount, creditControl, deliveryMethod,
    } = this.state;
    const data = {
      product_id: productInput?.id,
      delivery_point_id: deliveryMethod.id,
      payment_term_id: payment?.id,
      date: setHours(date, 12),
      total_amount: parseInt(amount, 10),
      credit_control_id: creditControl?.id,
      delivery_id: mta.id,
      Amounts: (deliveryMethod.id === 1 || !(item[0].amount > 0)) ? ([{ amount, hour: '-' }]) : item,
    };
    this.props.createOrders(data);
    // sipariş oluşturuldu mu diye kontrol edilecek
    this.props.onClose();
    this.props.setCreditLimit({});
    this.setState({ questionModal: false });
  }

  getCreditLimit = () => {
    const {
      // productInput,
      // mta,
      // payment,
      // amount,
      // deliveryMethod,
      creditControl,
    } = this.state;
    const data = {
      //   product_id: productInput?.id,
      //   delivery_point_id: deliveryMethod.id,
      //   payment_term_id: payment?.id,
      //   total_amount: parseInt(amount, 10),
      credit_control_id: creditControl?.id,
      //   delivery_id: mta.id,
    };
    // const data = creditControl?.id;
    this.props.getCreditLimit(data);
  }

  chooseHours = () => {
    const { amount, date } = this.state;
    this.setState({ errorMessage: null });
    if (amount > 0 && date) {
      this.setState({ content: 1 });
    } else if (date) {
      // this.props.setToast(true, tra('Enter_a_value_greater_than_zero'));
      this.setState({ errorMessage: tra('Enter_a_value_greater_than_zero') });
    } else {
      // this.props.setToast(true, tra('Enter_a_value_greater_than_zero'));
      this.setState({ errorMessage: tra('Please_enter_valid_date') });
    }
  }

  saveHours = () => {
    const { item, amount } = this.state;
    const total = item.reduce((a, b) => a + parseFloat(b.amount), 0);
    const amountCheck = item.filter((a) => !(a.amount > 0)).length > 0;
    const hourCheck = item.filter((a) => a.hour === null).length > 0;
    this.setState({ reRender: Math.random() });
    // this.setState({ errorMessage: null, hourSelected: false });
    if (!amount) {
      // this.props.setToast(true, tra('You_must_enter_the_amount_first'));
      this.setState({ errorMessage: tra('You_must_enter_the_amount_first'), isErrorSub: true });
    } else if (amountCheck) {
      // this.props.setToast(true, tra('Enter_a_value_greater_than_zero'));
      this.setState({ errorMessage: tra('Enter_a_value_greater_than_zero'), isErrorSub: true });
    } else if (hourCheck) {
      // this.props.setToast(true, tra('Choose_a_Time'));
      this.setState({ errorMessage: tra('Choose_a_Time'), isErrorSub: true });
    } else if (total === parseFloat(amount)) {
      this.setState({
        content: 0, hourSelected: true, errorMessage: false, oldItem: JSON.parse(JSON.stringify(item)), isErrorSub: false, // onaylandı
      });
    } else {
      // this.props.setToast(true, tra('Please_make_sure_that_the_sum_of_their_tonnages_equals', [amount]));
      this.setState({ errorMessage: tra('Please_make_sure_that_the_sum_of_their_tonnages_equals', [amount]), isErrorSub: true });
    }
  }

  isBlockageData = (date) => {
    const { productInput } = this.state;
    for (let i = 0; i < productInput?.Blocks?.length; i++) {
      const startDate = new Date(productInput?.Blocks?.[i]?.block_start_date)?.setDate(new Date(productInput?.Blocks?.[i]?.block_start_date)?.getDate() - 1);
      const endDate = new Date(productInput?.Blocks?.[i]?.block_end_date);
      const currentDate = new Date(date);
      // return currentDate >= startDate && currentDate <= endDate;
      if (currentDate >= startDate && currentDate <= endDate) {
        return true;
      }
    }
    return false;
  }

  creditCalculate = () => {
    const { creditLimit } = this.props;
    const {
      // productInput,
      amount,
      payment,
    } = this.state;

    const remainder = creditLimit?.limit ? Number((creditLimit?.limit).toFixed(2)) - Number((parseFloat(amount) * (payment?.price || 0)).toFixed(2)) : false;
    this.setState({ newCredit: remainder });
  }

  close = () => {
    if (this.state.creditControl) {
      this.setState({ sureModal: true });
    } else {
      this.setClear();
    }
  }

  setClear = () => {
    this.setState({
      content: 0,
    });
    this.props.setCreditLimit({});
    this.props.onClose();
  }

  inputChange = (key, value) => {
    const {
      mta, creditControl, productTypeInput,
    } = this.state;
    const { selectedProduct } = this.props;
    switch (key) {
      case 'productTypeInput': {
        this.setState({
          productTypeInput: value,
          creditControl: null,
          mta: null,
          deliveryMethod: null,
          productInput: null,
          payment: null,
          date: null,
        });
        break;
      }
      case 'creditControl': {
        this.setState({
          creditControl: value,
          mta: null,
          deliveryMethod: null,
          productInput: null,
          payment: null,
          // progress: true,
          date: null,
        });
        this.props.setOrderProduct(false);
        this.props.setDeliveries(false);

        this.props.getDeliveries(value.id);
        this.props.getCreditLimit({ credit_control_id: value?.id });
        break;
      }
      case 'mta': {
        this.setState({
          mta: value,
          deliveryMethod: null,
          productInput: null,
          payment: null,
          date: null,
        });
        this.props.setOrderProduct(false);

        this.setState({ mta: value });
        break;
      }
      case 'deliveryMethod': {
        this.setState({
          productInput: null,
          payment: null,
          date: null,
          deliveryMethod: value,
        }, () => this.props.getOrderProduct(creditControl?.id, (selectedProduct || productTypeInput?.id), value?.id, mta?.id));
        // this.props.getOrderProduct(creditControl?.id, (selectedProduct || productTypeInput?.id), value?.id, mta?.id);
        break;
      }
      case 'productInput': {
        this.setState({
          productInput: value,
          payment: null,
          date: null,
        });
        break;
      }
      case 'payment': {
        if (value?.sap_payment_term_no !== this.state.payment?.sap_payment_term_no) {
          this.setState({ amount: '' });
          this.creditCalculate();
        }
        this.setState({
          payment: value,
        });
        break;
      }
      default:
        break;
    }
  }

  calc = () => {
    const r = this.state.item.reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0);
    if (parseInt(this.state.amount, 10) === r) {
      return true;
    }
    return false;
  }

  fixedHours = () => {
    const step = parseInt(this.props.initCompanyCodes?.find((item) => item?.code === this.props.appConfigs?.company)?.time_range, 10) || 0;
    const fixedHours = [];
    const isToday = new Date().getDate() === this.state.date?.getDate();
    // const isTomorrow = add(new Date(), { days: 1 }).getDate() === this.state.date?.getDate();
    const isDayTomorrow = isTomorrow(this.state.date);
    const currentHour = new Date().getHours();
    const availableHour = currentHour + 1;
    for (let i = 0; i < 24; i++) {
      fixedHours.push({ id: `${i < 10 ? 0 : ''}${i}`, name: `${i < 10 ? 0 : ''}${i}:00`, disabled: isToday ? availableHour + (step || 0) > i : (isDayTomorrow && availableHour + step > 24) && availableHour + step - 24 > i });
    }
    return fixedHours;
  };

  newMinDate = () => {
    const { hourSelected, item, date } = this.state;
    const { appConfigs } = this.props;
    const step = parseInt(this.props.initCompanyCodes?.find((itm) => itm?.code === appConfigs?.company)?.time_range, 10) || 0;
    const currentHour = new Date().getHours();
    const availableHour = currentHour + 1;
    const availableDay = (add(new Date(), { days: (availableHour + step) > 24 ? parseInt((availableHour + step) / 24, 10) : 0 })).setHours(availableHour + step, 0, 0, 0);
    const minSelectedHour = Math.min(...item?.map((h) => parseInt(h.hour, 10)));
    const orderDate = date.setHours(minSelectedHour, 0, 0, 0);
    // if ((availableHour + step) >= 24 && !hourSelected) {
    //   return add(new Date(), { days: 1 });
    // }
    // if ((availableHour + step) >= minSelectedHour) {
    //   if (step >= 24) {
    //     // { item: [{ amount: 0, hour: null }], hourSelected: false });
    //     return add(new Date(), { days: (availableHour + step) % 24 });
    //   }
    //   return add(new Date(), { days: (availableHour + step) > 24 ? (availableHour + step) % 24 : 1 });
    // }
    if (hourSelected && (orderDate < availableDay) && (availableHour + step) >= minSelectedHour) {
      this.setState({ hourSelected: false, oldItem: null, item: [{ amount: null, hour: null }] });
    }
    return new Date();
  };

  render() {
    const {
      open, productGroups, selectedProduct, orderProduct, creditLimit,
    } = this.props;
    const {
      content, item, amount, payment, productInput, deliveryMethod, mta, creditControl, errorMessage, productTypeInput, date, oldItem, isError, isErrorSub,
    } = this.state;
    const withValueLimit = ({ value }) => value <= 1000000; // maksimum tonaj
    // const tomorrow = new Date();
    // tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFadeOrder in={open}>
            <div>
              <div className="header">
                {
                  content === 0 ? (
                    <h1>{tra('Order_Creation')}</h1>
                  ) : (
                    <CustomButton
                      simple
                      onClick={() => {
                        this.setState({
                          content: (content - 1), item: JSON.parse(JSON.stringify(oldItem)) || [{ amount: null, hour: null }], errorMessage: false, isErrorSub: false,
                        });
                      }}
                      startIcon={<ArrowLeft />}
                    >
                      {tra('Back')}
                    </CustomButton>
                  )
                }
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              {
                content === 0 ? (
                  <div className="content">
                    {errorMessage && errorMessage?.length && (
                      <ErrorLine key={this.state.reRender}>
                        {errorMessage}!
                      </ErrorLine>
                    )}
                    {
                      // !(localStorage.getItem('company') === '0064') && (
                      !(this.props.appConfigs?.checkLimit === false) && (
                        <div
                          className={`info ${((this.state.newCredit || this.state.newCredit === 0) ? this.state.newCredit >= 0 : creditLimit?.limit > 0) && 'blue'}`}
                          style={{ display: (!creditLimit?.limit && creditLimit?.limit !== 0) && 'none' }}
                        >
                          {tra('Available_Credit')}:
                          <span style={{ fontSize: !(this.state.newCredit >= 0) && !creditLimit?.limit && '18px' }}>
                            {
                              (this.state.newCredit || this.state.newCredit === 0) ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.newCredit) : (
                                creditLimit?.limit ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(creditLimit?.limit)
                                  : tra('You_do_not_have_sufficient_credits')
                              )
                            }
                          </span>
                        </div>
                      )
                    }
                    <div className="formWrapper">
                      <Input
                        type="autoComplete"
                        label={tra('Product_Group')}
                        variant="standard"
                        data={productGroups || []}
                        fullWidth
                        value={productTypeInput}
                        disabled={selectedProduct}
                        onChange={(e, value) => this.inputChange('productTypeInput', value)}
                        error={isError && !this.state.productTypeInput}
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Factory')}
                        variant="standard"
                        data={productGroups?.filter((type) => type.id === (selectedProduct || this.state.productTypeInput?.id))[0]?.CreditControlAreas || []}
                        fullWidth
                        value={creditControl}
                        onChange={(e, value) => this.inputChange('creditControl', value)}
                        error={isError && !this.state.creditControl}
                        disabled={!productTypeInput}
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Goods_Delivery_Place')}
                        variant="standard"
                        data={this.props.deliveries || []}
                        fullWidth
                        value={mta}
                        onChange={(e, value) => this.inputChange('mta', value)}
                        error={isError && !this.state.mta}
                        disabled={!creditControl}
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Delivery_Method')}
                        variant="standard"
                        fullWidth
                        data={mta?.DeliveryPoints || []}
                        value={deliveryMethod || {}}
                        onChange={(e, value) => this.inputChange('deliveryMethod', value)}
                        error={isError && !this.state.deliveryMethod}
                        disabled={!mta}
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Product')}
                        variant="standard"
                        data={orderProduct || []}
                        fullWidth
                        value={productInput || null}
                        onChange={(e, value) => this.inputChange('productInput', value)}
                        error={isError && !this.state.productInput}
                        disabled={!deliveryMethod || !(orderProduct?.length > 0)}
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Payment_Condition')}
                        variant="standard"
                        data={productInput?.Payment || []}
                        fullWidth
                        value={payment}
                        onChange={(e, value) => this.inputChange('payment', value)}
                        error={isError && !this.state.payment}
                        disabled={!productInput}
                      />
                      <Input
                        type="number"
                        placeholder={tra('Quanta')}
                        variant="outlined"
                        disabled={!payment || !productInput || !deliveryMethod || !mta || !creditControl}
                        endAdornment={<InputAdornment position="end">{tra('Ton')}</InputAdornment>}
                        decimalScale={1}
                        isAllowed={withValueLimit}
                        value={amount}
                        onChange={(e) => this.setState({
                          amount: e.value, hourSelected: false, item: [{ amount: 0, hour: null }], oldItem: null,
                        }, () => this.creditCalculate())}
                        error={isError && !(parseInt(this.state.amount, 10) > 0)}
                      />
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          label={tra('Date')}
                          value={date || null}
                          // onChange={(e) => this.setState({ date: e })}
                          onChange={(e) => this.setState({ date: e })}
                          autoOk
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          // style={{ paddingTop: 10 }}
                          minDate={(deliveryMethod?.id === 2 && this.state.hourSelected) ? this.newMinDate() : new Date()}
                          shouldDisableDate={(e) => this.isBlockageData(e)}
                          disabled={!this.state.productInput?.id}
                          disablePast
                          invalidDateMessage={tra('Please_enter_valid_date')}
                          minDateMessage={tra('Please_enter_valid_date')}
                          error={isError && (!this.state.date || !(date >= subDays(new Date(), 1)))}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                ) : content === 1 && (
                  <>
                    {errorMessage && errorMessage?.length && (
                      <ErrorLine key={this.state.reRender}>
                        {errorMessage}!
                      </ErrorLine>
                    )}
                    <div className="content">
                      <h4>{tra('Select_the_requested_quantity_and_shipping_date_and_time')}</h4>
                      <h4 style={{ color: Colors.error }}>{tra('Total')}: {amount} {tra('Ton')}</h4>
                      {
                        item?.map((k, i) => (
                          <div className="item">
                            {
                              !(item?.length === 1) && (
                                <CustomButton simple style={{ color: Colors.burgundy }} onClick={() => this.itemProcess(0, i)} startIcon={<Close style={{ color: Colors.burgundy }} />}>{tra('Remove')}</CustomButton>
                              )
                            }
                            <div>
                              <Input
                                type="number"
                                placeholder={tra('Quanta')}
                                variant="outlined"
                                endAdornment={<InputAdornment position="end">{tra('Ton')}</InputAdornment>}
                                decimalScale={1}
                                isAllowed={withValueLimit}
                                key={item}
                                value={k?.amount || null}
                                onChange={(e) => this.changeData(e.value, i, 'amount')}
                                error={isErrorSub && (!(k?.amount > 0) || !(this.calc()))}
                              />
                              <Input
                                type="select"
                                placeholder={tra('Time')}
                                variant="outlined"
                                data={this.fixedHours()}
                                endAdornment={<InputAdornment position="end"><Waiting color={Colors.grey} /></InputAdornment>}
                                value={k.hour}
                                onChange={(e) => this.changeData(e.target.value, i, 'hour')}
                                autoOk
                                // inputProps={{ step: 3600 }}
                                error={isErrorSub && !k.hour}
                              />
                            </div>
                          </div>
                        ))
                      }
                      <div className="addItem">
                        <CustomButton
                          pink
                          onClick={() => this.itemProcess(1)}
                          startIcon={<PlusButton color={item?.length === 5 ? Colors.grey : Colors.burgundy} />}
                          style={{ margin: 'auto' }}
                          fullWidth
                          disabled={item?.length === 5}
                        >
                          {tra('Add_new_quantity_shipping_time')}
                        </CustomButton>
                      </div>
                    </div>
                  </>
                )
              }
              {
                content === 0 ? (
                  <div className="buttonWrapper">
                    <CustomButton simple onClick={() => this.close()}>{tra('Cancel')}</CustomButton>
                    {
                      this.state.deliveryMethod?.id === 2 && (
                        <CustomButton
                          blue
                          green={this.state.hourSelected || oldItem?.[0]?.amount > 0}
                          orange={!this.state.hourSelected && !(oldItem?.[0]?.amount > 0)}
                          colorOpacity={0.2}
                          onClick={() => this.chooseHours()}
                          endIcon={(this.state.hourSelected || oldItem?.[0]?.amount > 0) ? <CheckCircle /> : <WarningCircle />}
                        >
                          {this.state.hourSelected ? tra('Time_selected') : tra('Select_Time')}
                        </CustomButton>
                      )
                    }
                    <CustomButton blue onClick={() => this.checkInputs()}>{tra('Complete_Order')}</CustomButton>
                  </div>
                ) : content === 1 && (
                  <div className="buttonWrapper">
                    {/* <CustomButton
                      simple
                      onClick={() => this.setState({
                        content: 0, hourSelected: false, item: [{ amount: null, hour: null }], errorMessage: false,
                      })}
                    >
                      {tra('Cancel')}
                    </CustomButton> */}
                    <CustomButton blue onClick={() => this.saveHours()}>{tra('Save')}</CustomButton>
                  </div>
                )
              }
            </div>
          </CustomFadeOrder>
        </Modal>
        {
          this.state.questionModal && (
            <GenerateModal
              open={this.state.questionModal}
              type="Question"
              title={tra('Order')}
              text={tra('An_order_will_be_created_for_the_product_you_selected_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.createOrder()}>{tra('Done')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.setClear()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

CreateOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  productGroups: PropTypes.array.isRequired,
  createOrders: PropTypes.func.isRequired,
  appConfig: PropTypes.func.isRequired,
  appConfigs: PropTypes.object.isRequired,
  getOrderProduct: PropTypes.func.isRequired,
  orderProduct: PropTypes.array.isRequired,
  selectedProduct: PropTypes.number,
  getCreditLimit: PropTypes.func.isRequired,
  creditLimit: PropTypes.object.isRequired,
  setCreditLimit: PropTypes.func.isRequired,
  getDeliveries: PropTypes.func.isRequired,
  deliveries: PropTypes.array.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  setDeliveries: PropTypes.func.isRequired,
  initCompanyCodes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  serviceConfigs: PropTypes.object.isRequired, // livecycle'da kullanılıyor
};

CreateOrder.defaultProps = {
  selectedProduct: false,
};

const mapStateToProps = createStructuredSelector({
  // deliveryPoints: selectDeliveryPoints(),
  productGroups: selectProductGroups(),
  // products: selectProducts(),
  // buildingSites: selectBuildingSites(),
  // factories: selectFactories(),
  // creditControlAreas: selectCreditControlAreas(),
  orderProduct: selectOrderProduct(),
  creditLimit: selectCreditLimit(),
  deliveries: selectDeliveries(),
  shippingCost: selectShippingCost(),
  appConfigs: selectAppConfig(),
  initCompanyCodes: selectInitCompanyCodes(),
  serviceConfigs: selectServiceConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createOrders,
      // setToast,
      getOrderProduct,
      getCreditLimit,
      setCreditLimit,
      getDeliveries,
      setOrderProduct,
      setDeliveries,
      appConfig,
      // getConditionalService,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
