export default {
  Ortak: {
    Filter: 'Filtrar',
    Confirmation: 'Confirmación',
    The_Employee_Will_Be_Passive_Do_you_confirm: 'El empleado será pasivo. ¿Confirma?',
    Customer_will_be_inactive_Do_you_confirm: 'El cliente será pasivo. ¿Confirma?',
    Cancel: 'Cancelar',
    Accept: 'Aceptar',
    Cimsa_Customer_Portal: 'Fercim Portal del Cliente',
    Welcome_to_Çimsa_Customer_Portal: 'Bienvenido al Portal del Cliente de Fercim',
  },
  Header: {
    Overview: 'Visión general',
    Welcome_to_Cimsa_Admin_Screen: 'Bienvenido a la Pantalla de Administrador de Fercim',
    Announcements: 'Anuncios',
    No_Announcement: 'No hay anuncios',
    Settings: 'Configuración',
  },
  Widgets: {
    Total_Customers: 'Clientes totales',
    Total_Order_Quantity: 'Cantidad total de pedido',
    Orders_in_Delivery: 'Pedidos en Entrega',
    Compared_to_last_month: 'Comparado con el mes pasado',
    Orders_Report: 'Informe de pedidos',
  },
  Admin: {
    Create_New_Employee: 'Crear nuevo empleado',
  },
  Customers: {
    Must_be_created: 'Debe ser creado',
    Download_Excel: 'Descarga Excel',
    Create_Customer: 'Crear cliente',
  },
  Dash: {
    Overdue_Debt: 'Deuda vencida',
    Collection_Average_Maturity: 'Vencimiento medio de cobranza',
    available_Limits: 'Límites disponibles',
    Todays_Orders: 'Pedidos de hoy',
    Piece: 'Pieza',
    Day: 'Día',
    Balance: 'Equilibrio',
    Low_Limit: 'Limite bajo',
    Insufficient_Limit: 'Límite insuficiente',
    Payment_Information: 'Información del pago',
    Expected_Transactions: 'Transacciones esperadas',
    See_All: 'Ver todo',
    Current_Account_Information: 'Información de la cuenta corriente',
    Code: 'Código',
    Account_Name: 'Nombre de la cuenta',
    Group: 'Grupo',
    District: 'Distrito',
    Province: 'Provincia',
    Recent_Transactions: 'Transacciones recientes',
  },
  Login: {
    Password_Reset: 'Restablecer la contraseña',
    Please_enter_your_email_address_to_process: 'Por favor, ingrese su dirección de correo electrónico para procesar.',
    Email: 'Correo electrónico',
    Send_Reset_Email: 'Enviar correo electrónico de restablecimiento ',
    Please_enter_the_new_password_you_want_to_create: 'Por favor ingrese la nueva contraseña que desea crear.',
    Password: 'Contraseña',
    Password_Again: 'Contraseña de nuevo',
    Save: 'Guardar',
    I_accept_the_Personal_Data_Protection_Text: 'Acepto el Texto de Protección de Datos Personales.',
    Please_enter_a_valid_email_address: 'Por favor, ingrese una dirección de correo electrónico válida.',
    Your_Password_Does_Not_Match: 'Su contraseña no coincide',
    Your_password_must_be_at_least_8_digits: 'Su contraseña debe tener al menos 8 dígitos.',
    Please_log_in_to_the_system_by_entering_the_username_given_to_you: 'Por favor, inicie sesión en el sistema ingresando el nombre de usuario que se le proporcionó.',
    Enter_the_code_sent_to_your_phone_number_or_email: 'Por favor, ingrese el código enviado a su número de teléfono o correo electrónico.',
    Login: 'Iniciar sesión',
    Forget_Password: '¿Olvidó la contraseña?',
    Send_Code_Again: 'Reenviar el código',
  },
  Invoices: {
    Expense_Statistics: 'Estadísticas de gastos',
    Total_Invoice: 'Total de la factura',
    Guarantee: 'Garantía',
    Hypothec: 'Hipoteca',
    search_for_Invoice_number_product: 'Número de factura, buscar producto',
    Starting_Date: 'Fecha de inicio',
    End_Date: 'Fecha final',
    It_is_for_informational_purposes_only_It_has_no_financial_value: 'Es solo para fines informativos. No tiene valor económico.',
    Open_Invoices: 'Facturas abiertas',
    Paid: 'Pagado',
    Cash_Collection: 'Cobro al contado',
    Credit_Card: 'Tarjeta de crédito',
    Check_Information: 'Información de la cheque',
    DDS_Information: 'Información de DBS',
    Transfers: 'Transferencias',
  },
  Order: {
    Order_Now: 'Pedir ahora',
    Order_Status: 'Estado del pedido',
    Order_Date: 'Fecha de pedido',
    Tonnage_Request_Sent: 'Solicitud de tonelaje enviada',
    Request_Tonnage_Increase: 'Solicitar aumento de tonelaje',
    Order_Detail: 'Detalle de pedido',
    Repeat_Order: 'Repite el pedido',
    Cancel_Order: 'Cancelar pedido',
    Order_Stage: 'Etapa de pedido',
    Order_Ref_ID: 'ID de referencia de pedido',
    Product: 'Producto',
    Delivery_Time: 'El tiempo de entrega',
    Ton: 'Tonelada',
    There_is_no_order: 'No hay pedido',
    Tonnage_Increase: 'Aumento de tonelaje',
    Your_tonnage_increase_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm: 'Su solicitud de aumento de tonelaje se enviará al equipo de operaciones de ventas. ¿Confirma?',
    Continue: 'Continuar',
    Order_Will_Be_Canceled: 'Se cancelará el pedido',
    Your_order_cancellation_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm: 'Su solicitud de cancelación de pedido se enviará al equipo de operaciones de ventas. ¿Confirma?',
    Approved: 'Aprobado',
    On_Its_Way: 'En camino',
    Was_Delivered: 'Entregado',
    Delivery_Address: 'Dirección de entrega',
    Tonnage_Increase_Will_Be_Requested: 'Se solicitará aumento de tonelaje',
  },
  Product: {
    Product_No: 'Producto nº.',
    Product_Group: 'Grupo de productos',
    Product_Type: 'Tipo de producto',
    Product_Name: 'Nombre del producto',
    Product_Description: 'Descripción del Producto',
    Change_Product_Description: 'Cambiar la descripción del producto',
    Define_Blockage: 'Definir bloqueo',
    Search_Product: 'Buscar producto',
    New_Product: 'Nuevo producto',
    the_description_to_be_changed: 'Se cambiará la descripción del producto',
    Change_Description: 'Cambiar la descripción',
  },
  SideBar: {
    Order: 'Pedido',
    Invoices_Screen: 'Pantalla de facturas',
    Payments_Screen: 'Pantalla de pagos',
    Coverage_Information: 'Información de cobertura',
    Add_Sub_User: 'Añadir usuario secundario',
    Reports: 'Informes',
    Orders_Report: 'Informe de pedidos',
    Tonnage_Report: 'Informe de tonelaje',
    Transaction_Logs_Report: 'Informe de registros de transacciones',
    Cus_Rep_Match_Report: 'Informes coincidentes del representante del cliente',
    Payments_Report: 'Informe de pagos',
    Products: 'Productos',
    Cement: 'Cemento',
    Ready_Mixed_Concrete: 'Concreto premezclado',
    Create_Announcement: 'Crear anuncio',
    Management_Screen: 'Pantalla de gestión',
    Customer_Management: 'Gestión de clientes',
    Çimsa_Admin_Screen: 'Pantalla de administración',
    y2021_All_Rights_Reserved: '2021 Todos los derechos reservados',
    Made_with: 'Hecho con',
    by_MDP_Group: 'Por MDP Group',
    Made_with_by_MDP_Group: 'Hecho con MDP Group',
  },
  Columns: {
    Order_Number: 'Número de pedido',
    Customer: 'Cliente',
    Customers: 'Clientes',
    Customer_No: 'Número de cliente',
    Date: 'Fecha',
    Quanta: 'Cantidad',
    Situation: 'Estado',
    Cancelled: 'Cancelar',
    Action: 'Acción',
    Edit: 'Editar',
    Manage: 'Administrar',
    Make_Active: 'Activar',
    Make_Passive: 'Pasivar',
    Active: 'Activo',
    Passive: 'Pasivo',
    Authorized_Person: 'Persona autorizada',
    Authorized_Phone: 'Teléfono autorizado',
    Authorized_Email: 'Correo electrónico autorizado',
    Contact_Info: 'Información de contacto',
    Business_Area: 'Área de negocio',
    Sales_Representative: 'Representante de ventas',
    Type: 'Tipo',
    Pending: 'Pendiente',
    Amount: 'Monto',
    Status: 'Estado',
    Pay: 'Pagar',
    View: 'Ver',
    Print: 'Imprimir',
    Invoice_No: 'Factura nº.',
    Reference: 'Referencia',
    Total_Amount: 'Total',
    Currency: 'Moneda',
    Invoice_Date: 'Fecha de la factura',
    Posting_Date: 'Fecha de publicación',
    Explanation: 'Explicación',
    File_Number: 'Número de archivo',
    Credit_Control: 'Control de crédito',
    Payment_Status: 'Estado de pago',
    Due_Date: 'Fecha de vencimiento',
    Clearing_Document: 'Documento de compensación',
    Document_Type: 'Tipo de Documento',
    Tracking_No: 'Seguimiento nº.',
    External_Delivery_Id: 'Entrega al extranjero nº.',
    Material: 'Material',
    Material_Text: 'Información de material',
    Plaque: 'Placa',
    Sub_Packaging: 'Preenvasado',
    User_Deleted: 'Usuario eliminado',
    User_Type: 'Tipo de usuario',
    User_Name: 'Nombre de usuario',
    User_Surname: 'Apellido del usuario',
    Telephone: 'Teléfono',
    Transactions: 'Transacciones',
    Authorize: 'Autorizar',
    Add_New_User: 'Añadir nuevo usuario',
  },
  charts: {
    chart_date: 'Fecha del gráfico',
  },
  Table: {
    Cancel: 'Cancelar',
    Delete_Row: 'Borrar fila',
    Edit_Row: 'Editar línea',
    End: 'Finalizar',
    found: 'encontró.',
    Next: 'Siguiente',
    No_Data: 'Sin datos...',
    Prev: 'Anterior',
    Search_Data: 'Búsqueda de datos',
    Save_Row: 'Guardar fila',
    Starting: 'Inicial',
    table_pagination: 'mostrando [hay1] y [hay2] del documento [hay0]',
    Clear: 'Limpiar',
  },
  Modals: {
    Block_Create_Date: 'Fecha de creación del bloque',
    Please_Fill_in_the_mandatory_fields: 'Por favor llene los campos requeridos.',
    Select_Business_Areas: 'Seleccionar áreas comerciales',
    Select_Product_Code: 'Seleccionar código de producto',
    Choose_Customer_Number: 'Elegir el número de cliente',
    All_Except_Selected_Customers: 'Todos excepto los clientes seleccionados',
    Block_Start_Date: 'Fecha de inicio del bloque',
    Block_End_Date: 'Fecha de finalización del bloque',
    Complete: 'Completar',
    Selected_products_will_be_blocked_for_the_Customers_you_have_selected_Do_you_confirm: 'Los productos seleccionados se bloquearán para los Clientes que haya seleccionado. ¿Confirma?',
    Enter_hours_and_quantities: '¡Ingrese horas y cantidades!',
    Available_Credit: 'Crédito disponible',
    Delivery_Method: 'Método de entrega',
    Goods_Delivery_Place: 'Lugar de entrega de mercancías',
    Payment_Condition: 'Condiciones de pago',
    Select_the_requested_quantity_and_shipping_date_and_time: 'Seleccionar la cantidad solicitada y la fecha y hora de envío.',
    Time: 'Hora',
    Add_new_quantity_shipping_time: 'Añadir nueva cantidad, hora de envío',
    Select_Time: 'Seleccionar hora',
    Complete_Order: 'Completar pedido',
    Check_Credit: 'Verificar crédito',
    An_order_will_be_created_for_the_product_you_selected_Do_you_confirm: 'Se creará un pedido para el producto que seleccionó. ¿Confirma?',
    Done: 'Hecho',
    Shipping_Date: 'Fecha de envío',
    Order_Summary: 'Resumen del pedido',
    Delivery_1_Product: 'Entrega, 1 producto',
    Building_Site: 'Sitio de construcción',
    Do_you_confirm: '¿Confirma?',
    Tonnage_Increase_Request_Sent: 'Solicitud de aumento de tonelaje enviada',
    PDPA_Approval: 'Aprobación LPDL',
    PDPA_Text: 'Texto LPDP',
    Candidate_Clarification_Text_on_the_Protection_of_Your_Personal_Data: 'Texto de aclaración del candidato sobre la protección de sus datos personales',
    I_have_read_and_I_approve: 'He leído y apruebo',
    Edit_Customer: 'Editar cliente',
    SAP_Customer_Number: 'Número de cliente de SAP',
    Bring: 'Traer',
    Customer_Name: 'Nombre del cliente',
    Address: 'Dirección',
    Authorized_Person_Name: 'Nombre de la persona autorizada',
    Authorized_Person_Surname: 'Apellido de la persona autorizada',
    Authorized_Person_Email: 'Dirección de correo electrónico de la persona autorizada',
    Authorized_Person_Telephone: 'Número de teléfono de la persona autorizada',
    Customer_Representative: 'Representante del cliente',
    Verification_Method: 'Método de verificación',
    SMS: 'SMS',
    EMAIL: 'CORREO ELECTRÓNICO',
    EMAIL_AND_SMS: 'SMS Y CORREO ELECTRÓNICO',
    Add_Customer: 'Añadir cliente',
    Save_Edit: 'Guardar Editar',
    Create_New_Customer: 'Crear nuevo cliente',
    Customer_will_be_registered_Do_you_confirm: 'El cliente quedará registrado. ¿Confirmas?',
    Attention: '¡Atención!',
    If_you_exit_without_saving_your_data_will_be_lost: 'Si sale sin guardar, sus datos se perderán.',
    Admin: 'Administración',
    Edit_Employee: 'Editar empleado',
    Create_New_Employee: 'Crear nuevo empleado',
    SAP_Username: 'Nombre de usuario de SAP',
    Employee_Name: 'Nombre de empleado',
    Employee_Surame: 'Apellido del empleado',
    Employee_Type: 'Tipo de empleado',
    Represented_Customers: 'Clientes representados',
    Employee_will_be_arranged_Do_you_confirm: 'Se editará el empleado. ¿Confirma?',
    A_New_Employee_Will_Be_Created_Do_you_confirm: 'Se creará un nuevo empleado. ¿Confirma?',
    SAP_Product_Number: 'Número de producto de SAP',
    A_new_product_will_be_created_Do_you_confirm: 'Se creará un nuevo producto. ¿Confirma?',
    Customer_Admin: 'Administración de clientes',
    Master_Account_Cannot_Be_Changed: 'Cuenta principal (no se puede cambiar)',
    Edit_Subser: 'Editar usuario secundario',
    Create_Subuser: 'Añadir usuario secundario',
    Subuser_Type: 'Tipo de usuario secundario',
    Subuser: 'Usuario secundario',
    Deactivate_User: 'Pasivar usuario',
    Activate_User: 'Activar al usuario',
    Add_User: 'Añadir usuario',
    Subuser_Will_Be_Active: '¡El usuario secundario estará activo!',
    Subuser_To_Be_Edited: 'Usuario secundario a editar',
    Create_New_Subuser: 'Crear nuevo usuario secundario .',
    Subuser_will_be_activated_Do_you_confirm: 'El usuario secundario se activará. ¿Confirma?',
    Subuser_Will_Be_Registered_Do_You_Confirm: 'El usuario secundario será registrado. ¿Confirma?',
    Subuser_To_Be_Passive_Do_You_Confirm: 'El usuario secundario será pasivo. ¿Confirma?',
    Subuser_To_Be_Edited_Do_you_Confirm: 'El usuario secundario será editado. ¿Confirma?',
  },
  Saga: {
    Could_not_establish_a_connection_with_the_server: '¡No se pudo conectar al servidor!',
    Preparing_for_download: 'Preparándose para la descarga…',
    Customer_Edited: 'Cliente editado',
    Failed_to_Edit_Customer: 'No se puede editar el cliente',
    Error: 'Error',
    Successful: 'Exitoso',
    Failed_to_Retrieve_Customer_Information: '¡No se puede obtener la información del cliente!',
    Failed_to_Retrieve_User_Information: '¡No se puede obtener la información del usuario!',
    Order_Created: 'Pedido creado',
    Your_password_has_been_successfully_sent_to_your_email_Please_check_your_mail_to_login: 'Su contraseña ha sido enviada con éxito a su correo electrónico. Por favor revise su correo para iniciar sesión.',
    Your_Password_Has_Been_Changed_Successfully: 'Contraseña ha sido cambiada exitosamente',
    There_was_a_problem_during_the_password_process: 'Ocurrió un problema durante el proceso de contraseña.',
    User_Created: 'Usuario creado',
    Problem_Occurred_While_Creating_User: 'Ocurrió un problema al crear un usuario',
    User_Edited: 'Usuario editado',
    Failed_to_User_Edit: 'No se puede editar el usuario',
    User_Status_Changed: 'Estado de usuario cambiado',
    Failed_to_User_Status_Changed: 'No se puede cambiar el estado del usuario',
    Failed_to_Product_Fetch_from_SAP_Service: 'No se puede recuperar el producto del servicio SAP',
    An_error_occurred_while_fetching_Credit_Limit: 'Ocurrió un problema al obtener el límite de crédito.',
  },
  Utils: {
    now: 'ahora',
    minute_ago: 'hace __ minuto',
    hours_ago: 'hace __horas',
    day_ago: 'hace __ dia',
    week_ago: 'hace __ semana',
    month_ago: 'hace __ mes',
    years_ago: 'hace __ años que',
    minutes_left: 'quedan __ minutos',
    minutes: 'minuto',
    hours: 'hora',
    gün: 'día',
    week: 'semana',
    month: 'mes',
    years: 'años',
    left: 'quedan',
    Invalid_token: '¡Token no valido!',
    File_Downloaded_Successfully: 'Archivos descargados con éxito',
  },
  new: {
    Blockage_List: 'Lista de bloqueos',
    Close: 'Cerrar',
    write: 'escribir',
    Search: 'Buscar',
    Exit: 'Salida',
    Total: 'Total',
    Delivery_Place: 'lugar de entrega',
    New_Order: 'Nuevo orden',
    Previous_Orders: 'Órdenes previas',
    Change_Company: 'Şirket Değiştir',
    Block_Removed: 'Bloqueo eliminado',
    Failed_to_Block_Removing: '¡No se puede eliminar el bloqueo!',
    Order_Creation: 'Creación de pedidos',
    Back: 'espalda',
    Remove: 'retirar',
    Choose_File: 'seleccione Archivo',
    Name_Surname: 'Nombre Apellido',
    Blockage_will_be_delete_Do_you_confirm: 'El bloqueo se eliminará. ¿Confirmas?',
    Search_Blockage: 'Bloqueo de búsqueda',
    Enter_a_value_greater_than_zero: 'Ingrese un valor mayor que cero.',
    You_must_enter_the_amount_first: 'Primero debe ingresar la cantidad.',
    Please_make_sure_that_the_sum_of_their_tonnages_equals: 'Asegúrese de que la suma de su tonelaje sea igual a [var0].',
    Customer_will_be_active_Do_you_confirm: 'El cliente se activará. ¿Confirmas?',
    Year: 'Año',
    Please_contact_your_customer_representative_to_place_an_order: 'Comuníquese con su representante de atención al cliente para realizar un pedido.',
    There_were_no_results: 'No hubo resultados',
    Choose_a_Time: 'Seleccionar hora',
    Please_enter_valid_date: 'Ingrese una fecha válida.',
    Date_should_not_be_before_minimum_date: 'La fecha no debe ser anterior a la fecha mínima.',
    Time_not_selected: 'Sin tiempo seleccionado',
    Time_selected: 'Hora seleccionada',
    Order_Creator: 'Order Creator',
    The_transactions_you_will_do_after_that_will_be_done_on_behalf_of_x_Do_you_confirm: 'Las acciones que tomará a partir de ahora se realizarán en nombre de [var0]. ¿Confirmas?',
    CAUTION: 'PRECAUCIÓN',
    YOU_ARE_TRANSACTING_FOR_CUSTOMER_TO_SIGN_OUT: 'ESTÁ REALIZANDO TRANSACCIONES PARA [var0] CLIENTE.',
    CLICK_HERE: 'HAGA CLIC AQUÍ',
    You_Cannot_Manage_This_Customer: 'No puede administrar este cliente.',
    You_Cannot_Manage_This_Customer_Because_It_Is_Inactive_In_order_to_Manage_the_Customer_first_activate_it: 'No puede administrar este cliente porque está inactivo. Para administrar el cliente, primero configúrelo en estado activo.',
    Company_Selection: 'Selección de empresa',
    Unpaid: 'No pagado',
    Shipment_Report: 'Informe de envío',
    Create_Date: 'Fecha de creación',
    Material_No: 'Numero de Material.',
    Material_Name: 'Nombre del material',
    Unit: 'TRANSMISIÓN EXTERIOR.',
    Used_Amount: 'Cantidad usada',
    Delivery_Date: 'Fecha de entrega',
    Logs: 'registros',
    Message: 'Mensaje',
    Remote_Address: 'Dirección remota',
    True: 'Cierto',
    False: 'Incorrecto',
    Last_5_Orders: 'Últimos 20 pedidos',
    Select_Credit_Control: 'Seleccione Control de crédito',
    Action_Pending_Orders: 'Órdenes pendientes de acción',
    Company: 'Empresa',
    User: 'Usuario',
    Please_enter_the_6_digit_code_correctly: 'Ingrese el código de 6 dígitos correctamente',
    Transfer_Customers: 'Clientes de transferencia',
    Replacing_the_agent_representative: 'Reemplazo del agente',
    Represented_customers_will_be_transferred_Do_you_confirm: 'Los clientes representados serán transferidos. ¿Confirmas?',
    User_Operations: 'Operaciones de usuario',
    Credit_Control_No: 'Núm. de cont. de crédito',
    Change_Credit_Description: 'Cambiar la definición de crédito',
    Update_Date: 'Fecha de actualización',
    Company_Not_Selected: 'Ninguna empresa seleccionada',
    Times_up_Sign_in_again: '¡Se acabó el tiempo! Iniciar sesión de nuevo',
    Credit_Control_Description: 'Definición de control de crédito',
    Produced_by_MDP_Group: 'Producido por MDP Group.',
    You_do_not_have_sufficient_credits: 'No tienes suficientes créditos.',
    Key: 'llave',
    File_No: 'Documento núm',
    File_Type: 'Tipo de Documento',
    File_Description: 'Descripción del documento',
    Net_Due_Date: 'Fecha de vencimiento neta',
    Maturity_Description: 'Término Definición',
    Open_Account: 'Cuenta abierta',
    Invoices: 'Facturas',
    Document_Date: 'Fecha del documento',
    ODK: 'ÖDK',
    Reference_No: 'Numero de referencia',
    Debit_Credit_Indicator: 'Indicador de débito-crédito',
    Overdue: 'Atrasado',
    Undue: 'Sin duda',
    Payment_Type: 'Tipo de pago',
    Delivery_Type: 'tipo de entrega',
    eWayBillNo: 'E Waybill No',
    SAP_delivery_No: 'Número de entrega de SAP',
    Construction_Site_Number: 'Número de sitio',
    Construction_Site_Name: 'Nombre del sitio',
    Product_Name: 'Nombre del producto',
    Roles: 'Roles',
    Edit_Roles: 'Editar rol',
    Create_New_Role: 'Crear nuevo rol',
    New_Roles: 'Nuevo rol',
    Permissions: 'Permisos',
    Cimsa: 'Fercim',
    No_Role: 'Sin rol',
    Select_All: 'Seleccionar todo',
    Name: 'Nombre',
    Please_select_a_credit_control_area_with_a_customer: '¡Seleccione un área de control de crédito con un cliente!',
    To_edit_the_passive_user_you_need_to_activate_it: 'Para editar el usuario pasivo, debe activarlo.',
    The_role_is_being_created: 'Se está creando el rol.',
    Creating_a_new_role_Do_you_confirm: 'Creando un nuevo rol. ¿Confirmas?',
    Choose_Permission: 'Elegir permiso',
    Invoice_Description: 'Definición de factura',
    Orders_Opened_from_the_Portal: 'Órdenes abiertas desde el portal',
    Pump_Plaque: 'Placa de bomba',
    There_is_no_invoice: 'Factura no encontrada.',
    Factory_Description: 'Descripción de fábrica',
    Change_Fabric_Description: 'Cambiar descripción de fábrica',
    Title: 'Título',
    Text: 'Texto',
    Last: 'Fin',
    Order_Cancellation: 'Cancelación de Orden',
    Tonnage_Update: 'Actualización de Tonelaje',
    Order_Transactions: 'Transacciones de Pedidos',

    Total_Quantity: 'Cantidad total',
    Reject: 'rechazar',
    Approve: 'Aprobar',
    An_error_has_occurred: 'Se ha producido un error',
    The_order_update_request_will_be_approved_Do_you_confirm: 'Se aprobará la solicitud de actualización del pedido. ¿Confirmas?',
    Order_update_request_will_be_rejected_Do_you_confirm: 'Se rechazará la solicitud de actualización del pedido. ¿Confirmas?',
    Rejection: 'Rechazo',
    Company_Codes: 'Códigos de empresa',
    Company_Code: 'Codigo de compañia',
    Limit_Control: 'Control de límite',
    Company_Name: 'Nombre de empresa',
    Not_Defined: 'No definida',
    Confirmation_Code: 'Código de confirmación',
    Change_Defined_Credits: 'Cambiar créditos definidos',
    Defining_Credit_Control_to_the_Company: 'Definición de control crediticio para la empresa',
    Changing_credit_controls_defined_for_the_company: 'Modificación de los controles crediticios definidos para la empresa.',
    Send: 'Enviar',
    Draft: 'Borrador',
    Announcement_will_be_published_Do_you_confirm: 'Se publicará el anuncio. ¿Confirmas?',
    Check_limit: 'Verificación de límites',
    Do_not_Check_limit: 'No Verificación de límites',
    Time_Range: 'Intervalo de tiempo',
    Update_Company_Settings: 'Actualizar la configuración de la empresa',
    Enter_a_valid_time_range: 'Ingrese un rango de tiempo válido.',
    The_insufficient_limit_cannot_be_higher_than_the_low_limit: 'El límite insuficiente no puede ser superior al límite inferior.',
    Payment_Terms: 'Condiciones de pago',
    Update_from_SAP: 'Actualización de SAP',
    Payment_Term_No: 'Condición de pago No',
    Updated_from_SAP: 'Actualizado desde SAP',
    Tonnage_Increase_Amount: 'Aumento de tonelaje Mic.',
    Tonnage_Increase_Request: 'Solicitud de aumento de tonelaje',
    Repeat: 'Repetir',
    Repeat_Last_Order: 'Repetir último pedido',
    File_Downloaded_Successfully: 'Archivo descargado correctamente',
    All_Users: 'Todos los usuarios',
    KVKK_End_Date: 'Fecha de finalización de GDPR',
    Role: 'Papel',
    Announcement_has_been_published: 'Se ha publicado el anuncio.',
    Last_Announcement_Drafts: 'Borradores del anuncio final',
    Please_chance_password_by_entering_the_password_given_to_you: 'Cambie su contraseña ingresando la contraseña que se le proporcionó.',
    New_Password: 'Nueva contraseña',
    New_Password_Again: 'Nueva contraseña (de nuevo)',
    New_passwords_are_not_the_same: 'Las nuevas contraseñas no son iguales',
    Password_must_be_between_6_32_characters_long: 'La contraseña debe tener entre 8 y 32 caracteres',
    The_password_will_change_Do_you_confirm: 'Se cambiará la contraseña. ¿Confirmas?',
    Open: 'abierto',
    Previous_Announcements: 'Anuncios pasados',
    Drafts: 'borradores',
    Publish: 'Publicar',
    Template: 'Plantilla',
    Templates: 'plantillas',
    The_template_will_be_edit_Do_you_confirm: 'Se editará la plantilla. ¿Confirmas?',
    It_will_be_saved_as_a_template_Do_you_confirm: 'Se guardará como plantilla. ¿Confirmas?',

    Today_Debt: 'Deuda de hoy',
    KVKK_Validity: 'Validez del RGPD',
    KVKK_Text: 'Texto RGPD',
    Email_Sender_Name: 'Nombre de los remitentes de correo electrónico',
    Email_Sender_Address: 'Dirección de los remitentes de correo electrónico',
    SMTP_Port: 'Puerto SMTP',
    SMTP_Server: 'Servidor SMTP',
    Avaible: 'Disponible',
    Order_Settings: 'Configuración de pedidos',
    Mail_Settings: 'Configuración de pedidos',
    KVKK_Settings: 'Configuración de RGPD',
    Make_Payment: 'Hacer el Pago',
    The_Settings_Updated: 'La configuración se actualiza.',
    Months: 'Mes',
    Changing_company_settings: 'Se están cambiando los ajustes de la empresa.',
    The_role_is_being_edited: 'El rol es la edición.',
    Explicit_Consent_Statement_Form: 'Formulario de declaraciones de consentimiento explícito',
    Total_Debt: 'Deuda Total',
    Editing_the_role_Do_you_confirm: 'El papel se mantiene. ¿Confirmas?',
    An_error_occurred_while_fetching_Blockages_data: 'blokaj Listesi Getirilirken hata oluştu!',
    updating: 'Actualizando.',
    Password_must_be_between_8_32_characters_long: 'La contraseña debe tener entre 8 y 32 caracteres.',
    Welcome_to_Cimsa_Customer_Screen: 'Pantalla Bienvenidos a Clientes Fercim',
    SAP_Order_Number: 'Número de pedido de SAP',
    Click_to_add: 'Haga clic para agregar',
    Click_here_to_download_the_user_manual_of_the_Customer_Portal: 'Haga clic aquí para descargar el manual de usuario del Portal de Clientes',
    click_here: 'haga clic aquí.',
    manual_link: 'https://www.fercim.com.tr/bayi/Bayi_Portal_EN.pdf',
    Please_Create_a_New_8_character_Password: 'Por favor crea una nueva contraseña de 8 caracteres.',
    Shipping_cost_not_found_Contact_your_customer_representative: 'Costo de envío no encontrado. Póngase en contacto con su representante de atención al cliente.',
  },
  Months: {
    Jan: 'Ene',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Abr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Ago',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dic',
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    Mayy: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
  },
  DashboardCharts: {
    Avg: 'Promedio',
    Users: 'Usuarios',
    Monthly_Reports: 'Reportes mensuales',
    Order_Reports: 'Informes de pedidos',
    Please_Choose: 'Por favor elige',
    Select_Year: 'Seleccionar año',
    Log: 'Tronco',
    Choose_BuildingSite: 'Elija el sitio de construcción',
  },
};
