import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  ClickAwayListener, Dialog, Grid, Tooltip, Zoom,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  CustomButton,
  CustomTextField,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  SearchBar,
  CustomIconButton,
  CustomDialogContent,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  CrossFilled,
  EditMenu,
  Eye,
  MobileTripleIcon,
  PlusButton,
  Reload,
} from '../assets/icons';
import Blockage from '../components/Modals/Blockage';
import Info from '../components/Modals/Info';

import {
  getProducts,
  getCustomers,
  blockageProducts,
  setToast,
  editProduct,
  getSapProduct,
  createProduct,
  setSapProduct,
  getBlockages,
  getCreditControl,
  updateSapProducts,
  deleteBlockage,
} from '../store/actions';
import {
  selectProducts,
  selectCustomers,
  selectSapProduct,
  selectWorkAreas,
  selectCreditControl,
  selectBlockagesList,
} from '../store/selectors';
import NewProduct from '../components/Modals/NewProduct';
import { tra } from '../commons/utils';
import CustomFilter from '../components/CustomFilter';
import Input from '../components/Input';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeAlternativeName: false,
      openNewProduct: false,
      filter: [],
      innerTabValue: 1,
    };
    props.getProducts();
    props.getCustomers();
    props.getCreditControl();
  }

  innerTabChange = (event) => {
    this.setState({ innerTabValue: event, filter: [], searchFilter: '' });
    // localStorage.setItem('Dashboard', event);
    // this.props.getOrders(event + 1);
    switch (event) {
      case 1:
        // this.props.getProducts();
        // this.props.getCustomers();
        break;
      case 2:
        // this.props.getProducts();
        // this.props.getCustomers();
        this.props.getBlockages();
        break;
      default:
        break;
    }
  }

  // tabData = () => {
  //   const { innerTabValue } = this.state;
  //   switch (innerTabValue) {
  //     case 1:
  //       return this.props.products;
  //     case 2:
  //       return this.props.blockagesList;
  //     default:
  //       return this.props.products;
  //   }
  // }

  render() {
    const {
      searchFilter, openBlockage, openNewProduct, blockageCode, filterDrawer, filter, innerTabValue,
    } = this.state;
    const {
      products, customers, workAreas, creditControl, blockagesList,
    } = this.props;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? ((innerTabValue === 2 ? blockagesList : products)
    && (innerTabValue === 2 ? blockagesList : products)?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : (innerTabValue === 2 ? blockagesList : products);
    const columns = [
      // {
      //   name: 'is_removed',
      //   text: tra('Aktive'),
      //   filterable: true,
      //   align: 'center',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     <Tooltip title={!value ? tra('Aktive') : tra('Passive')} placement="top">
      //       <div style={{
      //         width: 15,
      //         height: 15,
      //         borderRadius: '100%',
      //         background: !value ? Colors.blue : 'red',
      //         margin: '0 auto',
      //       }}
      //       />
      //     </Tooltip>
      //   ),
      // },
      {
        name: 'sap_product_no',
        text: tra('Product_No'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'ProductGroup',
        text: tra('Product_Group'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'object',
        filterText: 'name',
        customBody: (value) => (value ? value.name : '-'),
      },
      // {
      //   name: 'ProductType',
      //   text: tra('Product_Type'),
      //   filterable: false,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => value && (value.name || '-'),
      // },
      {
        name: 'name',
        text: tra('Product_Name'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'alternative_name',
        text: tra('Product_Description'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'Action',
        text: tra('Action'),
        filterable: false,
        editable: false,
        align: 'left',
        sortable: false,
        type: 'action',
        disableSetting: false,
        customBody: (value, columnName, index, row) => (
          <div className="tableButton">
            <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
              <MobileTripleIcon />
            </CustomIconButton>
            {
              this.state[`anchorMobileTable${row.id}`] && (
                <MobileMoreMenu
                  className="mobileMoreMenu"
                  anchorEl={this.state[`anchorMobileTable${row.id}`]}
                  keepMounted
                  open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                  onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                  // placement="left"
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                >
                  <MobileMoreMenuItem
                    // active={!this.state[`columnVisibility${tabValue}`]}
                    onClick={() => {
                      this.setState({
                        changeAlternativeName: true, changingName: row.name, alternativeName: row.alternative_name, productId: row.id, [`anchorMobileTable${row.id}`]: null,
                      });
                    }}
                  >
                    <EditMenu />
                    {tra('Change_Product_Description')}
                  </MobileMoreMenuItem>
                  <MobileMoreMenuItem
                    // active={!this.state[`columnVisibility${tabValue}`]}
                    onClick={() => { this.setState({ blockageCode: row.sap_product_no, openBlockage: true, [`anchorMobileTable${row.id}`]: null }); }}
                  >
                    <CrossFilled color={Colors.grey} />
                    {tra('Define_Blockage')}
                  </MobileMoreMenuItem>
                </MobileMoreMenu>
              )
            }
          </div>
        ),
      },
    ];
    const blokajListColumns = [
      {
        name: 'createdAt',
        text: tra('Block_Create_Date'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && parseInt(value, 10) ? new Date(value).toLocaleDateString() : ''),
      },
      {
        name: 'Products',
        text: tra('Product_No'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value.map((c) => (<p>{c.sap_product_no}</p>))} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.sap_product_no} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'Products',
        text: tra('Product_Name'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value.map((c) => (<p>{c.name}</p>))} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.name} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'CreditControlAreas',
        text: tra('Credit_Control'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value?.length !== 1 ? value?.map((c) => (<p>{c.name}</p>)) : ''} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.name} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'Customers',
        text: tra('Customer_No'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value.map((c) => (<p>{c.name}</p>))} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.name} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'block_start_date',
        text: tra('Block_Start_Date'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && parseInt(value, 10) ? new Date(value).toLocaleDateString() : ''),
      },
      {
        name: 'block_end_date',
        text: tra('Block_End_Date'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && parseInt(value, 10) ? new Date(value).toLocaleDateString() : ''),
      },
      {
        name: 'Action',
        text: tra('Action'),
        filterable: false,
        align: 'left',
        sortable: false,
        type: 'action',
        disableSetting: false,
        customBody: (value, columnName, index, row) => (
          <div className="tableButton">
            <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
              <MobileTripleIcon />
            </CustomIconButton>
            {
              this.state[`anchorMobileTable${row.id}`] && (
                <MobileMoreMenu
                  className="mobileMoreMenu"
                  anchorEl={this.state[`anchorMobileTable${row.id}`]}
                  keepMounted
                  open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                  onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                  // placement="left"
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                >
                  <MobileMoreMenuItem
                    onClick={() => this.setState({ blockageCodeId: row.id, infoDelete: true, [`anchorMobileTable${row.id}`]: null })}
                  >
                    <CrossFilled color={Colors.grey} />
                    {tra('Delete_Blockage')}
                  </MobileMoreMenuItem>
                </MobileMoreMenu>
              )
            }
          </div>
        ),
      },
    ];
    // function currentColumn() {
    //   switch (innerTabValue) {
    //     case 1:
    //       return columns;
    //     case 2:
    //       return blokajListColumns;
    //     default:
    //       return columns;
    //   }
    // }
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            // columns={currentColumn()}
            columns={innerTabValue === 2 ? blokajListColumns : columns}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search_Product')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState({ filterDrawer: !filterDrawer })}>{tra('Filter')}</CustomButton>
              </div>
              <div className="rightButtons">
                <Input
                  type="select"
                  data={[{ id: 1, name: tra('Products') }, { id: 2, name: tra('Blockage_List') }]}
                  startAdornment={<Eye />}
                  value={innerTabValue}
                  onChange={(event) => this.innerTabChange(event.target.value)}
                />
                <CustomButton
                  white
                  startIcon={<CrossFilled />}
                  onClick={() => this.setState({ openBlockage: true })}
                >
                  {tra('Define_Blockage')}
                </CustomButton>
                <CustomButton
                  white
                  startIcon={<Reload color={Colors.blue} />}
                  onClick={() => this.props.updateSapProducts()}
                >
                  {tra('Update_from_SAP')}
                </CustomButton>
                <CustomButton
                  startIcon={<PlusButton />}
                  onClick={() => this.setState({ openNewProduct: true })}
                >
                  {tra('New_Product')}
                </CustomButton>
              </div>
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              // columns={currentColumn() || []}
              columns={innerTabValue === 2 ? blokajListColumns : columns}
              id="reportsTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
        {
          openBlockage && (
            <Blockage
              open={openBlockage}
              onClose={() => this.setState({ openBlockage: false, blockageCode: null })}
              products={products}
              customers={customers}
              currentProduct={blockageCode}
              blockageProducts={(data) => this.props.blockageProducts(data)}
              setToast={(msg, variant) => this.props.setToast(true, msg, variant)}
              workAreas={workAreas}
              creditControls={creditControl}
            />
          )
        }
        <Info
          open={this.state.openInfo}
          onClose={() => this.setState({ openInfo: false })}
          type={this.state.alternativeName?.length > 0 ? 'Info' : 'Warning'}
          title={this.state.alternativeName?.length > 0 ? tra('Change_Description') : tra('Please_Fill_in_the_mandatory_fields')}
          text={`${this.state.changingName} ${tra('the_description_to_be_changed')}(${this.state.alternativeName || '-'})`}
          cancelButton={(<CustomButton grey onClick={() => this.setState({ openInfo: false })}>{tra('Cancel')}</CustomButton>)}
          confirmButton={this.state.alternativeName?.length > 0 && (
            <CustomButton
              blue
              onClick={() => {
                this.props.editProduct(this.state.productId, { alternative_name: this.state.alternativeName });
                this.setState({ openInfo: false, changeAlternativeName: false });
              }}
            >
              {tra('Continue')}
            </CustomButton>
          )}
        />
        {
          this.state.changeAlternativeName && (
            <Dialog open={this.state.changeAlternativeName} maxWidth="sm" fullWidth="true" scroll="body">
              <CustomDialogContent>
                <div className="header">
                  <p><span>{this.state.changingName}</span> {tra('the_description_to_be_changed')}</p>
                </div>
                <div className="content">
                  {/* <p>Onaylıyor musunuz?</p> */}
                  <Input
                    type="text"
                    label={tra('Change_Description')}
                    variant="standard"
                    fullWidth
                    value={this.state.alternativeName}
                    onChange={(e) => this.setState({ alternativeName: e.target.value })}
                    autoFocus
                  />
                </div>
                <div className="buttonWrapper">
                  <CustomButton lined onClick={() => this.setState({ changeAlternativeName: false })}>
                    {tra('Cancel')}
                  </CustomButton>
                  {/* <CustomButton onClick={() => { this.props.editProduct(this.state.productId, { alternative_name: this.state.alternativeName }); this.setState({ changeAlternativeName: false }); }}> */}
                  <CustomButton onClick={() => this.setState({ openInfo: true })}>
                    {tra('Save')}
                  </CustomButton>
                </div>
              </CustomDialogContent>
            </Dialog>
          )
        }
        {
          openNewProduct && (
            <NewProduct
              open={openNewProduct}
              onClose={() => { this.setState({ openNewProduct: false }); this.props.setSapProduct(false); }}
              getSapProduct={(id) => this.props.getSapProduct(id)}
              createProduct={(data) => this.props.createProduct(data)}
              sapProduct={this.props.sapProduct}
              setToast={(a, b, c) => this.props.setToast(true, b, c)}
            />
          )
        }
        {
          this.state.infoDelete && (
            <Info
              open={this.state.infoDelete}
              type="Question"
              title={tra('Confirmation')}
              text={tra('Blockage_will_be_delete_Do_you_confirm')}
              cancelButton={(
                <CustomButton grey onClick={() => this.setState({ infoDelete: false, blockageCodeId: null })}>
                  {tra('Cancel')}
                </CustomButton>
              )}
              confirmButton={(
                <CustomButton blue onClick={() => { this.setState({ infoDelete: false }); this.props.deleteBlockage(this.state.blockageCodeId); }}>
                  {tra('Accept')}
                </CustomButton>
              )}
            />
          )
        }
      </PageWrapper>
    );
  }
}

Products.propTypes = {
  getProducts: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  blockageProducts: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  editProduct: PropTypes.func.isRequired,
  sapProduct: PropTypes.object.isRequired,
  getSapProduct: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  setSapProduct: PropTypes.func.isRequired,
  workAreas: PropTypes.array.isRequired,
  creditControl: PropTypes.array.isRequired,
  getBlockages: PropTypes.func.isRequired,
  blockagesList: PropTypes.array.isRequired,
  getCreditControl: PropTypes.func.isRequired,
  updateSapProducts: PropTypes.func.isRequired,
  deleteBlockage: PropTypes.func.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  products: selectProducts(),
  customers: selectCustomers(),
  sapProduct: selectSapProduct(),
  workAreas: selectWorkAreas(),
  creditControl: selectCreditControl(),
  blockagesList: selectBlockagesList(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getProducts,
      getCustomers,
      blockageProducts,
      setToast,
      editProduct,
      getSapProduct,
      createProduct,
      setSapProduct,
      getBlockages,
      getCreditControl,
      updateSapProducts,
      deleteBlockage,
  }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Products);
