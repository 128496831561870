/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Zoom,
  Button,
  Tooltip,
  Checkbox,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  Remove,
  // Search,
  EditOutlined,
  CloseOutlined,
  SaveAltOutlined,
  ViewColumnOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
  KeyboardArrowUpOutlined,
  AddCircleOutlineOutlined,
  KeyboardArrowDownOutlined,
  // Filter2,
} from '@material-ui/icons';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Colors, tablePagination } from '../assets/statics';
import CustomTable, {
  PagiItem,
  SettingMenu,
  CustomTableTh,
  CustomTableTr,
  CustomTableTd,
  TablePagination,
  SettingMenuItem,
  CustomTableHeader,
  CustomTableFilter,
  PaginationWrapper,
  TableMainwrapper,
} from '../assets/styledTable';
import { NoDataIcon } from '../assets/icons';
import { tra } from '../commons/utils';

class Table extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      anchorEl: null,
      allData: data ? [...data] : [],
      data: data || [],
      activePage: 1,
      pagiPerPage: this.getLocalePerPage(),
      // pagiPerPage: !this.props.detailedTable ? !!(options.pagination && localStorage.getItem('paginationUser') ? (tablePagination?.some((p) => p.value === parseInt(localStorage.getItem('paginationUser'), 10)) ? parseInt(localStorage.getItem('paginationUser'), 10) : 5) : options.pagination.perPage ? options.pagination.perPage : 20) : 5,
      searchInputVisible: true,
      sort: { column: '', direction: '' },
      filteredData: [],
      selectedRows: [],
      lang: {
        Starting: tra('Starting'),
        End: tra('End'),
        Piece: tra('Piece'),
        item: tra('item'),
        document: tra('document'),
        found: tra('found'),
        No_Data: tra('No_Data'),
        Save_Row: tra('Save_Row'),
        Cancel: tra('Cancel'),
        Search_Data: tra('Search_Data'),
        Edit_Row: tra('Edit_Row'),
        Prev: tra('Prev'),
        Next: tra('Next'),
        Column_Visiblity: tra('Column_Visiblity'),
      },
      // lang2: {
      //   Starting: 'Başlangıç',
      //   End: 'Bitiş',
      //   Piece: 'Adet',
      //   item: 'Kalem',
      //   document: 'döküman',
      //   found: 'bulundu.',
      //   No_Data: 'Veri Yok...',
      //   Save_Row: 'Satırı Kaydet',
      //   Cancel: 'Vazgeç',
      //   Search_Data: 'Veri Ara',
      //   Edit_Row: 'Satırı Düzenle',
      //   Prev: 'Önceki',
      //   Next: 'Sonraki',
      //   Column_Visiblity: 'Sütun Görünürlüğü',
      // },
    };
    let columnList = JSON.parse(localStorage.getItem(`columnList${this.props.id}`));
    if (columnList === null) {
      columnList = {};
    }
    this.setState({ ...columnList });
  }

  componentDidMount() {
    let columnList = JSON.parse(localStorage.getItem(`columnList${this.props.id}`));
    if (columnList === null) {
      columnList = {};
    }
    this.setState({ ...columnList, tableId: this.props.id });
  }

  componentWillReceiveProps(nextProps, prevState) {
    const { data } = this.state;
    if (prevState.tableId !== nextProps.id) {
      this.componentDidMount();
    }
    if (JSON.stringify(data) !== JSON.stringify(nextProps.data) && nextProps.data && nextProps.data.length >= 0) {
      this.setState({
        data: nextProps.data ? nextProps.data : [],
        allData: nextProps.data ? [...nextProps.data] : [],
        // activePage: 1,
      });
      if (data.length !== nextProps.data.length) {
        this.setState({ activePage: 1 });
      }
    }
    if (nextProps.options.filter) {
      this.setFilter(nextProps.options.filter.filters);
    }
    if (nextProps.options.selectableRows !== 'none' && nextProps.options.selectableRows && JSON.stringify(nextProps.options.currentSelectedRow !== JSON.stringify(this.state.selectedRows))) {
      this.setState({
        selectedRows: nextProps.options.currentSelectedRow || [],
      });
    }
  }

  getLocalePerPage = () => {
    const { options } = this.props;
    const localeValue = parseInt(localStorage.getItem('paginationUser'), 10);
    //  ? !!(options.pagination && localStorage.getItem('paginationUser') ?
    //  (tablePagination?.some((p) => p.value === parseInt(localStorage.getItem('paginationUser'), 10)) ? parseInt(localStorage.getItem('paginationUser'), 10) : 5) : options.pagination.perPage ? options.pagination.perPage : 20) : 5,
    if (!this.props.detailedTable) {
      if (options.pagination && localStorage.getItem('paginationUser')) {
        if (tablePagination?.some((p) => p.value === localeValue)) {
          return localeValue;
        }
        return 5;
      }
      return options.pagination.perPage ? options.pagination.perPage : 20;
    }
    return 5;
  };

  saveFunc = (index) => {
    this.props.options.onSave(index);
  }

  cancel = (index) => {
    this.setState({ [`edit${index}`]: false });
  }

  onDelete = (index) => {
    this.props.options.onDelete(index);
  }

  openMenu = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  }

  changeColumnVisiblity = (column) => {
    let columnList = JSON.parse(localStorage.getItem(`columnList${this.props.id}`));
    if (columnList === null) {
      columnList = {};
    }
    columnList[`columnVisibility${column}`] = !columnList[`columnVisibility${column}`];
    localStorage.setItem(`columnList${this.props.id}`, JSON.stringify(columnList));
    this.setState((prevState) => ({ [`columnVisibility${column}`]: !prevState[`columnVisibility${column}`] }));
  }

  setFilter = (filter) => {
    const { allData } = this.state;
    const oldfilter = this.state.filteredData;
    if (filter && filter.length) {
      const uniqColumnList = [...new Set(filter.map((item) => item.column))];
      if (uniqColumnList && uniqColumnList.length) {
        let newData = [];
        for (let i = 0; i < uniqColumnList.length; i++) {
          let filteredData = [];
          const valueList = filter.filter((f) => f.column === uniqColumnList[i]);
          if (uniqColumnList[i].split('.').length === 1) {
            if (newData.length === 0) {
              for (let j = 0; j < valueList.length; j++) {
                if (valueList[j].bt) {
                  if (valueList[j].bt.begin && !valueList[j].bt.end) {
                    filteredData = filteredData.concat(allData.filter((item) => new Date(item[uniqColumnList[i]]) >= valueList[j].bt.begin));
                  } else if (!valueList[j].bt.begin && valueList[j].bt.end) {
                    filteredData = filteredData.concat(allData.filter((item) => new Date(item[uniqColumnList[i]]) <= valueList[j].bt.end));
                  } else if (valueList[j].bt.begin && valueList[j].bt.end) {
                    filteredData = filteredData.concat(allData.filter((item) => new Date(item[uniqColumnList[i]]) >= valueList[j].bt.begin && new Date(item[uniqColumnList[i]]) <= valueList[j].bt.end));
                  } else {
                    return false;
                  }
                } else {
                  // const xData = allData.map(
                  //   (item) => (!Array.isArray(item[uniqColumnList[i]])
                  //     ? item[uniqColumnList[i]] === valueList[j].value && (item || null)
                  //     : item[uniqColumnList[i]].map((aItem) => JSON.stringify(aItem) === JSON.stringify(valueList[j].value) && (item || null))),
                  // );
                  // // filteredData = filteredData.concat(allData.filter((item) => item[uniqColumnList[i]] === valueList[j].value));
                  // filteredData = filteredData.concat(...xData);

                  filteredData = filteredData.concat(allData.filter((item) => item[uniqColumnList[i]] === valueList[j].value));
                  const xData = allData.map(
                    (item) => (
                      JSON.stringify(item[uniqColumnList[i]])?.includes?.(JSON.stringify(valueList[j].value)) && (item || null)
                    ),
                      // : item[uniqColumnList[i]].map(aItem => JSON.stringify(aItem) === JSON.stringify(valueList[j].value) && (item || null))),
                  );
                  filteredData = filteredData.concat(...xData)?.filter(Boolean);
                }
              }
            } else {
              for (let j = 0; j < valueList.length; j++) {
                if (valueList[j].bt) {
                  if (valueList[j].bt.begin && !valueList[j].bt.end) {
                    newData.filter((item) => new Date(item[uniqColumnList[i]]) >= valueList[j].bt.begin && filteredData.push(item));
                  } else if (!valueList[j].bt.begin && valueList[j].bt.end) {
                    newData.filter((item) => new Date(item[uniqColumnList[i]]) <= valueList[j].bt.end && filteredData.push(item));
                  } else if (valueList[j].bt.begin && valueList[j].bt.end) {
                    newData.filter((item) => new Date(item[uniqColumnList[i]]) >= valueList[j].bt.begin && new Date(item[uniqColumnList[i]]) <= valueList[j].bt.end && filteredData.push(item));
                  } else {
                    return false;
                  }
                } else {
                  // newData.filter((item) => item[uniqColumnList[i]] === valueList[j].value && filteredData.push(item));
                  newData.map((item) => JSON.stringify(item[uniqColumnList[i]])?.includes?.(JSON.stringify(valueList[j].value)) && filteredData.push(item));
                  // newData.map(
                  //   (item) => (!Array.isArray(item[uniqColumnList[i]])
                  //     ? item[uniqColumnList[i]] === valueList[j].value && filteredData.push(item)
                  //     : item[uniqColumnList[i]].map((aItem) => JSON.stringify(aItem) === JSON.stringify(valueList[j].value) && filteredData.push(item))),
                  // );
                }
              }
            }
          } else if (newData.length === 0) {
            for (let j = 0; j < valueList.length; j++) {
              filteredData = allData.concat(allData.filter((item) => (JSON.stringify(item).indexOf(valueList[j].value) > -1 && item)));
            }
          } else {
            for (let j = 0; j < valueList.length; j++) {
              newData.filter((item) => item[uniqColumnList[i]] === valueList[j].value && filteredData.push(item));
            }
          }
          newData = filteredData;
        }
        const total = [...new Set(newData)];
        this.setState({ data: total, filteredData: filter });
      }
    } else if (oldfilter && oldfilter.length !== 0) {
      this.setState({ filteredData: [] });
    }
  }

  changePage = (pageNum) => {
    this.setState({
      activePage: pageNum,
    });
  }

  changePageButtom = (pageNum, type) => {
    if (type === 'prev' && pageNum !== 1) {
      this.setState({
        activePage: pageNum - 1,
      });
    }
    if (type === 'next' && pageNum < this.getPagiNumber().length) {
      this.setState({
        activePage: pageNum + 1,
      });
    }
  }

  getTableData = () => {
    const { data } = this.state;
    const { options } = this.props;
    if (options && options.pagination && options.pagination.active) {
      const { activePage, pagiPerPage } = this.state;
      const datas = [...data];
      const rData = datas.splice((activePage === 0 ? 1 : activePage - 1) * pagiPerPage, pagiPerPage);
      return rData;
    }
    return data;
  }

  openSearch = () => {
    this.setState((prevState) => ({ searchInputVisible: !prevState.searchInputVisible }));
  }

  searchChange = (val) => {
    this.setState({ searchValue: val });
    const { allData } = this.state;
    if (val.length > 2) {
      const filteredData = allData.filter((item) => item.desc?.toUpperCase()?.includes?.(val?.toUpperCase()) || (item.details && item.details?.toUpperCase()?.includes?.(val?.toUpperCase())));
      this.setState({ data: filteredData, activePage: 1 });
    } else {
      this.setState({ data: allData });
    }
  }

  setSorting = (column) => {
    if (column.sortable) {
      // this.setState((prevState) => ({ sort: { column: column.name, direction: prevState.sort.direction === '' && 'asc' } }));
      const { data, sort } = this.state;
      if (sort.direction === 'desc') {
        const sortedData = data.sort((x, y) => {
          const a = x[column.name] ? (typeof x[column.name] === 'string') ? x[column.name].toUpperCase() : x[column.name] : '';
          const b = y[column.name] ? (typeof y[column.name] === 'string') ? y[column.name].toUpperCase() : y[column.name] : '';
          return a === b ? 0 : a > b ? 1 : -1;
        });
        this.setState({
          sort: { column: column.name, direction: 'asc' },
          data: sortedData,
        });
      } else {
        const sortedData = data.sort((x, y) => {
          const a = x[column.name] ? (typeof x[column.name] === 'string') ? x[column.name].toUpperCase() : x[column.name] : '';
          const b = y[column.name] ? (typeof y[column.name] === 'string') ? y[column.name].toUpperCase() : y[column.name] : '';
          return a === b ? 0 : a < b ? 1 : -1;
        });
        this.setState({
          sort: { column: column.name, direction: 'desc' },
          data: sortedData,
        });
      }
    }
  }

  changeFilter = (state, value, text, type) => {
    const { options } = this.props;
    if (options.filter) {
      if (type === 'resetAll') {
        this.setState({ filteredData: [] });
        options.filter.onChange(state, value, text, 'resetAll');
      }
      options.filter.onChange(state, value, text, type);
    }
  }

  selectRow = (rowId) => {
    let { selectedRows } = this.state;
    const { options } = this.props;
    if (options.selectableRows === 'multi') {
      const isSelectedIndex = selectedRows && selectedRows.findIndex((row) => row === rowId);
      if (isSelectedIndex === -1) {
        selectedRows.push(rowId);
      } else {
        selectedRows.splice(isSelectedIndex, 1);
      }
    } else if (options.selectableRows === 'single') {
      selectedRows = [rowId];
    }
    this.setState({ selectedRows });
    this.props.options.selectedRows(selectedRows);
  }

  selectAll = () => {
    let { selectedRows } = this.state;
    const { options } = this.props;
    const allData = this.getTableData();
    // if (selectedRows.length !== allData.length) {
    //   selectedRows = [];
    //   for (let i = 0; i < allData.length; i++) {
    //     selectedRows.push(allData[i].id);
    //   }
    // } else {
    //   selectedRows = [];
    // }
    if (selectedRows && selectedRows.length > 0) {
      selectedRows = [];
    } else {
      selectedRows = [];
      for (let i = 0; i < allData.length; i++) {
        selectedRows.push(allData[i][options.selectableRowKey]);
      }
    }
    this.setState({ selectedRows: selectedRows || [] });
    this.props.options.selectedRows(selectedRows || []);
  }

  handlePagiPages = (pagiArr, activePage) => {
    const tempArr = [];
    if (pagiArr.length < 6) {
      return pagiArr;
    }
    if (activePage === pagiArr[pagiArr.length - 1]) {
      tempArr.push(pagiArr[pagiArr.length - 4]);
      tempArr.push(pagiArr[pagiArr.length - 3]);
    }
    if (activePage === pagiArr[pagiArr.length - 2]) {
      tempArr.push(pagiArr[pagiArr.length - 4]);
    }
    for (let i = 0; i < pagiArr.length; ++i) {
      if (activePage === pagiArr[i] || activePage - 1 === pagiArr[i] || activePage + 1 === pagiArr[i]) {
        tempArr.push(pagiArr[i]);
      }
    }
    if (activePage === 1) {
      tempArr.push(pagiArr[2]);
      tempArr.push(pagiArr[3]);
    }
    if (activePage === 2) {
      tempArr.push(pagiArr[3]);
    }
    return tempArr;
  }

  getPagiNumber = (isSave) => {
    const { data, pagiPerPage } = this.state;
    const { options } = this.props;
    if (options.pagination.active) {
      const count = data.length / pagiPerPage;
      const pagiArr = Array.from({ length: Math.ceil(count) }, (_, i) => i + 1); // [1,2,3,... n]
      // const pagiArr = [];
      // for (let i = 0; i < count; i++) {
      //   pagiArr.push(i + 1);
      // }
      if (!this.props.detailedTable && isSave) {
        localStorage.setItem('paginationUser', pagiPerPage);
      }
      return pagiArr;
    }
    return ['1'];
  }

  render() {
    const {
      options, columns, rowClick, detailedTable,
    } = this.props;
    const {
      // searchInputVisible,
      searchValue,
      anchorEl,
      data,
      sort,
      activePage,
      filteredData,
      selectedRows,
      pagiPerPage,
      lang,
    } = this.state;
    const pagiNumbers = this.getPagiNumber();
    return (
      <div style={{ position: 'relative' }}>
        {options.filter && options.filter.show && (
          <CustomTableFilter>
            <div className="boxWrapper">
              {/* <CustomButton onClick={options.filter.drawerChange} startIcon={<Filter />}>
                Filtreler
              </CustomButton> */}
              {filteredData.length > 0 && <Link style={{ borderBottom: '1px solid #3E5970' }} to="#" onClick={() => this.changeFilter(null, null, null, 'resetAll')} className="resetAll">Temizle</Link>}
              {filteredData.map((r) => (
                <div className="filterTag">
                  <IconButton onClick={() => this.changeFilter(r.column, r.label, r.header, 'delete')} className="icon">
                    <Remove />
                  </IconButton>
                  <div className="info">
                    <p>{r.header}</p>
                    <p>
                      {
                        r.bt ? (
                          `${r.bt.begin ? r.bt.begin.toLocaleDateString() : lang.Starting} - ${r.bt.end ? r.bt.end.toLocaleDateString() : lang.End}`
                        ) : (
                          typeof r.label === 'object' ? `${r.label?.name} ${r.label?.surname ? r.label?.surname : ''}` : typeof r.label === 'boolean' ? r.label ? '1' : '0' : (r?.label || '-')
                        )
                      }
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {
              // detailedTable && (
              //   <div className="descWrapper">
              //     <p><span>{`(${data.length})`}</span> {lang.Piece} {detailedTable ? lang.item : lang.document} {lang.found}</p>
              //   </div>
              // )
            }
          </CustomTableFilter>
        )}
        {options.header && options.header.visible && (
          <CustomTableHeader search={options.header.search}>
              {options.header.search && (
                <TextField
                  type="text"
                  placeholder={options.header.search && lang.Search_Data}
                  // disabled={!options.header.search || searchInputVisible}
                  value={searchValue}
                  onChange={(e) => this.searchChange(e.target.value)}
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                />
              )}
            {/* <div className="headerIcons">
              {options.header.search && (
                <Tooltip placement="top" TransitionComponent={Zoom} title="Search">
                  <IconButton onClick={() => this.openSearch()}>
                    <Search />
                  </IconButton>
                </Tooltip>
              )}
              {options.header.columnSetting && (
                <>
                  <Tooltip placement="top" TransitionComponent={Zoom} title="Column Visible">
                    <IconButton onClick={(e) => this.openMenu(e)}>
                      <ViewColumnOutlined />
                    </IconButton>
                  </Tooltip>
                  <SettingMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                  >
                    {
                      columns.map((item) => (
                        !item.disableSetting && item.text && (
                          <SettingMenuItem active={!this.state[`columnVisibility${item.name}`]} onClick={() => this.changeColumnVisiblity(item.name)}>
                            {item.text}
                            {
                              this.state[`columnVisibility${item.name}`] ? (
                                <VisibilityOffOutlined style={{ fontSize: 13, marginLeft: 10 }} />
                              ) : (
                                <VisibilityOutlined style={{ fontSize: 13, marginLeft: 10 }} />
                              )
                            }
                          </SettingMenuItem>
                        )
                      ))
                    }
                  </SettingMenu>
                </>
              )}
            </div> */}
          </CustomTableHeader>
        )}
        {
          data.length === 0 && (
            <div style={{
              position: 'absolute',
              left: '50%',
              // bottom: '50%',
              transform: 'translate(-50%, 50%)',
              zIndex: -1,
            }}
            >
              <NoDataIcon />
              <p style={{
                fontSize: 22, fontWeight: 500, textAlign: 'center', padding: 0,
              }}
              >
                {lang.No_Data}
              </p>
            </div>
          )
        }
        <TableMainwrapper>
          <CustomTable isRow={options.isRow} pagipos={options.pagination.position}>
            <thead style={{ borderBottom: `1px solid ${Colors.lightGrey}` }}>
              <tr>
                {
                  options.selectableRows && options.selectableRows !== 'none' && (
                    <CustomTableTh>
                      {
                        options.selectableRows === 'multi' && (
                          <Checkbox
                            checked={selectedRows && selectedRows.length > 0 && selectedRows.length === this.getTableData().length}
                            onChange={() => this.selectAll()}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            indeterminate={selectedRows && selectedRows.length !== this.getTableData().length && selectedRows.length > 0}
                          />
                        )
                      }
                    </CustomTableTh>
                  )
                }
                {
                  columns && columns.map((column) => (
                    column.type !== 'action' && (
                      <CustomTableTh
                        textAlign={column.align && column.align}
                        visible={this.state[`columnVisibility${column.name}`]}
                        onClick={() => this.setSorting(column)}
                        style={{
                          width: column.width,
                          cursor: column.sortable && 'pointer',
                          paddingRight: column.sortable ? 20 : 8,
                        }}
                      >
                        {column.text}
                        {
                          column.sortable && (
                            <div className="sortable">
                              <KeyboardArrowUpOutlined style={{
                                color: sort.column === column.name && column.text !== '' && sort.direction === 'asc' && Colors.mainBlue,
                                display: sort.column === column.name && column.text !== '' && sort.direction === 'desc' && 'none',
                                visibility: sort.column === column.name && column.text !== '' && sort.direction === 'asc' && 'visible',
                              }}
                              />
                              <KeyboardArrowDownOutlined style={{
                                color: sort.column === column.name && column.text !== '' && sort.direction === 'desc' && Colors.mainBlue,
                                display: sort.column === column.name && column.text !== '' && sort.direction === 'asc' && 'none',
                                marginTop: sort.column === column.name && column.text !== '' && sort.direction === 'desc' && '0',
                                visibility: sort.column === column.name && column.text !== '' && sort.direction === 'desc' && 'visible',
                              }}
                              />
                            </div>
                          )
                        }
                        {/* {
                          sort.column === column.name && column.text !== '' && (
                            sort.direction === 'desc' ? (<ArrowDown style={{ fontSize: 15, marginLeft: 5 }} />) : (<ArrowDown style={{ transform: 'rotate(90deg)', fontSize: 15, marginLeft: 5 }} />)
                          )
                        } */}
                      </CustomTableTh>
                    )
                  ))
                }
                {
                  options.header && options.header.columnSetting && (
                    <CustomTableTh style={{ position: 'unset', display: 'block', width: 30 }}>
                      <div className="headerIconsTable">
                        <>
                          <Tooltip placement="top" TransitionComponent={Zoom} title={lang.Column_Visiblity || ''}>
                            <IconButton onClick={(e) => this.openMenu(e)}>
                              <ViewColumnOutlined />
                            </IconButton>
                          </Tooltip>
                          {
                            Boolean(anchorEl) && (
                              <SettingMenu
                                className="mobileMoreMenu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({ anchorEl: null })}
                              >
                                {
                                  columns.map((item) => (
                                    !item.disableSetting && item.text && (
                                      <SettingMenuItem active={!this.state[`columnVisibility${item.name}`]} onClick={() => this.changeColumnVisiblity(item.name)}>
                                        {item.text}
                                        {
                                          this.state[`columnVisibility${item.name}`] ? (
                                            <VisibilityOffOutlined style={{ fontSize: 13, marginLeft: 10 }} />
                                          ) : (
                                            <VisibilityOutlined style={{ fontSize: 13, marginLeft: 10 }} />
                                          )
                                        }
                                      </SettingMenuItem>
                                    )
                                  ))
                                }
                              </SettingMenu>
                            )
                          }
                        </>
                      </div>
                    </CustomTableTh>
                  )
                }
                {data.length !== 0 && options.editable && (<CustomTableTh> </CustomTableTh>)}
              </tr>
              {
                options.secondaryColumns && (
                  <tr>
                    {
                      columns && columns.map((column) => (
                        column.type !== 'action' && (
                          <CustomTableTh
                            textAlign={column.align && column.align}
                            style={{
                              width: column.width,
                              paddingRight: 8,
                              fontSize: 12,
                              height: 20,
                            }}
                          >
                            {column.secondaryColumnsText || ''}
                          </CustomTableTh>
                        )
                      ))
                    }
                    {options?.header?.columnSetting && (<CustomTableTh style={{ height: 'unset' }}> </CustomTableTh>)}
                  </tr>
                )
              }
            </thead>
            <tbody>
              {
                data && data.length > 0 && this.getTableData().map((row, index) => (
                  <CustomTableTr
                    modalType={this.props.modalType}
                    selected={selectedRows && selectedRows.some((id) => (id === row[options.selectableRowKey]))}
                    editing={this.state[`edit${index}`]}
                    // onDoubleClick={() => rowClick(row)
                    style={{ cursor: rowClick && 'pointer' }}
                    isSubTotal={options.subTotalColumn && row[options.subTotalColumn]}
                    actionRow={columns.some((d) => d.type === 'action')}
                  >
                    {
                      options.selectableRows && options.selectableRows !== 'none' && (
                        <CustomTableTd textAlign="center">
                          <Checkbox
                            checked={selectedRows && selectedRows.some((r) => r === row[options.selectableRowKey])}
                            onChange={() => this.selectRow(row[options.selectableRowKey])}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </CustomTableTd>
                      )
                    }
                    {
                      row && columns && columns.map?.((column) => (
                        <Tooltip disableHoverListener={!column.tooltip} disableFocusListener={!column.tooltip} disableTouchListener={!column.tooltip} title={row?.[column?.name] || ''}>
                          <CustomTableTd
                            textAlign={column.align && column.align}
                            visible={this.state[`columnVisibility${column.name}`]}
                            aria-describedby="none"
                            style={{
                              width: column.width,
                              // display: column.width && 'table-cell',
                            }}
                          >
                            {
                              options.editable && column.editable && this.state[`edit${index}`] ? (
                                <TextField
                                  type={column.type}
                                  data={column.selectData}
                                  value={row[column.name]}
                                  title=""
                                  onChange={(e) => options.onChange(e, column.name, index, row)}
                                  style={{ textAlign: 'center' }}
                                />
                              ) : (
                                column.customBody ? (
                                  column.customBody(row[column.name], column.name, index, row)
                                ) : (
                                  column.type === 'date' ? (
                                    new Date(row[column.name]).toLocaleDateString()
                                  ) : (
                                    column.type === 'select' ? (
                                      column.name.split('.').length === 1 ? (
                                        column.selectData.find((item) =>
                                          item.id === row[column.name]) && column.selectData.find((item) => item.id === row[column.name]).desc
                                      )
                                        : column.name.split('.').length === 2 && (
                                          column.selectData.find((x) =>
                                            x.id === row[column.name.split('.')[0]][column.name.split('.')[1] && column.name.split('.')[1]])
                                        && column.selectData.find((x) =>
                                          x.id === row[column.name.split('.')[0]][column.name.split('.')[1] && column.name.split('.')[1]]).desc
                                        )
                                    ) : column.type === 'array' ? (
                                      [...new Set(row[column.arrayPath].map((test) => test[column.name]))]
                                    ) : (
                                      column.width ? (
                                        <Tooltip title={row?.[column?.name] || ''} TransitionComponent={Zoom} placement="top">
                                          <span style={{ width: column.width && column.width, display: 'flex', overflow: 'hidden' }}>
                                            {row[column.name]}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        row[column.name]
                                      )
                                    )
                                  )
                                )
                              )
                            }
                          </CustomTableTd>
                        </Tooltip>
                      ))
                    }
                    {
                      data.length !== 0 && options.editable && (
                        <CustomTableTd textAlign="center">
                          {
                            this.state[`edit${index}`] ? (
                              <>
                                <Tooltip TransitionComponent={Zoom} title={lang.Save_Row}>
                                  <SaveAltOutlined onClick={() => this.saveFunc(index)} style={{ cursor: 'pointer', fontSize: 18 }} />
                                </Tooltip>
                                <Tooltip TransitionComponent={Zoom} title={lang.Cancel}>
                                  <CloseOutlined onClick={() => this.cancel(index)} style={{ cursor: 'pointer', fontSize: 18, marginLeft: 15 }} />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip TransitionComponent={Zoom} title={lang.Edit_Row}>
                                  <EditOutlined onClick={() => this.setState({ [`edit${index}`]: true })} style={{ cursor: 'pointer', fontSize: 18, marginRight: 10 }} />
                                </Tooltip>
                                {/* <Tooltip TransitionComponent={Zoom} title={tra('Delete_Row')}>
                                <DeleteOutlineOutlined onClick={() => this.onDelete(index)} style={{ cursor: 'pointer', fontSize: 18 }} />
                                </Tooltip> */}
                              </>
                            )
                          }
                        </CustomTableTd>
                      )
                    }
                    {/* <CustomTableTd
                      style={{
                        position: 'fixed', zIndex: 1, right: 20, padding: 0, border: 'none',
                      }}
                    >
                      <CustomButton
                        soloSvg
                        style={{
                          minWidth: 24, maxWidth: 24, padding: 0, border: 'none', borderRadius: 0, backgroundColor: Colors.grayExtraLight,
                        }}
                      >
                        <MobileTripleIcon />
                      </CustomButton>
                    </CustomTableTd> */}
                    {/* {
                      !detailedTable && (
                        data.length !== 0 && (
                          <CustomTableTd textAlign="center">
                            <SimpleLink>Detaylar</SimpleLink>
                          </CustomTableTd>
                        )
                      )
                    }
                    {
                      !detailedTable && (
                        data.length !== 0 && (
                          <CustomTableTd textAlign="center">
                            <SimpleLink><History /></SimpleLink>
                          </CustomTableTd>
                        )
                      )
                    } */}
                  </CustomTableTr>
                ))
              }
              {
                data.length === 0 && (
                  <tr style={{ textAlign: 'center', height: '250px' }}>
                    {/* <p style={{
                      position: 'absolute', left: '50%', top: '50%', fontSize: 22, fontWeight: 500,
                    }}
                    >
                      {tra('No_Data')}
                    </p> */}
                  </tr>
                )
              }
              {
                options.addItem && (
                  <CustomTableTr>
                    <CustomTableTd colSpan={data.length !== 0 && options.editable ? columns.length + 1 : columns.length} style={{ padding: 15 }}>
                      <Button small bg="transparent" fcolor={Colors.purple} onClick={() => options.onAddItem()}>Add New Item<AddCircleOutlineOutlined /></Button>
                    </CustomTableTd>
                  </CustomTableTr>
                )
              }
            </tbody>
          </CustomTable>
        </TableMainwrapper>
        {
          options.pagination && options.pagination.active && data.length > 1 && (
            <PaginationWrapper>
              <div className="perPage">
                <FormControl>
                  {/* <InputLabel>Sayfa</InputLabel> */}
                  {
                    detailedTable ? (
                      <Select
                        disabled
                        value={5}
                      >
                        <MenuItem value={5}>5</MenuItem>
                      </Select>
                    ) : (
                      <Select
                        value={pagiPerPage}
                        onChange={(e) => this.setState({ pagiPerPage: e.target.value, activePage: 1 }, () => this.getPagiNumber(true))}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    )
                  }
                </FormControl>
                <p>
                  {
                    ((activePage - 1) * pagiPerPage + 1) === data.length ? (
                      `${data.length} dökümandan ${data.length}. döküman gösteriliyor`
                    ) : ((activePage - 1) * pagiPerPage + 2) === data.length ? (
                      `${data.length} dökümandan ${data.length - 1}. ve ${data.length}. döküman gösteriliyor`
                    ) : (
                      // `${data.length} dökümandan ${(activePage - 1) * pagiPerPage + 1} ile
                      // ${data.length <= (activePage * pagiPerPage) ? data.length : (activePage * pagiPerPage)}
                      // arası gösteriliyor`
                      tra('table_pagination', [data.length, (activePage - 1) * pagiPerPage + 1, data.length <= (activePage * pagiPerPage) ? data.length : (activePage * pagiPerPage)])
                    )
                  }
                </p>
              </div>
              {
                options.pagination && options.pagination.active && pagiPerPage <= data.length && pagiNumbers.length > 1 && (
                  <TablePagination>
                    {
                      activePage !== 1 && (
                        <PagiItem disabled={activePage === 1} onClick={() => this.changePageButtom(activePage, 'prev')} style={{ marginRight: 5 }}>{lang.Prev}</PagiItem>
                      )
                    }
                    {
                      // first index
                      pagiNumbers.length > 5 && (pagiNumbers[0] < activePage - 1) && (
                        <PagiItem onClick={() => this.changePage(1)}>{pagiNumbers[0]}</PagiItem>
                      )
                    }
                    {
                      pagiNumbers.length > 5 && activePage > 3 && (
                        <PagiItem style={{ cursor: 'default' }}>...</PagiItem>
                      )
                    }
                    {
                      this.handlePagiPages(pagiNumbers, activePage).map((i) => (
                        <PagiItem active={activePage === i} onClick={() => this.changePage(i)}>{i}</PagiItem>
                      ))
                    }
                    {
                      pagiNumbers.length > 5 && (activePage !== pagiNumbers[pagiNumbers.length - 1] && activePage !== pagiNumbers[pagiNumbers.length - 2] && activePage !== pagiNumbers[pagiNumbers.length - 3]) && (
                        <PagiItem style={{ cursor: 'default' }}>...</PagiItem>
                      )
                    }
                    {
                    // lastIndex
                      pagiNumbers.length > 5 && (pagiNumbers[pagiNumbers.length - 1] - 1 > activePage) && (
                        <PagiItem onClick={() => this.changePage(pagiNumbers[pagiNumbers.length - 1])}>{pagiNumbers[pagiNumbers.length - 1]}</PagiItem>
                      )
                    }
                    {
                      activePage !== pagiNumbers.length && (
                        <PagiItem disabled={activePage === pagiNumbers.length} onClick={() => this.changePageButtom(activePage, 'next')} style={{ marginLeft: 5 }}>{lang.Next}</PagiItem>
                      )
                    }
                  </TablePagination>
                )
              }
            </PaginationWrapper>
          )
        }
      </div>
    );
  }
}

// columns = { // Columns Props
//   disableSetting: false,
//   name: 'isRemoved',
//   text: 'Durum',
//   editable: false,
//   align: 'center',
//   sortable: true,
//   customBody: (value) => {},
//   tooltip: false,
//   filterable: false,
//   filterLocation: 'locations',
//   arrayPath: 'BusinessOwners',
// };

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
  rowClick: PropTypes.func,
  detailedTable: PropTypes.any,
  modalType: PropTypes.any,
  id: PropTypes.string.isRequired,
};

Table.defaultProps = {
  options: {
    subTotalColumn: false,
    selectableRows: 'none',
    selectableRowKey: '',
    currentSelectedRow: [],
    selectedRows: () => {},
    isRow: false,
    footer: false,
    editable: false,
    addItem: false,
    row: {},
    filter: [],
    onChange: () => { },
    onDelete: () => { },
    onSave: () => { },
    onAddItem: () => { },
    header: {
      visible: false,
      columnSetting: false,
      search: false,
    },
    pagination: {
      active: false,
      perPage: 0,
    },
    secondaryColumns: false,
  },
  rowClick: false,
  detailedTable: false,
  modalType: false,
};

export default Table;
