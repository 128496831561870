import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal } from '@material-ui/core';
import {
  CustomButton,
  CustomFade,
  CustomTextField,
} from '../../assets/styled';
import { tra } from '../../commons/utils';

export default class Kvkk extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { open } = this.props;
    return (
      <Modal
        open={open}
        // onClose={() => this.props.onClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ zIndex: 1302 }}
      >
        <CustomFade in={open} style={{ minWidth: '50%' }}>
          <div>
            <div className="header">
              <h1>{tra('PDPA_Approval')}</h1>
            </div>
            <div className="kvkkContent">
              {/* <h2>{tra('Candidate_Clarification_Text_on_the_Protection_of_Your_Personal_Data')}</h2> */}
              <h2>{tra('Explicit_Consent_Statement_Form')}</h2>
              {/* <p>
                {this.props.kvkkText || tra('Explicit_Consent_Statement_Form')}
              </p> */}
              <CustomTextField kvkk multiline style={{ width: '100%' }} disabled fullWidth value={this.props.kvkkText || ''} />
            </div>
            <div className="kvkkButton">
              <CustomButton onClick={() => this.props.onClose()}>{tra('I_have_read_and_I_approve')}</CustomButton>
            </div>
          </div>
        </CustomFade>
      </Modal>
    );
  }
}

Kvkk.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  kvkkText: PropTypes.string.isRequired,
};

// Kvkk.defaultProps = {
//   kvkkText: '',
// };
