import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Backdrop,
  InputAdornment,
  Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { tr } from 'date-fns/esm/locale';
import {
  add, isTomorrow, setHours, subDays,
} from 'date-fns';
import {
  CustomButton,
  CustomFadeOrder,
  CustomIconButton,
  ErrorLine,
} from '../../assets/styled';
import GenerateModal from './Info';
import {
  ArrowLeft,
  CheckCircle,
  PlusButton,
  Waiting,
  WarningCircle,
} from '../../assets/icons';
import Input from '../Input';
import { Colors } from '../../assets/statics';
import {
  createOrders,
  getOrderProduct,
  getCreditLimit,
  setCreditLimit,
  getDeliveries,
  getShippingCost,
  appConfig,
  setOrderProduct,
  setDeliveries,
  setShippingCost,
} from '../../store/actions';
import {
  selectProductGroups,
  selectOrderProduct,
  selectCreditLimit,
  selectDeliveries,
  selectShippingCost,
  selectAppConfig,
  selectInitCompanyCodes,
  selectServiceConfig,
} from '../../store/selectors';
import { tra } from '../../commons/utils';

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: 0,
      item: [{
        amount: null,
        hour: null,
      }],
      date: null,
      newCredit: null,
      hourSelected: false,
      mta: null,
      productTypeInput: null,
      creditControl: null,
      deliveryMethod: null,
      productInput: null,
      payment: null,
      amount: null,
    };
    props.setCreditLimit(false);
    props.appConfig('checkLimit', props.initCompanyCodes?.find((item) => item?.code === props.appConfigs?.company)?.check_limit); // reduxa alınacak
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const productTypeInput = nextProps.productGroups?.find?.((p) => p.id === nextProps.orderDetail?.product_group_id) || null;
    const creditControl = productTypeInput?.CreditControlAreas?.find?.((p) => p.id === nextProps.orderDetail?.CreditControlArea?.id) || null;
    const mta = nextProps.deliveries?.find?.((d) => d.id === nextProps.orderDetail?.Delivery?.id) || null;
    const deliveryMethod = mta?.DeliveryPoints?.find?.((d) => d.id === nextProps.orderDetail?.delivery_point_id) || null;
    const productInput = nextProps.orderProduct?.find?.((d) => d.id === nextProps.orderDetail?.product_id) || null;
    const payment = productInput?.Payment?.find((d) => d.id === nextProps.orderDetail?.payment_term_id) || null;
    // const amount = prevState.amount === null ? nextProps.orderDetail?.total_amount : prevState.amount;
    if (mta?.id && deliveryMethod?.id && !nextProps.orderProduct && nextProps.serviceConfigs?.getOrderProduct !== false) {
      nextProps.getOrderProduct(creditControl?.id, (nextProps.selectedProduct || productTypeInput?.id), deliveryMethod?.id, mta?.id);
    }
    if (prevState.creditControl && !nextProps.deliveries && nextProps.creditLimit?.limit !== 0 && nextProps.serviceConfigs?.getDeliveries !== false) {
      nextProps.getDeliveries(prevState.creditControl.id);
    }
    if (creditControl && !nextProps.creditLimit?.limit && nextProps.creditLimit?.limit !== 0 && nextProps.serviceConfigs?.getCreditLimit !== false) {
      nextProps.getCreditLimit({ credit_control_id: nextProps.orderDetail?.CreditControlArea?.id });
    }
    if ((prevState.productInput?.id !== productInput?.id) && productInput?.id && !nextProps.shippingCost && nextProps.serviceConfigs?.getShippingCost !== false && deliveryMethod?.id === 2 && productInput?.Payment?.length === 1) {
      const data = {
        material: productInput?.sap_product_no,
        creditControlArea: creditControl?.sap_credit_control_no,
        deliveryType: deliveryMethod?.sap_point_no,
        deliveryPoint: mta?.sap_building_sites_no,
        paymentTerm: productInput?.Payment[0]?.sap_payment_term_no,
      };
      nextProps.getShippingCost(data);
    }
    if (nextProps.appConfigs?.repeatError?.open && nextProps.appConfigs?.repeatError?.message !== prevState.errorMessage) {
      return {
        errorMessage: nextProps.appConfigs?.repeatError?.message,
      };
    }
    // if (nextProps.appConfigs?.repeatError?.open) {
    //   nextProps.appConfig('repeatError', { open: false, message: '' });
    // }
    return {
      productTypeInput,
      creditControl,
      mta,
      deliveryMethod,
      productInput,
      payment,
      // amount,
    };
  }

  componentWillUnmount() {
    this.setState({
      productTypeInput: null,
      creditControl: null,
      mta: null,
      deliveryMethod: null,
      productInput: null,
      payment: null,
      amount: null,
    });
    this.props.setOrderProduct(false);
    this.props.setDeliveries(false);
    this.props.setShippingCost(false);
  }

  itemProcess = (type, i) => {
    const { item } = this.state;
    const items = [...item];
    if (type === 0) { // decrease
      items.splice(i, 1);
    } else if (type === 1 && items?.length < 5) { // increase
      items.push({ amount: null, hour: null });
    } else {
      // this.props.setToast('5 ten fazla farkı giriş yapamazsınız.', 'warning');
    }
    this.setState({ item: items });
  };

  changeData = (val, i, name) => {
    const { item } = this.state;
    item[i][name] = val;
    this.setState({ item });
  }

  checkInputs = () => {
    const { shippingCost } = this.props;
    const {
      date, productInput, mta, payment, amount, deliveryMethod, newCredit,
    } = this.state;
    this.setState({ errorMessage: null, reRender: Math.random() });
    if ((date >= subDays(new Date(), 1)) && productInput && mta && (deliveryMethod?.id === 1 || shippingCost?.shippingCost) && payment && amount > 0 && (this.props.appConfigs?.checkLimit === false ? true : (newCredit === 0 || newCredit > 0))) {
      this.setState({ questionModal: true }); // sipariş sorunsuz
    } else if (deliveryMethod?.id === 2 && !shippingCost?.shippingCost) {
      this.setState({ errorMessage: tra('Shipping_cost_not_found_Contact_your_customer_representative'), isError: true });
    } else if (parseInt(amount, 10) === 0) {
      this.setState({ errorMessage: tra('Enter_a_value_greater_than_zero'), isError: true });
    } else if (newCredit < 0) {
      this.setState({ errorMessage: tra('Insufficient_Limit'), isError: true });
    } else if (!(date >= subDays(new Date(), 1)) && date) {
      this.setState({ errorMessage: tra('Please_enter_valid_date'), isError: true });
    } else if (date && amount && !(newCredit === 0 || newCredit > 0)) {
      this.setState({ errorMessage: tra('You_do_not_have_sufficient_credits'), isError: true });
    } else {
      this.setState({ errorMessage: tra('Please_Fill_in_the_mandatory_fields'), isError: true });
    }
  }

  createOrder = () => {
    const {
      item, date, productInput, mta, payment, amount, creditControl, deliveryMethod,
    } = this.state;
    const data = {
      product_id: productInput?.id,
      delivery_point_id: deliveryMethod.id,
      payment_term_id: payment?.id,
      date: setHours(date, 12),
      total_amount: parseInt(amount, 10),
      credit_control_id: creditControl?.id,
      delivery_id: mta.id,
      Amounts: (deliveryMethod.id === 1 || !(item[0].amount > 0)) ? ([{ amount, hour: '-' }]) : item,
    };
    this.props.createOrders(data);
    this.props.onClose();
    this.props.setCreditLimit({});
    this.setState({ questionModal: false });
  }

  getCreditLimit = () => {
    const {
      creditControl,
    } = this.state;
    const data = {
      credit_control_id: creditControl?.id,
    };
    this.props.getCreditLimit(data);
  }

  chooseHours = () => {
    const { amount, date } = this.state;
    this.setState({ errorMessage: null });
    if (amount > 0 && date) {
      this.setState({ content: 1 });
    } else if (date) {
      this.setState({ errorMessage: tra('Enter_a_value_greater_than_zero') });
    } else {
      this.setState({ errorMessage: tra('Please_enter_valid_date') });
    }
  }

  saveHours = () => {
    const { item, amount } = this.state;
    const total = item.reduce((a, b) => a + parseFloat(b.amount), 0);
    const amountCheck = item.filter((a) => !(a.amount > 0)).length > 0;
    const hourCheck = item.filter((a) => a.hour === null).length > 0;
    this.setState({ reRender: Math.random() });
    if (!amount) {
      this.setState({ errorMessage: tra('You_must_enter_the_amount_first'), isErrorSub: true });
    } else if (amountCheck) {
      this.setState({ errorMessage: tra('Enter_a_value_greater_than_zero'), isErrorSub: true });
    } else if (hourCheck) {
      this.setState({ errorMessage: tra('Choose_a_Time'), isErrorSub: true });
    } else if (total === parseFloat(amount)) {
      this.setState({
        content: 0, hourSelected: true, errorMessage: false, oldItem: JSON.parse(JSON.stringify(item)), isErrorSub: false, // onaylandı
      });
    } else {
      this.setState({ errorMessage: tra('Please_make_sure_that_the_sum_of_their_tonnages_equals', [amount]), isErrorSub: true });
    }
  }

  isBlockageData = (date) => {
    const { productInput } = this.state;
    for (let i = 0; i < productInput?.Blocks?.length; i++) {
      const startDate = new Date(productInput.Blocks[i].block_start_date).setDate(new Date(productInput.Blocks[i].block_start_date).getDate() - 1);
      const endDate = new Date(productInput.Blocks[i].block_end_date);
      const currentDate = new Date(date);
      return currentDate >= startDate && currentDate <= endDate;
    }
    return false;
  }

  // getShippingCostFunc = (pay) => {
  //   const {
  //     productInput, mta, creditControl, deliveryMethod, payment,
  //   } = this.state;

  //   const data = {
  //     material: productInput?.sap_product_no,
  //     creditControlArea: creditControl?.sap_credit_control_no,
  //     deliveryType: deliveryMethod?.sap_point_no,
  //     deliveryPoint: mta?.sap_building_sites_no,
  //     paymentTerm: pay?.sap_payment_term_no || payment?.sap_payment_term_no,
  //   };
  //   this.props.getShippingCost(data);
  // }

  creditCalculate = () => {
    const { creditLimit, shippingCost } = this.props;
    const {
      productInput,
      amount,
      deliveryMethod,
    } = this.state;

    const perPrice = productInput?.Payment?.length === 1 ? productInput?.Payment?.[0]?.price : productInput?.Payment?.[0]?.price; // payment type eklenecek
    // const remainder = creditLimit?.limit ? creditLimit?.limit - parseFloat(amount) * (perPrice + ((shippingCost?.shippingCost && shippingCost?.shippingCost > 0) ? shippingCost?.shippingCost : 0)) : false;
    const remainder = creditLimit?.limit ? Number((creditLimit?.limit).toFixed(2)) - Number((parseFloat(amount) * (perPrice + ((deliveryMethod?.id === 2 && shippingCost?.shippingCost && shippingCost?.shippingCost > 0) ? shippingCost?.shippingCost : 0))).toFixed(2)) : false;

    this.setState({ newCredit: remainder });
  }

  close = () => {
    if (this.state.creditControl) {
      this.setState({ sureModal: true });
    } else {
      this.setClear();
    }
  }

  setClear = () => {
    this.setState({
      content: 0,
      // item: null,
      // date: null,
      // productInput: null,
      // mta: null,
      // payment: null,
      // amount: null,
      // creditControl: null,
      // deliveryMethod: null,
    });
    this.props.setCreditLimit({});
    this.props.onClose();
  }

  calc = () => {
    const r = this.state.item.reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0);
    if (parseInt(this.state.amount, 10) === r) {
      return true;
    }
    return false;
  }

  fixedHours = () => {
    const step = parseInt(this.props.initCompanyCodes?.find((item) => item?.code === this.props.appConfigs?.company)?.time_range, 10) || 0;
    const fixedHours = [];
    const isToday = new Date().getDate() === this.state.date?.getDate();
    const isDayTomorrow = isTomorrow(this.state.date);
    const currentHour = new Date().getHours();
    const availableHour = currentHour + 1;
    for (let i = 0; i < 24; i++) {
      fixedHours.push({ id: `${i < 10 ? 0 : ''}${i}`, name: `${i < 10 ? 0 : ''}${i}:00`, disabled: isToday ? availableHour + (step || 0) > i : (isDayTomorrow && availableHour + step > 24) && availableHour + step - 24 > i });
    }
    return fixedHours;
  };

  newMinDate = () => {
    const step = parseInt(this.props.initCompanyCodes?.find((item) => item?.code === this.props.appConfigs?.company)?.time_range, 10) || 0;
    const currentHour = new Date().getHours();
    const availableHour = currentHour + 1;
    const availableDay = add(new Date(), { days: (availableHour + step) > 24 ? parseInt((availableHour + step) / 24, 10) : 0 });
    const minSelectedHour = Math.min(...this.state.item?.map((h) => parseInt(h.hour, 10)));
    // if ((availableHour + step) >= 24 && !this.state.hourSelected) {
    //   return add(new Date(), { days: 1 });
    // }
    // if ((availableHour + step) >= minSelectedHour) {
    //   if (step >= 24) {
    //     // this.setState({ item: [{ amount: 0, hour: null }], hourSelected: false });
    //     return add(new Date(), { days: (availableHour + step) % 24 });
    //   }
    //   return add(new Date(), { days: (availableHour + step) > 24 ? (availableHour + step) % 24 : 1 });
    // }
    if (this.state.hourSelected && this.state.date < availableDay && (availableHour + step) >= minSelectedHour) {
      this.setState({ hourSelected: false, oldItem: null, item: [{ amount: null, hour: null }] });
    }
    return new Date();
  };

  render() {
    const {
      open, productGroups, selectedProduct, orderProduct, creditLimit,
    } = this.props;
    const {
      content, item, amount, payment, productInput, deliveryMethod, mta, creditControl, errorMessage, productTypeInput, date, oldItem, isError, isErrorSub,
    } = this.state;
    const withValueLimit = ({ value }) => value <= 1000000; // maksimum tonaj
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFadeOrder in={open}>
            <div>
              <div className="header">
                {
                  content === 0 ? (
                    <h1>{tra('Order_Creation')}</h1>
                  ) : (
                    <CustomButton
                      simple
                      onClick={() => {
                        this.setState({
                          content: (content - 1), item: JSON.parse(JSON.stringify(oldItem)) || [{ amount: null, hour: null }], errorMessage: false, isErrorSub: false,
                        });
                      }}
                      startIcon={<ArrowLeft />}
                    >
                      {tra('Back')}
                    </CustomButton>
                  )
                }
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              {
                content === 0 ? (
                  <div className="content">
                    {errorMessage && errorMessage?.length && (
                      <ErrorLine key={this.state.reRender}>
                        {errorMessage}!
                      </ErrorLine>
                    )}
                    {
                      // !(localStorage.getItem('company') === '0064') && (
                      !(this.props.appConfigs?.checkLimit === false) && (
                        <div className={`info ${((this.state.newCredit || this.state.newCredit === 0) ? this.state.newCredit >= 0 : creditLimit?.limit > 0) && 'blue'}`} style={{ display: (!creditLimit?.limit && creditLimit?.limit !== 0) && 'none' }}>
                          {tra('Available_Credit')}:
                          <span style={{ fontSize: !(this.state.newCredit >= 0) && !creditLimit?.limit && '18px' }}>
                            {
                              (this.state.newCredit || this.state.newCredit === 0) ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(this.state.newCredit) : (
                                creditLimit?.limit ? new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(creditLimit?.limit)
                                  : tra('You_do_not_have_sufficient_credits')
                              )
                            }
                          </span>
                        </div>
                      )
                    }
                    <div className="formWrapper">
                      <Input
                        type="autoComplete"
                        label={tra('Product_Group')}
                        variant="standard"
                        data={productGroups || []}
                        fullWidth
                        value={productTypeInput}
                        disabled
                        error={isError && !this.state.productTypeInput}
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Factory')}
                        variant="standard"
                        data={productGroups?.filter((type) => type.id === (selectedProduct || this.state.productTypeInput?.id))[0]?.CreditControlAreas || []}
                        fullWidth
                        value={creditControl}
                        error={isError && !this.state.creditControl}
                        disabled
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Goods_Delivery_Place')}
                        variant="standard"
                        data={this.props.deliveries || []}
                        fullWidth
                        value={mta}
                        error={isError && !this.state.mta}
                        disabled
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Delivery_Method')}
                        variant="standard"
                        fullWidth
                        data={mta?.DeliveryPoints || []}
                        value={deliveryMethod || {}}
                        error={isError && !this.state.deliveryMethod}
                        disabled
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Product')}
                        variant="standard"
                        data={orderProduct || []}
                        fullWidth
                        value={productInput}
                        error={isError && !this.state.productInput}
                        disabled
                      />
                      <Input
                        type="autoComplete"
                        label={tra('Payment_Condition')}
                        variant="standard"
                        data={productInput?.Payment || []}
                        fullWidth
                        value={payment}
                        error={isError && !this.state.payment}
                        disabled
                      />
                      <Input
                        type="number"
                        placeholder={tra('Quanta')}
                        variant="outlined"
                        disabled={!payment || !productInput || !deliveryMethod || !mta || !creditControl}
                        endAdornment={<InputAdornment position="end">{tra('Ton')}</InputAdornment>}
                        decimalScale={1}
                        isAllowed={withValueLimit}
                        value={amount}
                        onChange={(e) => this.setState({
                          amount: e.value, hourSelected: false, item: [{ amount: 0, hour: null }], oldItem: null,
                        }, () => this.creditCalculate())}
                        error={isError && !(parseInt(this.state.amount, 10) > 0)}
                      />
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          label={tra('Date')}
                          value={date}
                          onChange={(e) => this.setState({ date: e })}
                          autoOk
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          // style={{ paddingTop: 10 }}
                          minDate={(deliveryMethod?.id === 2 && this.state.hourSelected) ? this.newMinDate() : new Date()}
                          shouldDisableDate={(e) => this.isBlockageData(e)}
                          disabled={!this.state.productInput?.id}
                          disablePast
                          invalidDateMessage={tra('Please_enter_valid_date')}
                          minDateMessage={tra('Please_enter_valid_date')}
                          error={isError && (!this.state.date || !(date >= subDays(new Date(), 1)))}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                ) : content === 1 && (
                  <>
                    {errorMessage && errorMessage?.length && (
                      <ErrorLine key={this.state.reRender}>
                        {errorMessage}!
                      </ErrorLine>
                    )}
                    <div className="content">
                      <h4>{tra('Select_the_requested_quantity_and_shipping_date_and_time')}</h4>
                      <h4 style={{ color: Colors.error }}>{tra('Total')}: {amount} {tra('Ton')}</h4>
                      {
                        item?.map((k, i) => (
                          <div className="item">
                            {
                              !(item?.length === 1) && (
                                <CustomButton simple style={{ color: Colors.burgundy }} onClick={() => this.itemProcess(0, i)} startIcon={<Close style={{ color: Colors.burgundy }} />}>{tra('Remove')}</CustomButton>
                              )
                            }
                            <div>
                              <Input
                                type="number"
                                placeholder={tra('Quanta')}
                                variant="outlined"
                                endAdornment={<InputAdornment position="end">{tra('Ton')}</InputAdornment>}
                                decimalScale={1}
                                isAllowed={withValueLimit}
                                key={item}
                                value={k?.amount || null}
                                onChange={(e) => this.changeData(e.value, i, 'amount')}
                                error={isErrorSub && (!(k?.amount > 0) || !(this.calc()))}
                              />
                              <Input
                                type="select"
                                placeholder={tra('Time')}
                                variant="outlined"
                                data={this.fixedHours()}
                                endAdornment={<InputAdornment position="end"><Waiting color={Colors.grey} /></InputAdornment>}
                                value={k.hour}
                                onChange={(e) => this.changeData(e.target.value, i, 'hour')}
                                autoOk
                                // inputProps={{ step: 3600 }}
                                error={isErrorSub && !k.hour}
                              />
                            </div>
                          </div>
                        ))
                      }
                      <div className="addItem">
                        <CustomButton
                          pink
                          onClick={() => this.itemProcess(1)}
                          startIcon={<PlusButton color={item?.length === 5 ? Colors.grey : Colors.burgundy} />}
                          style={{ margin: 'auto' }}
                          fullWidth
                          disabled={item?.length === 5}
                        >
                          {tra('Add_new_quantity_shipping_time')}
                        </CustomButton>
                      </div>
                    </div>
                  </>
                )
              }
              {
                content === 0 ? (
                  <div className="buttonWrapper">
                    <CustomButton simple onClick={() => this.close()}>{tra('Cancel')}</CustomButton>
                    {
                      this.state.deliveryMethod?.id === 2 && (
                        <CustomButton
                          blue
                          green={this.state.hourSelected || oldItem?.[0]?.amount > 0}
                          orange={!this.state.hourSelected && !(oldItem?.[0]?.amount > 0)}
                          colorOpacity={0.2}
                          onClick={() => this.chooseHours()}
                          endIcon={(this.state.hourSelected || oldItem?.[0]?.amount > 0) ? <CheckCircle /> : <WarningCircle />}
                        >
                          {this.state.hourSelected ? tra('Time_selected') : tra('Select_Time')}
                        </CustomButton>
                      )
                    }
                    <CustomButton blue onClick={() => this.checkInputs()}>{tra('Complete_Order')}</CustomButton>
                  </div>
                ) : content === 1 && (
                  <div className="buttonWrapper">
                    {/* <CustomButton
                      simple
                      onClick={() => this.setState({
                        content: 0, hourSelected: false, item: [{ amount: null, hour: null }], errorMessage: false,
                      })}
                    >
                      {tra('Cancel')}
                    </CustomButton> */}
                    <CustomButton blue onClick={() => this.saveHours()}>{tra('Save')}</CustomButton>
                  </div>
                )
              }
            </div>
          </CustomFadeOrder>
        </Modal>
        {
          this.state.questionModal && (
            <GenerateModal
              open={this.state.questionModal}
              type="Question"
              title={tra('Order')}
              text={tra('An_order_will_be_created_for_the_product_you_selected_Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.createOrder()}>{tra('Done')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.setClear()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

CreateOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  productGroups: PropTypes.array.isRequired,
  createOrders: PropTypes.func.isRequired,
  appConfig: PropTypes.func.isRequired,
  appConfigs: PropTypes.object.isRequired,
  getOrderProduct: PropTypes.func.isRequired,
  orderProduct: PropTypes.array.isRequired,
  selectedProduct: PropTypes.number,
  getCreditLimit: PropTypes.func.isRequired,
  creditLimit: PropTypes.object.isRequired,
  setCreditLimit: PropTypes.func.isRequired,
  getDeliveries: PropTypes.func.isRequired,
  deliveries: PropTypes.array.isRequired,
  getShippingCost: PropTypes.func.isRequired,
  shippingCost: PropTypes.object.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  setDeliveries: PropTypes.func.isRequired,
  setShippingCost: PropTypes.func.isRequired,
  initCompanyCodes: PropTypes.array.isRequired,
  orderDetail: PropTypes.object.isRequired,
  serviceConfigs: PropTypes.object.isRequired,
};

CreateOrder.defaultProps = {
  selectedProduct: false,
};

const mapStateToProps = createStructuredSelector({
  // deliveryPoints: selectDeliveryPoints(),
  productGroups: selectProductGroups(),
  // products: selectProducts(),
  // buildingSites: selectBuildingSites(),
  // factories: selectFactories(),
  // creditControlAreas: selectCreditControlAreas(),
  orderProduct: selectOrderProduct(),
  creditLimit: selectCreditLimit(),
  deliveries: selectDeliveries(),
  shippingCost: selectShippingCost(),
  appConfigs: selectAppConfig(),
  initCompanyCodes: selectInitCompanyCodes(),
  serviceConfigs: selectServiceConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      createOrders,
      // setToast,
      getOrderProduct,
      getCreditLimit,
      setCreditLimit,
      getDeliveries,
      getShippingCost,
      setOrderProduct,
      setDeliveries,
      setShippingCost,
      appConfig,
      // getConditionalService,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
