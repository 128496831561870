import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  TextField,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { tr, enUS } from 'date-fns/locale';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BallotRounded } from '@material-ui/icons';
import { isValid, set } from 'date-fns';
import { CustomButton, CustomDrawer, FilteredSidebar } from '../assets/styled';
import {
  Colors,
} from '../assets/statics';
import { CrossIcons, Filter } from '../assets/icons';
import { tra } from '../commons/utils';

export default class CustomFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      data: [],
      columns: [],
      deleteFilter: {},
      func1: this.changeFilter.bind(this),
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (JSON.stringify(nextProps.columns) !== JSON.stringify(prevState.columns)) {
  //     return {
  //       columns: nextProps.columns,
  //     };
  //   }
  //   if (JSON.stringify(nextProps.data) !== JSON.stringify(prevState.data)) {
  //     // return {
  //     //   data: nextProps.data,
  //     //   columns: nextProps.columns,
  //     // };
  //     return {
  //       data: nextProps.data,
  //       columns: nextProps.columns,
  //     };
  //   }
  //   if (nextProps.filters && JSON.stringify(nextProps.filters) !== JSON.stringify(prevState.filter)) {
  //     return { filter: nextProps.filters };
  //   }
  //   // state: "ebeln"
  //   // text: null
  //   // type: "delete"
  //   // value: false
  //   if (nextProps.deleteFilter) {
  //     if (JSON.stringify(nextProps.deleteFilter) !== JSON.stringify(prevState.deleteFilter)) {
  //       // return {
  //       //   deleteFilter: nextProps.deleteFilter,
  //       // };
  //       this.changeFilter(nextProps.deleteFilter.state, nextProps.deleteFilter.value, nextProps.deleteFilter.text, nextProps.deleteFilter.type);
  //       return { deleteFilter: nextProps.deleteFilter };
  //     }
  //   }
  //   return null;
  // }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.columns) !== JSON.stringify(this.state.columns)) {
      this.setState({
        columns: nextProps.columns,
      });
    }
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.state.data)) {
      // return {
      //   data: nextProps.data,
      //   columns: nextProps.columns,
      // };
      this.setState({
        data: nextProps.data,
        columns: nextProps.columns,
      });
    }
    if (nextProps.filters && JSON.stringify(nextProps.filters) !== JSON.stringify(this.state.filter)) {
      this.setState({ filter: nextProps.filters });
    }
    // state: "ebeln"
    // text: null
    // type: "delete"
    // value: false
    if (nextProps.deleteFilter) {
      if (JSON.stringify(nextProps.deleteFilter) !== JSON.stringify(this.state.deleteFilter)) {
        this.setState({ deleteFilter: nextProps.deleteFilter });
        // return {
        //   deleteFilter: nextProps.deleteFilter,
        // };
        this.changeFilter(nextProps.deleteFilter.state, nextProps.deleteFilter.value, nextProps.deleteFilter.text, nextProps.deleteFilter.type);
      }
    }
    return null;
  }

  changeFilter = (state, value, text, type, label, header) => {
    const { filter } = this.state;
    if (type === 'resetAll') {
      this.setState({ filter: [] });
      this.props.onChangeFilter([]);
    } else if (state && type) {
      // const stateName = state.replace(/[0-9]/g, '');
      if (type === 'delete') {
        const index = filter.findIndex((item) => item.column === state && item.value === value);
        filter.splice(index, 1);
      }
      const oldValue = filter.find((item) => item.column === state && item.value === text);
      if (oldValue) {
        filter.splice(filter.indexOf(oldValue), 1);
      }
      if (value && type !== 'delete' && type !== 'resetAll') {
        filter.push({
          column: state, value: text, label, header,
        });
      }
      this.setState({
        filter,
        [state]: type === 'select' ? text : value,
      });
      this.props.onChangeFilter(filter);
    }
  }

  changeDateFilter = (value, state, header, isBegin) => {
    const { filter } = this.state;
    const current = filter.find((item) => item.column === state);
    if (current) {
      current.bt[isBegin ? 'begin' : 'end'] = value;
    } else {
      filter.push({
        column: state,
        label: isBegin ? tra('Starting') : tra('End'),
        header,
        value: isBegin ? tra('Starting') : tra('End'),
        bt: {
          [isBegin ? 'begin' : 'end']: value,
        },
      });
    }
    this.setState({ filter });
    this.props.onChangeFilter(filter);
  }

  getArrFilters = (itemName, arrayPath, count, countKey) => {
    const allData = this.filterCurrentData();
    const data = [];
    for (let i = 0; i < allData.length; i++) {
      for (let j = 0; j < allData[i][arrayPath].length; j++) {
        data.push(allData[i][arrayPath][j][itemName]);
      }
    }
    if (count) {
      return data.filter((item) => item === countKey).length;
    }
    return [...new Set(data)];
  }

  getFilteredData = (name) => {
    const { data } = this.state;
    if (data) {
      const allData = [...data];
      const sKey = this.state[`search${name}`];
      if (sKey && sKey.length > 2) {
        const filteredData = allData.filter((d) => d[name]?.toString()?.toLocaleUpperCase()?.includes?.(sKey?.toLocaleUpperCase()));
        return filteredData;
      }
      return [...new Map(allData.map((item) => [item[name], item])).values()].splice(0, 50); // Max 50 kayıt önerisi
      // return [...new Map(allData.map((item) => [item[name], item])).values()];
    }
    return [];
  }

  getCheck = (value, name) => {
    const { filter } = this.state;
    // if (filter) {
    if (filter && (value === false || value === '' || value) && name) {
      const index = filter?.findIndex((f) => f.value === value && f.column === name);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  selectAllSearched = (name, text) => {
    const list = [...new Set(this.getFilteredData(name).map((items) => items[name]))];
    for (let i = 0; i < list?.length; i++) {
      this.changeFilter(name, true, list[i], 'checkbox', list[i], text);
    }
  }

  getArrayData = (name) => {
    const { data } = this.state;
    const arData = [];
    for (let i = 0; i < data?.length; i++) {
      for (let j = 0; j < data[i][name]?.length; j++) {
        if (!arData?.some((d) => d.id === data[i][name][j]?.id)) {
          arData.push(data[i][name][j]);
        }
      }
    }
    return arData || [];
  }

  getObjectData = (name) => {
    const { data } = this.state;
    const data2 = data;
    const arData = [];
    for (let i = 0; i < data2.length; i++) {
      if (!arData.some((d) => d.id === data2[i][name].id)) {
        arData.push(data2[i][name]);
      }
    }
    return arData;
  };

  render() {
    const { data, columns } = this.state;
    const { open } = this.props;
    return (
      <CustomDrawer anchor="left" variant="persistent" open={open}>
        <FilteredSidebar>
          <div className="title">
            <div className="fixed">
              <CustomButton onClick={() => this.props.onClose()} startIcon={<Filter color={Colors.blueBlack} />}><div><p style={{ color: Colors.blueBlack2 }}>{tra('Close')}</p><CrossIcons /></div></CustomButton>
            </div>
          </div>
          <div className="content">
            {
              columns.map((item) => (
                item.filterable && (
                  item.type === 'date' ? (
                    <Accordion>
                      <AccordionSummary expandIcon={<CrossIcons />}>
                        <p>{item.text}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('language') === 'T' ? tr : enUS}>
                          <KeyboardDatePicker
                            label={tra('Starting')}
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="dd/MM/yyyy"
                            value={this.state.filter.find((f) => f.column === item.name) ? (this.state.filter.find((f) => f.column === item.name).bt.begin || null) : null}
                            // onChange={(e) => this.changeDateFilter(e, item.name, item.text, true)}
                            onChange={(e) => {
                              if (isValid(e)) {
                                this.changeDateFilter(
                                  set(e, {
                                    hours: 0, minutes: 0, seconds: 0, milliseconds: 0,
                                  }),
                                  item.name,
                                  item.text,
                                  true,
                                );
                              }
                            }}
                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            InputLabelProps={{ shrink: true }}
                            autoOk
                          />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('language') === 'T' ? tr : enUS}>
                          <KeyboardDatePicker
                            label={tra('End')}
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="dd/MM/yyyy"
                            value={this.state.filter.find((f) => f.column === item.name) ? (this.state.filter.find((f) => f.column === item.name).bt.end || null) : null}
                            // onChange={(e) => this.changeDateFilter(e, item.name, item.text)}
                            onChange={(e) => {
                              if (isValid(e)) {
                                this.changeDateFilter(
                                  set(e, {
                                    hours: 23, minutes: 59, seconds: 59, milliseconds: 999,
                                  }),
                                  item.name,
                                  item.text,
                                );
                              }
                            }}
                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            InputLabelProps={{ shrink: true }}
                            autoOk
                            minDate={this.state.filter.find((f) => f.column === item.name) ? (this.state.filter.find((f) => f.column === item.name).bt.begin || null) : null}
                            style={{ marginTop: '10px' }}
                          />
                        </MuiPickersUtilsProvider>
                      </AccordionDetails>
                    </Accordion>
                  ) : item.type === 'array' ? (
                    <Accordion>
                      <AccordionSummary expandIcon={<CrossIcons />}>
                        <p>{item.text}</p>
                      </AccordionSummary>
                      <AccordionDetails style={{ overflow: 'auto' }}>
                        {
                          this.getArrayData(item.name).map((aD) => (
                            <>
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    checked={this.getCheck(aD, item.name)}
                                    onChange={(e) => this.changeFilter(item.name, e.target.checked, aD, 'checkbox', aD, item.text)}
                                  />
                                )}
                                label={`${aD.name} ${aD.surname || ''}`}
                              />
                            </>
                          ))
                        }
                      </AccordionDetails>
                    </Accordion>
                  ) : item.type === 'object' ? (
                    // Tipi object olan columnlarda filterText yazılmalıdır örn: user.name ise filterText name olmalıdır.
                    <Accordion>
                      <AccordionSummary expandIcon={<CrossIcons />}>
                        <p>{item.text}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        {
                          this.getObjectData(item.name).map((o) => (
                            // <FormControlLabel
                            //   control={(
                            //     <Checkbox
                            //       checked={this.getCheck(o[item.filterText], item.name)}
                            //       onChange={e => this.changeFilter(item.name, e.target.checked, o[item.filterText], 'checkbox', o[item.filterText], item.text)}
                            //     />
                            //     )}
                            //   label={`${item.filterText ? o[item.filterText] : ''}`}
                            // />
                            o?.[item?.filterText] && (
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    checked={this.getCheck(o[item?.filterText], item?.name)}
                                    onChange={(e) => this.changeFilter(item?.name, e.target.checked, o[item?.filterText], 'checkbox', o[item?.filterText], item?.text)}
                                  />
                                  )}
                                label={`${item?.filterText ? o[item?.filterText] : ''}`}
                              />
                            )
                          ))
                        }
                      </AccordionDetails>
                    </Accordion>
                  ) : item.type === 'bool' ? (
                    // Tipi bool olan columnlarda filterArrayText yazılmalıdır örn: true veya false ise filterArrayText=[ 'olmlu', 'olumsuz' ] gibi olmalıdır.
                    <Accordion>
                      <AccordionSummary expandIcon={<CrossIcons />}>
                        <p>{item?.text}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        {
                          data && (
                            [...new Set(data?.map((items) => items[item.name]))].length > 10 && (
                              <TextField
                                type="text"
                                placeholder={`${item?.text} write`}
                                fullWidth
                                value={[`search${item?.name}`]}
                                onChange={(e) => this.setState({ [`search${item?.name}`]: e.target.value })}
                                InputProps={{
                                  endAdornment: (
                                    [...new Set(this.getFilteredData(item?.name)?.map((items) => items[item?.name]))]?.length > 5 && (
                                      <Tooltip title="Hepsini Seç" TransitionComponent={Zoom} placement="top">
                                        <IconButton style={{ padding: 0 }} onClick={() => this.selectAllSearched(item?.name, item?.text)}><BallotRounded /></IconButton>
                                      </Tooltip>
                                    )
                                  ),
                                }}
                              />
                            )
                          )
                        }
                        <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                          {
                            [...new Set(this.getFilteredData(item.name)?.map((items) => items[item.name]))].length > 0 ? (
                              [...new Set(this.getFilteredData(item.name)?.map((items) => items[item.name]))]?.map((licence) => (
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      checked={this.getCheck(licence, item.name)}
                                      onChange={(e) => this.changeFilter(item.name, e.target.checked, licence, 'checkbox', licence, item?.text)}
                                    />
                                  )}
                                  label={`${item?.filterArrayText ? (licence ? item?.filterArrayText?.[0] : item?.filterArrayText?.[1]) : (licence || '-')} (${data?.filter((fltr) => fltr[item.name] === licence).length})`}
                                />
                              ))
                            ) : (
                              <p style={{ padding: '10px 0', textAlign: 'center' }}>Sonuç Yok</p>
                            )
                          }
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    // Tipi text olan columnlarda filterArrayText yazılmalıdır örn: 1 veya 2 veya 3 ise filterArrayText=[ 'olmlu', 'olumsuz', 'bekliyor' ] gibi olmalıdır.
                    <Accordion>
                      <AccordionSummary expandIcon={<CrossIcons />}>
                        <p>{item.text}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        {
                          data && (
                            [...new Set(data.map((items) => items[item.name]))].length > 10 && (
                              <TextField
                                type="text"
                                placeholder={`${item.text} ${tra('write')}`}
                                fullWidth
                                value={this.state[`search${item.name}`]}
                                onChange={(e) => this.setState({ [`search${item.name}`]: e.target.value })}
                                InputProps={{
                                  endAdornment: (
                                    [...new Set(this.getFilteredData(item.name).map((items) => items[item.name]))].length > 5 && (
                                      <Tooltip title={tra('Select_All')} TransitionComponent={Zoom} placement="top">
                                        <IconButton style={{ padding: 0 }} onClick={() => this.selectAllSearched(item.name, item.text)}><BallotRounded /></IconButton>
                                      </Tooltip>
                                    )
                                  ),
                                }}
                              />
                            )
                          )
                        }
                        <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                          {
                            [...new Set(this.getFilteredData(item.name).map((items) => items[item.name]))].length > 0 ? (
                              [...new Set(this.getFilteredData(item.name).map((items) => items[item.name]))].map((licence) => (
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      checked={this.getCheck(licence, item.name)}
                                      onChange={(e) => this.changeFilter(item.name, e.target.checked, licence, 'checkbox', licence, item.text)}
                                    />
                                  )}
                                  // label={`${licence || licence === 0 ? licence : '-'} (${data.filter((fltr) => fltr[item.name] === licence).length})`}
                                  label={`${item?.filterArrayText ? (licence || licence === 0 ? item?.filterArrayText[parseInt(licence, 10)] : '-') : (licence || '-')} (${data?.filter((fltr) => fltr[item.name] === licence).length})`}
                                />
                              ))
                            ) : (
                              <p style={{ padding: '10px 0', textAlign: 'center' }}>{tra('There_were_no_results')}</p>
                            )
                          }
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                )
              ))
            }
          </div>
        </FilteredSidebar>
      </CustomDrawer>
    );
  }
}

CustomFilter.propTypes = {
  columns: PropTypes.object.isRequired,
  filters: PropTypes.any,
  data: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  deleteFilter: PropTypes.object.isRequired,
};

CustomFilter.defaultProps = {
  filters: [],
};
