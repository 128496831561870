/* eslint-disable import/no-mutable-exports */
// MAIN URL
export const MAIN_URL = process.env.REACT_APP_MAIN_URL;

// ACCOUNT
export const LOGIN = `${MAIN_URL}account/v1/Login`;
export const PASSWORD = `${MAIN_URL}account/v1/Password`;

// ADMIN
export const CUSTOMERS = `${MAIN_URL}admin/v1/Customer`;
export const PRODUCTS = `${MAIN_URL}admin/v1/Product`;
export const USERS = `${MAIN_URL}admin/v1/User`;
export const LOG = `${MAIN_URL}admin/v1/Log`;
export const SETTING = `${MAIN_URL}admin/v1/Setting`;
export const ANNOUNCEMENT = `${MAIN_URL}admin/v1/Announcement`;
export const DASHBOARD_ADMIN = `${MAIN_URL}admin/v1/Dashboard`;
export const REPORT_ADMIN = `${MAIN_URL}admin/v1/Report`;

// PRIVATE
export const ORDERS = `${MAIN_URL}private/v1/Order`;
export const INIT = `${MAIN_URL}private/v1/Init`;
export const INVOICES = `${MAIN_URL}private/v1/Invoice`;
export const PAYMENTS = `${MAIN_URL}private/v1/Payment`;
export const DASHBOARD = `${MAIN_URL}private/v1/Dashboard`;
export const REPORT = `${MAIN_URL}private/v1/Report`;
export const ANNOUNCEMENTPR = `${MAIN_URL}private/v1/Announcement`;
export const EXCEL = `${MAIN_URL}private/v1/Excel`;
