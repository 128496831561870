import * as constants from './constants';

export const setToast = (value, message, variant) => ({
  type: constants.SET_TOAST,
  value,
  message,
  variant,
});

export const appConfig = (key, value) => ({ type: constants.APP_CONFIG, key, value });
export const serviceConfig = (key, value) => ({ type: constants.SERVICE_CONFIG, key, value });
export const doLogin = (email, password) => ({ type: constants.DO_LOGIN, email, password });
export const enterCode = (code) => ({ type: constants.ENTER_CODE, code });
export const setActiveUser = (data) => ({ type: constants.SET_ACTIVE_USER, data });
export const changePassword = (sType, data) => ({ type: constants.CHANGE_PASSWORD, sType, data });

export const getCustomers = (id) => ({ type: constants.GET_CUSTOMERS, id });
export const setCustomers = (data) => ({ type: constants.SET_CUSTOMERS, data });
export const setCustomerDetail = (data) => ({ type: constants.SET_CUSTOMER_DETAIL, data });
export const createCustomer = (data) => ({ type: constants.CREATE_CUSTOMER, data });
export const editCustomer = (id, data) => ({ type: constants.EDIT_CUSTOMER, id, data });
export const getSapCustomer = (id) => ({ type: constants.GET_SAPCUSTOMER, id });
export const setSapCustomer = (data) => ({ type: constants.SET_SAPCUSTOMER, data });

export const getProducts = (id, creditControl) => ({ type: constants.GET_PRODUCTS, id, creditControl });
export const setProducts = (data) => ({ type: constants.SET_PRODUCTS, data });
export const blockageProducts = (data) => ({ type: constants.BLOCKAGE_PRODUCTS, data });
export const editProduct = (id, name) => ({ type: constants.EDIT_PRODUCT, id, name });
export const getSapProduct = (id) => ({ type: constants.GET_SAPPRODUCT, id });
export const setSapProduct = (data) => ({ type: constants.SET_SAPPRODUCT, data });
export const createProduct = (data) => ({ type: constants.CREATE_PRODUCT, data });
export const getBlockages = () => ({ type: constants.GET_BLOCKAGES });
export const setBlockages = (data) => ({ type: constants.SET_BLOCKAGES, data });
export const deleteBlockage = (id) => ({ type: constants.DELETE_BLOCKAGES, id });
export const getCustomerByCreditControl = (data) => ({ type: constants.GET_CUSTOMER_BY_CREDIT, data });
export const setCustomerByCreditControl = (data) => ({ type: constants.SET_CUSTOMER_BY_CREDIT, data });

export const init = () => ({ type: constants.INIT });
export const setInit = (data) => ({ type: constants.SET_INIT, data });

export const getOrders = (id, isRepeat) => ({ type: constants.GET_ORDERS, id, isRepeat });
export const setOrders = (data) => ({ type: constants.SET_ORDERS, data });
export const setOrderDetail = (data) => ({ type: constants.SET_ORDER_DETAIL, data });
export const createOrders = (data) => ({ type: constants.CREATE_ORDERS, data });
export const getOrderProduct = (creditId, groupId, deliveryType, mta) => ({
  type: constants.GET_ORDER_PRODUCT, creditId, groupId, deliveryType, mta,
});
export const setOrderProduct = (data) => ({ type: constants.SET_ORDER_PRODUCT, data });
export const orderTonnageUpdate = (id, data) => ({ type: constants.ORDER_TONNAGE_UPDATE, id, data });
export const cancelOrder = (id) => ({ type: constants.CANCEL_ORDER, id });
export const getDeliveries = (creditId) => ({ type: constants.GET_DEVLIVERIES, creditId });
export const setDeliveries = (data) => ({ type: constants.SET_DEVLIVERIES, data });
export const getTonnageUpdates = (data) => ({ type: constants.GET_TONNAGE_UPDATES, data });
export const setTonnageUpdates = (data) => ({ type: constants.SET_TONNAGE_UPDATES, data });
export const confirmDeclineOrder = (id, data) => ({ type: constants.CONFIRMDECLINE, id, data });
export const getCreditLimit = (data) => ({ type: constants.GET_CREDIT_LIMIT, data });
export const setCreditLimit = (data) => ({ type: constants.SET_CREDIT_LIMIT, data });
export const toggleCreditLimit = (id) => ({ type: constants.TOGGLE_CREDIT_LIMIT, id });
export const editMatchCreditControl = (data) => ({ type: constants.EDIT_MATCH_CREDIT_CONTROL, data });

export const getSubusers = (id) => ({ type: constants.GET_SUBUSERS, id });
export const setSubusers = (data) => ({ type: constants.SET_SUBUSERS, data });
export const createSubuser = (data) => ({ type: constants.CREATE_SUBUSER, data });
export const setSubuserDetail = (data) => ({ type: constants.SET_SUBUSER_DETAIL, data });
export const editSubuser = (id, data, isAll) => ({ type: constants.EDIT_SUBUSER, id, data, isAll });
export const deleteSubuser = (id) => ({ type: constants.DELETE_SUBUSER, id });
export const getSapUser = (name) => ({ type: constants.GET_SAPUSER, name });
export const setSapUser = (name) => ({ type: constants.SET_SAPUSER, name });
export const swapCustomers = (newRepId, oldRepId) => ({ type: constants.SWAP_CUSTOMERS, newRepId, oldRepId });
export const getUserRoleByType = () => ({ type: constants.GET_USER_ROLE_BY_TYPE });
export const setUserRoleByType = (data) => ({ type: constants.SET_USER_ROLE_BY_TYPE, data });

export const getInvoices = (data) => ({ type: constants.GET_INVOICES, data });
export const setInvoices = (data) => ({ type: constants.SET_INVOICES, data });
export const setOpenInvoices = (data) => ({ type: constants.SET_OPEN_INVOICES, data });
export const getInvoiceLink = (data) => ({ type: constants.GET_INVOICELINK, data });
export const setInvoiceLink = (data) => ({ type: constants.SET_INVOICELINK, data });

export const getPayments = (tab, data) => ({ type: constants.GET_PAYMENTS, tab, data });
export const setPayments = (data) => ({ type: constants.SET_PAYMENTS, data });

export const getWarnings = () => ({ type: constants.GET_WARNINGS });
export const setWarnings = (data) => ({ type: constants.SET_WARNINGS, data });
export const getDashboard = () => ({ type: constants.GET_DASHBOARD });
export const setDashboard = (data) => ({ type: constants.SET_DASHBOARD, data });
export const getDashboardChart = () => ({ type: constants.GET_DASHBOARD_CHART });
export const setDashboardChart = (data) => ({ type: constants.SET_DASHBOARD_CHART, data });
export const getDashboardByCreditControl = (id) => ({ type: constants.GET_DASHBOARD_BY_CREDIT_CONTROL, id });
export const setDashboardByCreditControl = (data) => ({ type: constants.SET_DASHBOARD_BY_CREDIT_CONTROL, data });

export const getReports = (startDate, endDate) => ({ type: constants.GET_REPORTS, startDate, endDate });
export const setReports = (data) => ({ type: constants.SET_REPORTS, data });

export const getReportsOrder = (startDate, endDate) => ({ type: constants.GET_ORDER_REPORTS, startDate, endDate });
export const setReportsOrder = (data) => ({ type: constants.SET_ORDER_REPORTS, data });

export const getBuildingsites = (data) => ({ type: constants.GET_BUNILDING_SITES, data });
export const setBuildingsites = (data) => ({ type: constants.SET_BUNILDING_SITES, data });

export const getShippingCost = (data) => ({ type: constants.GET_SHIPPING_COST, data });
export const setShippingCost = (data) => ({ type: constants.SET_SHIPPING_COST, data });

export const getLog = (startDate, endDate) => ({ type: constants.GET_LOG, startDate, endDate });
export const setLog = (data) => ({ type: constants.SET_LOG, data });

export const getNotSapOrder = () => ({ type: constants.GET_NOT_SAP_ORDER });
export const setNotSapOrder = (data) => ({ type: constants.SET_NOT_SAP_ORDER, data });

export const workAreaAlternative = (data) => ({ type: constants.SET_WORKAREA_ALTERNATIVE, data });
export const getAllRoles = () => ({ type: constants.GET_ALL_ROLES });
export const setAllRoles = (data) => ({ type: constants.SET_ALL_ROLES, data });
export const getAllPermissions = () => ({ type: constants.GET_ALL_PERMISSIONS });
export const setAllPermissions = (data) => ({ type: constants.SET_ALL_PERMISSIONS, data });
export const createRole = (data, id) => ({ type: constants.CREATE_ROLE, data, id });
export const getCreditControl = () => ({ type: constants.GET_CREDIT_CONTROL });
export const setCreditControl = (data) => ({ type: constants.SET_CREDIT_CONTROL, data });
export const removeCreditControl = (id) => ({ type: constants.REMOVE_CREDIT_CONTROL, id });
export const getCompanyCodes = () => ({ type: constants.GET_COMPANY_CODES });
export const setCompanyCodes = (data) => ({ type: constants.SET_COMPANY_CODES, data });
export const companySettingUpdate = (data) => ({ type: constants.COMPANY_SETTING_UPDATE, data });
export const getPaymentTerms = () => ({ type: constants.GET_PAYMENT_TERMS });
export const setPaymentTerms = (data) => ({ type: constants.SET_PAYMENT_TERMS, data });
export const updateSapPaymentTerms = () => ({ type: constants.UPDATE_SAP_PAYMNET_TERMS });
export const updateSapCreditControls = () => ({ type: constants.UPDATE_SAP_CREDIT_CONTROLS });
export const updateSapProducts = () => ({ type: constants.UPDATE_SAP_PRODUCTS });
export const getAllUsers = (data) => ({ type: constants.GET_ALL_USERS, data });
export const setAllUsers = (data) => ({ type: constants.SET_ALL_USERS, data });
export const updateSapCustomer = (customerNo) => ({ type: constants.UPDATE_SAP_CUSTOMER, customerNo });

export const getAnnouncement = (data) => ({ type: constants.GET_ANNOUNCEMENT, data });
export const setAnnouncement = (data) => ({ type: constants.SET_ANNOUNCEMENT, data });
export const announce = (data, id) => ({ type: constants.ANNOUNCE, data, id });

export const getExcel = (hash, startDate, endDate, serviceType) => ({
  type: constants.GET_EXCEL, hash, startDate, endDate, serviceType,
});
export const kvkkAprove = (confirm) => ({ type: constants.KVKK_APROVE, confirm });

export const approveSurvey = (data) => ({ type: constants.SURVEY_APROVE, data });

export const getDashboardAdmin = data => ({ type: constants.GET_DASHBOARD_ADMIN, data });
export const setDashboardAdmin = data => ({ type: constants.SET_DASHBOARD_ADMIN, data });

export const getDashboardAdminCharts = data => ({ type: constants.GET_DASHBOARD_ADMIN_CHARTS, data });
export const setDashboardAdminCharts = data => ({ type: constants.SET_DASHBOARD_ADMIN_CHARTS, data });

export const getDashboardAdminProductCharts = data => ({ type: constants.GET_DASHBOARD_ADMIN_PRODUCT_CHARTS, data });
export const setDashboardAdminProductCharts = data => ({ type: constants.SET_DASHBOARD_ADMIN_PRODUCT_CHARTS, data });

/**
 * @param {object} data
 * @param {"order" | "month"} route
 * @param {boolean} isExcel
 */
export const getReportAdmin = (data, route, isExcel) => ({ type: constants.GET_REPORT_ADMIN, data, route, isExcel });
export const setReportAdminOrder = data => ({ type: constants.SET_REPORT_ADMIN_ORDER, data });
export const setReportAdminMonth = data => ({ type: constants.SET_REPORT_ADMIN_MONTH, data });
export const setReportAdminBuildingSites = data => ({ type: constants.SET_REPORT_ADMIN_BUILDING_SITES, data });
