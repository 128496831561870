import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Tooltip } from '@material-ui/core';
import { CustomButton, PageWrapper, TableNameBox } from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import { Edit, PlusButton } from '../assets/icons';
import NewSubuser from '../components/Modals/NewSubuser';
import {
  createSubuser,
  getSubusers,
  editSubuser,
  deleteSubuser,
  setToast,
  getUserRoleByType,
} from '../store/actions';
import {
  selectAllBuildingSites,
  selectSubusers,
  selectSubuserDetail,
  selectUserRoleByType,
} from '../store/selectors';
import { tra } from '../commons/utils';

class SubUserAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNewUser: false,
    };
    props.getSubusers();
    props.getUserRoleByType();
  }

  // create
  //   "name": "string",
  //   "surname": "string",
  //   "email": "string",
  //   "phone": "string",
  //   "sap_user_no": "string", // gerekli değil
  //   "user_type_id": 0 //  2=Müşteri Admini  3=Alt Kullanıcı

  render() {
    const { openNewUser } = this.state;
    const columns = [
      {
        name: 'is_removed',
        text: 'Durum',
        filterable: false,
        align: 'center',
        sortable: true,
        type: 'text',
        customBody: (value) => (
          <Tooltip title={value === true ? tra('Passive') : tra('Aktive')}>
            <div style={{
              width: 10,
              height: 10,
              borderRadius: '100%',
              margin: '0 auto',
              background: value ? Colors.burgundy : Colors.mint,
            }}
            />
          </Tooltip>
        ),
      },
      {
        name: 'Roles',
        text: tra('User_Type'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        customBody: (value) => (
          <div>
            {value?.[0]?.name}
          </div>
        ),
      },
      {
        name: 'name',
        text: tra('User_Name'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'surname',
        text: tra('User_Surname'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'email',
        text: tra('Email'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'phone',
        text: tra('Telephone'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'BuildingSites',
        text: tra('Building_Site'),
        filterable: false,
        align: 'left',
        sortable: false,
        type: 'text',
        customBody: (value, columnName, index, row) => (
          value?.length > 0 ? (
            <Tooltip
              title={(value && value?.length > 1) ? value?.map((item) => (
                <p>{item?.name}</p>
              )) : ''}
            >
              <TableNameBox style={{ maxWidth: '200px' }}>
                <span>
                  {value?.[0]?.name}
                </span>
                {
                  value.length > 1 && (
                    <i>
                      {` +${value?.length - 1}`}
                    </i>
                  )
                }
              </TableNameBox>
            </Tooltip>
          ) : row?.Roles?.[0]?.Permissions?.some((p) => p.id === 25) ? (
            <TableNameBox success>
              <span>{row?.Roles?.[0]?.name}</span>
            </TableNameBox>
          ) : (
            <TableNameBox notExist>
              <span>{tra('Must_be_created')}</span>
            </TableNameBox>
          )
        ),
      },
      // {
      //   name: 'id',
      //   text: tra('Transactions'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     <CustomButton
      //       lined
      //       onClick={() => this.setState({ openNewUser: true, subAuth: true })}
      //     >
      //       {tra('Authorize')}
      //     </CustomButton>
      //   ),
      // },
      {
        name: 'id',
        text: tra('Action'),
        filterable: false,
        align: 'left',
        sortable: false,
        type: 'action',
        disableSetting: false,
        customBody: (value, columnName, index, row) => (
          row.Roles[0].id !== 5 && (
            <CustomButton
              simple
              onClick={() => { this.setState({ subEdit: true, openNewUser: true, editId: value }); this.props.getSubusers(value); }}
              startIcon={<Edit />}
              style={{ color: Colors.blue }}
            >
              {tra('Edit')}
            </CustomButton>
          )
        ),
      },
    ];
    return (
      <PageWrapper>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomButton
              onClick={() => this.setState({ openNewUser: true })}
              startIcon={<PlusButton />}
              style={{ float: 'right' }}
            >
              {tra('Add_New_User')}
            </CustomButton>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={this.props.subusers}
              columns={columns}
              id="InvoiceTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: [],
                  // onChange: (state, value, text, type) => console.log(state, value, text, type),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
        {
          openNewUser && (
            <NewSubuser
              open={openNewUser}
              onClose={() => this.setState({ openNewUser: false, subEdit: false })}
              subEdit={this.state.subEdit}
              allBuildingSites={this.props.allBuildingSites}
              createSubuser={(data1) => this.props.createSubuser(data1)}
              editSubuser={(data2) => this.setState({ openNewUser: false, subEdit: false }, () => this.props.editSubuser(this.state.editId, data2))}
              deleteSubuser={() => { this.props.deleteSubuser(this.state.editId); this.props.getSubusers(this.state.editId); }}
              subuserDetail={this.props.subuserDetail}
              setToast={(msg, type) => this.props.setToast(true, msg, type)}
              userRoleByType={this.props.userRoleByType}
            />
          )
        }
      </PageWrapper>
    );
  }
}

SubUserAdd.propTypes = {
  // getDashboard: PropTypes.func.isRequired,
  // dashboardData: PropTypes.object.isRequired,
  allBuildingSites: PropTypes.array.isRequired,
  getSubusers: PropTypes.func.isRequired,
  createSubuser: PropTypes.func.isRequired,
  subusers: PropTypes.array.isRequired,
  editSubuser: PropTypes.func.isRequired,
  subuserDetail: PropTypes.object.isRequired,
  deleteSubuser: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  getUserRoleByType: PropTypes.func.isRequired,
  userRoleByType: PropTypes.array.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  // dashboardData: selectDashboard(),
  allBuildingSites: selectAllBuildingSites(),
  subusers: selectSubusers(),
  subuserDetail: selectSubuserDetail(),
  userRoleByType: selectUserRoleByType(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // getDashboard,
      getSubusers,
      createSubuser,
      editSubuser,
      deleteSubuser,
      setToast,
      getUserRoleByType,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(SubUserAdd);
