/* eslint-disable no-return-assign */
import React from 'react';
import {
  call, take, all, put, select,
} from 'redux-saga/effects';
import { b64toBlob, request, tra } from '../commons/utils';
import * as url from '../commons/constants';
import * as constants from './constants';
import * as actions from './actions';
import { selectAppConfig } from './selectors';
import { CustomButton } from '../assets/styled';

import { store } from '../index';

function* getProducts(id, creditControl) {
  let newUrl = `${url.PRODUCTS}/getAll`;
  if (id && creditControl) {
    newUrl = `${url.PRODUCTS}/getAll/${creditControl}/${id}`;
  }
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setProducts(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* updateSapData() {
  // Şu an true ya da false dönmesinin bir önemi yok init ve loginde kontrol edilmiyor.
  const data = yield call(request, url.INIT, 'PUT', false, true);
  if (data !== 'invalid') {
    if (data.status) {
      return true;
    }
  }
  return false;
}

function* getDashboard() {
  const result = yield call(request, `${url.DASHBOARD}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboard(result.data));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getDashboardAdmin() {
  const result = yield call(request, `${url.DASHBOARD_ADMIN}/card`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboardAdmin(result.data));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getDashboardAdminCharts() {
  const result = yield call(request, `${url.DASHBOARD_ADMIN}/charts`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboardAdminCharts(result.data));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getDashboardAdminProductCharts() {
  const result = yield call(request, `${url.DASHBOARD_ADMIN}/product`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboardAdminProductCharts(result.data));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getDashboardChart() {
  const result = yield call(request, `${url.DASHBOARD}/chart`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboardChart(result.data));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* init() {
  const result = yield call(request, url.INIT, 'GET', false, true);
  debugger
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setInit(result.data));
      if (result.data?.companyCodes?.length === 1) {
        localStorage.setItem('company', result.data.companyCodes[0]?.code);
        if (!store.getState().appConfig?.company) {
          yield put(actions.appConfig('company', result.data.companyCodes[0]?.code));
          yield call(updateSapData);
          yield call(init);
        }
      }
      if (result.data?.user?.has_kvkk && new Date(result.data?.user?.kvkk_end_date) < new Date()) {
        yield put(actions.appConfig('kvkk', true));
      }
      // if (result.data?.user?.has_survey && !result.data?.user?.survey_status) { // anket modalı
      //   yield put(actions.appConfig('survey', true));
      // }
      const appConfig = yield select(selectAppConfig());
      const godModeCustomerId = localStorage.getItem('godModeCustomerId');
      const userIsAdmin = localStorage.getItem('userIsAdmin');
      yield put(actions.appConfig('userIsAdmin', userIsAdmin === 'true'));
      const currentCustomerId = result.data?.user?.customer_id;
      if (godModeCustomerId && godModeCustomerId !== currentCustomerId?.toString() && !appConfig.godMode) {
        const users = yield call(request, url.CUSTOMERS, 'GET', false, true);
        if (users.status) {
          const customer = users.data.find(u => u.id.toString() === godModeCustomerId);
          yield put(actions.appConfig('godMode', true));
          yield put(actions.appConfig('godModeCustomer', customer));
          yield put(actions.appConfig('userIsAdmin', userIsAdmin));
          yield call(updateSapData);
          yield call(init);
          if (localStorage.getItem('company') && window.location.hash === '/') {
            yield call(getDashboard);
            yield call(getDashboardChart);
          }
        }
      }
    }
  }
}

function* doLogin(email, password) {
  const jsonData = JSON.stringify({ email, password });
  const result = yield call(request, url.LOGIN, 'POST', jsonData, false);
  yield put(actions.appConfig('code', false));
  if (result !== 'invalid') {
    if (result.status) {
      // localStorage.setItem('token', result.data.token);
      // localStorage.setItem('customer', result.data.customer_id);
      // window.location.href = '/';
      yield put(actions.appConfig('code', true));
      yield put(actions.appConfig('userId', result.data?.id));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* enterCode(code) {
  const appConfig = yield select(selectAppConfig());
  const jsonData = JSON.stringify({ verification_code: code, user_id: appConfig.userId });
  const result = yield call(request, `${url.LOGIN}/verification`, 'POST', jsonData, false);
  if (result !== 'invalid') {
    if (result.status) {
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('customer', result.data.customer_id);
      if (result.data.customer_id !== 1) {
        yield call(updateSapData);
      }
      window.location.href = process.env.REACT_APP_HREF_URL;
      yield put(actions.appConfig('code', false));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getCustomers(id) {
  let newUrl = url.CUSTOMERS;
  if (id) {
    newUrl = `${url.CUSTOMERS}/${id}`;
  }
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (id) {
        yield put(actions.setCustomerDetail(result.data));
      } else {
        yield put(actions.setCustomers(result.data));
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* createCustomer(data) {
  const jsonData = JSON.stringify(data);
  const result = yield call(request, url.CUSTOMERS, 'POST', jsonData, true);
  yield put(actions.appConfig('successCustomer', false));
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.appConfig('successCustomer', true));
      yield call(getCustomers);
      yield put(actions.setSapCustomer({}));
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Successful'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: 'Hata',
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* editCustomer(id, data = false) {
  let method = 'DELETE';
  let jsonStr = false;
  if (data) {
    method = 'PUT';
    jsonStr = JSON.stringify(data);
  }
  const result = yield call(request, `${url.CUSTOMERS}/${id}`, method, jsonStr, true);
  yield put(actions.appConfig('successCustomer', false));
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.appConfig('successCustomer', true));
      yield call(getCustomers);
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Customer_Edited'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Failed_to_Edit_Customer'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getSapCustomer(id) {
  const jsonStr = JSON.stringify({ sap_customer_no: id });
  const result = yield call(request, `${url.CUSTOMERS}/getSapCustomer`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSapCustomer(result.data));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Failed_to_Retrieve_Customer_Information'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>Vazgeç</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getSapUser(name) {
  const jsonStr = JSON.stringify({ sap_username: name });
  const result = yield call(request, `${url.USERS}/getSapUser`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSapUser(result.data));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Failed_to_Retrieve_User_Information'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>Vazgeç</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getOrders(id, isRepeat) {
  let newUrl = url.ORDERS;
  if (id) {
    if (isRepeat) {
      store.dispatch(actions.appConfig('repeatError', { open: false, message: '' }));
      newUrl = `${url.ORDERS}/${id}/1`;
    } else {
      newUrl = `${url.ORDERS}/${id}/0`;
    }
  }
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (id) {
        yield put(actions.setOrderDetail(result.data));
      } else {
        yield put(actions.setOrders(result.data));
      }
    } else if (isRepeat) {
        store.dispatch(actions.appConfig('repeatError', { open: true, message: result.message }));
        yield put(actions.setToast(true, result.message, 'error'));
      } else {
        yield put(actions.setToast(true, result.message, 'error'));
      }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* createOrders(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, url.ORDERS, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getOrders);
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Order_Created'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* changePassword(type, data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.PASSWORD}/${type}`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Successful'),
        text: type === 'forget' ? tra('Your_password_has_been_successfully_sent_to_your_email_Please_check_your_mail_to_login') : tra('Your_Password_Has_Been_Changed_Successfully'),
        cancelButton: (<CustomButton grey onClick={() => window.location.href = process.env.REACT_APP_HREF_URL}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('There_was_a_problem_during_the_password_process'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getSubusers(id) {
  let newUrl = url.USERS;
  if (id) {
    newUrl = `${url.USERS}/${id}`;
  }
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (id) {
        yield put(actions.setSubuserDetail(result.data));
      } else {
        yield put(actions.setSubusers(result.data));
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* createSubuser(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, url.USERS, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getSubusers);
      yield call(init);
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('User_Created'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Problem_Occurred_While_Creating_User'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* deleteSubuser(id) {
  const result = yield call(request, `${url.USERS}/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getSubusers);
      yield call(init);
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('User_Status_Changed'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Failed_to_User_Status_Changed'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getInvoices(data) {
  let newUrl = `${url.INVOICES}/open`;
  if (data) {
    newUrl = `${url.INVOICES}/${data.startDate}/${data.endDate}`;
  }
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (data) {
        yield put(actions.setInvoices(result.data));
      } else {
        yield put(actions.setOpenInvoices(result.data));
      }
    } else if (data) {
      yield put(actions.setToast(true, result.message, 'error'));
      yield put(actions.setInvoices([]));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
      yield put(actions.setOpenInvoices([]));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getInvoiceLink(data) {
  const result = yield call(request, `${url.INVOICES}/link/${data}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setInvoiceLink(result.data));
      if (result.data.doc_type === 2) {
        window.open(result.data.data);
      } else if (result.data.doc_type === 1) {
        const file = b64toBlob(result.data.data, 'application/pdf');
        const blobUrl = URL.createObjectURL(file);
        // window.location = blobUrl;
        window.open(blobUrl, '_blank') || window.location.replace(blobUrl);
      }
    } else {
      yield put(actions.setToast(true, tra('There_is_no_invoice'), 'error'));
      yield put(actions.setInvoiceLink(''));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getOrderProduct(creditId, groupId, deliveryType, mta) {
  const newUrl = `${url.ORDERS}/getProducts/${creditId}/${groupId}/${deliveryType}/${mta}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setOrderProduct(result.data));
      yield put(actions.serviceConfig('getOrderProduct', true));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
      yield put(actions.setOrderProduct([]));
      yield put(actions.serviceConfig('getOrderProduct', false));
      yield put(actions.setOrderProduct([]));
    }
  } else {
    yield put(actions.setOrderProduct([]));
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getPayments(tab, data) {
  const newUrl = `${url.PAYMENTS}/${tab}/${data.startDate}/${data.endDate}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setPayments(result.data));
    } else {
      yield put(actions.setPayments([]));
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getReports(startDate, endDate) {
  const newUrl = `${url.REPORT}/delivery/${startDate}/${endDate}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setReports(result.data));
    } else {
      yield put(actions.setReports([]));
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getReportsOrder(startDate, endDate) {
  const newUrl = `${url.REPORT}/order/${startDate}/${endDate}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setReportsOrder(result.data));
    } else {
      yield put(actions.setReportsOrder([]));
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getWarnings() {
  const newUrl = `${url.DASHBOARD}/warnings`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setWarnings(result.data));
    } else {
      // yield put(actions.setToast(true, result.message, 'error'));
      yield put(actions.setWarnings([]));
    }
  }
}

function* editProduct(id, name) {
  const newUrl = `${url.PRODUCTS}/${id}`;
  const jsonStr = JSON.stringify(name);
  const result = yield call(request, newUrl, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getProducts);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getSapProduct(id) {
  const jsonStr = JSON.stringify({ sap_product_no: id });
  const result = yield call(request, `${url.PRODUCTS}/getSapProduct`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setSapProduct(result.data));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Failed_to_Product_Fetch_from_SAP_Service'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>Vazgeç</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* createProduct(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, url.PRODUCTS, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getProducts);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* orderTonnageUpdate(id, data) {
  const newUrl = `${url.ORDERS}/tonnage/${id}`;
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, newUrl, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getOrders);
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* cancelOrder(id) {
  const newUrl = `${url.ORDERS}/cancel/${id}`;
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
      yield call(getOrders);
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getCreditLimit(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.ORDERS}/checkLimit`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCreditLimit(result.data));
      yield put(actions.serviceConfig('getCreditLimit', true));
      // yield call(getProducts);
      // yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setCreditLimit({}));
      yield put(actions.serviceConfig('getCreditLimit', false));
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('An_error_occurred_while_fetching_Credit_Limit'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getDeliveries(creditId) {
  const result = yield call(request, `${url.ORDERS}/getDeliveries/${creditId}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDeliveries(result.data));
      yield put(actions.serviceConfig('getDeliveries', true));
    } else {
      yield put(actions.serviceConfig('getDeliveries', false));
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('An_error_occurred_while_fetching_Credit_Limit'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getBlockages() {
  const result = yield call(request, `${url.PRODUCTS}/blockage/get `, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setBlockages(result.data));
    }
    // else {
    //   yield put(actions.appConfig('infoModal', {
    //     open: true,
    //     type: 'Error',
    //     title: tra('An_error_occurred_while_fetching_Blockages_data'),
    //     text: result.message,
    //     cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
    //   }));
    // }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* blockageProducts(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.PRODUCTS}/blockage`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getProducts);
      yield call(getBlockages);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* deleteBlockage(id) {
  const result = yield call(request, `${url.PRODUCTS}/blockage/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getProducts);
      yield call(getBlockages);
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Block_Removed'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Failed_to_Block_Removing'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getBuildingsites() {
  const result = yield call(request, `${url.INIT}/buildingSites`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setBuildingsites(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getShippingCost(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.ORDERS}/shippingCost`, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setShippingCost(result.data));
      yield put(actions.serviceConfig('getShippingCost', true));
    } else {
      yield put(actions.serviceConfig('getShippingCost', false));
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getLog(startDate, endDate) {
  const jsonStr = JSON.stringify({ start_date: startDate, end_date: endDate });
  // const newUrl = `${url.LOG}/${startDate}/${endDate}`;
  const result = yield call(request, url.LOG, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setLog(result.data));
    } else {
      yield put(actions.setLog([]));
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getNotSapOrder() {
  const result = yield call(request, `${url.ORDERS}/notTransferSapOrderList`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setNotSapOrder(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'warning'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* swapCustomers(newRepId, oldRepId) {
  const newUrl = `${url.USERS}/replace/${newRepId}/${oldRepId}`;
  const result = yield call(request, newUrl, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getSubusers);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getCustomerByCreditControl(data) {
  const jsonStr = JSON.stringify(data);
  const newUrl = `${url.PRODUCTS}/getCustomerByCreditControl`;
  const result = yield call(request, newUrl, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCustomerByCreditControl(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getUserRoleByType() {
  const result = yield call(request, `${url.USERS}/role`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setUserRoleByType(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getAllRoles() {
  const result = yield call(request, `${url.SETTING}/role`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAllRoles(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getAllPermissions() {
  const result = yield call(request, `${url.SETTING}/permission`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAllPermissions(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* createRole(data, id) {
  const jsonStr = JSON.stringify(data);
  let newUrl = `${url.SETTING}/role`;
  let method = 'POST';
  if (id) {
    newUrl = `${url.SETTING}/role/${id}`;
    method = 'PUT';
  }
  const result = yield call(request, newUrl, method, jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.getAllRoles());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getAnnouncement() {
  const result = yield call(request, `${url.ANNOUNCEMENTPR}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAnnouncement(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* announce(data, id) {
  const jsonStr = JSON.stringify(data);
  let newUrl = `${url.ANNOUNCEMENT}`;
  let method = 'POST';
  if (id) {
    newUrl = `${url.ANNOUNCEMENT}/${id}`;
    method = 'PUT';
  }
  const result = yield call(request, newUrl, method, jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.getAnnouncement());
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Announcement_has_been_published'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getTonnageUpdates() {
  const result = yield call(request, `${url.ORDERS}/getTonnageUpdateAndDecline`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setTonnageUpdates(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* confirmDeclineOrder(id, data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.ORDERS}/confirmDecline/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      // yield put(actions.setTonnageUpdates(result.data));
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getCreditControl() {
  const result = yield call(request, `${url.SETTING}/creditControl`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCreditControl(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* removeCreditControl(id) {
  const result = yield call(request, `${url.SETTING}/creditControl/${id}`, 'DELETE', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.getCreditControl());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* workAreaAlternative(data) {
  const jsonStr = JSON.stringify(data);
  const newUrl = `${url.SETTING}/workAreaAlternative`;
  const result = yield call(request, newUrl, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      // yield call(init);
      yield call(getCreditControl);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getCompanyCodes() {
  const result = yield call(request, `${url.SETTING}/companyCodes`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setCompanyCodes(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* toggleCreditLimit(id) {
  const result = yield call(request, `${url.SETTING}/checkLimit/${id}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getCompanyCodes);
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* editMatchCreditControl(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.SETTING}/matchCreditControl`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getCompanyCodes);
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* companySettingUpdate(data) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.SETTING}/companyCodes`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield call(getCompanyCodes);
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('The_Settings_Updated'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getPaymentTerms() {
  const result = yield call(request, `${url.SETTING}/paymentTerms`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setPaymentTerms(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* updateSapPaymentTerms() {
  const result = yield call(request, `${url.SETTING}/updatePaymentTerms`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Updated_from_SAP'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
      yield call(getPaymentTerms);
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* updateSapCreditControls() {
  const result = yield call(request, `${url.SETTING}/creditControl`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Updated_from_SAP'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
      yield call(getCreditControl);
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* updateSapProducts() {
  const result = yield call(request, `${url.SETTING}/updateProducts`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('Updated_from_SAP'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
      yield call(getProducts);
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getDashboardByCreditControl(id) {
  const result = yield call(request, `${url.DASHBOARD}/sapCard/${id}`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setDashboardByCreditControl(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getExcel(hash, startDate, endDate, serviceType) {
  let newUrl = `${url.EXCEL}/${hash}`;
  if (serviceType && startDate && endDate) {
    newUrl = `${url.EXCEL}/${hash}/${startDate}/${endDate}/${serviceType}`;
  } else if (startDate && endDate) {
    newUrl = `${url.EXCEL}/${hash}/${startDate}/${endDate}`;
  }
  const result = yield call(request, newUrl, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      // yield put(actions.setExcel(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* getAllUsers() {
  const result = yield call(request, `${url.SETTING}/users`, 'GET', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setAllUsers(result.data));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* editSubuser(id, data, isAll) {
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, `${url.USERS}/${id}`, 'PUT', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (isAll) {
        yield call(getAllUsers);
      } else {
        yield call(getSubusers, id);
        yield call(getSubusers);
      }
      yield call(init);
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Success',
        title: tra('User_Edited'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    } else {
      yield put(actions.appConfig('infoModal', {
        open: true,
        type: 'Error',
        title: tra('Failed_to_User_Edit'),
        text: result.message,
        cancelButton: (<CustomButton grey onClick={() => store.dispatch(actions.appConfig('infoModal', { open: false }))}>{tra('Done')}</CustomButton>),
      }));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* kvkkAprove(confirm) {
  const result = yield call(request, `${url.INIT}/kvkk/${confirm}`, 'PUT', false, true);
  if (result !== 'invalid') {
    if (result.status) {
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

/**
 * @param {object} data
 * @param {"order" | "month" | "buildingSites"} route
 * @param {true | undefined} isExcel
 */
function* getReportAdmin(data, route, isExcel) {
  let newUrl = `${url.REPORT_ADMIN}/${route}`;
  if (isExcel) {
    newUrl = `${url.REPORT_ADMIN}/${route}/excel`;
  }
  const jsonStr = JSON.stringify(data);
  const result = yield call(request, newUrl, 'POST', jsonStr, true);
  if (result !== 'invalid') {
    if (result.status) {
      if (isExcel) {
        yield put(actions.setToast(true, result.message, 'success'));
      } else if (route === 'order') {
        yield put(actions.appConfig('adminReportExpanded', true));
        yield put(actions.setReportAdminOrder(result.data));
      } else if (route === 'month') {
        yield put(actions.appConfig('adminReportExpanded', true));
        yield put(actions.setReportAdminMonth(result.data));
      } else if (route === 'buildingSites') {
        yield put(actions.setReportAdminBuildingSites(result.data));
      }
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* approveSurvey(data) {
  const jsonStr = JSON.stringify({ answers: data });
  const result = yield call(request, `${url.INIT}/survey`, 'POST', jsonStr, true);
  // const result = { status: true, message: 'saga' };
  if (result !== 'invalid') {
    if (result.status) {
      // yield put(actions.setSurvey(result.data));
      yield put(actions.appConfig('survey', false));
      yield put(actions.setToast(true, result.message, 'success'));
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* updateSapCustomer(customerNo) {
  const jsonStr = JSON.stringify({ sap_customer_no: customerNo });
  const result = yield call(request, `${url.CUSTOMERS}/updateSapCustomer`, 'PUT', jsonStr, true);
  // const result = { status: true, message: 'saga' };
  if (result !== 'invalid') {
    if (result.status) {
      // yield put(actions.setSurvey(result.data));
      yield put(actions.setToast(true, tra('Updated_from_SAP'), 'success'));
      yield put(actions.getCustomers());
    } else {
      yield put(actions.setToast(true, result.message, 'error'));
    }
  } else {
    yield put(actions.setToast(true, tra('Could_not_establish_a_connection_with_the_server'), 'warning'));
  }
}

function* updateSapCustomerWatcher() {
  while (true) {
    const action = yield take(constants.UPDATE_SAP_CUSTOMER);
    yield call(updateSapCustomer, action.customerNo);
  }
}

function* approveSurveyWatcher() {
  while (true) {
    const action = yield take(constants.SURVEY_APROVE);
    yield call(approveSurvey, action.data);
  }
}

function* getDashboardAdminProductChartsWatcher() {
  while (true) {
    yield take(constants.GET_DASHBOARD_ADMIN_PRODUCT_CHARTS);
    yield call(getDashboardAdminProductCharts);
  }
}

function* getDashboardAdminWatcher() {
  while (true) {
    yield take(constants.GET_DASHBOARD_ADMIN);
    yield call(getDashboardAdmin);
  }
}

function* getDashboardAdminChartsWatcher() {
  while (true) {
    yield take(constants.GET_DASHBOARD_ADMIN_CHARTS);
    yield call(getDashboardAdminCharts);
  }
}

function* getReportAdminWatcher() {
  while (true) {
    const action = yield take(constants.GET_REPORT_ADMIN);
    yield call(getReportAdmin, action.data, action.route, action.isExcel);
  }
}

function* kvkkAproveWatcher() {
  while (true) {
    const action = yield take(constants.KVKK_APROVE);
    yield call(kvkkAprove, action.confirm);
  }
}

function* getAllUsersWatcher() {
  while (true) {
    yield take(constants.GET_ALL_USERS);
    yield call(getAllUsers);
  }
}

function* getExcelWatcher() {
  while (true) {
    const action = yield take(constants.GET_EXCEL);
    yield call(getExcel, action.hash, action.startDate, action.endDate, action.serviceType);
  }
}

function* getDashboardByCreditControlWatcher() {
  while (true) {
    const action = yield take(constants.GET_DASHBOARD_BY_CREDIT_CONTROL);
    yield call(getDashboardByCreditControl, action.id);
  }
}

function* updateSapProductsWatcher() {
  while (true) {
    yield take(constants.UPDATE_SAP_PRODUCTS);
    yield call(updateSapProducts);
  }
}

function* updateSapCreditControlsWatcher() {
  while (true) {
    yield take(constants.UPDATE_SAP_CREDIT_CONTROLS);
    yield call(updateSapCreditControls);
  }
}

function* updateSapPaymentTermsWatcher() {
  while (true) {
    yield take(constants.UPDATE_SAP_PAYMNET_TERMS);
    yield call(updateSapPaymentTerms);
  }
}

function* getPaymentTermsWatcher() {
  while (true) {
    yield take(constants.GET_PAYMENT_TERMS);
    yield call(getPaymentTerms);
  }
}

function* companySettingUpdateWatcher() {
  while (true) {
    const action = yield take(constants.COMPANY_SETTING_UPDATE);
    yield call(companySettingUpdate, action.data);
  }
}

function* editMatchCreditControlWatcher() {
  while (true) {
    const action = yield take(constants.EDIT_MATCH_CREDIT_CONTROL);
    yield call(editMatchCreditControl, action.data);
  }
}

function* toggleCreditLimitWatcher() {
  while (true) {
    const action = yield take(constants.TOGGLE_CREDIT_LIMIT);
    yield call(toggleCreditLimit, action.id);
  }
}

function* getCompanyCodesWatcher() {
  while (true) {
    const action = yield take(constants.GET_COMPANY_CODES);
    yield call(getCompanyCodes, action.data);
  }
}

function* removeCreditControlWatcher() {
  while (true) {
    const action = yield take(constants.REMOVE_CREDIT_CONTROL);
    yield call(removeCreditControl, action.id);
  }
}

function* getCreditControlWatcher() {
  while (true) {
    const action = yield take(constants.GET_CREDIT_CONTROL);
    yield call(getCreditControl, action.data);
  }
}

function* confirmDeclineOrderWatcher() {
  while (true) {
    const action = yield take(constants.CONFIRMDECLINE);
    yield call(confirmDeclineOrder, action.id, action.data);
  }
}

function* getTonnageUpdatesWatcher() {
  while (true) {
    const action = yield take(constants.GET_TONNAGE_UPDATES);
    yield call(getTonnageUpdates, action.data);
  }
}

function* announceWatcher() {
  while (true) {
    const action = yield take(constants.ANNOUNCE);
    yield call(announce, action.data, action.id);
  }
}

function* getAnnouncementWatcher() {
  while (true) {
    const action = yield take(constants.GET_ANNOUNCEMENT);
    yield call(getAnnouncement, action.data);
  }
}

function* getDashboardWatcher() {
  while (true) {
    const action = yield take(constants.GET_DASHBOARD);
    yield call(getDashboard, action.data);
  }
}

function* getDashboardChartWatcher() {
  while (true) {
    const action = yield take(constants.GET_DASHBOARD_CHART);
    yield call(getDashboardChart, action.data);
  }
}

function* getInvoiceLinkWatcher() {
  while (true) {
    const action = yield take(constants.GET_INVOICELINK);
    yield call(getInvoiceLink, action.data);
  }
}

function* createRoleWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_ROLE);
    yield call(createRole, action.data, action.id);
  }
}

function* getAllPermissionsWatcher() {
  while (true) {
    const action = yield take(constants.GET_ALL_PERMISSIONS);
    yield call(getAllPermissions, action.data);
  }
}

function* getAllRolesWatcher() {
  while (true) {
    const action = yield take(constants.GET_ALL_ROLES);
    yield call(getAllRoles, action.data);
  }
}

function* getUserRoleByTypeWatcher() {
  while (true) {
    const action = yield take(constants.GET_USER_ROLE_BY_TYPE);
    yield call(getUserRoleByType, action.data);
  }
}

function* getCustomerByCreditControlWatcher() {
  while (true) {
    const action = yield take(constants.GET_CUSTOMER_BY_CREDIT);
    yield call(getCustomerByCreditControl, action.data);
  }
}

function* workAreaAlternativeWatcher() {
  while (true) {
    const action = yield take(constants.SET_WORKAREA_ALTERNATIVE);
    yield call(workAreaAlternative, action.data);
  }
}

function* swapCustomersWatcher() {
  while (true) {
    const action = yield take(constants.SWAP_CUSTOMERS);
    yield call(swapCustomers, action.newRepId, action.oldRepId);
  }
}

function* getNotSapOrderWatcher() {
  while (true) {
    yield take(constants.GET_NOT_SAP_ORDER);
    yield call(getNotSapOrder);
  }
}

function* getLogWatcher() {
  while (true) {
    const action = yield take(constants.GET_LOG);
    yield call(getLog, action.startDate, action.endDate);
  }
}

function* getShippingCostWatcher() {
  while (true) {
    const action = yield take(constants.GET_SHIPPING_COST);
    yield call(getShippingCost, action.data);
  }
}
function* getBuildingsitesWatcher() {
  while (true) {
    yield take(constants.GET_BUNILDING_SITES);
    yield call(getBuildingsites);
  }
}
function* deleteBlockageWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_BLOCKAGES);
    yield call(deleteBlockage, action.id);
  }
}
function* getBlockagesWatcher() {
  while (true) {
    yield take(constants.GET_BLOCKAGES);
    yield call(getBlockages);
  }
}
function* getDeliveriesWatcher() {
  while (true) {
    const action = yield take(constants.GET_DEVLIVERIES);
    yield call(getDeliveries, action.creditId);
  }
}
function* getCreditLimitWatcher() {
  while (true) {
    const action = yield take(constants.GET_CREDIT_LIMIT);
    yield call(getCreditLimit, action.data);
  }
}

function* createProductWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_PRODUCT);
    yield call(createProduct, action.data);
  }
}

function* orderTonnageUpdateWatcher() {
  while (true) {
    const action = yield take(constants.ORDER_TONNAGE_UPDATE);
    yield call(orderTonnageUpdate, action.id, action.data);
  }
}

function* cancelOrderWatcher() {
  while (true) {
    const action = yield take(constants.CANCEL_ORDER);
    yield call(cancelOrder, action.id);
  }
}

function* editProductWatcher() {
  while (true) {
    const action = yield take(constants.EDIT_PRODUCT);
    yield call(editProduct, action.id, action.name);
  }
}

function* getSapProductWatcher() {
  while (true) {
    const action = yield take(constants.GET_SAPPRODUCT);
    yield call(getSapProduct, action.id);
  }
}

function* getReportsWatcher() {
  while (true) {
    const action = yield take(constants.GET_REPORTS);
    yield call(getReports, action.startDate, action.endDate);
  }
}

function* getReportsOrderWatcher() {
  while (true) {
    const action = yield take(constants.GET_ORDER_REPORTS);
    yield call(getReportsOrder, action.startDate, action.endDate);
  }
}

function* getOrderProductWatcher() {
  while (true) {
    const action = yield take(constants.GET_ORDER_PRODUCT);
    yield call(getOrderProduct, action.creditId, action.groupId, action.deliveryType, action.mta);
  }
}
function* changePasswordWatcher() {
  while (true) {
    const action = yield take(constants.CHANGE_PASSWORD);
    yield call(changePassword, action.sType, action.data);
  }
}
function* getOrdersWatcher() {
  while (true) {
    const action = yield take(constants.GET_ORDERS);
    yield call(getOrders, action.id, action.isRepeat);
  }
}
function* blockageProductsWatcher() {
  while (true) {
    const action = yield take(constants.BLOCKAGE_PRODUCTS);
    yield call(blockageProducts, action.data);
  }
}
function* createCustomerWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_CUSTOMER);
    yield call(createCustomer, action.data);
  }
}
function* getSapCustomerWatcher() {
  while (true) {
    const action = yield take(constants.GET_SAPCUSTOMER);
    yield call(getSapCustomer, action.id);
  }
}
function* initWatcher() {
  while (true) {
    const action = yield take(constants.INIT);
    yield call(init, action.isUser);
  }
}
function* getProductsWatcher() {
  while (true) {
    const action = yield take(constants.GET_PRODUCTS);
    yield call(getProducts, action.id, action.creditControl);
  }
}
function* getCustomersWatcher() {
  while (true) {
    const action = yield take(constants.GET_CUSTOMERS);
    yield call(getCustomers, action.id);
  }
}
function* doLoginWatcher() {
  while (true) {
    const action = yield take(constants.DO_LOGIN);
    yield call(doLogin, action.email, action.password);
  }
}
function* enterCodeWatcher() {
  while (true) {
    const action = yield take(constants.ENTER_CODE);
    yield call(enterCode, action.code);
  }
}
function* createOrdersWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_ORDERS);
    yield call(createOrders, action.data);
  }
}
function* getSubusersWatcher() {
  while (true) {
    const action = yield take(constants.GET_SUBUSERS);
    yield call(getSubusers, action.id);
  }
}
function* createSubuserWatcher() {
  while (true) {
    const action = yield take(constants.CREATE_SUBUSER);
    yield call(createSubuser, action.data);
  }
}
function* editSubuserWatcher() {
  while (true) {
    const action = yield take(constants.EDIT_SUBUSER);
    yield call(editSubuser, action.id, action.data, action.isAll);
  }
}
function* deleteSubuserWatcher() {
  while (true) {
    const action = yield take(constants.DELETE_SUBUSER);
    yield call(deleteSubuser, action.id);
  }
}
function* editCustomerWatcher() {
  while (true) {
    const action = yield take(constants.EDIT_CUSTOMER);
    yield call(editCustomer, action.id, action.data);
  }
}
function* getSapUserWatcher() {
  while (true) {
    const action = yield take(constants.GET_SAPUSER);
    yield call(getSapUser, action.name);
  }
}
function* getInvoicesWatcher() {
  while (true) {
    const action = yield take(constants.GET_INVOICES);
    yield call(getInvoices, action.data);
  }
}
function* getPaymentsWatcher() {
  while (true) {
    const action = yield take(constants.GET_PAYMENTS);
    yield call(getPayments, action.tab, action.data);
  }
}
function* getWarningsWatcher() {
  while (true) {
    yield take(constants.GET_WARNINGS);
    yield call(getWarnings);
  }
}
export default function* rootSaga() {
  yield all([
    blockageProductsWatcher(),
    createCustomerWatcher(),
    initWatcher(),
    getSapCustomerWatcher(),
    getProductsWatcher(),
    getCustomersWatcher(),
    doLoginWatcher(),
    enterCodeWatcher(),
    getOrdersWatcher(),
    createOrdersWatcher(),
    changePasswordWatcher(),
    getSubusersWatcher(),
    createSubuserWatcher(),
    editSubuserWatcher(),
    deleteSubuserWatcher(),
    editCustomerWatcher(),
    getSapUserWatcher(),
    getInvoicesWatcher(),
    getPaymentsWatcher(),
    getWarningsWatcher(),
    getOrderProductWatcher(),
    getReportsWatcher(),
    getReportsOrderWatcher(),
    editProductWatcher(),
    getSapProductWatcher(),
    createProductWatcher(),
    orderTonnageUpdateWatcher(),
    cancelOrderWatcher(),
    getCreditLimitWatcher(),
    getDeliveriesWatcher(),
    getBlockagesWatcher(),
    deleteBlockageWatcher(),
    getBuildingsitesWatcher(),
    getShippingCostWatcher(),
    getLogWatcher(),
    getNotSapOrderWatcher(),
    swapCustomersWatcher(),
    workAreaAlternativeWatcher(),
    getCustomerByCreditControlWatcher(),
    getUserRoleByTypeWatcher(),
    getAllRolesWatcher(),
    getAllPermissionsWatcher(),
    createRoleWatcher(),
    getDashboardWatcher(),
    getDashboardChartWatcher(),
    getInvoiceLinkWatcher(),
    getAnnouncementWatcher(),
    announceWatcher(),
    getTonnageUpdatesWatcher(),
    confirmDeclineOrderWatcher(),
    getCreditControlWatcher(),
    removeCreditControlWatcher(),
    getCompanyCodesWatcher(),
    toggleCreditLimitWatcher(),
    editMatchCreditControlWatcher(),
    companySettingUpdateWatcher(),
    getPaymentTermsWatcher(),
    updateSapPaymentTermsWatcher(),
    updateSapCreditControlsWatcher(),
    updateSapProductsWatcher(),
    getDashboardByCreditControlWatcher(),
    getExcelWatcher(),
    getAllUsersWatcher(),
    kvkkAproveWatcher(),
    getDashboardAdminWatcher(),
    getDashboardAdminChartsWatcher(),
    getDashboardAdminProductChartsWatcher(),
    getReportAdminWatcher(),
    approveSurveyWatcher(),
    updateSapCustomerWatcher(),
  ]);
}
