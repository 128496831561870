import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ClickAwayListener, Grid, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { tr } from 'date-fns/locale';
import {
 addDays, format, isValid, setHours,
} from 'date-fns';
import {
  CustomButton,
  CustomTextField,
  PageWrapper,
  SearchBar,
} from '../assets/styled';
import Table from '../components/Table';

import { getLog } from '../store/actions';
import { selectLog } from '../store/selectors';
import { tra } from '../commons/utils';
import CustomFilter from '../components/CustomFilter';
import { Colors } from '../assets/statics';

// const tomorrow = new Date();

// const tomorrow = addDays(new Date(), 1);
// tomorrow.setDate(tomorrow.getDate() + 1);
class Log extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: setHours(new Date(), 12),
      endDate: setHours(addDays(new Date(), 1), 12),
      filter: [],
    };
    props.getLog(
      format(setHours(new Date(), 12), 'yyyy-MM-dd'),
      format(setHours(addDays(new Date(), 1), 12), 'yyyy-MM-dd'),
    );
  }

  getLog = () => {
    const { startDate, endDate } = this.state;
    if (isValid(startDate) && isValid(endDate)) {
      this.props.getLog(format(setHours(this.state.startDate, 12), 'yyyy-MM-dd'), format(setHours(this.state.endDate, 12), 'yyyy-MM-dd'));
    }
  }

  fixDate = (date, onlyTime = false) => {
    // const lang = 'default';
    const lang = 'tr-TR';
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      // timeZone: 'America/Los_Angeles'
    };
    if (onlyTime) {
      delete options.year;
      delete options.month;
      delete options.day;
    }
    return new Intl.DateTimeFormat(lang, options).format(new Date(date));
  }

  render() {
    const { log } = this.props;
    const {
      searchFilter, filterDrawer, filter,
    } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (log?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : log;
    const columns = [
      {
        name: 'type',
        text: tra('Type'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'bool',
        filterArrayText: [tra('Successful'), tra('Error')],
        customBody: (value) => (
          <Tooltip title={value ? tra('Successful') : tra('Error')}>
            <div style={{
              width: 15,
              height: 15,
              borderRadius: '100%',
              background: value ? Colors.mint : Colors.burgundy,
              margin: '0 auto',
            }}
            />
          </Tooltip>
        ),
      },
      {
        name: 'customer_name',
        text: tra('Customer_Name'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'user_name',
        text: tra('User'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'message',
        text: tra('Message'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'key',
        text: tra('Key'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'date',
        text: tra('Date'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'date',
        // customBody: (value) => value && (this.fixDate(value) || '-'),
      },
      {
        name: 'date',
        text: tra('Time'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => value && (this.fixDate(value, true) || '-'),
      },
      // {
      //   name: 'remote_address',
      //   text: tra('Remote_Address'),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      // {
      //   name: 'salesManager',
      //   text: tra('Sales_Representative),
      //   filterable: true,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     value ? (
      //       <TableNameBox>
      //         {/* <img src={row.img} alt="salesManager" /> */}
      //         <span>{value}</span>
      //       </TableNameBox>

      //     ) : (
      //       <TableNameBox notExist={!value}>
      //         {/* <img src={row.img} alt="salesManager" /> */}
      //         <span>{tra('Must_be_created)}</span>
      //       </TableNameBox>
      //     )
      //   ),
      // },
      // {
      //   name: 'Action',
      //   text: tra('Action'),
      //   filterable: false,
      //   align: 'left',
      //   sortable: false,
      //   type: 'action',
      //   disableSetting: false,
      //   customBody: (value, columnName, index, row) => (
      //     <div className="tableButton">
      //       <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
      //         <MobileTripleIcon />
      //       </CustomIconButton>
      //       {
      //         this.state[`anchorMobileTable${row.id}`] && (
      //           <MobileMoreMenu
      //             className="mobileMoreMenu"
      //             anchorEl={this.state[`anchorMobileTable${row.id}`]}
      //             keepMounted
      //             open={Boolean(this.state[`anchorMobileTable${row.id}`])}
      //             onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
      //             // placement="left"
      //             anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      //             transformOrigin={{ horizontal: 'right', vertical: 'center' }}
      //           >
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ openNewCustomer: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <EditMenu />
      //               {tra('Edit')}
      //             </MobileMoreMenuItem>
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ detailModalOpen: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <ManageMenu />
      //               {tra('Manage)}
      //             </MobileMoreMenuItem>
      //             <MobileMoreMenuItem
      //               // active={!this.state[`columnVisibility${tabValue}`]}
      //               onClick={() => { this.setState({ detailModalOpen: true, [`anchorMobileTable${row.id}`]: null }); }}
      //             >
      //               <DeactiveMenu />
      //               {tra('Make_Passive')}
      //             </MobileMoreMenuItem>
      //           </MobileMoreMenu>
      //         )
      //       }
      //     </div>
      //   ),
      // },
    ];
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={columns}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar style={{ paddingBottom: 10 }}>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState((prevState) => ({ filterDrawer: !prevState.filterDrawer }))}>
                  {tra('Filter')}
                </CustomButton>
              </div>
              {/* <div className="rightButtons">
                <CustomButton
                  lightBlue
                  startIcon={<Excel />}
                >
                  {tra('Download_Excel')}
                </CustomButton>
              </div> */}
            </SearchBar>
            <div className="datePickerWrapper">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  label={tra('Starting_Date')}
                  value={this.state.startDate}
                  onChange={(e) => isValid(e) && this.setState({ startDate: e }, () => this.getLog())}
                  autoOk
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDate={this.state.endDate}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  label={tra('End_Date')}
                  value={this.state.endDate}
                  onChange={(e) => isValid(e) && this.setState({ endDate: e }, () => this.getLog())}
                  autoOk
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDate={this.state.startDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              columns={columns || []}
              id="reportsTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

Log.propTypes = {
  getLog: PropTypes.func.isRequired,
  log: PropTypes.array.isRequired,
};

// Log.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  log: selectLog(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getLog,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Log);
