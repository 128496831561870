import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionDetails, AccordionSummary, Grid,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isValid, subMonths } from 'date-fns';
import { tr } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { CustomButton, ServiceFilter as ServiceFilter2 } from '../assets/styled';
import { tra } from '../commons/utils';
import Input from './Input';
import {
  appConfig,
  getCustomers,
  getReportAdmin,
  setToast,
} from '../store/actions';

const ServiceFilter = (props) => {
  const [state, setState] = useState({
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
  });

  // Reducer
  const appConfigs = useSelector(reducer => reducer?.appConfig);
  const customers = useSelector(reducer => reducer?.customers);
  const allProductGroup = useSelector(reducer => reducer?.allProductGroup);
  const reportAdminBuildingSites = useSelector(reducer => reducer?.reportAdminBuildingSites);
  const orderStatus = useSelector(reducer => reducer?.orderStatus);

  // Action
  const dispatch = useDispatch();
  const setAppConfig = useCallback((key, value) => dispatch(appConfig(key, value)), [dispatch]);
  const showToast = useCallback((text, type) => dispatch(setToast(true, text, type)), [dispatch]);
  const getReportAdminService = useCallback((data, route, isExcel) => dispatch(getReportAdmin(data, route, isExcel)), [dispatch]);
  // const getCustomersService = useCallback(() => dispatch(getCustomers()), [dispatch]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);


  const clearServiceFilter = () => {
    setState({
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
    });
  };

  const buildingSiteService = () => {
    const newCustomers = [];
    for (let index = 0; index < state.userList.length; index++) {
      newCustomers.push(state.userList?.[index]?.id);
    }
    if (state.userList?.length && newCustomers?.length) {
      setState({ ...state, buildingSite: [], userListOld: state.userList });
      getReportAdminService({ customers: newCustomers || [] }, 'buildingSites', false);
    }
  };

  const filteredService = (isExcel) => {
    let route = 'order';
    const newCustomers = [];
    const newProduct = [];
    const newBuildingSite = [];
    const newStatus = [];
    for (let index = 0; index < state.userList?.length; index++) {
      newCustomers.push(state.userList?.[index]?.id);
    }
    if (props.tabValue === 0) {
      for (let index = 0; index < state.product?.length; index++) {
        newProduct.push(state.product?.[index]?.id);
      }
      for (let index = 0; index < state.buildingSite?.length; index++) {
        newBuildingSite.push(state.buildingSite?.[index]?.id);
      }
      for (let index = 0; index < state.status?.length; index++) {
        newStatus.push(state.status?.[index]?.id);
      }
    }
    let data = {
      customer: newCustomers,
      building_sites_id: newBuildingSite,
      start_date: new Date(state.startDate.setHours(0, 0, 0, 0)),
      end_date: new Date(state.endDate.setHours(23, 59, 59, 999)),
      statu: newStatus,
      product_group_id: newProduct,
    };
    if (newCustomers.length === 0) {
      delete data.customer;
    }
    if (newBuildingSite.length === 0) {
      delete data.building_sites_id;
    }
    if (newStatus.length === 0) {
      delete data.statu;
    }
    if (newProduct.length === 0) {
      delete data.product_group_id;
    }
    // const dataLength = Object.keys(data).length;
    // for (let index = 0; index < 6; index++) {
    //   if (data[Object.keys(data)?.[index]]?.length === 0) {
    //     delete data?.[Object.keys(data)?.[index]];
    //   }
    // }
    // for (const keys in data) {
    //   if (data[keys]?.length === 0) {
    //     delete data[keys];
    //   }
    // }
    if (props.tabValue === 1) {
      route = 'month';
      data = {};
      data.year = state.endDate.getFullYear();
      // data.customers = newCustomers;
      if (newCustomers?.length > 0) {
        data.customers = newCustomers;
      }
    }
    if (isValid(state.startDate) && isValid(state.endDate)) {
      getReportAdminService(data, route, !!isExcel);
    } else {
      showToast(tra('Date_fields_are_mandatory'), 'warning');
    }
    // this.props.getGeneralReport({ statu: buildingSite });
  };

  useEffect(() => {
    clearServiceFilter();
  }, [props.tabValue]);

  useEffect(() => {
    if (props.downloadExcel) {
      filteredService(true);
    }
  }, [props.downloadExcel]);

  return (
    <ServiceFilter2>
      <Accordion
        expanded={!appConfigs?.adminReportExpanded}
        onChange={() => setAppConfig('adminReportExpanded', !appConfigs?.adminReportExpanded)}
        style={{ backgroundColor: 'unset', boxShadow: 'none' }}
      >
        <AccordionSummary
          // expandIcon={<ArrowDown />}
          aria-controls="filter-content"
          id="filter-header"
          style={{ height: 0, minHeight: 0, visibility: 'hidden' }}
        >
          {/* {tra('Filter')} */}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {
              !(props.tabValue === 1) && (
                <>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Input
                      type="autoComplete"
                      selectAll
                      disableCloseOnSelect
                      variant="standard"
                      label={tra('Status')}
                      value={state.status || []}
                      data={orderStatus || []}
                      multiple
                      clearable
                      placeholder={tra('Please_Choose')}
                      onChange={(e, newValue) => setState({ ...state, status: newValue })}
                      limitTags={3}
                      style={{ marginTop: 'auto' }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Input
                      type="autoComplete"
                      selectAll
                      disableCloseOnSelect
                      variant="standard"
                      label={tra('Product_Group')}
                      value={state.product || []}
                      data={allProductGroup || []}
                      multiple
                      clearable
                      placeholder={tra('Please_Choose')}
                      onChange={(e, newValue) => setState({ ...state, product: newValue })}
                      limitTags={3}
                      style={{ marginTop: 'auto' }}
                      fullWidth
                    />
                  </Grid>
                </>
              )
            }
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Input
                type="autoComplete"
                selectAll
                disableCloseOnSelect
                variant="standard"
                label={tra('Customers')}
                value={state.userList || []}
                data={customers || []}
                multiple
                clearable
                placeholder={tra('Please_Choose')}
                onChange={(e, newValue) => setState({ ...state, userList: newValue })}
                limitTags={3}
                style={{ marginTop: 'auto' }}
                fullWidth
              />
            </Grid>
            {
              !(props.tabValue === 1) && (
                <>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Input
                      type="autoComplete"
                      selectAll
                      disableCloseOnSelect
                      variant="standard"
                      label={tra('Building_Site')}
                      value={state.buildingSite || []}
                      data={reportAdminBuildingSites || []}
                      multiple
                      clearable
                      placeholder={tra('Please_Choose')}
                      onChange={(e, newValue) => setState({ ...state, buildingSite: newValue })}
                      // disableCloseOnSelect
                      limitTags={3}
                      style={{ marginTop: 'auto' }}
                      fullWidth
                      onFocus={() => { if (JSON.stringify(state.userList) !== JSON.stringify(state.userListOld)) buildingSiteService(); }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                      <KeyboardDatePicker
                        // disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        label={tra('Starting_Date')}
                        value={state.startDate}
                        onChange={e => isValid(e) && setState({ ...state, startDate: e })}
                        autoOk
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        // maxDate={state.endDate}
                        maxDate={state.endDate}
                        fullWidth
                        // helperText={tra('Analysis_Date')}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                      <KeyboardDatePicker
                        // disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        label={tra('End_Date')}
                        value={state.endDate}
                        onChange={e => isValid(e) && setState({ ...state, endDate: e })}
                        autoOk
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDate={state.startDate}
                        fullWidth
                        // helperText={tra('Analysis_Date')}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              )
            }
            {
              props.tabValue === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                    <KeyboardDatePicker
                      // disableToolbar
                      variant="inline"
                      format="yyyy"
                      label={tra('Select_Year')}
                      value={state.endDate}
                      onChange={e => isValid(e) && setState({ ...state, endDate: e })}
                      autoOk
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      maxDate={new Date()}
                      views={['year']}
                      fullWidth
                      // helperText={tra('Analysis_Date')}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )
            }
            <Grid item lg={12} md={12} sm={12} xs={12} className="buttonWrapper">
              <CustomButton grey style={{ marginLeft: 'auto' }} onClick={() => clearServiceFilter()}>
                {tra('Clear')}
              </CustomButton>
              <CustomButton navy onClick={() => filteredService()}>
                {tra('Search')}
              </CustomButton>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </ServiceFilter2>
  );
};

ServiceFilter.propTypes = {
  tabValue: PropTypes.number.isRequired,
  downloadExcel: PropTypes.number.isRequired,
};

export default ServiceFilter;
