// import FormData from 'form-data';
import { languageData } from '../assets/statics';
import { store } from '../index';
import {
  setActiveUser,
  appConfig,
} from '../store/actions';
// import { selectCompanyCodes } from '../store/selectors';

// const initCompanyCodes = selectCompanyCodes();

export const getLangCode = () => {
  if (!languageData.some((o) => o.name === localStorage.getItem('language'))) {
    localStorage.setItem('language', 'T');
  }
  return languageData.find((o) => o.name === localStorage.getItem('language'))?.name || 'T';
};

// export const getCompanyCode = () => {
//   if (!initCompanyCodes.some(o => o.code === localStorage.getItem('company'))) {
//     store.dispatch(appConfig('changeCompany', true));
//   }
//   return true;
// };

// export const getLang = async () => {
//   const lang = localStorage.getItem('language');
//   if (!lang) {
//     localStorage.setItem('language', 'T');
//   } else if (!(languageData.find((o) => o.name === lang))) {
//     localStorage.setItem('language', 'T');
//   }
//   return lang;
// };

export const tra = (key, params) => {
  const json = require(`../assets/locales/${getLangCode() || 'tr'}_translations.js`).default;
  const keys = Object.keys(json);
  if (json.key) {
    return json[key];
  }
  for (let i = 0; i < keys.length; ++i) {
    if (json[keys[i]][key]) {
      let foundedKey = json[keys[i]][key];
      if (params && params.length > 0) {
        for (let j = 0; j < params.length; ++j) {
          foundedKey = foundedKey.replace(`[var${j}]`, params[j]);
        }
      }
      return foundedKey;
    }
  }
  return `${key}`;
};

export const b64toBlob = (b64Data, contentType = 'application/pdf', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const saveAs = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const anchorElem = document.createElement('a');
  anchorElem.style = 'display: none';
  anchorElem.href = url;
  anchorElem.download = fileName;
  document.body.appendChild(anchorElem);
  anchorElem.click();
  document.body.removeChild(anchorElem);
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 1000);
};

export const request = (url, method, data = false, authorization = false) => {
  const headers = {
    'Content-Type': 'application/json',
    language: localStorage.getItem('language') || 'T',
    companyCode: localStorage.getItem('company') || store.getState().appConfig?.company,
  };
  if (authorization) {
    const token = localStorage.getItem('token');
    const customerId = store.getState().appConfig?.godModeCustomer?.id || localStorage.getItem('godModeCustomerId') || localStorage.getItem('customer');
    if (token) {
      headers.authorization = token;
      headers.customer = customerId;
    }
  }
  const options = {
    headers,
    method,
  };

  if (data) {
    options.body = data;
  }
  store.dispatch(appConfig('loading', true));
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401 && !window.location.hash.includes('#/ForgetPassword/')) {
          localStorage.removeItem('token');
          localStorage.removeItem('godModeCustomerId');
          store.dispatch(setActiveUser({}));
          store.dispatch(appConfig('godMode', false));
          store.dispatch(appConfig('godModeCustomer', null));
          window.location.href = `#${process.env.REACT_APP_HREF_URL}Login`;
          throw Error(response.statusText);
        }
      }
      store.dispatch(appConfig('loading', false));
      return response;
    })
    // .then(response => response.json())
    .then(async (response) => {
      const type = response.headers.get('Content-Type');
      if (type.includes('officedocument')) {
        const res = await response.blob();
        saveAs(res, 'Document');
        return {
          status: true,
          message: tra('File_Downloaded_Successfully'),
        };
      }
      return response.json();
    })
    .catch(() => {
      store.dispatch(appConfig('loading', false));
      return 'invalid';
    });
};

export const filesRequest = (url, method, data = false, file) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', localStorage.getItem('token'));
  // myHeaders.append('language', localStorage.getItem('language') || 'T');
  // myHeaders.append('companyCode', localStorage.getItem('company') || store.getState().appConfig?.company);

  const formdata = new FormData();
  formdata.append('file', file);
  formdata.append('isCreated', data);

  const requestOptions = {
    method,
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.json())
    .catch(() => 'invalid');
};

export const fileDownload = (url, method, data = false) => {
  const headers = {
    authorization: localStorage.getItem('token'),
    responseType: 'arraybuffer',
  };
  const options = {
    headers,
    method,
  };
  if (data) {
    options.body = data;
  }
  fetch(url, options)
    .then((res) => res.blob())
    .then((blob) => {
      const fileName = 'export.xlsx';
      saveAs(blob, fileName);
    });
};

export const hexToRgbA = (hex, opacity) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
  }
  throw new Error('Bad Hex');
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], ' ', match[4], ' ', match[5]].join('');
  }
  return null;
};

export const isMailValid = (mail) => {
  // const validArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  const invalidArray = ['ğ', 'Ğ', 'ş', 'Ş', 'ü', 'Ü', 'ö', 'Ö', 'ç', 'Ç', 'ı', 'İ'];
  const valid = !(invalidArray.some((a) => mail?.includes(a)));
  return Boolean(valid && mail?.length > 0 && /\S+@\S+\.\S+/.test(mail));
};
