import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Backdrop, InputAdornment, TextField } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  AdminScreen,
  Box,
  CreditCard,
  Customers,
  CustomersManagement,
  Deposit,
  HomePage,
  Invoice,
  Log,
  Megaphone,
  OrderTransactions,
  Payment,
  Person,
  Products,
  Report,
  World,
} from '../assets/icons';
import { CustomMenuItem, LeftWrapper, SidebarItem, LogoImage } from '../assets/styled';
import Logo from '../assets/img/logo.png';
import { languageData } from '../assets/statics';
import { tra, getLangCode } from '../commons/utils';
import { selectAppConfig, selectAuth, selectShowPayment } from '../store/selectors';
import { appConfig } from '../store/actions';

// const sideBarList = [
//   { id: 1, name: 'Dashboard', hash: '/' },
//   { id: 2, name: 'Reports', hash: '/Reports' },
//   { id: 3, name: 'Customers', hash: '/Customers' },
//   { id: 4, name: 'Products', hash: '/Products' },
//   { id: 5, name: 'Announcement', hash: '/Announcement' },
//   { id: 6, name: 'Order', hash: '/Order' },
//   { id: 7, name: 'Invoice', hash: '/Invoice' },
//   { id: 8, name: 'Payment', hash: '/Payment' },
//   { id: 9, name: 'Guarantee', hash: '/Guarantee' },
//   { id: 10, name: 'SubUserAdd', hash: '/SubUserAdd' },
//   { id: 11, name: 'Admin', hash: '/Admin' },
//   { id: 12, name: 'CustomersManagement', hash: '/CustomersManagement' },
//   { id: 13, name: 'Log', hash: '/Log' },
//   { id: 13, name: 'OrderUpdate', hash: '/OrderUpdate' },
// ];
class LeftSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1,
      mobileExpanded: false,
      subActive: 0,
      languageData: [{ id: 1, name: 'T' }, { id: 2, name: 'E' }, { id: 3, name: 'S' }],
      // language: null,
      sideBarList: [
        { icon: <HomePage />, text: tra('Overview'), hash: '/' },
        ((props.auth?.auth17 ? (props.appConfigs?.godMode && props.auth?.auth1 && !props.appConfigs?.userIsAdmin) : props.auth?.auth1) && { icon: <Box />, text: tra('Order'), hash: '/Order' }),
        ((props.auth?.auth17 ? (props.appConfigs?.godMode && props.auth?.auth22 && !props.appConfigs?.userIsAdmin) : props.auth?.auth22) && { icon: <Invoice />, text: tra('Invoices_Screen'), hash: '/Invoice' }),
        ((props.auth?.auth17 ? (props.appConfigs?.godMode && props.auth?.auth9 && !props.appConfigs?.userIsAdmin) : props.auth?.auth9) && { icon: <Payment />, text: tra('Payments_Screen'), hash: '/Payment' }),
        ((props.auth?.auth17 ? (props.appConfigs?.godMode && props.auth?.auth23 && !props.appConfigs?.userIsAdmin) : props.auth?.auth23) && { icon: <Deposit />, text: tra('Coverage_Information'), hash: '/Guarantee' }),
        ((props.auth?.auth17 ? false : props.auth?.auth13) && { icon: <Person />, text: tra('Add_Sub_User'), hash: '/SubUserAdd' }),
        ((props.auth?.auth17 ? (props.appConfigs?.godMode && props.auth?.auth2 && !props.appConfigs?.userIsAdmin) : props.auth?.auth2) && { icon: <Report />, text: tra('Reports'), hash: '/Reports' }),
        (props.auth?.auth4 && (!props.appConfigs?.godMode || props.appConfigs?.userIsAdmin) && { icon: <Customers />, text: tra('Customers'), hash: '/Customers' }),
        (props.auth?.auth5 && (!props.appConfigs?.godMode || props.appConfigs?.userIsAdmin) && { icon: <Products />, text: tra('Products'), hash: '/Products' }),
        (props.auth?.auth31 && (!props.appConfigs?.godMode || props.appConfigs?.userIsAdmin) && { icon: <OrderTransactions />, text: tra('Order_Transactions'), hash: '/OrderTransactions' }),
        (props.auth?.auth31 && (!props.appConfigs?.godMode || props.appConfigs?.userIsAdmin) && { icon: <Report />, text: tra('Reports'), hash: '/AdminReports' }),
        (props.auth?.auth21 && (!props.appConfigs?.godMode || props.appConfigs?.userIsAdmin) && { icon: <Megaphone />, text: tra('Create_Announcement'), hash: '/Announcement' }),
        (props.auth?.auth6 && (!props.appConfigs?.godMode || props.appConfigs?.userIsAdmin) && { icon: <AdminScreen />, text: tra('Management_Screen'), hash: '/Admin' }),
        (props.auth?.auth7 && (!props.appConfigs?.godMode || props.appConfigs?.userIsAdmin) && { icon: <Log />, text: tra('Log'), hash: '/Log' }),
        (props.auth?.auth17 && props.appConfigs?.godMode && { icon: <CustomersManagement />, text: tra('Customer_Management'), hash: '/CustomersManagement' }),
      ].filter(Boolean),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const index = prevState.sideBarList?.findIndex((x) => x.hash?.split('/')[1] === window.location.hash?.split('/')[1]);
    if ((JSON.stringify(nextProps.appConfigs?.godModeCustomer) !== JSON.stringify(prevState.godModeCustomer)) || (JSON.stringify(nextProps.auth) !== JSON.stringify(prevState.auth)) || (JSON.stringify(nextProps.appConfigs?.userIsAdmin) !== JSON.stringify(prevState.userIsAdmin)) || (JSON.stringify(nextProps.showPayment) !== JSON.stringify(prevState.showPayment))) {
      return {
        sideBarList: [
          { icon: <HomePage />, text: tra('Overview'), hash: '/' },
          ((nextProps.auth?.auth17 ? (nextProps.appConfigs?.godMode && nextProps.auth?.auth1 && !nextProps.appConfigs?.userIsAdmin) : nextProps.auth?.auth1) && { icon: <Box />, text: tra('Order'), hash: '/Order' }),
          ((nextProps.auth?.auth17 ? (nextProps.appConfigs?.godMode && nextProps.auth?.auth22 && !nextProps.appConfigs?.userIsAdmin) : nextProps.auth?.auth22) && { icon: <Invoice />, text: tra('Invoices_Screen'), hash: '/Invoice' }),
          ((nextProps.auth?.auth17 ? (nextProps.appConfigs?.godMode && nextProps.auth?.auth9 && !nextProps.appConfigs?.userIsAdmin) : nextProps.auth?.auth9) && { icon: <Payment />, text: tra('Payments_Screen'), hash: '/Payment' }),
          ((nextProps.auth?.auth17 ? (nextProps.appConfigs?.godMode && nextProps.auth?.auth23 && !nextProps.appConfigs?.userIsAdmin) : nextProps.auth?.auth23) && { icon: <Deposit />, text: tra('Coverage_Information'), hash: '/Guarantee' }),
          ((nextProps.auth?.auth17 ? false : nextProps.auth?.auth13) && { icon: <Person />, text: tra('Add_Sub_User'), hash: '/SubUserAdd' }),
          ((nextProps.auth?.auth17 ? (nextProps.appConfigs?.godMode && nextProps.auth?.auth2 && !nextProps.appConfigs?.userIsAdmin) : nextProps.auth?.auth2) && { icon: <Report />, text: tra('Reports'), hash: '/Reports' }),
          (nextProps.auth?.auth4 && !nextProps.auth?.auth26 && (!nextProps.appConfigs?.godMode || nextProps.appConfigs?.userIsAdmin) && { icon: <Customers />, text: tra('Customers'), hash: '/Customers' }),
          (nextProps.auth?.auth5 && (!nextProps.appConfigs?.godMode || nextProps.appConfigs?.userIsAdmin) && { icon: <Products />, text: tra('Products'), hash: '/Products' }),
          (nextProps.auth?.auth31 && (!nextProps.appConfigs?.godMode || nextProps.appConfigs?.userIsAdmin) && { icon: <OrderTransactions />, text: tra('Order_Transactions'), hash: '/OrderTransactions' }),
          (nextProps.auth?.auth6 && (!nextProps.appConfigs?.godMode || nextProps.appConfigs?.userIsAdmin) && { icon: <Report />, text: tra('Reports'), hash: '/AdminReports' }),
          (nextProps.auth?.auth21 && (!nextProps.appConfigs?.godMode || nextProps.appConfigs?.userIsAdmin) && { icon: <Megaphone />, text: tra('Create_Announcement'), hash: '/Announcement' }),
          (nextProps.auth?.auth6 && (!nextProps.appConfigs?.godMode || nextProps.appConfigs?.userIsAdmin) && { icon: <AdminScreen />, text: tra('Management_Screen'), hash: '/Admin' }),
          (nextProps.auth?.auth7 && (!nextProps.appConfigs?.godMode || nextProps.appConfigs?.userIsAdmin) && { icon: <Log />, text: tra('Log'), hash: '/Log' }),
          (nextProps.auth?.auth17 && nextProps.appConfigs?.godMode && { icon: <CustomersManagement />, text: tra('Customer_Management'), hash: '/CustomersManagement' }),
        ].filter(Boolean),
        godModeCustomer: nextProps.appConfigs?.godModeCustomer,
        userIsAdmin: nextProps.appConfigs?.userIsAdmin,
        auth: nextProps.auth,
        showPayment: nextProps.showPayment,
        active: index === -1 ? -10 : index + 1,
      };
    }
    if (index + 1 !== prevState.active) {
      return {
        active: index === -1 ? -10 : index + 1,
      };
    }
    return null;
  }

  // componentDidMount() {
  //   const index = sideBarList.findIndex((x) => x.hash.split('/')[1] === window.location.hash.split('/')[1]);
  //   this.setState({ active: index + 1 });
  // }

  // componentDidMount() {
  //   const { sideBarList } = this.state;
  //   // const index = sideBarList.findIndex((s) => `#${s.hash}` === window.location.hash);
  //   // const index = sideBarList.findIndex((x) => x.hash.substring(1) === window.location.hash.split('/')[1]);
  //   const index = sideBarList?.findIndex((x) => x.hash.split('/')[1] === window.location.hash.split('/')[1]);
  //   this.setState({ active: index === -1 ? -10 : index + 1 });
  // }

  stateToggle = (stateName) => {
    this.setState((prevstate) => ({ [stateName]: !prevstate[stateName] }));
  }

  render() {
    const {
      active,
    } = this.state;
    const {
      activeUser, appConfigs,
    } = this.props;
    return (
      <LeftWrapper visible={this.props.visible} mobileOpen={appConfigs.mobileMenuOpen}>
        <Backdrop style={{ zIndex: 9 }} open={this.props.appConfigs.mobileMenuOpen} onClick={() => this.props.appConfig('mobileMenuOpen', false)} />
        <div className="fixed">
          <div className="logo">
            <Link to="/">
              <LogoImage src={Logo} alt="Ferçim" />
            </Link>
          </div>
          <div className="navigator">
            {/* <p>ANA MENÜ</p> */}
            {
              this.state.sideBarList?.map((item, i) => (
                <SidebarItem
                  active={active === i + 1}
                  to={item.hash}
                  onClick={() => this.setState({ active: i + 1 }, () => { if (window.innerWidth <= 770) this.props.appConfig('mobileMenuOpen', false); })}
                >
                  <div>
                    {/* <i><HomePage color={active === 1 ? Colors.blueLight : Colors.grey} /></i> */}
                    <i>{item.icon}</i>
                    <span>{item.text}</span>
                  </div>
                </SidebarItem>
              ))
            }
            {/* {
              this.props.auth?.auth30 && this.props.showPayment && (
                <a
                  className="collectionButton"
                  href="https://etahsilat.fercim.com.tr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CreditCard />{tra('Make_Payment')}
                </a>
              )
            } */}
          </div>
          <div className="languageInput">
            <TextField
              select
              value={languageData.find((lang) => lang.name === getLangCode()).name}
              onChange={(e) => { localStorage.setItem('language', this.state.languageData.find((lang) => lang.name === e.target.value)?.name); window.location.reload(); }}
              fullWidth
              variant="standard"
              style={{ margin: '10px 0' }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <World width={22} />
                  </InputAdornment>
                ),
              }}
            >
              {
                languageData?.map((item) => (
                  <CustomMenuItem value={item.name}>
                    {item.text}
                  </CustomMenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="footer">
            <p>{tra('Cimsa_Customer_Portal')}</p>
            <p>&copy; {tra('y2021_All_Rights_Reserved')}</p>
            {/* <p>{tra('Made_with_by_MDP_Group')}</p> */}
            <p>{tra('Produced_by_MDP_Group')}</p>
            {/* <p>{tra('Made_with')} svg {tra('by_MDP_Group')}</p> */}
          </div>
        </div>
      </LeftWrapper>
    );
  }
}

LeftSide.propTypes = {
  visible: PropTypes.bool.isRequired,
  activeUser: PropTypes.object.isRequired,
  appConfig: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  showPayment: PropTypes.bool.isRequired,
  appConfigs: PropTypes.object.isRequired,
};

LeftSide.defaultProps = {
  // isBread: true,
  // timeAgo: 'zaman hatalı',
};

const mapStateToProps = createStructuredSelector({
  // activeUser: selectActiveUser(),
  // notifications: selectNotifications(),
  // announcements: selectAnnouncements(),
  auth: selectAuth(),
  showPayment: selectShowPayment(),
  appConfigs: selectAppConfig(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      // setActiveUser,
      appConfig,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(LeftSide);
