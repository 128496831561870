import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import Customers from '../pages/Customers';
import Products from '../pages/Products';
import BlockageList from '../pages/BlockageList';
import Announcement from '../pages/Announcement';
import AllAnnouncement from '../pages/AllAnnouncement';
import Reports from '../pages/Reports';
import DashboardCustomer from '../pages/DashboardCustomer';
import Invoice from '../pages/Invoice';
import Payment from '../pages/Payment';
import SubUserAdd from '../pages/SubUserAdd';
import Order from '../pages/Order';
import Admin from '../pages/Admin';
import CustomersManagement from '../pages/CustomersManagement';
import Guarantee from '../pages/Guarantee';
import Log from '../pages/Log';
import OrderTransactions from '../pages/OrderTransactions';
import ChangePassword from '../pages/ChangePassword';

import loading from '../assets/img/loading.gif';
import ReportAdmin from '../pages/ReportAdmin';
import Sidebar from '../components/Sidebar';
import { LoadingWrapper, MainWrapper } from '../assets/styled';
import Header from '../components/Header';

// if auth?.auth35 ? (auth?.auth4 ? ((appConfig?.godMode && localStorage.getItem('company')) ? DashboardCustomer : Dashboard) : DashboardCustomer) : (auth?.auth26 ? (appConfig?.godMode && localStorage.getItem('company') ? DashboardCustomer : Customers) : auth?.auth1 ? Order : AllAnnouncement)
// ana sayfa ekranı
// if (auth?.auth35) {
//   if (auth?.auth4) {
//     if (appConfig?.godMode && localStorage.getItem('company')) {
//       DashboardCustomer
//     } else {
//       Dashboard
//     }
//   } else {
//     DashboardCustomer
//   }
// } else {
//   if (auth?.auth26) {
//     if (appConfig?.godMode && localStorage.getItem('company')) {
//       DashboardCustomer
//     } else {
//       Customers
//     }
//   } else {
//     if (auth?.auth1) {
//       Order
//     } else {
//       AllAnnouncement
//     }
//   }
// }

const Routes = ({
  activeUser, appConfig, auth,
}) => {
  const token = localStorage.getItem('token');
  return (
    <>
      {!token && <Redirect to="/Login" />}
      <Sidebar activeUser={activeUser} />
      <MainWrapper activeUser={Object.keys(activeUser).length} style={{ paddingTop: appConfig.godMode && 50 }}>
        <Header activeUser={activeUser} />
        <Switch>
          {
            Object.keys(activeUser).length === 0 ? (
              <>
                <LoadingWrapper>
                  <img src={loading} alt="Loading" />
                </LoadingWrapper>
                {/* <Route key="/" path="/Login" exact component={Login} /> */}
              </>
            ) : activeUser.first_login ? (
              <Route key="/" path="/" exact component={ChangePassword} />
            ) : (
              <>
                <Route
                  path="/"
                  key="/"
                  exact
                  component={
                    auth?.auth35 ? (
                      auth?.auth4 ? (
                        appConfig?.godMode && localStorage.getItem('company') ? (
                          DashboardCustomer
                        ) : (
                          Dashboard
                        )
                      ) : (
                        DashboardCustomer
                      )
                    ) : (
                      auth?.auth26 ? (
                        appConfig?.godMode && localStorage.getItem('company') ? (
                          DashboardCustomer
                        ) : Customers
                      ) : (
                        auth?.auth1 ? Order : AllAnnouncement
                      )
                    )
                  }
                />
                {(auth?.auth17 ? (appConfig?.godMode && auth?.auth1) : auth?.auth1) && <Route key="/Order" path="/Order" exact component={Order} />}
                {(auth?.auth17 ? (appConfig?.godMode && auth?.auth2) : auth?.auth2) && <Route key="/Reports" path="/Reports" exact component={Reports} />}
                {(auth?.auth4 ? false : auth?.auth13) && <Route key="/SubUserAdd" path="/SubUserAdd" exact component={SubUserAdd} />}
                {auth?.auth4 && (!appConfig?.godMode || appConfig?.userIsAdmin) && <Route key="/Customers" path="/Customers" exact component={Customers} />}
                {auth?.auth5 && (!appConfig?.godMode || appConfig?.userIsAdmin) && <Route key="/Products" path="/Products" exact component={Products} />}
                {auth?.auth5 && (!appConfig?.godMode || appConfig?.userIsAdmin) && <Route key="/BlockageList" path="/BlockageList" exact component={BlockageList} />}
                {auth?.auth6 && (!appConfig?.godMode || appConfig?.userIsAdmin) && <Route key="/Admin" path="/Admin" exact component={Admin} />}
                {auth?.auth6 && (!appConfig?.godMode || appConfig?.userIsAdmin) && <Route key="/AdminReports" path="/AdminReports" exact component={ReportAdmin} />}
                {auth?.auth31 && (!appConfig?.godMode || appConfig?.userIsAdmin) && <Route key="/OrderTransactions" path="/OrderTransactions" exact component={OrderTransactions} />}
                {auth?.auth7 && (!appConfig?.godMode || appConfig?.userIsAdmin) && <Route key="/Log" path="/Log" exact component={Log} />}
                {auth?.auth21 && <Route key="/Announcement" path="/Announcement" exact component={Announcement} />}
                <Route key="/AllAnnouncement" path="/AllAnnouncement" exact component={AllAnnouncement} />
                {auth?.auth9 && <Route key="/Payment" path="/Payment" exact component={Payment} />}
                {auth?.auth17 && appConfig.godMode && <Route key="/CustomersManagement" path="/CustomersManagement" exact component={CustomersManagement} />}
                {(auth?.auth17 ? (appConfig?.godMode && auth?.auth22) : auth?.auth22) && <Route key="/Invoice" path="/Invoice" exact component={Invoice} />}
                {(auth?.auth17 ? (appConfig?.godMode && auth?.auth23) : auth?.auth23) && <Route key="/Guarantee" path="/Guarantee" exact component={Guarantee} />}
                <Route key="/ChangePassword" path="/ChangePassword" exact component={ChangePassword} />
              </>
              // <>
              //   {
              //     activeUser.user_type_id === 1 ? (
              //       <>
              //         <Route key="/" path="/" exact component={Dashboard} />
              //         <Route key="/" path="/Customers" exact component={Customers} />
              //         <Route key="/" path="/Products" exact component={Products} />
              //         <Route key="/" path="/BlockageList" exact component={BlockageList} />
              //         <Route key="/" path="/Admin" exact component={Admin} />
              //         <Route key="/" path="/Log" exact component={Log} />
              //       </>
              //     ) : (activeUser.user_type_id === 2 || activeUser.user_type_id === 5) ? (
              //       <>
              //         <Route key="/" path="/" exact component={DashboardCustomer} />
              //         <Route key="/" path="/Invoice" exact component={Invoice} />
              //         <Route key="/" path="/Payment" exact component={Payment} />
              //         {!appConfig.godMode && <Route key="/" path="/SubUserAdd" exact component={SubUserAdd} />}
              //         {/* <Route key="/" path="/SubUserAdd" exact component={SubUserAdd} /> */}
              //         <Route key="/" path="/Order" exact component={Order} />
              //         <Route key="/" path="/Guarantee" exact component={Guarantee} />
              //         {appConfig.godMode && <Route key="/" path="/CustomersManagement" exact component={CustomersManagement} />}
              //         {loading && <Route key="/" path="/Reports" exact component={Reports} />}
              //       </>
              //     ) : activeUser.user_type_id === 3 ? (
              //       <>
              //         <Route key="/" path="/" exact component={Dashboard} />
              //         <Route key="/" path="/Order" exact component={Order} />
              //       </>
              //     ) : activeUser.user_type_id === 4 && (
              //       <Route key="/" path="/" exact component={CustomersManagement} />
              //     )
              //   }
              //   <Route key="/" path="/Announcement" exact component={Announcement} />
              // </>
            )
          }
        </Switch>
      </MainWrapper>
    </>
  );
};

Routes.propTypes = {
  activeUser: PropTypes.object.isRequired,
  appConfig: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
export default Routes;
