import {
  Button, TextField, MenuItem, Menu, IconButton, Fade, FormControl, Tab, Tabs, Popper, DialogContent, Drawer,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { hexToRgbA } from '../commons/utils';
import { Colors } from './statics';
import fingerprint from './img/fingerprint.png';

export const GlobalStyle = createGlobalStyle`
  * {
    outline: none!important;
    margin: 0;
    padding: 0;
    font-family: Mulish, Arial, sans-serif;
    font-style: normal;
    box-sizing: border-box;
  }
  body {
    position: relative;
  }
  a, a:hover {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  svg {
    /* min-width: fit-content;
    width: intrinsic;           /* Safari/WebKit uses a non-standard name */
    /* min-width: -moz-fit-content;    Firefox/Gecko */
    /* min-width: -webkit-fit-content; Chrome */
  }
  .mainContent {
    overflow: hidden;
  }
  .disableRipple {
    &.MuiIconButton-root:hover {
      background-color: transparent;
    }
  }
  ::-webkit-scrollbar{
    width: 8px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb{
    background: ${Colors.blueLight};
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background: ${Colors.blue};
  }
  ::-webkit-scrollbar-track{
    background: ${Colors.lightGrey};
    border-radius: 0px;
  }
  .MuiFormLabel-root {
    font-family: "Mulish", "Helvetica", "Arial", sans-serif;
  }
  .MuiTypography-body1 {
    font-family: 'Mulish', sans-serif;
  }
  .MuiMenuItem-root {
    text-transform: capitalize;
  }
  .MuiOutlinedInput-root {
    border-radius: 6px;
  }
  .MuiPickersDay-daySelected {
    background-color: ${Colors.blue};
    :hover {
      background-color: ${Colors.blue};
    }
  }
  .MuiAutocomplete-tag {
    max-width: 200px;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-top: 35px;
    input {
      position: absolute;
      top: 0;
      height: 35px;
      width: 100%!important;
    }
  }
  .mobileMoreMenu {
    * {
      color: ${Colors.blueBlack2};
    }
    li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
      color: ${Colors.lightBlack};
      font-weight: 600;
      opacity: 1;
      &.Mui-disabled {
        color: ${Colors.navy};
        opacity: 0.6;
      }
    }
  }
  .tableButton {
    display: flex;
    height: 45px;
    width: 43px; // temp
    .MuiIconButton-root:hover {
      background-color: ${Colors.white};
    }
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      /* z-index: 1; */
      /* width: 24px; */
      width: inherit;
      /* height: 30px; */
      /* height: inherit; */
      height: 55px;
      right: 1px;
      background-color: ${Colors.white};
      border-left: 1px solid ${Colors.lightGrey};
      /* border-bottom: 1px solid ${Colors.lightGrey}; */
      border-radius: 0!important;
      transform: translate(0, -5px);
    }
  }
  .chartTooltip {
    position: relative;
    background: ${Colors.white};
    color: ${Colors.lightBlack};
    padding: 12px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0px 20px 30px rgba(3, 113, 208, 0.15);
    h6 {
      color: ${Colors.lightBlack};
      font-Size: 16px;
      font-weight: 400;
      padding-bottom: 10;
    }
    :after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: ${Colors.white} transparent transparent transparent;
    }
  }
  .noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px 0;
    p {
      font-size: 20px;
      color: ${Colors.lightBlack};
      font-weight: 600;
      padding-top: 20px;
      text-align: center;
    }
  }
  .loading {
    width : 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 10px solid ;
    border-color: #88CCFB  #B5E1FF  #CBEAFF  #E2F3FF;
    animation: rotation 1s linear infinite;
    @keyframes rotation {
      0% { transform: rotate(0deg) }
      100% { transform: rotate(360deg) }
    }
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .4);
  z-index: 9999999999999;
  .ring {
    width: 48px;
    height: 48px;
    border: 3px solid ${Colors.white};
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 1s linear infinite;
    :after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-bottom-color: ${Colors.blueBlack2};
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const CustomButton = styled(Button)`
  background-color: ${Colors.blue};
  color: ${Colors.white};
  text-transform: none;
  padding: 10px 30px;
  height: 35px;
  white-space: nowrap;
  overflow: hidden;
  :hover {
    background-color: ${(props) => (props.light ? Colors.blueLight : props.white ? Colors.White : hexToRgbA(Colors.blue, 0.7))};
    color: ${(props) => (props.light ? Colors.blue : Colors.white)};
  }
  ${((props) => props.simple && `
    background-color: transparent;
    color: ${Colors.lightBlack};
    :hover {
      background-color: transparent;
      color: ${Colors.lightBlack};
    }
  `)}
  ${((props) => props.lined && `
    background-color: transparent;
    color: ${Colors.blue};
    border: 1px solid ${Colors.blue};
    :hover {
      background-color: transparent;
      color: ${Colors.blue};
    }
  `)}
  ${((props) => props.lightBlue && `
    background-color: ${hexToRgbA(Colors.blueLight, 0.5)};
    color: ${Colors.blue};
    :hover {
      background-color: ${Colors.blueLight};
      color: ${Colors.blue};
    }
  `)}
   ${((props) => props.darkGrey && `
    background-color: ${hexToRgbA(Colors.grey, 1)};
    color: ${Colors.white};
    :hover {
      background-color: ${Colors.lightBlack};
      color: ${Colors.white};
    }
  `)}
  ${((props) => props.green && `
    background-color: ${hexToRgbA(Colors.green, props.colorOpacity || 1)};
    color: ${props.colorOpacity ? Colors.greenBlack : Colors.lightBlack};
    :hover {
      background-color: ${hexToRgbA(Colors.green, props.colorOpacity || 1)};
      color: ${props.colorOpacity ? Colors.greenBlack : Colors.lightBlack};
    }
  `)}
  ${((props) => props.orange && `
    background-color: ${hexToRgbA(Colors.lightOrange, props.colorOpacity || 1)};
      color: ${props.colorOpacity ? Colors.orange : Colors.lightBlack};
    :hover {
      background-color: ${hexToRgbA(Colors.lightOrange, props.colorOpacity || 1)};
      color: ${props.colorOpacity ? Colors.orange : Colors.white};
    }
  `)}
  ${((props) => props.red && `
    background-color: ${hexToRgbA(Colors.burgundy, props.colorOpacity || 1)};
    color: ${props.colorOpacity ? Colors.redBlack : Colors.white};
    :hover {
      background-color: ${hexToRgbA(Colors.burgundy, props.colorOpacity || 1)};
      color: ${props.colorOpacity ? Colors.redBlack : Colors.white};
    }
  `)}
  ${((props) => props.grey && `
    background-color: ${Colors.lightGrey};
    color: ${Colors.lightBlack};
    &.MuiButton-root.Mui-disabled {
      color: ${hexToRgbA(Colors.lightBlack, 0.8)};
    }
    :hover {
      background-color: ${Colors.lightGrey};
      color: ${Colors.lightBlack};
    }
  `)}
  ${((props) => props.pink && `
    background-color: ${hexToRgbA(Colors.pink, 0.5)};
    color: ${Colors.burgundy};
    :hover {
      background-color: ${hexToRgbA(Colors.pink, 0.5)};
      color: ${Colors.burgundy};
    }
  `)}
  ${((props) => props.white && `
    background-color: ${Colors.white};
    color: ${Colors.lightBlack};
    border: 1px solid ${Colors.darkGrey};
    :hover {
      background-color: ${Colors.white};
      color: ${Colors.lightBlack};
    }
  `)}
`;

export const Simplelink = styled(Link)`
  display: inline-block;
  color: ${Colors.navy};
  transition: all 0.3s ease;
  :hover {
    color: ${Colors.blue};
  }
`;

export const CustomIconButton = styled(IconButton)`
  * {
    border-radius: 5px;
  }
  &.MuiIconButton-root {
    border-radius: 5px;
  }
  ${((props) => props.column && (`
    .MuiIconButton-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      font-size: 13px;
    }
  `))}
`;

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .slider {
    display: flex;
    overflow: hidden;
    position: absolute;
    height: 100vh;
    width: 100%;
    .wrapper {
      display: flex;
      transition: all 0.5s ease;
      width: 100%;
      img {
        width: 100vw;
        height: 100vh;
      }
      > div {
        min-width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px 30px 10px 15px;
  }
`;

export const LoginBox = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  width: 45%;
  /* float: right; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 0 10% 0 auto;
  min-width: 320px;
  overflow: auto;
  background-color: ${Colors.white};
  :after {
    background: ${Colors.white};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 0;
    /* filter: blur(2px); */
  }
  > div {
    z-index: 9;
    width: 60%;
    min-width: 300px;
    position: relative;
    /* padding: 80px 0; */
    padding: 8vh 0;
    margin: auto;
    h1 {
      font-size: 26px;
      font-weight: 800;
      color: ${Colors.navy};
      margin-bottom: 25px;
      margin-top: 15px;
    }
    p {
      font-size: 18px;
      color: ${Colors.darkGrey};
      margin-bottom: 25px;
    }
    .MuiFormControl-root {
      margin: 15px 0;
      * {
        font-size: 24px;
      }
    }
    ${CustomButton} {
      margin-top: 50px;
      height: 55px;
      font-size: 18px;
      font-weight: 600;
      & + ${CustomButton} {
        margin-top: 30px;
      }
    }
    ${Simplelink} {
      font-size: 20px;
      margin-top: 25px;
      float: right;
    }
  }
  .logo {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 120px;
    }
  }
  .changeLanguage {
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
    .MuiSelect-root {
      font-size: 18px;
      height: 35px;
      min-height: 35px;
      padding: 0;
      padding-right: 32px;
      line-height: 35px;
    }
    .MuiOutlinedInput-root {
      height: 35px;
    }
    .MuiFormControl-root {
      height: 35px;
    }
  }
  @media (max-width: 425px) {
    margin: 0;
    width: 100%;
  }
`;

export const MainWrapper = styled.div`
  padding: ${(props) => (props.activeUser ? '0 40px' : '0')};
  width: ${(props) => (props.activeUser ? 'calc(100% - 250px)' : '100%')};
  float: left;
  @media (max-width: 1500px) {
    width: ${(props) => (props.activeUser ? 'calc(100% - 51px)' : '100%')};
  }
  @media (max-width: 768px) {
    width:  100%;
    padding: ${(props) => (props.activeUser ? '0 10px' : '0')};
  }
`;

export const LeftWrapper = styled.div`
  display: ${(props) => (props.visible && 'none')};
  height: 99vh;
  width: 250px;
  float: left;
  overflow: hidden;
  .collectionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: linear-gradient(86.28deg, #88CCFB -4.16%, #61B8F3 108.59%);
    box-shadow: 0px 6px 9px rgba(150, 160, 175, 0.2), 0px 18.9565px 37.913px rgba(30, 28, 46, 0.08);
    border-radius: 4px;
    white-space: nowrap;
    color: ${Colors.white};
    transition: all 0.5s ease;
    margin: 5px;
    overflow: hidden;
    svg {
      margin-right: 15px;
    }
    :hover {
      opacity: 0.7;
    }
  }
  .fixed {
    position: fixed;
    height: 100vh;
    width: 250px;
    display: flex;
    flex-direction: column;
    background: ${Colors.lightGrey};
    padding: 25px;
    overflow: auto;
    z-index: 10;
    box-shadow: 3px 0 5px ${hexToRgbA(Colors.grey, 0.5)};
  }
  .navigator {
    flex: 1;
    transition: all 0.5s ease;
    /* padding-top: 40px; */
    margin-top: 40px;
    /* p {
      color: ${Colors.grey};
      margin: 50px 0 25px 0;
      user-select: none;
      display: none;
    } */
  }
  .languageInput {
    .MuiInput-underline:before {
      display: none;
    }
    .MuiInput-underline.Mui-focused:after {
      display: none;
    }
    .MuiSelect-select.MuiSelect-select {
      padding-right: 30px;
      padding-left: 5px;
      text-transform: capitalize;
      color: ${Colors.darkGrey};
    }
  }
  .footer {
    p {
      font-size: 13px;
      font-weight: 800;
      color: ${Colors.grey};
      font-weight: 400;
      padding-top: 5px;
      :first-of-type {
        color: ${Colors.darkGrey};
        font-weight: 800;
      }
    }
  }
  @media (max-width: 1500px) {
    width: 50px;
    min-width: 50px;
    .logo {
      > * {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        text-align: center;
        transition: all 0.5s ease;
        img {
          width: 100%;
          max-width: 105px;
          margin: auto;
        }
      }
    }
    .navigator {
      p {
        height: 40px;
        text-align: center;
        transition: all 0.5s ease;
        font-size: 14px;
      }
    }
    .languageInput {
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease;
      padding: 0 10px;
    }
    .collectionButton {
      font-size: 0;
      svg {
        min-width: 31px;
        margin-right: 0;
      }
    }
    :hover {
      .fixed {
        width: 200px;
        min-width: 200px;
        box-shadow: 5px 0 10px ${hexToRgbA(Colors.grey, 0.5)};
      }
      .angle {
        opacity: 1;
      }
      .footer {
        p {
          white-space: nowrap;
          visibility: visible;
          opacity: 1;
          transition: all 0.5s ease;
        }
      }
      .languageInput {
        visibility: visible;
        opacity: 1;
      }
      .collectionButton {
        font-size: 16px;
        svg {
          margin-right: 15px;
        }
      }
    }
    .fixed {
      width: 50px;
      min-width: 50px;
      overflow: hidden;
      transition: all 0.5s ease;
      padding: 25px 0;
    }
    .footer {
      width: 200px;
      position: relative; // temp
      left: 50%; // temp
      transform: translate(-50%, 0px); // temp
      p {
        white-space: nowrap;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
      }
    }
  }
  @media (max-width: 768px) {
    width: 0;
    min-width: 0;
    .fixed {
      width: 200px;
      min-width: 200px;
      transform: translateX(-100%);
    }
    .navigator {
      overflow: auto;
    }
    .footer {
      p {
        white-space: nowrap;
        visibility: visible;
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
    .languageInput {
      visibility: visible;
      opacity: 1;
    }
    .collectionButton {
      font-size: 16px;
      svg {
        margin-right: 15px;
      }
    }
    ${(props) => props.mobileOpen && `
      .fixed {
        transform: translateX(0);
      }
    `}
  }
`;

export const SidebarItem = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
  flex-direction: column;
  * {
    transition: all 0.5s ease;
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    .angle {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      &.active {
        transform: translate(0, -50%) rotate(90deg);
      }
    }
  }
  i {
    width: 50px;
    min-width: 50px;
    display: flex;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => (props.active ? Colors.blue : Colors.darkGrey)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .subMenu {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
  .subMenu.active {
    overflow: visible;
    visibility: visible;
    opacity: 1;
    height: ${(props) => (props.height ? props.height : 'auto')};
    /* height: auto; */
    overflow: hidden;
  }
  svg path {
    fill: ${(props) => (props.active ? Colors.blueLight : Colors.grey)};
    stroke: ${(props) => (props.active ? Colors.blueLight : Colors.grey)};
  }
  @media (max-width: 1500px) {
    .angle {
      opacity: 0;
      margin-right: 15px;
    }
    i {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: 768px) {
    padding: 10px 5px;
    i {
      width: 40px;
      min-width: 40px;
    }
    svg {
      width: 24px;
    }
    span {
      font-size: 14px;
      padding-left: 5px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: ${(props) => (props.visible ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  .left {
    display: flex;
    align-items: center;
    h1 {
      font-weight: 800;
      font-size: 20px;
      color: ${Colors.lightBlack};
    }
    p {
      font-weight: 400;
      font-size: 16px;
      color: ${Colors.lightBlack};
    }
  }
  .right {
    display: flex;
    align-items: center;
    button {
      position: relative;
    }
    button + button {
      margin-left: 15px;
    }
    .count {
      width: 34px;
      height: 34px;
      border-radius: 100%;
      color: ${Colors.white};
      border: 2px solid ${Colors.white};
      background-color: ${Colors.blue};
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -15px;
      right: -15px;
    }
    /* img {
      max-width: 100%;
      width: 52px;
      height: 52px;
      overflow: hidden;
      border-radius: 100%;
    } */
    .iconWrapper {
      display: flex;
      align-items: center;
    }
    .nameIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      overflow: hidden;
      border-radius: 100%;
      background-color: ${Colors.blue};
      margin-right: 10px;
      p {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: ${Colors.white};
      }
    }
  }
  .selectedCompany {
    background-color: ${Colors.lightGrey};
    border-radius: 6px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    max-width: 150px;
    height: 60px;
    transition: all 0.3s ease;
    :hover {
      box-shadow: 0px 6px 9px rgb(150 160 175 / 90%);
    }
    svg {
      min-width: 15px;
    }
    div {
      padding-left: 5px;
      p {
        font-weight: 600;
        font-size: 12px;
        color: ${Colors.lightBlack};
        text-align: left;
      }
      span {
        font-size: 12px;
        /* line-height: 15px; */
        letter-spacing: -0.015em;
        color: ${Colors.blue};
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        @supports (-webkit-line-clamp: 2) {
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .mobileButton {
    display: none;
    ${CustomIconButton} {
      padding: 0;
    }
  }
  @media (max-width: 768px) {
    .mobileButton {
      display: unset;
    }
    .selectedCompany {
      max-width: 90px;
      margin-right: 0;
      padding: 10px 5px;
    }
  }
  @media (max-width: 650px) {
    .left {
      flex: 1;
      >* {
        display: none;
      }
      .mobileButton {
        display: unset;
      }
    }
  }
  @media (max-width: 425px) {
    .changeLanguage {
      display: none;
    }
    .announceButton {
      display: none;
    }
    .selectedCompany {
      max-width: 120px;
      margin-right: 0;
      padding: 10px 5px;
    }
  }
`;

export const PageWrapper = styled.div`
  .dashTitle {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: ${Colors.lightBlack};
    padding-bottom: 25px;
    ${Simplelink} {
      margin-left: auto;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }
  .datePickerWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    >* {
      margin: 5px;
    }
  }
  .announceForm {
    >* + * {
      margin-top: 15px;
    }
    .checkingArea {
      display: flex;
      padding-bottom: 15px;
      >* + * {
        margin-left: 10px;
      }
    }
  }
  .buttonWrapperAnnounce {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -5px;
    padding: 5px 0;
    >* {
      margin: 5px;
    }
  }
`;

export const DashboardBox = styled.div`
  border-radius: 6px;
  background: ${(props) => props.bg || Colors.lightGrey};
  color: ${(props) => (props.bg ? Colors.white : Colors.lightBlack)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 25px; */
  box-shadow: 0px 6px 9px rgba(150, 160, 175, 0.2), 0px 19px 38px rgba(30, 28, 46, 0.08);
  transition: all 0.3s ease;
  height: 100%;
  position: relative;
  overflow: hidden;
  .content {
    /* flex: 1; */
    z-index: 1;
    background-color: #eceff224;
    backdrop-filter: blur(1px);
    width: fit-content;
    padding: 25px;
    overflow: hidden;
    padding-right: 10px;
    h3 {
      font-size: 24px;
      font-weight: 800;
    }
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .icon {
    width: 65px;
    height: 65px;
    background: ${Colors.white};
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 0;
    right: 25px;
  }
  :hover {
    box-shadow: 0px 6px 9px rgba(150, 160, 175, 0.9), 0px 19px 38px rgba(30, 28, 46, 0.4);
  }
  ${(props) => props.disable && `
    box-shadow: none;
    opacity: 0.8;
    cursor: default;
    :hover {
      box-shadow: none;
    }
  `};
`;

export const TableNameBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  background: ${(props) => (props.notExist ? 'rgba(255, 91, 91, 0.05)' : props.success ? hexToRgbA(Colors.green, 0.2) : hexToRgbA(Colors.blueLight, 0.15))};
  border: ${(props) => (props.notExist ? '1px solid rgba(176, 15, 15, 0.3)' : props.success ? `1px solid ${hexToRgbA(Colors.green, 0.4)}` : `1px solid ${hexToRgbA(Colors.blueLight, 0.6)}`)};
  /* border: 1px solid rgba(62, 155, 240, 0.3); */
  border-radius: 5px;
  padding: 5px 10px;
  max-width: 180px;
  /* width: fit-content; */
  /* img {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    margin-right: 10px;
  } */
  svg {
    margin-right: 20px;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* max-width: 105px; */
    color: ${(props) => (props.notExist ? Colors.burgundy : props.success ? Colors.islamicGreen : Colors.blueBlack2)};
  }
  i {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: ${Colors.blue};
    padding-left: 5px;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .find {
    position: relative;
    display: flex;
    min-width: 45%;
    /* margin-top: 10px; */
    margin: 5px -5px -5px;
    flex-wrap: wrap;
    >* {
      height: 35px;
      margin: 5px;
    }
    ${CustomButton} {
      height: 35px;
      /* margin-left: 10px; */
    }
    .inputWrapper {
      position: relative;
      flex: 1;
      /* min-width: 220px; */
      min-width: 100px;
      svg {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(0, -50%);
      }
      input {
        padding-left: 20px;
        padding-right: 50px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        border: none;
        width: 100%;
        height: 35px;
        border-radius: 5px;
        color: ${Colors.blueBlack2};
        /* background-color: #EFF0F3; */
        background-color: ${Colors.lightGrey};
        ::placeholder {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: ${Colors.navy};
        }
      }
    }
  }
  .rightButtons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px -5px -5px;
    /* margin-top: 10px; */
    >* {
      height: 35px;
      margin: 5px;
    }
    .MuiFormControl-root {
      height: 35px;
    }
  }
`;

export const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.blueBlack2};
  }
  .MuiInput-underline:after {
    /* border-bottom: 2px solid ${Colors.blueBlack2}; */
    border-bottom: 2px solid ${Colors.blueBlack2};
  }
  .MuiInput-underline:before {
    /* border-bottom: 1px solid ${Colors.mainBlue}; */
    border-bottom: 1px solid ${Colors.blue};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    /* border-bottom: 2px solid ${Colors.mainBlue}; */
    border-bottom: 2px solid ${Colors.blue};
  }
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
    text-align: center;
  }
  .MuiFilledInput-underline:after {
    border-bottom-color: ${Colors.blue};
  }
  .MuiOutlinedInput-input {
    /* padding: unset; */
  }
  .MuiOutlinedInput-root {
    border-radius: ${((props) => (props.search ? '15px' : '5px'))};
    height: 100%;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.blue};
  }
  .MuiInputLabel-outlined {
  }
  .MuiInputLabel-shrink  {
    color: ${Colors.blue};
  }
  ${((props) => props.search && (`
    border-radius: 15px;
    background-color: ${Colors.white};
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiOutlinedInput-input {
      padding: unset;
    }
  `))}
  ${((props) => props.kvkk && (`
    background-color: ${Colors.white};
    color: ${Colors.navy};
    .MuiInput-underline:after {
      content: none
    }
    .MuiInput-underline:before {
      content: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      content: none;
    }
    .MuiInputBase-root.Mui-disabled {
      color: ${Colors.navy};
    }
  `))}
`;

export const MobileMoreMenu = styled(Menu)`
`;

export const MobileMoreMenuItem = styled(MenuItem)`
  display: flex;
  font-size: 13px;
  background: ${Colors.grayExtraLight};
  color: ${Colors.navy};
  height: 35px;
  /* & + & {
    border-top: 1px solid ${Colors.redLight};
  } */
  :active {
    background: ${Colors.redLight};
  }
  svg {
    margin-right: 5px;
    width: 15px;
    min-width: 15px;
    height: 15px;
    path {
      /* fill: ${Colors.blueBlack2}; */
    }
  }
`;

export const CustomFade = styled(Fade)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* min-width: 400px; */
  /* min-width: 50%; */
  max-width: 90%;
  max-height: 100vh;
  padding: 35px 40px;
  border-radius: 6px;
  background-color: ${Colors.white};
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${Colors.lightGrey};
    padding-bottom: 10px;
    h1 {
      font-size: 18px;
      line-height: 22px;
      color: ${Colors.lightBlack};
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    padding: 5px 0;
    max-height: 70vh;
    overflow: auto;
    &.blokage {
      .formWrapper .MuiFormControl-root.MuiTextField-root {
        width: calc(50% - 10px);
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
    }
    .bringCustomer {
      display: flex;
      align-items: flex-end;
      width: 100%;
      flex-wrap: wrap;
      .MuiFormControl-root {
        min-width: 170px;
        max-width: 300px;
        flex: 1;
      }
      button {
        margin-left: auto;
      }
    }
    .formWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      >* {
        padding: 5px 0;
        width: calc(50% - 10px);
        /* margin: 5px auto; */
      }
      .MuiFormControl-root {
        min-width: 200px;
        /* height: 64px; */
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      .MuiFormControl-fullWidth {
        width: 100%!important;
      }
      .MuiInputBase-input {
        padding: 6px 0 0;
      }
      .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        width: unset;
      }
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
      }
    }
    ${CustomButton} {
      margin-left: 20px;
      margin-right: 0;
      margin-top: 20px;
    }
  }
  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    padding-top: 25px;
    padding-bottom: 10px;
  }
  .kvkkContent {
    color: ${Colors.lightBlack};
    overflow: auto;
    /* max-height: 500px; */
    max-height: calc(100vh - 140px);
    margin-bottom: 20px;
    p {
      font-size: 14px;
    }
    .MuiInputBase-root {
      font-size: 14px;
    }
  }
  .kvkkButton {
    display: flex;
    justify-content: center;
  }
  .survey {
    .surveyContent {
      overflow: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 200px);
    }
    .buttonWrapper {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }
  }
  @media (max-width: 425px) {
    padding: 25px 30px;
  }
`;

export const CustomFadeInfo = styled(Fade)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* min-width: 40%; */
  width: 400px;
  max-width: 90%;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: ${Colors.white};
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    h2 {
      padding-top: 40px;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: ${Colors.green};
    }
    p {
      font-size: 14px;
      line-height: 20px;
      padding-top: 15px;
      text-align: center;
    }
    ${CustomButton} {
      margin-top: 50px;
      width: 100%;
    }
    .buttonWrapper {
      display: flex;
      align-items: center;
      width: 100%;
      /* >* {
        margin-left: 20px;
      }
      :first-child {
        margin-left: 0;
      } */
      ${CustomButton} {
        margin-top: 50px;
        & + ${CustomButton} {
          margin-left: 20px;
        }
      }
    }
  }
`;

export const ChartWrapper = styled.div`
  box-shadow: 0px 6px 9px rgba(150, 160, 175, 0.2), 0px 18.9565px 37.913px rgba(30, 28, 46, 0.08);
`;

export const Widget = styled.div`
  box-shadow: 0px 6px 9px rgba(150, 160, 175, 0.2), 0px 18.9565px 37.913px rgba(30, 28, 46, 0.08);
  padding: 25px;
  border-radius: 6px;
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: ${Colors.lightBlack};
    margin-bottom: 25px;
  }
  p {
    font-size: 35px;
    line-height: 44px;
    color: ${Colors.blue};
    margin-bottom: 5px;
  }
  span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.03em;
    color: ${Colors.darkGrey};
    span {
      font-weight: 600;
      font-size: 25px;
      line-height: 31px;
      letter-spacing: -0.015em;
      color: ${Colors.green};
    }
  }
`;

// Input
export const CustomFormControl = styled(FormControl)`
  > label {
    font-size: 20px;
    color: ${(props) => props.theme.text1};
    font-weight: 600;
  }
  .MuiAutocomplete-tag {
    color: ${Colors.blueBlack2};
    border-radius: 10px;
    background: ${Colors.graySoft};
    font-weight: 600;
    .MuiChip-deleteIcon{
      fill: ${hexToRgbA(Colors.blueBlack2, 0.3)};
      :hover {
        fill: ${hexToRgbA(Colors.blueBlack2, 0.6)};
        }
    }
  }
  .MuiInputBase-root {
    color: ${(props) => props.theme.text2};
    border-radius: 5px;
    background: ${(props) => props.theme.inputBackg};
    padding: 0;
    max-height: 190px;
    /* overflow: auto; */
    overflow: hidden;
    min-height: 38px; // temp
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(15px, -25px) scale(0.75);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.inputBorder};
    border-width: 1px;
    border-style: solid;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.inputBorder};
  }
  .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.inputBorder};
  }
  ${(props) => (props.error && `
    .MuiOutlinedInput-notchedOutline {
      border-color: ${Colors.red}!important;
    }
  `)}
`;

export const CustomFileText = styled(TextField)`
  ${(props) => props.disabled && `
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
      border-width: 1px;
      border-style: solid;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
    .MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: rgba(184, 200, 219, .6);
    }
  `}
`;

export const CustomMenuItem = styled(MenuItem)`
  ${(props) => props.active && `
    color: #2144D4;
    svg {
      path {
        fill: #2144D4;
      }
      circle {
        fill: #2144D4;
      }
      rect {
        fill: #2144D4;
      }
    }
  `}
`;

export const TabWrapper = styled(Tabs)`
  background-color: ${(props) => (!props.innerTab ? Colors.lightGrey : 'transparent')};
  min-height: ${(props) => (props.innerTab && '35px')};
  width: 100%; // ?
  margin-bottom: 10px;
  .MuiTabs-fixed {
    width: 100%;
    overflow: auto!important;
  }
  .MuiTabs-indicator {
    background-color: ${Colors.blue};
    height: ${(props) => (!props.innerTab ? '5px' : '3px')};
    bottom: 0px;
  }
  .MuiTabs-flexContainer {
    padding-bottom: 5px;
    /* width: max-content; // temp */
  }
  .MuiTabs-flexContainer {
    position: relative;
    display: flex;
    /* justify-content: space-between; */
    /* overflow: auto; */
    ::before {
      content: '';
      position: absolute;
      bottom: 0.5px;
      left: 0;
      width: 100%;
      background-color: ${(props) => (!props.innerTab ? 'transparent' : Colors.grey)};
      height: 2px;
      opacity: 0.5;
    }
  }
  .MuiTab-root {
    min-width: unset;
  }
  ${(props) => props.modal && `

  `}
`;

export const TabItem = styled(Tab)`
  text-transform: unset;
  color: ${Colors.lightBlack};
  padding: ${(props) => (!props.innerTab ? '0 30px' : '0 10px')};
  p {
    font-size: ${(props) => (!props.innerTab ? '18px' : '15px')};
    position: relative;
  }
  i {
    border-radius: 10px;
    padding: 0 5px;
    background-color: ${Colors.grayLight};
    color: ${Colors.lightBlack};
    line-height: 20px;
    font-size: 15px;
    margin-left: 5px;
    /* position: absolute;
    right: -10px;
    top: -10px; */
    font-size: 12px;
  }
  &.MuiTab-textColorInherit {
    opacity: 0.9;
  }
  &.MuiTab-textColorInherit.Mui-disabled {
    opacity: 0.5;
  }
  &.MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    color: ${Colors.blue};
    /* font-size: 20px;
    font-weight: bold; */
    i {
      background-color: ${Colors.redLight};
      color: ${Colors.blueBlack};
    }
  }
  &.MuiTab-root {
    min-height: ${(props) => (props.innerTab && '30px')};
    max-width: 350px;
    line-height: 1.5;
  }
  ${(props) => props.modal && `
    max-width: 180px;
  `}
  @media (max-width: 425px) {
    padding: ${(props) => (!props.innerTab ? '0 15px' : '0 10px')};
    p {
      font-size: 14px;
    }
  }
`;

export const LimitCard = styled.div`
  width: 100%;
  height: 100%;
  background-color: red;
  background-color: ${(props) => (props.statu === 1 // 1: düşük limit, 0: limit yetersiz, harici: normal
    ? Colors.lightOrange : props.statu === 0
      ? Colors.pink : Colors.blueLight)};
  border-radius: 6px;
  padding: 30px 30px;
  color: ${Colors.navy};
  background-image: url('${fingerprint}');
  background-repeat: no-repeat;
  background-position: right top;
  background-blend-mode: color-burn;
  overflow: hidden;
  h5 {
    font-size: 21px;
  }
  p {
    font-weight: 600;
    font-size: 45px;
    padding-top: 15px;
  }
  > span {
    display: block;
    font-size: 16px;
    padding-top: 10px;
  }
  ${CustomButton} {
    font-weight: 600;
    font-size: 16px;
    margin-top: 25px;
    padding: 10px 15px;
    color: ${Colors.blueBlack2};
    background-color: gold;
    background: rgba(0, 83, 159, 0.2);
    background: rgba(251, 251, 251, 0.3);
    border-radius: 14px;
    display: flex;
    align-items: center;
    width: fit-content;
    svg {
      margin-right: 15px;
    }
  }
  ${CustomIconButton} {
    float: right;
    padding: 0;
    margin: 0;
    height: 40px;
    width: 40px;
  }
`;

export const AccountInformation = styled.div`
  background-color: ${Colors.lightGrey};
  padding: 25px 20px;
  p {
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.lightBlack};
    padding-top: 15px;
    &:first-of-type {
      padding-top: 0;
    }
    span {
      padding-left: 10px;
    }
  }
`;

export const AnnouncementWrapper = styled.div`
  /* max-height: calc(100% - 50px); */
  /* max-height: 376px; */
  max-height: 247px;
  overflow-y: auto;
`;

export const AnnouncementBox = styled.div`
  display: flex;
  margin-top: ${(props) => (props.seeAll ? '20px' : '10px')};
  position: relative;
  padding-bottom:  ${(props) => (props.seeAll ? '20px' : '10px')};
  :first-of-type {
    margin-top: 0;
    ::before {
      content: none;
    }
  }
  ::before {
    content: '';
    position: absolute;
    right: 0;
    top: ${(props) => (props.seeAll ? '-20px' : '-10px')};
    width: ${(props) => (props.seeAll ? '100%' : 'calc(100% - 50px)')};
    height: 1px;
    background-color: ${Colors.lightGrey};
  }
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: rgba(180, 204, 222, 0.3);
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: ${(props) => (props.seeAll ? '20px' : '10px')};
    svg {
    }
  }
  .content {
    color: ${Colors.lightBlack};
    width: calc(100% - 50px);
    .buttonWrapper {
      padding-top: 15px;
      ${CustomButton} + ${CustomButton} {
        margin-left: ${(props) => (props.seeAll ? '20px' : '10px')};
      }
    }
    p {
      font-size: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
    }
    >span {
      display: block;
      font-size: 14px;
      font-weight: 400;
      padding-top: 10px;
      max-height: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      padding-top: ${(props) => (props.seeAll ? '20px' : '10px')};
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    padding-top: 10px;
    color: ${Colors.grey};
  }
`;

export const CustomPopper = styled(Popper)`
  z-index: 10;

  ${AnnouncementWrapper} {
    max-height: 50vh;
    max-width: 500px;
  }
  h2 {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: ${Colors.lightBlack};
    padding-bottom: 25px;
    ${Simplelink} {
      margin-left: auto;
      font-size: 16px;
    }
  }
  .MuiPaper-root {
    border: 1px solid ${hexToRgbA(Colors.grey, 0.5)};
    border-radius: 10px;
    position: relative;
  }
  .MuiPaper-elevation1 {
    box-shadow: 0px 20px 30px rgba(164, 130, 132, 0.3);
    top: 10px;
    /* right: 50px; // temp */
    padding: 30px;
  }
  .triangle {
    position: absolute;
    top: -10px;
    /* right: 60px; */
    right: 5px;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${Colors.white} transparent;
    ::after, ::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 14px;
      left: -5px;
      top: -2px;
      transform: rotate(45deg);
      background-color: ${hexToRgbA(Colors.grey, 0.5)};
    }
    ::before {
      transform: rotate(-45deg);
      left: unset;
      right: -5px;
    }
  }
  @media print {
    display: none;
  }
  @media (max-width: 768px) {
    /* .triangle {
      display: none;
    } */
    ${AnnouncementWrapper} {
      max-width: 300px;
    }
    ${AnnouncementWrapper} {
      ${AnnouncementBox} {
        padding-right: 15px;
        p {
          font-size: 16px;
          padding-bottom: 5px;
        }
      }
    }
  }
`;

export const CustomPopperUser = styled(Popper)`
  z-index: 10;
  ${CustomButton} {
    justify-content: flex-start;
    margin-top: 5px;
    :first-of-type {
      margin-top: 0;
    }
  }
  .MuiPaper-root {
    border: 1px solid ${hexToRgbA(Colors.grey, 0.5)};
    border-radius: 10px;
    position: relative;
  }
  .MuiPaper-elevation1 {
    box-shadow: 0px 20px 30px rgba(164, 130, 132, 0.3);
    top: 10px;
    /* right: 20px; // temp */
    padding: 10px 0;
  }
  .triangle {
    position: absolute;
    top: -10px;
    right: 5px;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${Colors.white} transparent;
    ::after, ::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 14px;
      left: -5px;
      top: -2px;
      transform: rotate(45deg);
      background-color: ${hexToRgbA(Colors.grey, 0.5)};
    }
    ::before {
      transform: rotate(-45deg);
      left: unset;
      right: -5px;
    }
  }
  @media print {
    display: none;
  }
  @media (max-width: 768px) {
    .triangle {
      display: none;
    }
    
  }
`;

export const SimpleTable = styled.table`
  width: 100%;
  background-color: ${(props) => (props.detail ? Colors.white : Colors.lightWhite)};
  border: 1px solid ${Colors.lightGrey};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  overflow: auto;
  border-collapse: collapse;
  * {
    /* text-align: center; */
  }
  thead {
    height: 75px;
    border-bottom: 1px solid ${Colors.lightGrey};
    th {
      /* padding-left: ${(props) => (props.detail ? '15px' : '30px')}; */
      text-align: left;
      white-space: nowrap;
      text-align: center;
      padding: 20px 30px;
    }
  }
  tbody {
    tr {
      /* padding: 20px 0; */
      border-top: 1px solid ${Colors.lightGrey};
      td {
        white-space: nowrap;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-top: 1px solid ${Colors.lightGrey};
        /* padding: 20px 0 20px 30px;
        padding-left: 30px; */
        text-align: center;
        /* padding: ${(props) => (props.detail ? '20px 15px' : '20px 0 20px 30px')}; */
        padding: ${(props) => (props.detail ? '20px 15px' : '20px 30px')};
        &:last-of-type {
          padding-right: 30px;
          padding-right: ${(props) => (props.detail ? '15px' : '30px')};
          box-sizing: content-box;
        }
        span {
          overflow: hidden;
          /* max-width: 200px; */
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }
      }
      ${(props) => (props.detail && `
        &:nth-of-type(odd) {
          background-color: ${Colors.lightWhite};
        }
      `)}
    }
  }
`;

export const OrderCard = styled.div`
  border: ${(props) => (props.detail ? 'none' : `1px solid ${Colors.grey}`)};
  margin-bottom: 20px;
  overflow: hidden;
  .cardHeader {
    display: flex;
    align-items: center;
    padding: ${(props) => (props.detail ? '20px 0' : '20px 35px')};
    background-color: ${(props) => (props.detail ? Colors.white : hexToRgbA(Colors.grey, 0.15))};
    justify-content: ${(props) => (props.detail ? 'space-between' : 'unset')};
    overflow: auto;
    border: ${(props) => (!props.detail ? 'none' : `1px solid ${Colors.grey}`)};
    margin-bottom: ${(props) => (props.detail ? '30px' : '0')};
    p {
      font-size: 18px;
      line-height: 20px;
      color: ${Colors.navy};
      font-weight: bold;
      white-space: nowrap;
      text-align: center;
    }
    > div {
      padding: 0 15px;
    }
    div > span {
      display: block;
      font-size: 18px;
      line-height: 20px;
      color: ${Colors.navy};
      padding-top: 10px;
      white-space: nowrap;
      max-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
    ${CustomButton} {
      margin-left: auto;
      margin-right: 15px;
      min-width: max-content;
    }
  }
  .cardContent {
    position: relative;
    padding: ${(props) => (props.detail ? 0 : '30px')};
    background-color: ${Colors.white};
    overflow: auto;
    border: ${(props) => (!props.detail ? 'none' : `1px solid ${Colors.grey}`)};
    .message {
      position: absolute;
      display: flex;
      align-items: center;
      top: 5px;
      left: 30px;
      max-width: calc(100% - 60px);
      width: fit-content;
      .messageText {
        margin-right: 20px;
        display: flex;
        align-items: center;
        cursor: default;
      }
      svg {
        margin-right: 10px;
      }
      span {
        font-size: 15px;
        line-height: 25px;
        color: ${Colors.brown};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .buttonWrapper {
    display: flex;
    margin-left: auto;
    ${CustomButton} {
      :last-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const ErrorLine = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  /* color: ${Colors.darkGrey}; */
  color: ${Colors.error};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 10px;
  animation: error 1s linear;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0;
  overflow: hidden;
  @keyframes error {
    0% {
      transform: translateX(3px);
      color: ${Colors.white};
      background-color: ${hexToRgbA(Colors.error, 0.5)};
    }
    10% { transform: translateX(-3px) }
    20% {
      transform: translateX(3px);
      color: ${Colors.error};
      background-color: transparent;
    }
    30% { transform: translateX(-3px) }
    40% { transform: translateX(3px) }
    50% { transform: translateX(-3px) }
    60% { transform: translateX(3px) }
    70% { transform: translateX(-3px) }
    80% { transform: translateX(3px) }
    90% { transform: translateX(-3px) }
    100% { transform: translateX(3px) }
  }
`;

export const CustomFadeOrder = styled(Fade)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 50%;
  max-width: 90%;
  max-height: 100vh;
  padding: 35px 40px;
  border-radius: 6px;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    min-width: 70%;
  }
  @media (max-width: 768px) {
    min-width: 90%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${Colors.lightGrey};
    padding-bottom: 10px;
    overflow: hidden;
    h1 {
      font-size: 18px;
      line-height: 22px;
      color: ${Colors.lightBlack};
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    padding: 5px 0;
    overflow: auto;
    overflow-x: hidden;
    flex: 1;
    h4 {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: ${Colors.darkGrey};
      padding-bottom: 10px;
    }
    .MuiInputAdornment-root {
      height: 100%;
      border-left: 1px solid ${Colors.grey};
      padding: 0 5px;
      z-index: 1;
    }
    .info {
      display: flex;
      align-items: baseline;
      width: 100%;
      font-size: 18px;
      padding: 15px 20px;
      margin-top: 10px;
      /* background: ${hexToRgbA(Colors.pink, 0.1)}; */
      background: rgb(255 230 230);
      border: 1px solid ${Colors.blueLight};
      border-radius: 4px;
      color: ${Colors.blue};
      font-weight: 500;
      margin-bottom: 15px;
      flex-wrap: wrap;
      position: sticky;
      top: -20px;
      z-index: 1;
      &.blue {
        /* background: ${hexToRgbA(Colors.blueLight, 0.1)}; */
        background: rgb(226 243 255);
      }
      span {
        font-size: 24px;
        padding-left: 10px;
      }
    }
    .item {
      width: 100%;
      background: ${hexToRgbA(Colors.blueLight, 0.1)};
      border-radius: 4px;
      margin-bottom: 20px;
      padding: 10px;
      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 10px;
        > * {
          width: calc(50% - 5px);
        }
      }
      ${CustomButton} {
        margin: 0;
        float: right;
      }
      .MuiSelect-icon {
        color: rgb(0 0 0 / 0%);
      }
    }
    .addItem {
      width: 100%;
      padding: 20px 0;
    }
    .formWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      /* overflow: auto; */
      max-height: 90vh; // temp
      >* {
        padding: 5px 0;
        width: calc(50% - 10px);
        /* margin: 5px auto; */
      }
      .MuiFormControl-root {
        min-width: 200px;
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      .MuiFormControl-fullWidth {
        width: 100%!important;
      }
    }
    .checkbox {
      padding: 10px;
      span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        color: ${Colors.darkGrey};
        user-select: none;
      }
    }
    ${CustomButton} {
      margin-left: 20px;
      margin-right: 0;
      margin-top: 20px;
    }
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: -10px;
    > * {
      margin: 10px;
    }
    ${CustomButton} {
      /* margin-left: 20px; */
    }
  }
  .detailContent {
    .bottom {
      display: flex;
      flex-wrap: wrap;
      .delivery {
        flex: 1;
        min-width: 300px;
        margin-top: 15px;
        h2 {
          font-size: 20px;
          font-weight: bold;
          white-space: nowrap;
          color: ${Colors.lightBlack};
          padding-bottom: 10px;
        }
        .status {
          display: flex;
          padding: 0 10px;
          .statusBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 50px;
            .svgBox {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              height: 50px;
              border-radius: 100%;
              background-color: ${hexToRgbA(Colors.blueLight, 0.25)};
            }
            p {
              font-size: 14px;
              white-space: nowrap;
              color: ${Colors.lightBlack};
              padding-top: 10px;
            }
          }
          span {
            height: 1px;
            /* width: 100%; */
            flex: 1;
            background-color: ${Colors.grey};
            margin: 25px 15px 0;
          }
        }
      }
    }
    .address {
      border: 1px solid ${Colors.grey};
      padding: 15px;
      margin-left: 15px;
      max-width: 300px;
      margin-top: 15px;
      p {
        font-size: 16px;
        font-weight: bold;
        color: ${Colors.navy};
      }
      span {
        display: block;
        font-size: 14px;
        color: ${Colors.navy};
        padding-top: 10px;
      }
    }
  }
`;

export const CustomDialogContent = styled(DialogContent)`
  padding: 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${Colors.lightGrey};
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${Colors.lightBlack};
      span {
        font-weight: 700;
      }
    }
  }
  .content {
    >* + * {
      margin-top: 15px;
    }
  }
  ${CustomButton} {
    margin-top: 20px;
    font-weight: 600;
    float: right;
    min-width: 100px;
    & + ${CustomButton} {
      margin-left: 20px;
    }
  }
  .buttonWrapper {
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const CustomDrawer = styled(Drawer)`
  display: flex;
  position: relative;
  width: 250px;
  .MuiDrawer-paperAnchorLeft {
    width: 250px;
    left: 0px;
    box-shadow: 21px 13px 17px -1px rgba(53, 63, 102, 0.05);
    background-color: ${Colors.lightGrey};
    border: none;
  }
  /* .footer {
    box-shadow: 0px -17px 21px rgba(53, 63, 102, 0.1);
    position: sticky;
    background: white;
    padding: 25px;
    bottom: 0;
    left: 0;
    .info {
      p {
        font-size: 12px;
        font-weight: 600;
        color: #353F66;
        span {
          color: ${Colors.blue};
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 15px;
      a {
        font-weight: 600;
        color: #353F66;
        margin-left: 15px;
      }
    }
  } */
  @media (max-width: 768px) {
    width: 250px;
    .MuiDrawer-paperAnchorLeft {
      width: 250px;
    }
  }
  @media (max-width: 425px) {
    /* width: 180px;
    .MuiDrawer-paperAnchorLeft {
      width: 180px;
    } */
  }
`;

export const FilteredSidebar = styled.div`
  border-radius: 8px;
  /* min-width: 250px; */
  z-index: 9999;
  .title {
    height: 90px;
    .fixed {
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      justify-content: flex-end;
      z-index: 99;
      width: 250px;
      /* padding: 40px 20px 30px; */
      padding: 20px 20px;
      background-color: ${Colors.lightGrey};
      box-shadow: 0px 6px 20px -10px ${hexToRgbA(Colors.lightGrey, 0.27)};
      ${CustomButton} {
        color: ${Colors.blueBlack2};
        background-color: ${Colors.white};
        font-weight: 600;
        font-size: 16px;
        height: 50px;
        width: 260px;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        /* box-shadow: 0px 24px 34px rgba(145, 205, 255, 0.35); */
        box-shadow: 0px 3px 10px 0px rgb(125, 128, 130, 0.20);
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        }
      }
    }
  }
  .content {
    overflow: auto;
    max-height: calc(100vh - 90px);
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${Colors.blueLight2};
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: rgb(137 204 251 / 20%);
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(0deg);
  }
  .MuiAccordionSummary-expandIcon {
    padding: 0;
    transform: rotate(45deg);
  }
  p {
    font-size: 13px;
    color: ${Colors.blueBlack2};
    font-weight: 600;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  label span {
    font-size: 12px;
    word-break: break-word;
  }
  label + .MuiInput-formControl {
    margin-top: 10px;
  }
  .MuiPaper-root.MuiAccordion-root.Mui-expanded, .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1 {
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid ${hexToRgbA(Colors.blueBlack2, 0.1)};
    background-color: transparent;
    margin: 0 15px;
    padding: 0 10px;
    border-radius: 10px;
  }
  .MuiAccordion-root:before {
    display: none;
    height: 50px;
  }
  .MuiAccordionSummary-root.Mui-expanded, .MuiAccordionSummary-root {
    min-height: auto;
    padding: 0px;
    /* padding-right: 10px; */
  }
  .MuiInputLabel-formControl {
    /* top: 0;
    left: 20px;
    position: absolute;
    color: ${Colors.grayBlackLight};
    transform: translate(0px, 10px) scale(1);
    height: 15px;
    overflow: hidden; */
  }
  .MuiInputBase-input {
    width: unset;
    margin-bottom: 3px;
    /* color: ${Colors.mainBlue}; */
    color: ${Colors.black};
    width: 100%;
  }
  .MuiIconButton-edgeEnd {
    margin-right: 0px;
  }
  .MuiAccordionSummary-content {
    margin: 15px 0;
  }
  .MuiAccordionDetails-root {
    padding: 0;
    flex-direction: column;
    max-height: 245px;
    /* overflow: auto; */
  }
  .MuiPaper-root.MuiAccordion-root.Mui-expanded {
    background-color: ${Colors.white}!important;
    border-bottom: none!important;
    padding-bottom: 10px!important;
    margin: 10px 5px!important;
  }
  @media (max-width: 768px) {
    /* min-width: 250px; */
    .title {
      .fixed {
        width: 250px;
      }
    }
    .MuiPaper-root.MuiAccordion-root.Mui-expanded, .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1 {
      margin: 0 10px;
      padding: 0 10px;
    }
    .MuiPaper-root.MuiAccordion-root.Mui-expanded {
      margin: 10px 10px!important;
    }
    .title {
      height: 70px;
      .fixed {
        padding: 10px;
      }
    }
    p {
      max-width: 180px;
    }
  }
  @media (max-width: 425px) {
    min-width: 180px;
    .title {
      .fixed {
        width: 180px;
      }
    }
    p {
      max-width: 120px;
    }
    .MuiAccordionSummary-content {
      margin: 10px 0;
    }
    .PrivateSwitchBase-root-9 {
      padding: 2px 9px;
    }
    .MuiInputBase-input {
      font-size: 13px;
    }
  }
`;

export const Company = styled.div`
  width: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 50px 10px;
  transition: all 0.5s ease;
  cursor: pointer;
  :after {
    content: '';
    position: absolute;
    right: 0;
    top: 10%;
    height: 80%;
    background-color: ${Colors.grey};
    width: 1px;
    opacity: .5;
  }
  :last-of-type:after {
    display: none;
  }
  .head {
    width: 75px;
    height: 75px;
    background-color: ${Colors.grey};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    transition: all 0.3s ease;
    p {
      margin: 0;
      color: ${Colors.white};
      font-size: 35px;
      letter-spacing: 2px;
    }
  }
  .content {
    p {
      height: 33px;
      overflow: hidden;
      width: 100%;
      font-size: 13px;
    }
  }
  :hover {
    .head {
      background-color: ${Colors.blueBlack2};
    }
  }
`;

export const GodModeHeader = styled.div`
  height: 50px;
  /* width: 100%; */
  padding: 10px 10px 10px 40px;
  width: calc(100% - 250px);
  background-color: ${Colors.white};
  color: ${Colors.brown};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  box-shadow: 5px 0 10px rgba(150,160,175,0.5);
  ${CustomButton} {
    font-weight: 500;
    height: 50px;
    color: ${Colors.brown};
  }
  ::before {
    content: '';
    position: absolute;
    /* background-color: ${Colors.white}; */
    background-color: ${hexToRgbA(Colors.lightOrange, 0.20)};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  @media (max-width: 1500px) {
    width: calc(100% - 50px);
  }
  @media (max-width: 768px) {
    left: 0;
    width: 100vw;
    padding: 10px 10px 10px 10px;
    height: 60px;
    strong {
      font-size: 12px;
    }
  }
`;

export const AnnounceDraftCard = styled.div`
  background: ${Colors.white};
  border: 1px solid rgba(144, 153, 177, 0.3);
  box-shadow: 0px 4px 20px rgba(94, 132, 194, 0.15);
  border-radius: 6px;
  position: relative;
  padding: 25px 20px;
  cursor: pointer;
  margin-top: 15px;
  overflow: hidden;
  user-select: none;
  :first-of-type {
    margin-top: 0;
  }
  ::after {
    content: '';
    position: absolute;
    left: -20px;
    top: 0;
    height: 100%;
    width: 10px;
    border-radius: 6px 0 0 6px;
    transition: all 0.3s ease;
  }
  ${(props) => props.active && `
    ::after {
      background: ${Colors.blue};
      left: 0px;
    }
  `}
  .content {
    /* height: 125px; */
    h5 {
      font-size: 14px;
      line-height: 15px;
      font-weight: 600;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${Colors.navy};
    }
    p {
      max-width: 100%; 
      font-size: 14px;
      line-height: 17px;
      color: ${Colors.darkGrey};
      padding-top: 10px;
    }
  }
`;

export const AnnounceWrapper = styled.div`
  max-height: calc(95vh - 84px);
  overflow: overlay;
  padding:  20px 15px;
  margin: -20px -15px;
  @media (max-width: 600px) {
    max-height: 400px;
  }
`;

export const ChangePasswordWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${hexToRgbA(Colors.black, 0.85)};
  //temp
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  .content {
    background-color: ${Colors.white};
    border-radius: 16px;
    /* padding: 50px; */
    padding: 2vw; // temp
    padding-top: 50px;
    position: relative;
    max-width: 600px;
    >* + * {
      margin-top: 30px;
    }
  }
`;

export const InputButton = styled(IconButton)`
  position: absolute;
  right: 30px;
  padding: 0;
  border-radius: 100%;
  top: 50%;
  width: 30px;
  height: 30px;
  transform: translate(0, -50%);
`;

export const AnnounceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  h2 {
    font-size: 18px;
    color: ${Colors.navy};
  }
  .MuiFormControl-root {
    height: 35px;
  }
`;

export const ServiceFilterButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  > i {
    flex: 1;
    height: 2px;
    background-color: ${Colors.blue};
    margin-left: 10px;
    &:first-of-type {
      margin-left: 0;
      margin-right: 10px;
    }
  }
`;

export const ServiceFilter = styled.div`
  h4 {
    color: ${Colors.dark};
    font-weight: 500;
    margin-bottom: -20px;
  }
  .MuiAccordion-root:before {
    content: none;
  }
  .buttonWrapper {
    display: flex;
    align-items: end;
    justify-content: end;
    button:first-of-type {
      margin-right: 15px;
      margin-left: auto;
    }
  }
`;

export const QuestionCard = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.greyBorder};
  border-radius: 15px;
  padding: 20px 25px;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: ${Colors.dark};
    padding-bottom: 10px;
    span {
      font-weight: 700;
      font-size: 18px;
      color: ${Colors.error};
    }
  }
  .MuiFormGroup-root {
    width: fit-content;
    padding-left: 20px;
  }
  .MuiRadio-root, .MuiCheckbox-root {
    padding: 5px;
  }
`;

export const LogoImage = styled.img`
  max-width:120px;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .6);
`;
