import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Search } from '@material-ui/icons';
import {
  CustomButton,
  CustomTextField,
  PageWrapper,
  SearchBar,
  ServiceFilterButton,
  TabItem,
  TabWrapper,
} from '../assets/styled';
import Table from '../components/Table';
import { tra } from '../commons/utils';
import {
  selectAppConfig,
  selectDashboardAdminData,
  selectReportAdminMonth,
  selectReportAdminOrder,
} from '../store/selectors';
import { appConfig } from '../store/actions';
import { ArrowDown, Excel } from '../assets/icons';
import ServiceFilter from '../components/ServiceFilter';
import { Colors, monthsLong } from '../assets/statics';

// const orderReportold = [
//   {
//     name: 'order_status_name',
//     text: tra('Status'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//   },
//   {
//     name: 'id',
//     text: tra('Order_Number'),
//     filterable: true,
//     editable: false,
//     align: 'center',
//     sortable: true,
//     type: 'text',
//   },
//   {
//     name: 'sap_order_no',
//     text: tra('SAP_Order_Number'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//     customBody: value => (
//       value || '-'
//     ),
//   },
//   {
//     name: 'asd',
//     text: tra('Order_Number'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//     customBody: value => (
//       value || '-'
//     ),
//   },
//   {
//     name: 'customer',
//     text: tra('Customer_Name'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//   },
//   {
//     name: 'product_name',
//     text: tra('Product_Name'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//   },
//   {
//     name: 'delivery_point_name',
//     text: tra('Delivery_Place'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//   },
//   {
//     name: 'total_amount',
//     text: `${tra('Total_Quantity')}(${tra('Ton')})`,
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//     customBody: value => (
//       <NumberFormat
//         value={value}
//         displayType="text"
//         thousandSeparator
//         suffix={` ${tra('Ton')}`}
//         renderText={(value1, props) => <div {...props}>{value1}</div>}
//       />
//     ),
//   },
//   {
//     name: 'tonnage_update_amount',
//     text: tra('Tonnage_Increase_Amount'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//     customBody: value => (
//       <NumberFormat
//         value={value}
//         displayType="text"
//         thousandSeparator
//         suffix={` ${tra('Ton')}`}
//         renderText={(value1, props) => <div {...props}>{value1}</div>}
//       />
//     ),
//   },
//   {
//     name: 'payment_condition_name',
//     text: tra('Payment_Condition'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'text',
//   },
//   {
//     name: 'date',
//     text: tra('Shipping_Date'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'date',
//   },
//   {
//     name: 'createdAt',
//     text: tra('Create_Date'),
//     filterable: true,
//     editable: false,
//     align: 'left',
//     sortable: true,
//     type: 'date',
//   },
// ];
const orderReport = [
  {
    name: 'sap_order_no',
    text: tra('SAP_Order_Number'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      value || '-'
    ),
  },
  {
    name: 'id',
    text: tra('Order_Number'),
    filterable: true,
    editable: false,
    align: 'center',
    sortable: true,
    type: 'text',
  },
  // {
  //   name: 'delivery_point_name',
  //   text: tra('Delivery_Place'),
  //   filterable: true,
  //   editable: false,
  //   align: 'left',
  //   sortable: true,
  //   type: 'text',
  // },
  // {
  //   name: 'tonnage_update_amount',
  //   text: tra('Tonnage_Increase_Amount'),
  //   filterable: true,
  //   editable: false,
  //   align: 'left',
  //   sortable: true,
  //   type: 'text',
  //   customBody: value => (
  //     <NumberFormat
  //       value={value}
  //       displayType="text"
  //       thousandSeparator
  //       suffix={` ${tra('Ton')}`}
  //       renderText={(value1, props) => <div {...props}>{value1}</div>}
  //     />
  //   ),
  // },
  // {
  //   name: 'payment_condition_name',
  //   text: tra('Payment_Condition'),
  //   filterable: true,
  //   editable: false,
  //   align: 'left',
  //   sortable: true,
  //   type: 'text',
  // },
  {
    name: 'date',
    text: tra('Shipping_Date'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'date',
  },
  {
    name: 'customer',
    text: tra('Customer_Name'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'building_sites_name',
    text: tra('Construction_Site_Name'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'product_name',
    text: tra('Product_Name'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'factory_name',
    text: tra('Factory_Description'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'total_amount',
    text: `${tra('Total_Quantity')}(${tra('Ton')})`,
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator
        suffix={` ${tra('Ton')}`}
        renderText={(value1, props) => <div {...props}>{value1}</div>}
      />
    ),
  },
  {
    name: 'created_user_name',
    text: tra('Order_Creator'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'order_status_name',
    text: tra('Status'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'message',
    text: tra('Message'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      value || '-'
    ),
  },
];

const monthlyReport = [
  {
    name: 'customer_name',
    text: tra('Customer_Name'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'sap_customer_no',
    text: tra('Customer_No'),
    filterable: true,
    editable: false,
    align: 'center',
    sortable: true,
    type: 'text',
  },
];

class ReportAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerTabValue: 0,
      searchFilter: '',
      check: 0,
    };
    this.addColumns();
  }

  innerTabChange = (event, newValue) => {
    this.setState({ innerTabValue: newValue, searchFilter: '' });
    if ((!this.props.reportAdminOrder.length && newValue === 0) || (!this.props.reportAdminMonth.length && newValue === 1)) {
      this.props.appConfig('adminReportExpanded', false);
    } else {
      this.props.appConfig('adminReportExpanded', true);
    }
  }

  addColumns = () => {
    if (monthlyReport.length === 2) { // f0r stop duplicate columns
      for (let index = 0; index < 12; index++) {
        monthlyReport.push({
          name: 'data',
          text: tra(monthsLong?.[index]),
          filterable: false,
          editable: false,
          align: 'center',
          sortable: true,
          type: 'array',
          secondaryColumnsText: `${tra('Piece')} | ${tra('Ton')}`,
          customBody: value => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: `1px solid ${Colors.grey}`,
                borderRadius: '5px',
                padding: '5px',
              }}
            >
              <span style={{ minWidth: 35 }}>{value?.[index]?.count}</span>
              <span
                style={{
                  height: 20, width: 1, backgroundColor: Colors.blue, margin: '0 5px',
                }}
              />
              <span style={{ minWidth: 35 }}>{new Intl.NumberFormat('tr-TR').format(value?.[index]?.total)}</span>
            </div>
          ),
        });
      }
      monthlyReport.push({
        name: 'total_tonnage',
        text: tra('Total'),
        filterable: false,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
        secondaryColumnsText: `${tra('Piece')} | ${tra('Ton')}`,
        customBody: (value, columnName, index, row) => (
          // <NumberFormat
          //   value={value}
          //   displayType="text"
          //   thousandSeparator
          //   suffix={` ${tra('Ton')}`}
          //   renderText={(value1, props) => <div {...props}>{value1}</div>}
          // />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: `1px solid ${Colors.grey}`,
              borderRadius: '5px',
              padding: '5px',
            }}
          >
            <span style={{ minWidth: 35 }}>{row.total_order}</span>
            <span
              style={{
                height: 20, width: 1, backgroundColor: Colors.blue, margin: '0 5px',
              }}
            />
            <span style={{ minWidth: 35 }}>{new Intl.NumberFormat('tr-TR').format(value)}</span>
          </div>
        ),
      });
    }
  };

  render() {
    const { reportAdminOrder, reportAdminMonth, appConfigs } = this.props;
    const { innerTabValue, searchFilter } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? ((innerTabValue === 0 ? !!reportAdminOrder : !!reportAdminMonth)
    && (innerTabValue === 0 ? reportAdminOrder : reportAdminMonth)?.filter(item => Object.keys(item).some(key => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : (innerTabValue === 0 ? reportAdminOrder : reportAdminMonth);
    return (
      <PageWrapper>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar style={{ paddingBottom: 10 }}>
              <TabWrapper value={this.state.innerTabValue} innerTab style={{ width: 'unset' }} onChange={(event, newValue) => this.innerTabChange(event, newValue)} aria-label="simple tabs report">
                <TabItem value={0} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Order_Reports')}</p>)} />
                <TabItem value={1} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Monthly_Reports')}</p>)} />
              </TabWrapper>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search')}
                    value={searchFilter}
                    onChange={event => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                {
                  // (innerTabValue === 0 || innerTabValue === 1) && (
                  //   <CustomButton onClick={() => this.setState(prevState => ({ filterDrawer: !prevState.filterDrawer }))}>{tra('Filter')}</CustomButton>
                  // )
                }
              </div>
              <div className="rightButtons">
                <CustomButton
                  lightBlue
                  startIcon={<Excel />}
                  onClick={() => this.setState(prevState => ({ check: prevState.check + 1 }))}
                  disabled={innerTabValue === 0 ? reportAdminOrder.length === 0 : reportAdminMonth.length === 0}
                >
                  {tra('Download_Excel')}
                </CustomButton>
              </div>
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ServiceFilter tabValue={innerTabValue} downloadExcel={this.state.check} />
            <ServiceFilterButton>
              <i style={{ opacity: !appConfigs.adminReportExpanded && 0.3 }} />
              <CustomButton
                lined={!appConfigs.adminReportExpanded}
                navy={appConfigs.adminReportExpanded}
                onClick={() => this.props.appConfig('adminReportExpanded', !appConfigs.adminReportExpanded)}
                startIcon={(
                  <div style={{ transform: !appConfigs.adminReportExpanded && 'rotate(180deg)', transformOrigin: 'center' }}>
                    <ArrowDown color={appConfigs.adminReportExpanded ? Colors.white : Colors.blue} />
                  </div>
                )}
              >
                {tra('Filter')}
              </CustomButton>
              <i style={{ opacity: !appConfigs.adminReportExpanded && 0.3 }} />
            </ServiceFilterButton>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              monthlyReport
              data={filteredData || []}
              columns={innerTabValue === 0 ? orderReport : monthlyReport || []}
              id={innerTabValue === 0 ? 'orderReportTable' : 'monthlyReportTable'}
              options={{
                selectableRows: 'none',
                secondaryColumns: innerTabValue === 1,
                header: {
                  visible: true,
                  columnSetting: true,
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

ReportAdmin.propTypes = {
  reportAdminOrder: PropTypes.array.isRequired,
  reportAdminMonth: PropTypes.array.isRequired,
  appConfigs: PropTypes.object.isRequired,
  appConfig: PropTypes.func.isRequired,
};

// ReportAdmin.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  dashboardAdminData: selectDashboardAdminData(),
  reportAdminOrder: selectReportAdminOrder(),
  reportAdminMonth: selectReportAdminMonth(),
  appConfigs: selectAppConfig(),
});

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators({
      appConfig,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdmin);
