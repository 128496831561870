import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ClickAwayListener, Grid } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { tr } from 'date-fns/locale';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
 addDays, format, isValid, setHours,
} from 'date-fns';
import {
  CustomTextField,
  PageWrapper,
  SearchBar,
  TabWrapper,
  TabItem,
  CustomButton,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  Danger,
  Excel,
} from '../assets/icons';

import { getPayments, getExcel } from '../store/actions';
import { selectPayments } from '../store/selectors';
import { tra } from '../commons/utils';
import CustomFilter from '../components/CustomFilter';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerTabValue: 0,
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      tabString: 'cash',
    };
    this.props.getPayments(
      'cash',
      {
        startDate: format?.(setHours?.(new Date(), 12), 'yyyy-MM-dd'),
        endDate: format?.(setHours?.(addDays(new Date(), 1), 12), 'yyyy-MM-dd'),
      },
    );
  }

  innerTabChange = (event, newValue) => {
    let tabString = null;
    switch (newValue) {
      case 0:
        tabString = 'cash';
        break;
      case 1:
        tabString = 'credit_card';
        break;
      case 2:
        tabString = 'check';
        break;
      case 3:
        tabString = 'dbs';
        break;
      case 4:
        tabString = 'transfer';
        break;
      default:
        tabString = null;
        break;
    }
    this.setState({
      innerTabValue: newValue,
      tabString,
      filter: [],
      searchFilter: '',
    }, () => this.getData());
  }

  getData = () => {
    const { tabString, startDate, endDate } = this.state;
    if (tabString && isValid(startDate) && isValid(endDate)) {
      this.props.getPayments(
        tabString,
        {
          startDate: format?.(setHours?.(startDate, 12), 'yyyy-MM-dd'),
          endDate: format?.(setHours?.(endDate, 12), 'yyyy-MM-dd'),
        },
      );
    }
  }

  render() {
    const {
      searchFilter, innerTabValue, filterDrawer, filter, startDate, endDate,
    } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (this.props.payments && this.props.payments?.filter((item) => Object.keys(item)?.some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : this.props.payments;
    const columns = [
      // {
      //   name: 'view',
      //   text: '',
      //   filterable: false,
      //   editable: false,
      //   align: 'center',
      //   sortable: false,
      //   type: 'text',
      //   customBody: () => (
      //     <Simplelink style={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       alignItems: 'center',
      //       margin: '0 auto',
      //     }}
      //     >
      //       <View />
      //       <span style={{ paddingTop: 5, color: Colors.blue }}>{tra('View')}</span>
      //     </Simplelink>
      //   ),
      // },
      // {
      //   name: 'print',
      //   text: '',
      //   filterable: false,
      //   editable: false,
      //   align: 'center',
      //   sortable: false,
      //   type: 'text',
      //   customBody: () => (
      //     <Simplelink style={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       alignItems: 'center',
      //       margin: '0 auto',
      //     }}
      //     >
      //       <Printer />
      //       <span style={{ paddingTop: 5, color: Colors.burgundy }}>{tra('Print')}</span>
      //     </Simplelink>
      //   ),
      // },
      // {
      //   name: 'dueDateStatu',
      //   text: tra('Type'),
      //   filterable: true,
      //   editable: false,
      //   align: 'center',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => (
      //     title={value === '0' ? tra('Overdue') : value === '1' ? tra('Undue') : 'yanlış value'}>
      //       <div style={{
      //         width: 15,
      //         height: 15,
      //         borderRadius: '100%',
      //         background: value === '0' ? Colors.burgundy : value === '1' ? Colors.mint : 'red',
      //         margin: '0 auto',
      //       }}
      //       />
      //     >
      //   ),
      // },
      {
        name: 'documentNo',
        text: tra('File_No'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        hidden: !(this.state.innerTabValue === 2 || this.state.innerTabValue === 4),
      },
      // {
      //   name: 'privateLedgerFlag',
      //   text: tra('ODK'),
      //   filterable: true,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   hidden: !(this.state.innerTabValue === 2 || this.state.innerTabValue === 3),
      // },
      {
        name: 'documentType',
        text: tra('File_Type'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      {
        name: 'documentDate',
        text: tra('Document_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        hidden: this.state.innerTabValue === 4,
      },
      {
        name: 'dueDate',
        text: tra('Net_Due_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        hidden: this.state.innerTabValue === 0,
      },
      {
        name: 'businessAreaText',
        text: tra('Business_Area'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'creditControlText',
        text: tra('Credit_Control'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'year',
      //   text: tra('Year'),
      //   filterable: true,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      // {
      //   name: 'reference',
      //   text: tra('Referance'),
      //   filterable: true,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      {
        name: 'localAmount',
        text: tra('Total_Amount'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        // customBody: (value) => value && new Intl.NumberFormat('tr-TR').format(value),
        customBody: (value) => value && (
          <span style={{ color: value < 0 && Colors.darkGreen, fontWeight: value < 0 && 700 }}>{new Intl.NumberFormat('tr-TR').format(value)}</span>
        ),
      },
      {
        name: 'localCurrency',
        text: tra('Currency'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'postingDate',
      //   text: tra('Posting_Date'),
      //   filterable: true,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      //   customBody: (value) => value && (new Date(value).toLocaleDateString() || '-'),
      // },
      {
        name: 'reference',
        text: tra('Reference_No'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        hidden: this.state.innerTabValue !== 4,
      },
      {
        name: 'clearingDocument',
        text: tra('Clearing_Document'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        hidden: this.state.innerTabValue !== 4,
      },
      // {
      //   name: 'creditDebitFlag',
      //   text: tra('Debit_Credit_Indicator'),
      //   filterable: true,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'center',
      //   hidden: this.state.innerTabValue !== 4,
      // },
      {
        name: 'text',
        text: tra('Explanation'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
    ];
    const columnsFiltered = columns.filter((column) => !(column.hidden === true));
    // const barData = [
    //   {
    //     months: 'Aralık',
    //     invoice: 400,
    //   },
    //   {
    //     months: 'Ocak',
    //     invoice: 500,
    //   },
    //   {
    //     months: 'Şubat',
    //     invoice: 400,
    //   },
    //   {
    //     months: 'Mart',
    //     invoice: 150,
    //   },
    //   {
    //     months: 'Nisan',
    //     invoice: 500,
    //   },
    //   {
    //     months: 'Mayıs',
    //     invoice: 140,
    //   },
    //   {
    //     months: 'Haziran',
    //     invoice: 200,
    //   },
    //   {
    //     months: 'Temmuz',
    //     invoice: 250,
    //   },
    //   {
    //     months: 'Ağustos',
    //     invoice: 180,
    //   },
    // ];
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={columnsFiltered || []}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <h3>{tra('Expense_Statistics')}</h3> */}
            <h5 style={{ display: 'flex', alignItems: 'center' }}>
              <Danger />
              <span style={{ padding: '10px 0 10px 10px' }}>
                {tra('It_is_for_informational_purposes_only_It_has_no_financial_value')}
              </span>
            </h5>
            {/* <ChartWrapper style={{ boxShadow: 'none', height: 300 }}>
              <BarChart direction="vertical" dataProp={barData} keyProp="invoice" tit={tra('Total_Invoice')} color={hexToRgbA(Colors.mint, 1)} />
            </ChartWrapper> */}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <TabWrapper value={innerTabValue} innerTab style={{ width: 'unset' }} onChange={(event, newValue) => this.innerTabChange(event, newValue)} aria-label="simple tabs example">
                <TabItem value={0} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Cash_Collection')}</p>)} />
                <TabItem value={1} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Credit_Card')}</p>)} />
                <TabItem value={2} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Check_Information')}</p>)} />
                <TabItem value={3} label={(<p style={{ textTransform: 'capitalize' }}>{tra('DDS_Information')}</p>)} />
                <TabItem value={4} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Transfers')}</p>)} />
              </TabWrapper>
              <div className="find" style={{ minWidth: '30%' }}>
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('search_for_Invoice_number_product')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState({ filterDrawer: !filterDrawer })}>{tra('Filter')}</CustomButton>
              </div>
              <div className="rightButtons">
                <CustomButton
                  lightBlue
                  onClick={() => {
                    this.props.getExcel(
                      'payment',
                      format?.(setHours?.(isValid(startDate) ? startDate : new Date(), 12), 'yyyy-MM-dd'),
                      format?.(setHours?.(isValid(endDate) ? endDate : new Date(), 12), 'yyyy-MM-dd'),
                      this.state.tabString,
                    );
                  }}
                  startIcon={<Excel />}
                >
                  {tra('Download_Excel')}
                </CustomButton>
              </div>
              <div className="datePickerWrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                  <KeyboardDatePicker
                    // disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    label={tra('Starting_Date')}
                    value={startDate}
                    onChange={(e) => isValid(e) && this.setState({ startDate: e }, () => this.getData())}
                    autoOk
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    // style={{ paddingTop: 10 }}
                    maxDate={endDate}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
                  <KeyboardDatePicker
                    // disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    label={tra('End_Date')}
                    value={endDate}
                    onChange={(e) => isValid(e) && this.setState({ endDate: e }, () => this.getData())}
                    autoOk
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    // style={{ paddingTop: 10 }}
                    minDate={startDate}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              columns={columnsFiltered || []}
              id="InvoiceTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

Payment.propTypes = {
  getPayments: PropTypes.func.isRequired,
  payments: PropTypes.array.isRequired,
  getExcel: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  payments: selectPayments(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getPayments,
      getExcel,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
