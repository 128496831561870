import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, InputAdornment, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomFade,
  CustomButton,
  CustomIconButton,
  ErrorLine,
  TabItem,
  TabWrapper,
} from '../../assets/styled';
import Input from '../Input';
import GenerateModal from './Info';
import { tra } from '../../commons/utils';

export default class CompanySetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerTabValue: 0,
      timeRange: null,
      questionModal: null,
      insufficientLimit: null,
      lowLimit: null,
      senderName: null,
      senderAddress: null,
      smtpPort: null,
      smtpServer: null,
      kvkkValidity: null,
      kvkkText: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rowData?.time_range !== prevState.timeRange && prevState.timeRange === null) {
      const timeRange = nextProps.rowData?.time_range;
      const lowLimit = nextProps.rowData?.low_limit;
      const insufficientLimit = nextProps.rowData?.insufficient_limit;
      return {
        timeRange,
        lowLimit,
        insufficientLimit,
      };
    }
    if (nextProps.rowData?.from_name && nextProps.rowData?.from_name !== prevState.senderName && prevState.senderName === null) {
      const senderName = nextProps.rowData?.from_name;
      const senderAddress = nextProps.rowData?.from_email;
      const smtpPort = nextProps.rowData?.smtp_port;
      const smtpServer = nextProps.rowData?.smtp_server;
      return {
        senderName,
        senderAddress,
        smtpPort,
        smtpServer,
      };
    }
    if (nextProps.rowData?.kvkk_text !== prevState.kvkkText && prevState.kvkkText === null) {
      const kvkkValidity = nextProps.rowData?.kvkk_validity;
      const kvkkText = nextProps.rowData?.kvkk_text;
      return {
        kvkkValidity,
        kvkkText,
      };
    }
    return null;
  }

  innerTabChange = (event, newValue) => {
    const {
      innerTabValue,
    } = this.state;
    this.setState({ innerTabValue: newValue });
    switch (newValue) {
      case 0:
        // this.setState({ currentData: subusers, currentColumn: userColumns });
        break;
      case 1:
        // this.setState({ currentData: creditControl, currentColumn: creditColumns });
        break;
      case 2:
        // this.setState({ currentData: creditControl, currentColumn: paymentColumns });
        break;
      default:
        // this.setState({ currentData: [], currentColumn: [] });
        break;
    }
  }

  clearAllandClose = () => {
    this.setState({
      questionModal: false,
      timeRange: null,
      insufficientLimit: null,
      lowLimit: null,
    });
    this.props.onClose();
  }

  saveFunc = () => {
    const {
      timeRange, questionModal, insufficientLimit, lowLimit, senderName, senderAddress, smtpPort, smtpServer, kvkkValidity, kvkkText,
    } = this.state;
    this.setState({ errorMessage: null, reRender: Math.random() });
    if (kvkkText?.length > 0 && (timeRange || timeRange === 0) && timeRange <= 24 && (insufficientLimit || insufficientLimit === 0) && (lowLimit || lowLimit === 0) && this.props.rowData?.id && lowLimit >= insufficientLimit) {
      if (questionModal) {
        const data = {
          code_id: this.props.rowData.id,
          time_range: timeRange?.toString(),
          insufficient_limit: insufficientLimit,
          low_limit: lowLimit,
          from_name: senderName,
          from_email: senderAddress,
          smtp_port: smtpPort,
          smtp_server: smtpServer,
          kvkk_validity: kvkkValidity,
          kvkk_text: kvkkText,
        };
        this.props.runService(data);
        this.clearAllandClose();
      } else {
        this.setState({ questionModal: true });
      }
    } else if (timeRange > 24) {
      this.setState({ errorMessage: tra('Enter_a_valid_time_range'), isError: true });
    } else if (!(lowLimit >= insufficientLimit)) {
      this.setState({ errorMessage: tra('The_insufficient_limit_cannot_be_higher_than_the_low_limit'), isError: true });
    } else {
      this.setState({ errorMessage: tra('Please_Fill_in_the_mandatory_fields'), isError: true });
    }
    // this.setState({ questionModal: false });
  }

  close = () => {
    const { timeRange, insufficientLimit, lowLimit } = this.state;
    if (timeRange !== this.props.rowData?.time_range || insufficientLimit !== this.props.rowData?.insufficient_limit || lowLimit !== this.props.rowData?.low_limit) {
      this.setState({ sureModal: true });
    } else {
      this.clearAllandClose();
    }
  }

  render() {
    const { open } = this.props;
    const {
      questionModal, errorMessage, isError, innerTabValue,
    } = this.state;
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.close()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFade in={open}>
            <div>
              <div className="header">
                <h1>{tra('Update_Company_Settings')}</h1>
                <CustomIconButton onClick={() => this.close()}><Close /></CustomIconButton>
              </div>
              <div className="content">
                {errorMessage && errorMessage?.length && (
                  <ErrorLine key={this.state.reRender}>
                    {errorMessage}!
                  </ErrorLine>
                )}
                <TabWrapper value={innerTabValue} innerTab onChange={(event, newValue) => this.innerTabChange(event, newValue)} aria-label="simple tabs example">
                  {
                    <TabItem innerTab value={0} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Order_Settings')}</p>)} />
                  }
                  {
                    <TabItem innerTab value={1} label={(<p style={{ textTransform: 'capitalize' }}>{tra('Mail_Settings')}</p>)} />
                  }
                  {
                    <TabItem innerTab value={2} label={(<p style={{ textTransform: 'capitalize' }}>{tra('KVKK_Settings')}</p>)} />
                  }
                </TabWrapper>
                {
                  innerTabValue === 0 ? (
                    <div className="formWrapper" style={{ width: '100%' }}>
                      {/* <Input
                        type="autoComplete"
                        label={tra('Company_Name')}
                        variant="standard"
                        data={this.props.companyCodes || []}
                        fullWidth
                        value={this.props.rowData || this.state.companyCode}
                        // onChange={(e, value) => this.setState({ companyCode: value })}
                        disabled
                      /> */}
                      {/* <Input
                        type="select"
                        placeholder={tra('Time')}
                        variant="outlined"
                        data={avaibleHours}
                        endAdornment={<InputAdornment position="end"><Waiting color={Colors.grey} /></InputAdornment>}
                        value={k.hour}
                        onChange={(e) => this.changeData(e.target.value, i, 'hour')}
                        autoOk
                        inputProps={{ step: 3600 }}
                      /> */}
                      <Input
                        type="number"
                        label={tra('Time_Range')}
                        variant="standard"
                        fullWidth
                        value={this.state.timeRange}
                        onChange={(e) => this.setState({ timeRange: e.value })}
                        error={isError && !this.state.timeRange}
                      />
                      <Input
                        type="number"
                        label={tra('Insufficient_Limit')}
                        variant="standard"
                        fullWidth
                        value={this.state.insufficientLimit}
                        onChange={(e) => this.setState({ insufficientLimit: e.value })}
                        error={isError && !this.state.insufficientLimit && !(this.state.lowLimit >= this.state.insufficientLimit)}
                      />
                      <Input
                        type="number"
                        label={tra('Low_Limit')}
                        variant="standard"
                        fullWidth
                        value={this.state.lowLimit}
                        onChange={(e) => this.setState({ lowLimit: e.value })}
                        error={isError && !this.state.lowLimit && !(this.state.lowLimit >= this.state.insufficientLimit)}
                      />
                    </div>
                  ) : innerTabValue === 1 ? (
                    <div className="formWrapper" style={{ width: '100%' }}>
                      <Input
                        type="text"
                        label={tra('Email_Sender_Name')}
                        variant="standard"
                        value={this.state.senderName || ''}
                        onChange={(e) => this.setState({ senderName: e.target.value })}
                        error={isError && !this.state.senderName}
                        fullWidth
                      />
                      <Input
                        type="text"
                        label={tra('Email_Sender_Address')}
                        variant="standard"
                        value={this.state.senderAddress || ''}
                        onChange={(e) => this.setState({ senderAddress: e.target.value })}
                        error={isError && !this.state.senderAddress}
                        fullWidth
                      />
                      <Input
                        type="number"
                        label={tra('SMTP_Port')}
                        variant="standard"
                        justNumber
                        fullWidth
                        value={this.state.smtpPort || ''}
                        onChange={(e) => this.setState({ smtpPort: e.value })}
                        error={isError && !this.state.smtpPort}
                      />
                      <Input
                        type="text"
                        label={tra('SMTP_Server')}
                        variant="standard"
                        fullWidth
                        value={this.state.smtpServer || ''}
                        onChange={(e) => this.setState({ smtpServer: e.target.value })}
                        error={isError && !this.state.smtpServer}
                      />
                    </div>
                  ) : innerTabValue === 2 ? (
                    <div className="formWrapper" style={{ width: '100%' }}>
                      <Input
                        type="number"
                        label={tra('KVKK_Validity')}
                        variant="standard"
                        justNumber
                        fullWidth
                        value={this.state.kvkkValidity || ''}
                        onChange={(e) => this.setState({ kvkkValidity: e.value })}
                        error={isError && !this.state.kvkkValidity && !(this.state.kvkkValidity > 0)}
                        endAdornment={<InputAdornment position="end">{tra('Months')}</InputAdornment>}
                      />
                      <Input
                        type="text"
                        label={tra('KVKK_Text')}
                        variant="standard"
                        value={this.state.kvkkText || ''}
                        onChange={(e) => this.setState({ kvkkText: e.target.value })}
                        error={isError && !this.state.kvkkText}
                        fullWidth
                        tooLongText
                        row={10}
                      />
                    </div>
                  ) : ('false content')
                }
                <div style={{ marginLeft: 'auto' }}>
                  <CustomButton onClick={() => this.saveFunc()}>
                    {tra('Save_Edit')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </CustomFade>
        </Modal>
        {
          questionModal && (
            <GenerateModal
              open={questionModal}
              type="Warning"
              title={tra('Update_Company_Settings')}
              text={tra('Changing_company_settings')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.saveFunc()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
        {
          this.state.sureModal && (
            <GenerateModal
              open={this.state.sureModal}
              type="Question"
              title={tra('Attention')}
              text={tra('If_you_exit_without_saving_your_data_will_be_lost')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ sureModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton blue onClick={() => this.clearAllandClose()}>{tra('Continue')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

CompanySetting.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  runService: PropTypes.func.isRequired,
};

CompanySetting.defaultProps = {
};
