import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { hexToRgb } from '@material-ui/core';
import { Colors } from '../../assets/statics';
import { tra } from '../../commons/utils';

// const data = [ // sipariş
//   {
//     months: 'Tem',
//     'hot dog': 86,
//     'hot dogColor': 'hsl(260, 70%, 50%)',
//     order: 110,
//     orderColor: 'hsl(96, 70%, 50%)',
//     sandwich: 136,
//     sandwichColor: 'hsl(128, 70%, 50%)',
//   },
//   {
//     months: 'Haz',
//     'hot dog': 2,
//     'hot dogColor': 'hsl(255, 70%, 50%)',
//     order: 26,
//     orderColor: 'hsl(182, 70%, 50%)',
//     sandwich: 10,
//     sandwichColor: 'hsl(270, 70%, 50%)',
//   },
// ]:

const BarChart = ({
  direction, dataProp, keyProp, totalAmountTitle, titleTooltip, color,
}) => (
  <ResponsiveBar
    data={dataProp}
    keys={[
      keyProp,
    ]}
    indexBy="x"
    margin={{
      top: 0, right: 10, bottom: 20, left: 40,
    }}
    padding={0.3}
    groupMode="grouped"
    layout={direction}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    valueFormat={{ format: ' >-', enabled: false }}
    colors={color}
    // defs={[
    //   {
    //     id: 'dots',
    //     type: 'patternDots',
    //     background: 'inherit',
    //     color: '#38bcb2',
    //     size: 4,
    //     padding: 1,
    //     stagger: true,
    //   },
    //   {
    //     id: 'lines',
    //     type: 'patternLines',
    //     background: 'inherit',
    //     color: '#eed312',
    //     rotation: -45,
    //     lineWidth: 6,
    //     spacing: 10,
    //   },
    // ]}
    // fill={[
    //   {
    //     match: {
    //       id: 'fries',
    //     },
    //     id: 'dots',
    //   },
    //   {
    //     match: {
    //       id: 'sandwich',
    //     },
    //     id: 'lines',
    //   },
    // ]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    enableGridX
    // gridYValues={{'50'}}
    // axisBottom={{
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'Order Amount',
    //   legendPosition: 'middle',
    //   legendOffset: 32,
    // }}
    // axisLeft={{
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'Months',
    //   legendPosition: 'middle',
    //   legendOffset: -40,
    // }}
    enableLabel={false}
    // labelSkipWidth={12}
    // labelSkipHeight={12}
    // labelTextColor="#831616"
    // legends={[
    //   {
    //     dataFrom: 'keys',
    //     anchor: 'bottom-right',
    //     direction: 'column',
    //     justify: false,
    //     translateX: 120,
    //     translateY: 0,
    //     itemsSpacing: 2,
    //     itemWidth: 100,
    //     itemHeight: 20,
    //     itemDirection: 'left-to-right',
    //     itemOpacity: 0.85,
    //     symbolSize: 20,
    //     effects: [
    //       {
    //         on: 'hover',
    //         style: {
    //           itemOpacity: 1,
    //         },
    //       },
    //     ],
    //   },
    // ]}
    markers={[
      {
        axis: 'x',
        value: dataProp?.reduce((a, b) => a + b.y, 0) / dataProp.length,
        // value: 2,
        lineStyle: { stroke: Colors.grey, strokeWidth: 2 },
        legend: `${tra('Avg')} ${(dataProp?.reduce((a, b) => a + b.y, 0) / dataProp.length).toFixed(2)}`,
        legendOrientation: 'vertical',
      },
    ]}
    axisLeft={{ format: value => `${tra(value)}` }}
    tooltip={({ id, value, color }) => (
      <div className="chartTooltip">
        <h6>
          {totalAmountTitle ? `${((value / dataProp.reduce((a, b) => a + b.y, 0)) * 100).toFixed(1)}%` : titleTooltip}
        </h6>
        {totalAmountTitle ? `${value} ${totalAmountTitle}` : `${value} TL`}
      </div>
    )}
  />
);

BarChart.propTypes = {
  dataProp: PropTypes.array,
  direction: PropTypes.string,
  keyProp: PropTypes.string,
  titleTooltip: PropTypes.string,
  totalAmountTitle: PropTypes.string,
  color: PropTypes.any,
};

BarChart.defaultProps = {
  dataProp: [],
  direction: 'horizontal',
  keyProp: 'order',
  totalAmountTitle: '',
  color: hexToRgb(Colors.blueLight, 0.4),
  titleTooltip: '',
};

export default BarChart;
