import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Routes from './commons/routes';
import {
  GlobalStyle,
  Loading,
  GodModeHeader,
  CustomButton,
} from './assets/styled';
import { Colors } from './assets/statics';
import { LogoutFilled } from './assets/icons';
import { tra } from './commons/utils';
import Toast from './commons/Toast';
import SelectCompany from './components/Modals/SelectCompany';
import Info from './components/Modals/Info';
import Kvkk from './components/Modals/Kvkk';
// import Survey from './components/Modals/Survey';

import {
  setToast,
  init,
  appConfig,
  kvkkAprove,
} from './store/actions';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';

export default function App() {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast);
  const activeUser = useSelector((state) => state.activeUser);
  const appConfigs = useSelector((state) => state.appConfig);
  const companies = useSelector((state) => state.companies);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(init());
  }, []);

  const companyModalStatus = () => {
    const comp = localStorage.getItem('company');
    const reduxState = appConfigs?.changeCompany;
    const isActive = companies.some((c) => c.code === comp);
    if (isActive && !appConfigs?.company) {
      dispatch(appConfig('company', companies?.find?.((c) => c.code === localStorage.getItem('company'))?.code));
    }
    if ((!comp || !isActive || reduxState) && window.location.hash !== '#/Login' && (activeUser?.sap_customer_no !== '0000000000' || appConfigs?.godMode)) {
      return true;
    }
    return false;
  };

  const godModeOff = () => {
    localStorage.removeItem('godModeCustomerId');
    localStorage.removeItem('company');
    dispatch(appConfig('godMode', false));
    dispatch(appConfig('godModeCustomer', null));
    dispatch(init());
    setTimeout(() => {
      window.location.href = process.env.REACT_APP_HREF_URL;
    }, 1000);
  };

  return (
    <HashRouter>
      {appConfigs?.loading && <Loading><div className="ring" /></Loading>}
      {appConfigs?.godMode && (
        <GodModeHeader>
          {/* <strong>{tra('CAUTION')} <i>{appConfigs.godModeCustomer?.name}</i> MÜŞTERİSİ İÇİN İŞLEM YAPIYORSUNUZ. ÇIKIK YAPMAK İÇİN <CustomIconButton className="disableRipple" disableRipple onClick={() => this.godModeOff()}>TIKLAYINIZ</CustomIconButton></strong> */}
          <strong>{tra('CAUTION')} {tra('YOU_ARE_TRANSACTING_FOR_CUSTOMER_TO_SIGN_OUT', [appConfigs?.godModeCustomer?.name])}</strong>
          <CustomButton simple className="disableRipple" disableRipple endIcon={<LogoutFilled color={Colors.brown} />} onClick={() => godModeOff()}>{tra('Exit')}</CustomButton>
        </GodModeHeader>
      )}
      <Toast
        isOpen={toast.open}
        message={toast.message}
        handleClose={() => dispatch(setToast(false))}
        variant={toast.variant}
      />
      {
        companyModalStatus() && companies.length !== 0 && (
          <SelectCompany companies={companies} init={() => dispatch(init())} appConfig={(key, value) => dispatch(appConfig(key, value))} />
        )
      }
      {
        appConfigs && appConfigs.infoModal && appConfigs.infoModal.open && (
          <Info
            open={appConfigs.infoModal.open}
            type={appConfigs.infoModal.type}
            title={appConfigs.infoModal.title}
            text={appConfigs.infoModal.text}
            cancelButton={appConfigs.infoModal.cancelButton}
            confirmButton={appConfigs.infoModal.confirmButton}
          />
        )
      }
      <Kvkk
        open={appConfigs?.kvkk && localStorage.getItem('company')}
        onClose={() => {
          dispatch(kvkkAprove(1));
          dispatch(appConfig('kvkk', false));
        }}
        kvkkText={companies?.[0]?.kvkk_text}
      />
      {/* <Survey
        open={(appConfigs?.survey && !appConfigs.godMode)}
        // onClose={() => this.props.appConfig('survey', false)}
      /> */}
      <div className="mainContent">
        <Switch>
          {/* Public Routes - Not require token */}
          <Route path="/Login" exact component={Login} />
          <Route key="/ForgetPassword/:token" path="/ForgetPassword/:token" exact component={ForgetPassword} />
          {/* Private Routes - Require Token */}
          <Routes activeUser={activeUser} appConfig={appConfigs} auth={auth} />
        </Switch>
      </div>
      <GlobalStyle />
    </HashRouter>
  );
}
