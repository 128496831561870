import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  PageWrapper,
  DashboardBox,
  ChartWrapper,
  Widget,
} from '../assets/styled';
import {
  AddUser,
  OrderBasket,
  PaperPlane,
  Profile,
} from '../assets/icons';
import Table from '../components/Table';
// import Bump from '../components/charts/Bump';
import LineChart from '../components/charts/Line';
import BarChart from '../components/charts/Bar';
import { tra } from '../commons/utils';
import { selectDashboardAdminCharts, selectDashboardAdminData, selectDashProductCharts } from '../store/selectors';
import { getDashboardAdmin, getDashboardAdminCharts, getDashboardAdminProductCharts } from '../store/actions';

const lineData = [
  {
    id: '-',
    color: 'hsl(209, 100%, 31%)',
    data: [
      {
        x: 'Jan',
        y: 0,
      },
      {
        x: 'Feb',
        y: 0,
      },
      {
        x: 'Mar',
        y: 0,
      },
      {
        x: 'Apr',
        y: 0,
      },
      {
        x: 'May',
        y: 0,
      },
      {
        x: 'Jun',
        y: 0,
      },
      {
        x: 'Jul',
        y: 0,
      },
      {
        x: 'Aug',
        y: 0,
      },
      {
        x: 'Sep',
        y: 0,
      },
      {
        x: 'Oct',
        y: 0,
      },
      {
        x: 'Nov',
        y: 0,
      },
      {
        x: 'Dec',
        y: 0,
      },
    ],
  },
];

const barData = [
  {
    x: 'Jan',
    y: 0,
  },
  {
    x: 'Feb',
    y: 0,
  },
  {
    x: 'Mar',
    y: 0,
  },
  {
    x: 'Apr',
    y: 0,
  },
  {
    x: 'May',
    y: 0,
  },
  {
    x: 'Jun',
    y: 0,
  },
  {
    x: 'Jul',
    y: 0,
  },
  {
    x: 'Aug',
    y: 0,
  },
  {
    x: 'Sep',
    y: 0,
  },
  {
    x: 'Oct',
    y: 0,
  },
  {
    x: 'Nov',
    y: 0,
  },
  {
    x: 'Dec',
    y: 0,
  },
];

const lastOrders = [
  {
    name: 'sap_order_no',
    text: tra('SAP_Order_Number'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      value || '-'
    ),
  },
  {
    name: 'id',
    text: tra('Order_Number'),
    filterable: true,
    editable: false,
    align: 'center',
    sortable: true,
    type: 'text',
  },
  // {
  //   name: 'delivery_point_name',
  //   text: tra('Delivery_Place'),
  //   filterable: true,
  //   editable: false,
  //   align: 'left',
  //   sortable: true,
  //   type: 'text',
  // },
  // {
  //   name: 'tonnage_update_amount',
  //   text: tra('Tonnage_Increase_Amount'),
  //   filterable: true,
  //   editable: false,
  //   align: 'left',
  //   sortable: true,
  //   type: 'text',
  //   customBody: value => (
  //     <NumberFormat
  //       value={value}
  //       displayType="text"
  //       thousandSeparator
  //       suffix={` ${tra('Ton')}`}
  //       renderText={(value1, props) => <div {...props}>{value1}</div>}
  //     />
  //   ),
  // },
  // {
  //   name: 'payment_condition_name',
  //   text: tra('Payment_Condition'),
  //   filterable: true,
  //   editable: false,
  //   align: 'left',
  //   sortable: true,
  //   type: 'text',
  // },
  {
    name: 'date',
    text: tra('Shipping_Date'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'date',
  },
  {
    name: 'customer',
    text: tra('Customer_Name'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'building_sites_name',
    text: tra('Construction_Site_Name'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'product_name',
    text: tra('Product_Name'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'factory_name',
    text: tra('Factory_Description'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'total_amount',
    text: `${tra('Total_Quantity')}(${tra('Ton')})`,
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator
        suffix={` ${tra('Ton')}`}
        renderText={(value1, props) => <div {...props}>{value1}</div>}
      />
    ),
  },
  {
    name: 'created_user_name',
    text: tra('Order_Creator'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'order_status_name',
    text: tra('Status'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
  },
  {
    name: 'message',
    text: tra('Message'),
    filterable: true,
    editable: false,
    align: 'left',
    sortable: true,
    type: 'text',
    customBody: value => (
      value || '-'
    ),
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.boxes = React.createRef();
    window.addEventListener('resize', this.setSize);
    this.setSize();
    props.getDashboardAdmin();
    props.getDashboardAdminCharts();
    props.getDashboardAdminProductCharts();
  }

  componentDidMount() {
    this.setSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize);
  }

  setSize = () => {
    const { height } = this.state;
    const newHeight = this.boxes?.current?.clientHeight;
    if (height !== newHeight) {
      this.setState({ height: newHeight || 100 });
    }
  }

  render() {
    const { dashboardAdminData, dashboardAdminCharts, dashProductCharts } = this.props;

    return (
      <PageWrapper style={{ paddingBottom: 20 }}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={3} ref={this.boxes}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox>
                  <div className="content">
                    <h3>{dashboardAdminData?.customers?.toString() || '-'}</h3>
                    <p>{tra('Customers')}</p>
                  </div>
                  <div className="icon">
                    <Profile />
                  </div>
                </DashboardBox>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox>
                  <div className="content">
                    <h3>{dashboardAdminData?.total_order?.toString() || '-'}</h3>
                    <p>{tra('Total_Order_Quantity')}</p>
                  </div>
                  <div className="icon">
                    <OrderBasket />
                  </div>
                </DashboardBox>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox>
                  <div className="content">
                    <h3>{dashboardAdminData?.users?.toString() || '-'}</h3>
                    <p>{tra('Users')}</p>
                  </div>
                  <div className="icon">
                    <AddUser />
                  </div>
                </DashboardBox>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <DashboardBox>
                  <div className="content">
                    <h3>{dashboardAdminData?.order_count_today?.toString() || '-'}</h3>
                    <p>{tra('Todays_Orders')}</p>
                  </div>
                  <div className="icon">
                    <PaperPlane />
                  </div>
                </DashboardBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ChartWrapper style={{ height: this.state.height ? this.state.height - 24 : 'auto', borderRadius: '6px' }}>
              <LineChart data={dashProductCharts || lineData} />
            </ChartWrapper>
          </Grid>
          <Grid item lg={9} md={7} sm={12} xs={12}>
            <Table
              data={dashboardAdminData?.last_order || []}
              columns={lastOrders || []}
              id="lastOrdersTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // detailedTable
            />
          </Grid>
          <Grid item lg={3} md={5} sm={12} xs={12}>
            <Widget>
              <h3>{tra('Orders_Report')}</h3>
              {/* <p>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(98425)}</p>
              <span><span>+04%</span> {tra('Compared_to_last_month')}</span> */}
              <ChartWrapper style={{ boxShadow: 'none', height: 300 }}>
                <BarChart dataProp={dashboardAdminCharts?.[0]?.data || barData} keyProp="y" totalAmountTitle={tra('Order')} />
              </ChartWrapper>
            </Widget>
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

Dashboard.propTypes = {
  getDashboardAdmin: PropTypes.func.isRequired,
  dashboardAdminData: PropTypes.object.isRequired,
  getDashboardAdminCharts: PropTypes.func.isRequired,
  dashboardAdminCharts: PropTypes.array.isRequired,
  getDashboardAdminProductCharts: PropTypes.func.isRequired,
  dashProductCharts: PropTypes.array.isRequired,
};

// Dashboard.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  dashboardAdminData: selectDashboardAdminData(),
  dashboardAdminCharts: selectDashboardAdminCharts(),
  dashProductCharts: selectDashProductCharts(),
});

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators({
      getDashboardAdmin,
      getDashboardAdminCharts,
      getDashboardAdminProductCharts,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
