export default {
  Ortak: {
    Filter: 'Filtrele',
    Confirmation: 'Onay',
    The_Employee_Will_Be_Passive_Do_you_confirm: 'Çalışan Pasif Edilecektir. Onaylıyor musunuz?',
    Customer_will_be_inactive_Do_you_confirm: 'Müşteri Pasif Edilecektir. Onaylıyor musunuz?',
    Customer_will_be_active_Do_you_confirm: 'Müşteri Aktif Edilecektir. Onaylıyor musunuz?',
    Cancel: 'Vazgeç',
    Accept: 'Kabul Et',
    Cimsa_Customer_Portal: 'Ferçim Müşteri Portalı',
    Welcome_to_Çimsa_Customer_Portal: 'Ferçim Müşteri Portaline Hoş Geldiniz!',
    Close: 'Kapat',
    write: 'yazınız',
    Search: 'Ara',
    Factory: 'Fabrika',
  },
  Header: {
    Overview: 'Genel Bakış',
    Welcome_to_Cimsa_Admin_Screen: 'Ferçim Yönetici Ekranına Hoşgeldiniz',
    Announcements: 'Duyurular',
    No_Announcement: 'Duyuru Bulunmamaktadır.',
    Settings: 'Ayarlar',
    Change_Company: 'Şirket Değiştir',
  },
  Widgets: {
    Total_Customers: 'Toplam Müşteri',
    Total_Order_Quantity: 'Toplam Sipariş Adedi',
    Orders_in_Delivery: 'Teslimatta Olan Siparişler',
    Compared_to_last_month: 'Geçen aya göre',
  },
  Admin: {
    Create_New_Employee: 'Yeni Çalışan Oluştur',
  },
  Customers: {
    Must_be_created: 'Oluşturulmalı',
    Download_Excel: 'Excel İndir',
    Create_Customer: 'Yeni Müşteri',
  },
  Dash: {
    Overdue_Debt: 'Vadesi Geçmiş Borç',
    Collection_Average_Maturity: 'Tahsilat Ortalama Vade',
    available_Limits: 'Kullanılabilir Limit',
    Todays_Orders: 'Bugünkü Siparişler',
    Piece: 'Adet',
    Day: 'Gün',
    Balance: 'Bakiye',
    Low_Limit: 'Düşük Limit',
    Insufficient_Limit: 'Yetersiz Limit',
    Payment_Information: 'Ödeme Bilgileri',
    Expected_Transactions: 'Beklenilen İşlemler',
    See_All: 'Tümünü Gör',
    Current_Account_Information: 'Cari Hesap Bilgileri',
    Code: 'Kodu',
    Account_Name: 'Hesap Adı',
    Group: 'Grup',
    District: 'İlçe',
    Province: 'İl',
    Recent_Transactions: 'Son İşlemler',
  },
  Login: {
    Password_Reset: 'Şifre Sıfırlama',
    Please_enter_your_email_address_to_process: 'İşlem yapmak için lütfen e-posta adresinizi giriniz.',
    Email: 'Email',
    Send_Reset_Email: 'Sıfırlama E-postası Gönder',
    Please_enter_the_new_password_you_want_to_create: 'Yaratmak istediğiniz yeni şifrenizi lütfen giriniz.',
    Password: 'Şifre',
    Password_Again: 'Şifreyi Tekrarı',
    Save: 'Kaydet',
    I_accept_the_Personal_Data_Protection_Text: 'Kişisel Verilerin Korunması Metni’ni kabul ediyorum.',
    Please_enter_a_valid_email_address: 'Lütfen geçerli bir email adresi giriniz.',
    Your_Password_Does_Not_Match: 'Şifreniz Eşleşmiyor.',
    Your_password_must_be_at_least_8_digits: 'Şifreniz En az 8 Haneli olmalı.',
    Please_log_in_to_the_system_by_entering_the_username_given_to_you: 'Lütfen size verilen kullanıcı adını girerek sisteme giriş yapınız.',
    Enter_the_code_sent_to_your_phone_number_or_email: 'Telefon numaranıza ya da mailinize gönderilen kodu giriniz.',
    Login: 'Giriş Yap',
    Forget_Password: 'Şifreni mi unuttun?',
    Send_Code_Again: 'Kodu Tekrar Gönder',
  },
  Invoices: {
    Expense_Statistics: 'Gider İstatistikleri',
    Total_Invoice: 'Toplam Fatura',
    Guarantee: 'Teminat',
    Hypothec: 'İpotek',
    search_for_Invoice_number_product: 'Fatura no, ürün ara',
    Starting_Date: 'Başlangıç Tarihi',
    End_Date: 'Bitiş Tarihi',
    It_is_for_informational_purposes_only_It_has_no_financial_value: 'Sadece bilgilendirme amaçlıdır. Mali değeri yoktur.',
    Open_Invoices: 'Açık Faturalar',
    Paid: 'Ödenmiş',
    Cash_Collection: 'Nakit Tahsilat',
    Credit_Card: 'Kredi Kartı',
    Check_Information: 'Çek Bilgisi',
    DDS_Information: 'DBS Bilgisi',
    Transfers: 'Virmanlar',
  },
  Order: {
    Order_Now: 'Hemen Sipariş Ver',
    Order_Status: 'Sipariş Durumu',
    Order_Date: 'Sipariş Tarihi',
    Tonnage_Request_Sent: 'Tonaj Talebi Gönderildi',
    Request_Tonnage_Increase: 'Tonaj Artışı Talep Et',
    Order_Detail: 'Sipariş Detayı',
    Repeat_Order: 'Siparişi Tekrarla',
    Cancel_Order: 'Siparişi İptal Et',
    Order_Stage: 'Sipariş Aşaması',
    Order_Ref_ID: 'Sipariş Ref. ID',
    Product: 'Ürün',
    Delivery_Time: 'Teslimat Saati',
    Ton: 'Ton',
    There_is_no_order: 'Sipariş Bulunmamaktadır.',
    Tonnage_Increase: 'Tonaj Artışı',
    Your_tonnage_increase_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm: 'Tonaj artırımı talebiniz satış operasyon ekibine iletilecek. Onaylıyor musunuz?',
    Continue: 'Devam',
    Order_Will_Be_Canceled: 'Sipariş İptali Edilecek!',
    Your_order_cancellation_request_will_be_forwarded_to_the_sales_operation_team_Do_you_confirm: 'Sipariş iptal talebiniz satış operasyon ekibine iletilecek. Onaylıyor musunuz?',
    Approved: 'Onaylandı',
    On_Its_Way: 'Yola Çıktı',
    Was_Delivered: 'Teslim Edildi',
    Delivery_Address: 'Teslim Edilecek Adres',
    Tonnage_Increase_Will_Be_Requested: 'Tonaj Artışı Talep Edilecek.',
  },
  Product: {
    Product_Group: 'Ürün Grubu',
    Product_Type: 'Ürün Tipi',
    Product_Name: 'Ürün Adı',
    Product_Description: 'Ürün Tanımı',
    Change_Product_Description: 'Ürün Tanımı Değiştir',
    Define_Blockage: 'Blokaj Tanımla',
    Search_Product: 'Ürün Ara',
    New_Product: 'Yeni Ürün',
    the_description_to_be_changed: 'ürününün tanımı değiştirilecek',
    Change_Description: 'Tanımı Değiştir',
  },
  SideBar: {
    Order: 'Sipariş',
    Invoices_Screen: 'Faturalar Ekranı',
    Payments_Screen: 'Ödemeler Ekranı',
    Coverage_Information: 'Teminat Bilgileri',
    Add_Sub_User: 'Alt Kullanıcı Ekle',
    Reports: 'Raporlar',
    Orders_Report: 'Siparişler Raporu',
    Tonnage_Report: 'Tonaj Raporu',
    Transaction_Logs_Report: 'İşlem Logları Raporu',
    Cus_Rep_Match_Report: 'M.Temsilci Eşleşme Rap.',
    Payments_Report: 'Ödemeler Raporu',
    Products: 'Ürünler',
    Cement: 'Çimento',
    Ready_Mixed_Concrete: 'Hazır Beton',
    Create_Announcement: 'Duyuru Yarat',
    Management_Screen: 'Yönetim Ekranı',
    Customer_Management: 'Müşteri Yönetimi',
    Çimsa_Admin_Screen: 'Ferçim Yönetici Ekranı',
    y2021_All_Rights_Reserved: '2021 All Rights Reserved',
    Made_with: 'Made with',
    by_MDP_Group: 'by MDP Group',
    Made_with_by_MDP_Group: 'Made with by MDP Group',
  },
  Columns: {
    Order_Number: 'Sipariş No',
    Customer: 'Müşteri',
    Customers: 'Müşteriler',
    Customer_No: 'Müşteri No',
    Date: 'Tarih',
    Quanta: 'Miktar',
    Situation: 'Durum',
    Cancelled: 'İptal Edildi',
    Action: 'Aksiyon',
    Edit: 'Düzenle',
    Manage: 'Yönet',
    Make_Active: 'Aktif Yap',
    Make_Passive: 'Pasif Yap',
    Active: 'Aktif',
    Passive: 'Pasif',
    Authorized_Person: 'Yetkili Kişi',
    Authorized_Phone: 'Yetkili Telefonu',
    Authorized_Email: 'Yetkili Email',
    Contact_Info: 'İletişim Bilgisi',
    Business_Area: 'İş Alanı',
    Sales_Representative: 'Satış Temsilcisi',
    Type: 'Tür',
    Pending: 'Beklemede',
    Amount: 'Tutar',
    Status: 'Durum',
    Pay: 'Öde',
    View: 'Gör',
    Print: 'Yazdır',
    Invoice_No: 'Fatura No',
    Reference: 'Referans',
    Total_Amount: 'Toplam Tutar',
    Currency: 'Para Birimi',
    Invoice_Date: 'Faturalama Tarihi',
    Posting_Date: 'Gönderme Tarihi',
    Explanation: 'Açıklama',
    File_Number: 'Dosya No',
    Credit_Control: 'Kredi Kontrol',
    Payment_Status: 'Ödeme Durumu',
    Due_Date: 'Son Ödeme Tarihi',
    Clearing_Document: 'Denkleştirme Belgesi',
    Document_Type: 'Döküman Tipi',
    Tracking_No: 'Takip No',
    External_Delivery_Id: 'Dış Teslimat No',
    Material: 'Materyal',
    Material_Text: 'Materyal Bilgisi',
    Plaque: 'Plaka',
    Sub_Packaging: 'Ön Paketleme',
    User_Deleted: 'Kullanıcı Silindi',
    User_Type: 'Kullanıcı Tipi',
    User_Name: 'Kullanıcı Adı',
    User_Surname: 'Kullanıcı Soyadı',
    Telephone: 'Telefon',
    Transactions: 'İşlemler',
    Authorize: 'Yetkilendir',
    Add_New_User: 'Yeni Kullanıcı Ekle',
    Product_No: 'Ürün Kodu',
  },
  charts: {
    chart_date: 'Grafik tarihi', // ???
  },
  Table: {
    Delete_Row: 'Satırı Sil',
    Edit_Row: 'Satırı Düzenle',
    End: 'Bitiş',
    found: 'bulundu.',
    Next: 'Sonraki',
    No_Data: 'Veri yok...',
    Prev: 'Önceki',
    Search_Data: 'Veri Ara',
    Save_Row: 'Satırı Kaydet',
    Starting: 'Başlangıç',
    table_pagination: '[var0] dökümandan [var1] ile [var2] arası gösteriliyor',
    Clear: 'Temizle',
    Column_Visiblity: 'Sütun Görünürlüğü',
  },
  Filter: {
    There_were_no_results: 'Sonuç Bulunamadı',
  },
  Modals: {
    Please_Fill_in_the_mandatory_fields: 'Lütfen zorunlu alanları doldurunuz.',
    Select_Business_Areas: 'İş Alanları Seçiniz',
    Select_Product_Code: 'Ürün Kodu Seçiniz',
    Choose_Customer_Number: 'Müşteri No Seçiniz',
    All_Except_Selected_Customers: 'Seçilen Müşteriler Hariç Hepsi',
    Block_Start_Date: 'Blokaj Başlangıç Tarihi',
    Block_Create_Date: 'Blokaj Yaratılma Tarihi',
    Block_End_Date: 'Blokaj Bitiş Tarihi',
    Edit_Blockage: 'Blokajı Düzenle',
    Delete_Blockage: 'Blokajı Kaldır',
    Complete: 'Tamamla',
    Selected_products_will_be_blocked_for_the_Customers_you_have_selected_Do_you_confirm: 'Seçtiğiniz Müşteriler için Seçilen ürünler bloklanacaktır. Onaylıyor musunuz?',
    Enter_hours_and_quantities: 'Saatleri ve miktarları giriniz!',
    Available_Credit: 'Kullanılabilir Kredi',
    Delivery_Method: 'Teslim Şekli',
    Goods_Delivery_Place: 'Malın Teslim Yeri',
    Payment_Condition: 'Ödeme Koşulu',
    Select_the_requested_quantity_and_shipping_date_and_time: 'Talep edilen miktar ve sevkiyat tarihi, saatini seçiniz.',
    Time: 'Saat',
    Add_new_quantity_shipping_time: 'Yeni miktar, sevkiyat saati ekle',
    Select_Time: 'Saat Seç',
    Complete_Order: 'Siparişi Tamamla',
    Check_Credit: 'Krediyi Kontrol Et',
    An_order_will_be_created_for_the_product_you_selected_Do_you_confirm: 'Seçtiğiniz ürün için sipariş yaratılacaktır. Onaylıyor musunuz?',
    Done: 'Tamam',
    Shipping_Date: 'Sevkiyat Tarihi',
    Order_Summary: 'Sipariş Özeti',
    Delivery_1_Product: 'Teslimat, 1 Ürün',
    Building_Site: 'Şantiye',
    Do_you_confirm: 'Onaylıyor musunuz?',
    Tonnage_Increase_Request_Sent: 'Tonaj Artışı Talebi Gönderildi',
    PDPA_Approval: 'KVKK Onayı',
    PDPA_Text: 'KVKK Metni Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc, lorem pharetra, enim pellentesque aliquam. Egestas sagittis, id proin nunc, adipiscing volutpat placerat. Egestas dapibus ultricies lectus integer sit gravida. Enim, mattis ut turpis porttitor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc, lorem pharetra, enim pellentesque aliquam. Egestas sagittis, id proin nunc, adipiscing volutpat placerat. Egestas dapibus ultricies lectus integer sit gravida. Enim, mattis ut turpis porttitor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc, lorem pharetra, enim pellentesque aliquam. Egestas sagittis, id proin nunc, adipiscing volutpat placerat. Egestas dapibus ultricies lectus integer sit gravida. Enim, mattis ut turpis porttitor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc, lorem pharetra, enim pellentesque aliquam. Egestas sagittis, id proin nunc, adipiscing volutpat placerat. Egestas dapibus ultricies lectus integer sit gravida. Enim, mattis ut turpis porttitor.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc, lorem ',
    Candidate_Clarification_Text_on_the_Protection_of_Your_Personal_Data: 'Kişisel Verilerinizin Korunması Hakkında Aday Aydınlatma Metni',
    I_have_read_and_I_approve: 'Okudum ve Onaylıyorum',
    Edit_Customer: 'Müşteri Düzenle',
    SAP_Customer_Number: 'SAP Müşteri Numarası',
    Bring: 'Getir',
    Customer_Name: 'Müşteri Adı',
    Address: 'Adres',
    Authorized_Person_Name: 'Yetkili Kişi Adı',
    Authorized_Person_Surname: 'Yetkili Kişi Soyadı',
    Authorized_Person_Email: 'Yetkili Kişi Mail Adresi',
    Authorized_Person_Telephone: 'Yetkili Kişi Telefon Numarası',
    Customer_Representative: 'Müşteri Temsilcisi',
    Verification_Method: 'Doğrulama Yöntemi',
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    EMAIL_AND_SMS: 'EMAIL VE SMS',
    Add_Customer: 'Müşteriyi Ekle',
    Save_Edit: 'Düzenlemeyi Kaydet',
    Create_New_Customer: 'Yeni Müşteri Yarat',
    Customer_will_be_registered_Do_you_confirm: 'Müşteri Kaydedilecektir. Onaylıyor musunuz?',
    Attention: 'Dikkat!',
    If_you_exit_without_saving_your_data_will_be_lost: 'Kaydetmeden çıkarsanız verileriniz kaybolur.',
    Admin: 'Admin',
    Edit_Employee: 'Çalışanı Düzenle',
    SAP_Username: 'SAP Kullanıcı adı',
    Employee_Name: 'Çalışan Adı',
    Employee_Surame: 'Çalışan Soyadı',
    Employee_Type: 'Çalışan Türü',
    Represented_Customers: 'Temsil Ettiği Müşteriler',
    Employee_will_be_arranged_Do_you_confirm: 'Çalışan Düzenlenecektir. Onaylıyor musunuz?',
    A_New_Employee_Will_Be_Created_Do_you_confirm: 'Yeni Çalışan Oluşturulacaktır. Onaylıyor musunuz?',
    SAP_Product_Number: 'SAP Ürün Numarası',
    A_new_product_will_be_created_Do_you_confirm: 'Yeni ürün oluştulacak. Onaylıyor musunuz?',
    Customer_Admin: 'Müşteri Admini',
    Master_Account_Cannot_Be_Changed: 'Ana Hesap (Değiştirilemez)',
    Edit_Subser: 'Alt Kullanıcı Düzenle',
    Create_Subuser: 'Alt Kullanıcı Ekle',
    Subuser_Type: 'Alt Kullanıcı Tipi',
    Subuser: 'Alt Kullanıcı',
    Deactivate_User: 'Kullanıcıyı Pasif Yap',
    Activate_User: 'Kullanıcıyı Aktif Yap',
    Add_User: 'Kullanıcıyı Ekle',
    Subuser_Will_Be_Active: 'Alt Kullanıcı Aktif Edilecek!',
    Subuser_To_Be_Edited: 'Alt Kullanıcı Düzenlenecek',
    Create_New_Subuser: 'Yeni Alt Kullanıcı Yarat',
    Subuser_will_be_activated_Do_you_confirm: 'Alt Kullanıcı Aktif Edilecek Onaylıyor musunuz?',
    Subuser_Will_Be_Registered_Do_You_Confirm: 'Alt Kullanıcı Kaydedilecektir Onaylıyor musunuz?',
    Subuser_To_Be_Passive_Do_You_Confirm: 'Alt Kullanıcı Pasif Yapılacak Onaylıyor musunuz?',
    Subuser_To_Be_Edited_Do_you_Confirm: 'Alt Kullanıcı Düzenlenecek Onaylıyor musunuz?',
  },
  Saga: {
    Could_not_establish_a_connection_with_the_server: 'Sunucu ile bağlantı kurulamadı!',
    Preparing_for_download: 'Dosya indirme için hazırlanıyor...',
    Customer_Edited: 'Müşteri Düzenlendi',
    Failed_to_Edit_Customer: 'Müşteri Düzenlenemedi',
    Error: 'Hata',
    Successful: 'Başarılı',
    Failed_to_Retrieve_Customer_Information: 'Müşteri Bilgisi Getirilemedi!',
    Failed_to_Retrieve_User_Information: 'Kullanıcı Bilgisi Getirilemedi!',
    Order_Created: 'Sipariş Yaratıldı',
    Your_password_has_been_successfully_sent_to_your_email_Please_check_your_mail_to_login: 'Şifreniz mailinize başarılı bir şekilde gönderildi. Lütfen giriş yapmak için mailinizi kontrol ediniz.',
    Your_Password_Has_Been_Changed_Successfully: 'Şifreniz başarıyla Değiştirildi.',
    There_was_a_problem_during_the_password_process: 'Şifre işlemi esnasında sorun oluştu.',
    User_Created: 'Kullanıcı Yaratıldı',
    Problem_Occurred_While_Creating_User: 'Kullanıcı Yaratılırken Sorun Oluştu',
    User_Edited: 'Kullanıcı Düzenlendi',
    Failed_to_User_Edit: 'Kullanıcı Düzenlenemedi',
    User_Status_Changed: 'Kullanıcı Durumu Değiştirildi',
    Failed_to_User_Status_Changed: 'Kullanıcı Durumu Değiştirilemedi',
    Failed_to_Product_Fetch_from_SAP_Service: 'Ürün SAP Servisinden Getirilemedi',
    An_error_occurred_while_fetching_Credit_Limit: 'Kredi Limiti Getirilirken hata oluştu!',
    An_error_occurred_while_fetching_Blockages_data: 'blokaj Listesi Getirilirken hata oluştu!',
  },
  Utils: {
    now: 'şimdi',
    minute_ago: 'dakika önce',
    hours_ago: 'saat önce',
    day_ago: 'gün önce',
    week_ago: 'hafta önce',
    month_ago: 'ay önce',
    years_ago: 'yıl önce',
    minutes_left: 'dakika kaldı',
    minutes: 'dakika',
    hours: 'saat',
    gün: 'day',
    week: 'hafta',
    month: 'ay',
    years: 'yıl',
    left: 'kaldı',
    Invalid_token: 'Geçersiz token!',
    File_Downloaded_Successfully: 'Dosya Başarılı Bir Şekilde İndirildi',
  },
  new: {
    Blockage_List: 'Blokaj Listesi',
    Exit: 'Çıkış',
    Total: 'Toplam',
    Delivery_Place: 'Teslimat Yeri',
    New_Order: 'Yeni Sipariş',
    Previous_Orders: 'Önceki Siparişler',
    Block_Removed: 'Blokaj Kaldırıldı',
    Failed_to_Block_Removing: 'Blokaj Kaldırılamadı!',
    Order_Creation: 'Sipariş Yaratma',
    Back: 'Geri',
    Remove: 'Kaldır',
    Please_Select_a_File: 'Lütfen Bir Dosya Seçiniz',
    Choose_File: 'Dosya Seç',
    Name_Surname: 'İsim Soyisim',
    Blockage_will_be_delete_Do_you_confirm: 'Blokaj silinecektir. Onaylıyor musunuz?',
    Search_Blockage: 'Blokaj Ara',
    Enter_a_value_greater_than_zero: 'Sıfırdan büyük değer giriniz.',
    You_must_enter_the_amount_first: 'Önce Miktar Girmelisiniz.',
    Please_make_sure_that_the_sum_of_their_tonnages_equals: 'Lütfen tonajlarının toplamının [var0]’e eşit olduğundan emin olunuz.',
    Year: 'Yıl',
    Please_contact_your_customer_representative_to_place_an_order: 'Sipariş verebilmek için müşteri temsilcinizle iletişime geçiniz.',
    Choose_a_Time: 'Saat Seçiniz',
    Please_enter_valid_date: 'Geçerli tarih giriniz.',
    Date_should_not_be_before_minimum_date: 'Tarih, minimum tarihten önce olmamalıdır.',
    Time_not_selected: 'Saat seçilmedi',
    Time_selected: 'Saat seçildi',
    Order_Creator: 'Siparişi Yaratan',
    The_transactions_you_will_do_after_that_will_be_done_on_behalf_of_x_Do_you_confirm: 'Bundan sonra yapacağınız işlemler [var0] adına yapılacaktır. Onaylıyor musunuz?',
    CAUTION: 'DİKKAT!',
    YOU_ARE_TRANSACTING_FOR_CUSTOMER_TO_SIGN_OUT: '[var0] MÜŞTERİSİ İÇİN İŞLEM YAPIYORSUNUZ.',
    CLICK_HERE: 'TIKLAYINIZ',
    You_Cannot_Manage_This_Customer: 'Bu Müşteriyi Yönetemezsiniz.',
    You_Cannot_Manage_This_Customer_Because_It_Is_Inactive_In_order_to_Manage_the_Customer_first_activate_it: 'Bu Müşteri Pasif Durumda Olduğu için Yönetemezsiniz. Müşteriyi Yönetebilmek için önce aktif durumuna getiriniz.',
    Company_Selection: 'Şirket Seçimi',
    Unpaid: 'Ödenmemiş',
    Shipment_Report: 'Sevkiyat Raporu',
    Create_Date: 'Yaratılma Tarihi',
    Material_No: 'Materyal No',
    Material_Name: 'Materyal Adı',
    Unit: 'Ö.B',
    Used_Amount: 'Kullanılan Mik.',
    Delivery_Date: 'Teslimat Tarihi',
    Logs: 'Loglar',
    Message: 'Mesaj',
    Remote_Address: 'Uzak Adres',
    True: 'Doğru',
    False: 'Yanlış',
    Last_5_Orders: 'Son 20 Sipariş',
    Select_Credit_Control: 'Kredi Kontrol Seçiniz',
    Action_Pending_Orders: 'Aksiyon Bekleyen Siparişler',
    Company: 'Şirket',
    User: 'Kullanıcı',
    Please_enter_the_6_digit_code_correctly: 'Lütfen 6 haneli kodu doğru giriniz',
    Transfer_Customers: 'Müşterileri Aktar',
    Replacing_the_agent_representative: 'Temsilci değiştirliyor',
    Represented_customers_will_be_transferred_Do_you_confirm: 'Temsil edilen müşteriler aktarılacak. Onaylıyor musunuz?',
    User_Operations: 'Kullanıcı İşlemleri',
    Credit_Control_No: 'Kredi Kontrol No',
    Change_Credit_Description: 'Kredi Tanımını Değiştir',
    Update_Date: 'Güncelleme Tarihi',
    Company_Not_Selected: 'Şirket Seçilmedi',
    Times_up_Sign_in_again: 'Süre doldu! Tekrar giriş yapınız',
    Credit_Control_Description: 'Kredi Kontrol Tanımı',
    Produced_by_MDP_Group: 'Produced by MDP Group.',
    You_do_not_have_sufficient_credits: 'Yeterli krediniz bulunmamaktadır.',
    Key: 'Key',
    File_No: 'Belge No',
    File_Type: 'Belge Türü',
    File_Description: 'Belge Açıklaması',
    Net_Due_Date: 'Net Vade Tarihi',
    Maturity_Description: 'Vade Tanımı',
    Open_Account: 'Açık Hesap',
    Invoices: 'Faturalar',
    Document_Date: 'Belge Tarihi',
    ODK: 'ÖDK',
    Reference_No: 'Referans No',
    Debit_Credit_Indicator: 'Borç-Alacak Göstergesi',
    Overdue: 'Vadesi Geçmiş',
    Undue: 'Vadesi Gelmemiş',
    Payment_Type: 'Ödeme Tipi',
    Delivery_Type: 'Teslimat Şekli',
    eWayBillNo: 'E-İrsaliye No',
    SAP_delivery_No: 'SAP Teslimat No',
    Construction_Site_Number: 'Şantiye No',
    Construction_Site_Name: 'Şantiye Adı',
    Product_Name: 'Ürün Adı',
    Roles: 'Roller',
    Edit_Roles: 'Rolü Düzenle',
    Create_New_Role: 'Yeni Rol Oluştur',
    New_Roles: 'Yeni Rol',
    Permissions: 'İzinler',
    Cimsa: 'Ferçim',
    No_Role: 'Rol Yok',
    Select_All: 'Tümünü Seç',
    Name: 'İsim',
    Please_select_a_credit_control_area_with_a_customer: 'Müşterisi olan kredi kontrol alanı seçiniz!',
    To_edit_the_passive_user_you_need_to_activate_it: 'Pasif kullanıcıyı düzenlemek için aktif etmeniz gerekiyor.',
    The_role_is_being_created: 'Rol oluşturuluyor.',
    Creating_a_new_role_Do_you_confirm: 'Yeni rol oluşturuluyor. Onaylıyor musunuz?',
    The_role_is_being_edited: 'Rol düzenleniyor',
    Editing_the_role_Do_you_confirm: 'Rol düzenleniyor. Onaylıyor musunuz?',
    Choose_Permission: 'İzin seç',
    Invoice_Description: 'Fatura Tanımı',
    Orders_Opened_from_the_Portal: 'Portalden Açılan Siparişler',
    Pump_Plaque: 'Pompa Plakası',
    There_is_no_invoice: 'Fatura bulunamadı.',
    Factory_Description: 'Fabrika Tanımı',
    Change_Fabric_Description: 'Fabrika Tanımını Değiştir',
    Title: 'Başlık',
    Text: 'Metin',
    Last: 'Son',
    Order_Cancellation: 'Sipariş İptali',
    Tonnage_Update: 'Tonaj Güncellemesi',
    Order_Transactions: 'Sipariş İşlemleri',
    Total_Quantity: 'Toplam Miktar',
    Reject: 'Reddet',
    Approve: 'Onayla',
    An_error_has_occurred: 'Bir hata meydana geldi',
    The_order_update_request_will_be_approved_Do_you_confirm: 'Sipariş güncelleme isteği onaylanacaktır. Onaylıyor musunuz?',
    Order_update_request_will_be_rejected_Do_you_confirm: 'Sipariş güncelleme isteği reddedilecektir. Onaylıyor musunuz?',
    Rejection: 'Reddetme',
    Company_Codes: 'Şirket Kodları',
    Company_Code: 'Şirket Kodu',
    Limit_Control: 'Limit Kontrolü',
    Company_Name: 'Şirket Adı',
    Not_Defined: 'Tanımlı Değil',
    Confirmation_Code: 'Onay Kodu',
    Change_Defined_Credits: 'Tanımlı Kredileri Değiştir',
    Defining_Credit_Control_to_the_Company: 'Şirkete Kredi Kontrol Tanımlama',
    Changing_credit_controls_defined_for_the_company: 'Şirkete tanımlanmış kredi kontrolleri değiştiriliyor',
    Send: 'Gönder',
    Draft: 'Taslak',
    Announcement_will_be_published_Do_you_confirm: 'Duyuru yayınlanacaktır. Onaylıyor musunuz?',
    Check_limit: 'Limit kontrolü yap',
    Do_not_Check_limit: 'Limit kontrolü yapma',
    Time_Range: 'Saat Aralığı',
    Update_Company_Settings: 'Şirket Ayarlarını Güncelle',
    Enter_a_valid_time_range: 'Geçerli bir zaman aralığı giriniz.',
    The_insufficient_limit_cannot_be_higher_than_the_low_limit: 'Yetersiz limit düşük limitten yüksek olamaz.',
    Payment_Terms: 'Ödeme Koşulları',
    Update_from_SAP: "SAP'den Güncelle",
    Payment_Term_No: 'Ödeme Koşulu No',
    Updated_from_SAP: "SAP'den güncellendi",
    Tonnage_Increase_Amount: 'Tonaj Artırım Mik.',
    Tonnage_Increase_Request: 'Tonaj Artırımı Talebi',
    Repeat: 'Tekrarla',
    Repeat_Last_Order: 'Son Siparişi Tekrarla',
    File_Downloaded_Successfully: 'Dosya Başarıyla İndirildi',
    All_Users: 'Tüm Kullanıcılar',
    KVKK_End_Date: 'KVKK Bitiş Tarihi',
    Role: 'Rol',
    Announcement_has_been_published: 'Duyuru yayınlandı',
    Last_Announcement_Drafts: 'Son Duyuru Taslakları',
    Please_chance_password_by_entering_the_password_given_to_you: 'Lütfen size verilen şifreyi girerek şifrenizi değiştirin.',
    New_Password: 'Yeni Şifre',
    New_Password_Again: 'Yeni Şifre(Tekrar)',
    New_passwords_are_not_the_same: 'Yeni şifreler aynı değil',
    Password_must_be_between_6_32_characters_long: 'Şifre 8-32 karakter uzunluğu arasında olmalı',
    The_password_will_change_Do_you_confirm: 'Şifre değiştirilercek. Onaylıyor musunuz?',
    Open: 'Aç',
    Previous_Announcements: 'Geçmiş Duyurular',
    Drafts: 'Taslaklar',
    Publish: 'Yayınla',
    Template: 'Şablon',
    Templates: 'Şablonlar',
    The_template_will_be_edit_Do_you_confirm: 'Şablon düzenlenecek. Onaylıyor musunuz?',
    It_will_be_saved_as_a_template_Do_you_confirm: 'Şablon olarak kaydedilecek. Onaylıyor musunuz?',

    Today_Debt: 'Bugünkü Ödenmesi Gereken',
    KVKK_Validity: 'KVKK Geçerliliği',
    KVKK_Text: 'KVKK Metni',
    Email_Sender_Name: 'Mail Gönderici İsmi',
    Email_Sender_Address: 'Mail Gönderici Adresi',
    SMTP_Port: 'SMTP Portu',
    SMTP_Server: 'SMTP Sunucusu',
    Avaible: 'Mevcut',
    Order_Settings: 'Sipariş Ayarları',
    Mail_Settings: 'E-mail Ayarları',
    KVKK_Settings: 'KVKK Ayarları',
    Make_Payment: 'Ödeme Yap',
    The_Settings_Updated: 'Ayarlar Güncellendi',
    Months: 'Ay',
    Changing_company_settings: 'Şirkete ait ayarlar değiştiriliyor',
    Explicit_Consent_Statement_Form: 'Açık Rıza Beyan Formu',
    Total_Debt: 'Toplam Borç',
    updating: 'güncelleniyor.',
    Password_must_be_between_8_32_characters_long: 'Şifre 8 ila 32 karakter sayısı arasında olmalı',
    Welcome_to_Cimsa_Customer_Screen: 'Ferçim Müşteri Ekranına Hoş Geldiniz',
    SAP_Order_Number: 'SAP Sipariş No',
    Click_to_add: 'Eklemek için tıklayınız',
    Click_here_to_download_the_user_manual_of_the_Customer_Portal: 'Müşteri Portalinin kullanım kılavuzunu indirmek için',
    click_here: 'tıklayınız.',
    manual_link: 'https://www.fercim.com.tr/bayi/BAYİ_Portal_TR.pdf',
    Please_Create_a_New_8_character_Password: 'Lütfen 8 karakterli Yeni Şifre Oluşturun.',
    Shipping_cost_not_found_Contact_your_customer_representative: 'Nakliye bedeli bulunamadı. Müşteri temsilciniz ile görüşün.',
  },
  MonthsData: {
    Jan: 'Ock',
    Feb: 'Şbt',
    Mar: 'Mrt',
    Apr: 'Nsn',
    May: 'Mys',
    Jun: 'Haz',
    Jul: 'Tem',
    Aug: 'Agu',
    Sep: 'Eyl',
    Oct: 'Ekm',
    Nov: 'Ksm',
    Dec: 'Arl',
    January: 'Ocak',
    February: 'Şubat',
    March: 'Mart',
    April: 'Nisan',
    Mayy: 'Mayıs',
    June: 'Haziran',
    July: 'Temmuz',
    August: 'Ağustos',
    September: 'Eylül',
    October: 'Ekim',
    November: 'Kasım',
    December: 'Aralık',
  },
  DashboardCharts: {
    Avg: 'Ort.',
    Users: 'Kullanıcılar',
    Log: 'Log',
  },
  AdminReports: {
    Monthly_Reports: 'Aylık Raporlar',
    Order_Reports: 'Sipariş Raporları',
    Please_Choose: 'Lütfen Seçiniz',
    Select_Year: 'Yıl Seçiniz',
    Choose_BuildingSite: 'Şantiye Seçiniz',
  },
};
