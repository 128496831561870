import { createSelector } from 'reselect';

const domain = (state) => state;

export const selectToast = () => createSelector(
  domain,
  (substate) => substate.toast,
);

export const selectActiveUser = () => createSelector(
  domain,
  (substate) => substate.activeUser,
);

export const selectAppConfig = () => createSelector(
  domain,
  (substate) => substate.appConfig,
);

export const selectCustomers = () => createSelector(
  domain,
  (substate) => substate.customers,
);

export const selectProducts = () => createSelector(
  domain,
  (substate) => substate.products,
);

export const selectSapCustomer = () => createSelector(
  domain,
  (substate) => substate.sapCustomer,
);

export const selectRepresentatives = () => createSelector(
  domain,
  (substate) => substate.representatives,
);

export const selectFactories = () => createSelector(
  domain,
  (substate) => substate.factories,
);

export const selectWorkAreas = () => createSelector(
  domain,
  (substate) => substate.workAreas,
);

export const selectDeliveryPoints = () => createSelector(
  domain,
  (substate) => substate.deliveryPoints,
);

export const selectAuth = () => createSelector(
  domain,
  (substate) => substate.auth,
);

export const selectInitCompanyCodes = () => createSelector(
  domain,
  (substate) => substate.initCompanyCodes,
);

export const selectProductGroups = () => createSelector(
  domain,
  (substate) => substate.productGroups,
);

export const selectCreditControlAreas = () => createSelector(
  domain,
  (substate) => substate.creditControlAreas,
);

export const selectBuildingSites = () => createSelector(
  domain,
  (substate) => substate.buildingSites,
);

export const selectAllBuildingSites = () => createSelector(
  domain,
  (substate) => substate.allBuildingSites,
);

export const selectShowPayment = () => createSelector(
  domain,
  (substate) => substate.showPayment,
);

export const selectOrders = () => createSelector(
  domain,
  (substate) => substate.orders,
);

export const selectOrderDetail = () => createSelector(
  domain,
  (substate) => substate.orderDetail,
);

export const selectSubusers = () => createSelector(
  domain,
  (substate) => substate.subusers,
);

export const selectSubuserDetail = () => createSelector(
  domain,
  (substate) => substate.subuserDetail,
);

export const selectSapUser = () => createSelector(
  domain,
  (substate) => substate.sapUser,
);

export const selectCustomerDetail = () => createSelector(
  domain,
  (substate) => substate.customerDetail,
);

export const selectOpenInvoices = () => createSelector(
  domain,
  (substate) => substate.openInvoices,
);

export const selectInvoices = () => createSelector(
  domain,
  (substate) => substate.invoices,
);

export const selectPayments = () => createSelector(
  domain,
  (substate) => substate.payments,
);

export const selectWarnings = () => createSelector(
  domain,
  (substate) => substate.warnings,
);

export const selectOrderProduct = () => createSelector(
  domain,
  (substate) => substate.orderProduct,
);

export const selectReports = () => createSelector(
  domain,
  (substate) => substate.reports,
);

export const selectReportsOrder = () => createSelector(
  domain,
  (substate) => substate.reportsOrder,
);

export const selectSapProduct = () => createSelector(
  domain,
  (substate) => substate.sapProduct,
);

export const selectCreditLimit = () => createSelector(
  domain,
  (substate) => substate.creditLimit,
);

export const selectDeliveries = () => createSelector(
  domain,
  (substate) => substate.deliveries,
);

export const selectCompanies = () => createSelector(
  domain,
  (substate) => substate.companies,
);

export const selectBlockagesList = () => createSelector(
  domain,
  (substate) => substate.blockagesList,
);

export const selectShippingCost = () => createSelector(
  domain,
  (substate) => substate.shippingCost,
);

export const selectAllProductGroup = () => createSelector(
  domain,
  (substate) => substate.allProductGroup,
);

export const selectLog = () => createSelector(
  domain,
  (substate) => substate.log,
);

export const selectNotSapOrder = () => createSelector(
  domain,
  (substate) => substate.notSapOrder,
);

export const selectCustomerByCredit = () => createSelector(
  domain,
  (substate) => substate.customerByCredit,
);

export const selectUserRoleByType = () => createSelector(
  domain,
  (substate) => substate.userRoleByType,
);

export const selectAllRoles = () => createSelector(
  domain,
  (substate) => substate.allRoles,
);

export const selectAllPermissions = () => createSelector(
  domain,
  (substate) => substate.allPermissions,
);

export const selectDashboardData = () => createSelector(
  domain,
  (substate) => substate.dashboardData,
);

export const selectAnnouncements = () => createSelector(
  domain,
  (substate) => substate.announcements,
);

export const selectDashboardChart = () => createSelector(
  domain,
  (substate) => substate.dashboardChart,
);

export const selectTonnageUpdates = () => createSelector(
  domain,
  (substate) => substate.tonnageUpdates,
);

export const selectCreditControl = () => createSelector(
  domain,
  (substate) => substate.creditControl,
);

export const selectCompanyCodes = () => createSelector(
  domain,
  (substate) => substate.companyCodes,
);

export const selectPaymentTerms = () => createSelector(
  domain,
  (substate) => substate.paymentTerms,
);

export const selectDashboardByCredit = () => createSelector(
  domain,
  (substate) => substate.dashboardByCredit,
);

export const selectAllUsers = () => createSelector(
  domain,
  (substate) => substate.allUsers,
);

export const selectServiceConfig = () => createSelector(
  domain,
  (substate) => substate.serviceConfig,
);

export const selectDashboardAdminData = () => createSelector(
  domain,
  (substate) => substate.dashboardAdminData,
);

export const selectDashboardAdminCharts = () => createSelector(
  domain,
  (substate) => substate.dashboardAdminCharts,
);

export const selectDashProductCharts = () => createSelector(
  domain,
  (substate) => substate.dashProductCharts,
);

export const selectReportAdminOrder = () => createSelector(
  domain,
  (substate) => substate.reportAdminOrder,
);

export const selectReportAdminMonth = () => createSelector(
  domain,
  (substate) => substate.reportAdminMonth,
);

export const selectReportAdminBuildingSites = () => createSelector(
  domain,
  (substate) => substate.reportAdminBuildingSites,
);
