import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ClickAwayListener, Grid, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { format, setHours } from 'date-fns';
import {
  CustomTextField,
  PageWrapper,
  SearchBar,

  CustomButton,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  Danger,
  Excel,
} from '../assets/icons';
import { tra } from '../commons/utils';
import { getPayments, getExcel } from '../store/actions';
import { selectPayments } from '../store/selectors';
import CustomFilter from '../components/CustomFilter';

class Guarantee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    };
    this.props.getPayments(
      'assurance',
      {
        startDate: format(setHours(new Date(), 12), 'yyyy-MM-dd'),
        endDate: format(setHours(new Date(), 12), 'yyyy-MM-dd'),
      },
    );
  }

  render() {
    const { searchFilter, filterDrawer, filter } = this.state;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (this.props.payments && this.props.payments?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
      && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : this.props.payments;
    const columns = [
      // {
      //   name: 'view',
      //   text: '',
      //   filterable: false,
      //   editable: false,
      //   align: 'center',
      //   sortable: false,
      //   type: 'text',
      //   customBody: () => (
      //     <Simplelink style={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       alignItems: 'center',
      //       margin: '0 auto',
      //     }}
      //     >
      //       <View />
      //       <span style={{ paddingTop: 5, color: Colors.blue }}>{tra('View')}</span>
      //     </Simplelink>
      //   ),
      // },
      // {
      //   name: 'print',
      //   text: '',
      //   filterable: false,
      //   editable: false,
      //   align: 'center',
      //   sortable: false,
      //   type: 'text',
      //   customBody: () => (
      //     <Simplelink style={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       alignItems: 'center',
      //       margin: '0 auto',
      //     }}
      //     >
      //       <Printer />
      //       <span style={{ paddingTop: 5, color: Colors.burgundy }}>{tra('Print')}</span>
      //     </Simplelink>
      //   ),
      // },
      {
        name: 'dueDateStatu',
        text: tra('Type'),
        filterable: true,
        editable: false,
        align: 'center',
        sortable: true,
        type: 'text',
        filterArrayText: [tra('Passive'), tra('Active'), tra('Pending')],
        customBody: (value) => (
          <Tooltip title={value === '1' ? tra('Active') : value === '2' ? tra('Pending') : value === '0' ? tra('Passive') : 'yanlış value'}>
            <div style={{
              width: 15,
              height: 15,
              borderRadius: '100%',
              background: value === '1' ? Colors.blue : value === '2' ? Colors.grey : value === '0' ? Colors.burgundy : 'red',
              margin: '0 auto',
            }}
            />
          </Tooltip>
        ),
      },
      {
        name: 'documentNo',
        text: tra('File_No'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'documentType',
        text: tra('File_Type'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'documentDate',
        text: tra('Document_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => value && (new Date(value).toLocaleDateString() || '-'),
      },
      {
        name: 'dueDate',
        text: tra('Net_Due_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
      },
      {
        name: 'creditControlText',
        text: tra('Credit_Control'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'reference',
        text: tra('Reference'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      {
        name: 'localAmount',
        text: tra('Total_Amount'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
        // customBody: (value) => value && new Intl.NumberFormat('tr-TR').format(value),
        customBody: (value) => value && (
          <span style={{ color: value < 0 ? Colors.darkGreen : Colors.red, fontWeight: value < 0 && 700 }}>{new Intl.NumberFormat('tr-TR').format(value)}</span>
        ),
      },
      {
        name: 'localCurrency',
        text: tra('Currency'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
      // {
      //   name: 'privateLedgerFlag',
      //   text: tra('ODK'),
      //   filterable: true,
      //   editable: false,
      //   align: 'left',
      //   sortable: true,
      //   type: 'text',
      // },
      {
        name: 'postingDate',
        text: tra('Posting_Date'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => value && (new Date(value).toLocaleDateString() || '-'),
      },
      {
        name: 'text',
        text: tra('Explanation'),
        filterable: true,
        editable: false,
        align: 'left',
        sortable: true,
        type: 'text',
      },
    ];
    return (
      <PageWrapper>
        <ClickAwayListener
          mouseEvent="false"
          touchEvent="onTouchStart"
          onClickAway={() => this.setState({ filterDrawer: false })}
        >
          <CustomFilter
            columns={columns || []}
            data={filteredData || []}
            open={filterDrawer}
            onClose={() => this.setState({ filterDrawer: false })}
            onChangeFilter={(filters) => this.setState({ filter: filters })}
            filters={filter}
            deleteFilter={this.state.deleteFilter}
          />
        </ClickAwayListener>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <h3>{tra('Expense_Statistics')}</h3> */}
            <h5 style={{ display: 'flex', alignItems: 'center' }}>
              <Danger />
              <span style={{ padding: '10px 0 10px 10px' }}>
                {tra('It_is_for_informational_purposes_only_It_has_no_financial_value')}
              </span>
            </h5>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <div className="find" style={{ minWidth: '30%' }}>
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('search_for_Invoice_number_product')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton onClick={() => this.setState({ filterDrawer: !filterDrawer })}>{tra('Filter')}</CustomButton>
              </div>
              <div className="rightButtons">
                <CustomButton
                  lightBlue
                  onClick={
                    () => this.props.getExcel(
                      'assurance',
                      format(setHours(this.state.startDate, 12), 'yyyy-MM-dd'),
                      format(setHours(this.state.endDate, 12), 'yyyy-MM-dd'),
                    )
                  }
                  startIcon={<Excel />}
                >
                  {tra('Download_Excel')}
                </CustomButton>
              </div>
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || {}}
              columns={columns || []}
              id="InvoiceTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: filter,
                  onChange: (state, value, text, type) => this.setState({
                    deleteFilter: {
                      state, value, text, type,
                    },
                  }),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
            // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

Guarantee.propTypes = {
  getPayments: PropTypes.func.isRequired,
  payments: PropTypes.array.isRequired,
  getExcel: PropTypes.func.isRequired,
};

// Guarantee.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  payments: selectPayments(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getPayments,
      getExcel,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(Guarantee);
