import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import {
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
  TextField,
  Checkbox,
  RadioGroup,
  Radio,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { tr } from 'date-fns/esm/locale';
// import {
//   CustomFormControl, CustomFileText, CustomMenuItem,
// } from '../assets/styledInput';
import { Close } from '@material-ui/icons';
import {
  CustomButton, CustomFormControl, CustomFileText, CustomMenuItem, InputButton,
} from '../assets/styled';
import { CheckList } from '../assets/icons';
import { tra } from '../commons/utils';
import { Colors } from '../assets/statics';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.hiddenInput = React.createRef();
  }

  render() {
    const {
      type, value, title, onChange, error, row, disabled, variant, labelPlacement, required, label, fullWidth, autoFocus, justNumber, disableCloseOnSelect,
      data, labelKey, valueKey, helperText, placeholder, endAdornment, startAdornment, freeSolo, renderTags, readOnly, decimalScale, isAllowed, selectAll,
      maxDate, minDate, suffix, name, onKeyDown, onFocus, acceptedFiles, onValueChange, emptyItem, defaultVal, multiple, noMinRow, allowNegative, tooLongText,
    } = this.props;
    switch (type) {
      case 'number':
        return (
          <CustomFormControl fullWidth={fullWidth} variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <NumberFormat
              value={value}
              onValueChange={onChange}
              onKeyDown={onKeyDown}
              decimalScale={decimalScale}
              label={label}
              thousandSeparator={!justNumber ? ',' : false}
              allowedDecimalSeparators={['.', ',']}
              decimalSeparator="."
              allowdecimalSeparator={!justNumber}
              allowNegative={allowNegative}
              isAllowed={isAllowed}
              // format="(###) ###-##-##"
              customInput={TextField}
              fullWidth={fullWidth}
              placeholder={placeholder}
              autoFocus={autoFocus}
              // mask="_"
              suffix={` ${suffix}`}
              disabled={disabled}
              error={error}
              helperText={helperText}
              variant={variant || 'outlined'}
              InputProps={{
                endAdornment,
                startAdornment,
                max: 5,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </CustomFormControl>
        );
      case 'numberService':
        return (
          <CustomFormControl fullWidth variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <NumberFormat
              label={label}
              value={value}
              onChange={onChange}
              customInput={TextField}
              fullWidth
              placeholder={placeholder}
              suffix={` ${suffix}`}
              disabled={disabled}
              error={error}
              helperText={helperText}
              variant={variant || 'outlined'}
              InputProps={{
                endAdornment,
                startAdornment,
              }}
            />
          </CustomFormControl>
        );
      case 'tel':
        return (
          <CustomFormControl fullWidth={fullWidth} variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <NumberFormat
              value={value}
              onValueChange={onChange}
              label={label}
              format="(###) ###-##-##"
              customInput={TextField}
              fullWidth={fullWidth}
              placeholder={placeholder}
              mask="_"
              suffix={` ${suffix}`}
              disabled={disabled}
              error={error}
              helperText={helperText}
              variant={variant || 'outlined'}
              InputProps={{
                endAdornment,
                startAdornment,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </CustomFormControl>
        );
      case 'text':
        return (
          <CustomFormControl fullWidth={fullWidth} variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <TextField
              onFocus={onFocus}
              name={name}
              value={value}
              placeholder={placeholder}
              type={type}
              label={label}
              fullWidth={fullWidth}
              onChange={onChange}
              onKeyDown={onKeyDown}
              error={error}
              helperText={helperText}
              rows={!noMinRow && row > 0 && row}
              multiline={row > 0 && true}
              disabled={disabled}
              readOnly={readOnly}
              variant={variant || 'outlined'}
              autoFocus={autoFocus}
              inputProps={{
                endAdornment,
                startAdornment,
                max: 5,
                maxLength: tooLongText ? false : !(noMinRow || row > 0) ? 100 : 255,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </CustomFormControl>
        );
      case 'email':
        return (
          <CustomFormControl fullWidth={fullWidth} variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <TextField
              onFocus={onFocus}
              name={name}
              value={value}
              placeholder={placeholder}
              type={type}
              label={label}
              fullWidth={fullWidth}
              onChange={onChange}
              onKeyDown={onKeyDown}
              error={error}
              helperText={helperText}
              rows={!noMinRow && row > 0 && row}
              multiline={row > 0 && true}
              disabled={disabled}
              readOnly={readOnly}
              variant={variant || 'outlined'}
              autoFocus={autoFocus}
              inputProps={{
                endAdornment,
                startAdornment,
                maxLength: 30,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </CustomFormControl>
        );
      case 'password':
        return (
          <CustomFormControl fullWidth variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <TextField
              name={name}
              value={value}
              placeholder={placeholder}
              type={type}
              fullWidth
              onChange={onChange}
              error={error}
              helperText={helperText}
              rows={row > 0 && row}
              multiline={row > 0 && true}
              disabled={disabled}
              variant={variant || 'outlined'}
              InputProps={{
                endAdornment,
                startAdornment,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </CustomFormControl>
        );
      case 'currency':
        return (
          <CustomFormControl fullWidth variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <NumberFormat
              value={value}
              onChange={onChange}
              onValueChange={onValueChange}
              displayType="input"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              allowNegative={false}
              customInput={TextField}
              fullWidth
              placeholder={placeholder}
              suffix={` ${suffix}`}
              disabled={disabled}
              error={error}
              helperText={helperText}
              variant={variant || 'outlined'}
            />
          </CustomFormControl>
        );
      case 'select':
        return (
          <CustomFormControl fullWidth={fullWidth} variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <TextField
              select
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              fullWidth={fullWidth}
              label={label}
              disabled={disabled}
              variant={variant || 'outlined'}
              error={error}
              helperText={helperText}
              InputLabelProps={{ shrink: true }}
              // InputProps={{
              //   endAdornment,
              //   startAdornment,
              // }}
              // startAdornment={(
              //   <InputAdornment position="start">
              //     <Eye />
              //   </InputAdornment>
              // )}
              InputProps={{
                startAdornment: startAdornment && <InputAdornment position="start" style={{ marginLeft: '8px' }}>{startAdornment}</InputAdornment>,
              }}
            >
              {emptyItem && <MenuItem value="">Empty</MenuItem>}
              {
                data && data.length > 0 && (
                  data.map((item) => (
                    (item.display === undefined || item.display) && (
                      <CustomMenuItem
                        active={(parseInt(item.id, 10) === value) && item.icon}
                        value={valueKey !== '' ? item[valueKey] : item.id}
                        disabled={item.disabled}
                      >
                        {item.icon && item.icon} {labelKey !== '' ? item[labelKey] : item.name ? item.name : item.text}
                        <span style={{
                          padding: '3px 5px', backgroundColor: '#fde8e3', borderRadius: '10px', marginLeft: 5, color: '#8C172D', minWidth: 25, textAlign: 'center', display: !item.count && 'none',
                        }}
                        > {item.count && item.count}
                        </span>
                      </CustomMenuItem>
                    )
                  ))
                )
              }
            </TextField>
          </CustomFormControl>
        );
      case 'date':
        return (
          <CustomFormControl fullWidth variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr} style={{ margin: 0 }}>
              <DatePicker
                autoOk
                renderDay={this.renderDay}
                id="date-picker-dialog"
                inputVariant={variant || 'outlined'}
                format="dd/MM/yyyy"
                fullWidth
                value={value}
                error={error}
                onChange={onChange}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                helperText={helperText}
                InputProps={{
                  endAdornment,
                  startAdornment,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </CustomFormControl>
        );
      case 'switch':
        return (
          <CustomFormControl fullWidth variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <FormControlLabel
              label={placeholder}
              labelPlacement={labelPlacement || 'start'}
              control={(
                <Switch
                  checked={value}
                  onChange={onChange}
                  name="checkedB"
                  color="primary"
                  disabled={disabled}
                />
              )}
            />
          </CustomFormControl>
        );
      case 'autoComplete':
        return (
          <CustomFormControl fullWidth={fullWidth} variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <Autocomplete
              key={title}
              value={value}
              getOptionSelected={(
                option,
                val,
              ) => val.id === option.id}
              options={data?.filter?.((d) => !d.is_removed)}
              freeSolo={freeSolo}
              // defaultValue={defaultVal}
              getOptionLabel={(option) => (option !== undefined ? option?.alternative_name || option?.name : '')}
              onChange={onChange}
              // closeIcon={selectAll && (data?.filter?.((d) => !d.is_removed)?.length === value?.length ? <Close /> : <CheckList />)}
              closeIcon={false}
              multiple={multiple}
              disabled={disabled}
              disableCloseOnSelect={disableCloseOnSelect}
              closeText={tra('Close')}
              openText={tra('Open')}
              clearText=""
              limitTags={2}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder || tra('Click_to_add')}
                  fullWidth={fullWidth}
                  error={error}
                  disabled={disabled}
                  variant={variant || 'outlined'}
                  onFocus={() => onFocus()}
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {
                          multiple && selectAll && data?.length > 0 && (
                            data?.filter?.((d) => !d.is_removed)?.length === value?.length ? (
                              <InputButton disabled={disabled} onClick={(e) => this.props.onChange(e, [])}><Close /></InputButton>
                            ) : (
                              <Tooltip title={tra('Select_All')}>
                                <InputButton disabled={disabled} onClick={(e) => this.props.onChange(e, data?.filter?.((d) => !d.is_removed))}><CheckList color={Colors.darkGrey} /></InputButton>
                              </Tooltip>
                            )
                          )
                        }
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              // InputLabelProps={{ shrink: true }}
              // inputlabelprops={{ shrink: true }}
            />
          </CustomFormControl>
        );
      case 'autoCompleteFree':
        return (
          <CustomFormControl fullWidth variant="outlined">
            <InputLabel shrink>{title}</InputLabel>
            <Autocomplete
              value={value}
              getOptionSelected={(
                option,
                val,
              ) => val === option}
              options={data}
              freeSolo={freeSolo}
              defaultValue={defaultVal}
              getOptionLabel={(option) => (option !== undefined ? option : '')}
              onChange={onChange}
              closeIcon={false}
              multiple
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  fullWidth
                  error={error}
                  disabled={disabled}
                  variant={variant || 'outlined'}
                  required={required}
                />
              )}
              InputLabelProps={{ shrink: true }}
            />
          </CustomFormControl>
        );
      case 'checkbox':
        return (
          <div style={{ width: '100%', display: 'block' }}>
            <FormControlLabel
              disabled={disabled}
              control={(
                <Checkbox
                  checked={this.props.value}
                  onChange={(e) => this.props.onChange(e)}
                  name="checkedB"
                  color="primary"
                />
              )}
              label={this.props.title}
            />
          </div>
        );
      case 'radioButtonGroup':
        return (
          <div style={{ width: '100%', display: 'block' }}>
            <RadioGroup value={this.props.value} onChange={(e) => this.props.onChange(e)}>
              {data.map((choice) => (
                <FormControlLabel
                  control={(
                    <Radio />
                  )}
                  value={choice.aid}
                  label={choice.text}
                  disabled={disabled}
                />
              ))}
            </RadioGroup>
          </div>
        );
      case 'upload':
        return (
          <CustomFormControl fullWidth>
            <InputLabel shrink>{title}</InputLabel>
            <CustomFileText
              name={name}
              value={value}
              placeholder={tra('Please_Select_a_File')}
              type={type}
              fullWidth
              error={error}
              helperText={helperText}
              disabled
              // variant={variant || 'outlined'}
              InputProps={{
                endAdornment: (
                  <>
                    <CustomButton onClick={() => this.hiddenInput.current.click()}>{tra('Choose_File')}</CustomButton>
                    <input accept={acceptedFiles} onChange={onChange} ref={this.hiddenInput} type="file" style={{ display: 'none' }} />
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          </CustomFormControl>
        );
      default:
        return (
          <p>Hatalı Tip ({type})</p>
        );
    }
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
  emptyItem: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  data: PropTypes.array,
  variant: PropTypes.string,
  row: PropTypes.number,
  disabled: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  suffix: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  noMinRow: PropTypes.bool,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  allowNegative: PropTypes.bool,
  isAllowed: PropTypes.any.isRequired,
  justNumber: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  selectAll: PropTypes.bool,
  tooLongText: PropTypes.bool,
};

Input.defaultProps = {
  onChange: () => { },
  onKeyDown: () => { },
  onFocus: () => { },
  onValueChange: () => { },
  emptyItem: false,
  title: '',
  name: '',
  error: false,
  suffix: '',
  variant: 'outlined',
  helperText: '',
  data: [],
  row: 0,
  disabled: false,
  labelKey: '',
  valueKey: '',
  maxDate: {},
  minDate: {},
  placeholder: '',
  startAdornment: null,
  endAdornment: null,
  required: false,
  multiple: false,
  noMinRow: false,
  label: '',
  fullWidth: false,
  readOnly: false,
  autoFocus: false,
  allowNegative: false,
  justNumber: false,
  disableCloseOnSelect: false,
  selectAll: false,
  tooLongText: false,
};

export default Input;
