import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Dialog, Grid, Input, Tooltip, Zoom,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  CustomButton,
  CustomTextField,
  MobileMoreMenu,
  MobileMoreMenuItem,
  PageWrapper,
  SearchBar,
  CustomIconButton,
  CustomDialogContent,
} from '../assets/styled';
import Table from '../components/Table';
import { Colors } from '../assets/statics';
import {
  CrossFilled,
  MobileTripleIcon,
  // PlusButton,
} from '../assets/icons';
import Blockage from '../components/Modals/Blockage';
import Info from '../components/Modals/Info';

import {
  getProducts,
  getCustomers,
  blockageProducts,
  setToast,
  editProduct,
  getBlockages,
  deleteBlockage,
  getCreditControl,
} from '../store/actions';
import {
  selectProducts, selectCustomers, selectWorkAreas, selectBlockagesList, selectCreditControl,
} from '../store/selectors';
import { tra } from '../commons/utils';

class BlockageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeAlternativeName: false,
      openNewProduct: false,
      blockageCodeId: null,
      infoDelete: false,
    };
    this.props.getProducts();
    this.props.getCustomers();
    this.props.getBlockages();
    this.props.getCreditControl();
  }

  render() {
    const {
      searchFilter, openBlockage, blockageCode,
    } = this.state;
    const {
      products, customers, creditControl,
    } = this.props;
    const lowercasedFilter = searchFilter?.length > 0 && searchFilter?.toLocaleUpperCase();
    const filteredData = searchFilter?.length > 0 ? (this.props.blockagesList
    && this.props.blockagesList?.filter((item) => Object.keys(item).some((key) => typeof (item[(key)]) === 'string'
    && item[(key)]?.toLocaleUpperCase()?.includes(lowercasedFilter)))) : this.props.blockagesList;
    const columns = [
      {
        name: 'createdAt',
        text: tra('Block_Create_Date'),
        filterable: true,
        align: 'center',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && parseInt(value, 10) ? new Date(value).toLocaleDateString() : ''),
      },
      {
        name: 'Products',
        text: tra('Product_No'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value.map((c) => (<p>{c.sap_product_no}</p>))} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.sap_product_no} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'Products',
        text: tra('Product_Name'),
        filterable: true,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value.map((c) => (<p>{c.name}</p>))} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.name} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'CreditControlAreas',
        text: tra('Credit_Control'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value?.length !== 1 ? value?.map((c) => (<p>{c.name}</p>)) : ''} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.name} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'Customers',
        text: tra('Customer_No'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'array',
        customBody: (value) => (
          value?.length > 0 ? (
            <Tooltip title={value.map((c) => (<p>{c.name}</p>))} TransitionComponent={Zoom} placement="top">
              <span>{value[0]?.name} {value.length > 1 && <i style={{ fontStyle: 'italic', color: Colors.blueBlack2 }}>+{value.length - 1}</i>}</span>
            </Tooltip>
          ) : (
            '-'
          )
        ),
      },
      {
        name: 'block_start_date',
        text: tra('Block_Start_Date'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && parseInt(value, 10) ? new Date(value).toLocaleDateString() : ''),
      },
      {
        name: 'block_end_date',
        text: tra('Block_End_Date'),
        filterable: false,
        align: 'left',
        sortable: true,
        type: 'date',
        customBody: (value) => (value && parseInt(value, 10) ? new Date(value).toLocaleDateString() : ''),
      },
      {
        name: 'Action',
        text: tra('Action'),
        filterable: false,
        align: 'left',
        sortable: false,
        type: 'action',
        disableSetting: false,
        customBody: (value, columnName, index, row) => (
          <div className="tableButton">
            <CustomIconButton onClick={(e) => this.setState({ [`anchorMobileTable${row.id}`]: e.currentTarget })}>
              <MobileTripleIcon />
            </CustomIconButton>
            {
              this.state[`anchorMobileTable${row.id}`] && (
                <MobileMoreMenu
                  className="mobileMoreMenu"
                  anchorEl={this.state[`anchorMobileTable${row.id}`]}
                  keepMounted
                  open={Boolean(this.state[`anchorMobileTable${row.id}`])}
                  onClose={() => this.setState({ [`anchorMobileTable${row.id}`]: null })}
                  // placement="left"
                  anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                >
                  <MobileMoreMenuItem
                    onClick={() => this.setState({ blockageCodeId: row.id, infoDelete: true, [`anchorMobileTable${row.id}`]: null })}
                  >
                    <CrossFilled color={Colors.grey} />
                    {tra('Delete_Blockage')}
                  </MobileMoreMenuItem>
                </MobileMoreMenu>
              )
            }
          </div>
        ),
      },
    ];
    return (
      <PageWrapper>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchBar>
              <div className="find">
                <div className="inputWrapper">
                  <CustomTextField
                    search
                    fullWidth
                    placeholder={tra('Search_Blockage')}
                    value={searchFilter}
                    onChange={(event) => this.setState({ searchFilter: event.target.value })}
                    variant="outlined"
                  />
                  <Search />
                </div>
                <CustomButton>{tra('Filter')}</CustomButton>
              </div>
              <div className="rightButtons">
                <CustomButton
                  white
                  startIcon={<CrossFilled />}
                  onClick={() => this.setState({ openBlockage: true })}
                >
                  {tra('Define_Blockage')}
                </CustomButton>
              </div>
            </SearchBar>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              data={filteredData || []}
              columns={columns || []}
              id="blockagesTable"
              options={{
                selectableRows: 'none',
                header: {
                  visible: true,
                  columnSetting: true,
                },
                filter: {
                  show: true,
                  filters: [],
                  // onChange: (state, value, text, type) => console.log(state, value, text, type),
                  drawerChange: () => this.setState((prevstate) => ({ filterDrawer: !prevstate.filterDrawer })),
                },
                pagination: {
                  active: true,
                  perPage: 20,
                },
              }}
              // rowClick={(row) => console.log(row)}
            />
          </Grid>
        </Grid>
        {
          openBlockage && (
            <Blockage
              open={openBlockage}
              onClose={() => this.setState({ openBlockage: false, blockageCode: null })}
              products={products}
              customers={customers}
              currentProduct={blockageCode}
              blockageProducts={(data) => this.props.blockageProducts(data)}
              setToast={(msg, variant) => this.props.setToast(true, msg, variant)}
              // workAreas={workAreas}
              creditControls={creditControl}
            />
          )
        }
        <Info
          open={this.state.openInfo}
          onClose={() => this.setState({ openInfo: false })}
        />
        {
          this.state.changeAlternativeName && (
            <Dialog
              open={this.state.changeAlternativeName}
              onClose={() => this.setState({ changeAlternativeName: false })}
              maxWidth="sm"
              fullWidth="true"
              scroll="body"
            >
              <CustomDialogContent>
                <div className="header">
                  <p><span>{this.state.changingName}</span> {tra('the_description_to_be_changed')}</p>
                </div>
                <div className="content">
                  {/* <p>Onaylıyor musunuz?</p> */}
                  <Input
                    type="text"
                    label={tra('Change_Description')}
                    fullWidth
                    value={this.state.alternativeName}
                    onChange={(e) => this.setState({ alternativeName: e.target.value })}
                    autoFocus
                  />
                </div>
                <div className="buttonWrapper">
                  <CustomButton lined onClick={() => this.setState({ changeAlternativeName: false })}>
                    {tra('Cancel')}
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      this.props.editProduct(this.state.productId, { alternative_name: this.state.alternativeName });
                      this.setState({ changeAlternativeName: false });
                    }}
                  >
                    {tra('Save')}
                  </CustomButton>
                </div>
              </CustomDialogContent>
            </Dialog>
          )
        }
        {
          this.state.infoDelete && (
            <Info
              open={this.state.infoDelete}
              type="Question"
              title={tra('Confirmation')}
              text={tra('Blockage_will_be_delete_Do_you_confirm')}
              cancelButton={(
                <CustomButton grey onClick={() => this.setState({ infoDelete: false, blockageCodeId: null })}>
                  {tra('Cancel')}
                </CustomButton>
              )}
              confirmButton={(
                <CustomButton blue onClick={() => { this.setState({ infoDelete: false }); this.props.deleteBlockage(this.state.blockageCodeId); }}>
                  {tra('Accept')}
                </CustomButton>
              )}
            />
          )
        }
      </PageWrapper>
    );
  }
}

BlockageList.propTypes = {
  getProducts: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  // factories: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  blockageProducts: PropTypes.func.isRequired,
  setToast: PropTypes.func.isRequired,
  editProduct: PropTypes.func.isRequired,
  // sapProduct: PropTypes.object.isRequired,
  // getSapProduct: PropTypes.func.isRequired,
  // createProduct: PropTypes.func.isRequired,
  // setSapProduct: PropTypes.func.isRequired,
  workAreas: PropTypes.array.isRequired,
  creditControl: PropTypes.array.isRequired,
  getBlockages: PropTypes.func.isRequired,
  blockagesList: PropTypes.array.isRequired,
  deleteBlockage: PropTypes.func.isRequired,
  getCreditControl: PropTypes.func.isRequired,
};

// BlockageList.defaultProps = {
//   selectLayout: '',
// };

const mapStateToProps = createStructuredSelector({
  products: selectProducts(),
  // factories: selectFactories(),
  customers: selectCustomers(),
  // sapProduct: selectSapProduct(),
  workAreas: selectWorkAreas(),
  creditControl: selectCreditControl(),
  blockagesList: selectBlockagesList(),
});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({
      getProducts,
      getCustomers,
      blockageProducts,
      setToast,
      editProduct,
      // getSapProduct,
      // createProduct,
      // setSapProduct,
      getBlockages,
      deleteBlockage,
      getCreditControl,
    }, dispatch)
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(BlockageList);
