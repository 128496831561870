import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Modal,
  Tooltip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  CustomButton,
  CustomFadeOrder,
  CustomIconButton,
  OrderCard,
  SimpleTable,
} from '../../assets/styled';
import GenerateModal from './Info';
import { orderItemStatus } from '../../assets/statics';
import { tra } from '../../commons/utils';

export default class DetailOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionModal: false,
      successModal: false,
    };
  }
  // 1-beklemede
  // 2-içeri girdi
  // 3-dolumda
  // 4-çıkış yaptı

  // 1 güncel sipariş
  // 2 onay bekleyen
  // 3 ileriye dönük

  render() {
    const { open, orderDetail } = this.props;
    // const status = 2; // sipariş durumu prop gelecek
    // const inceased = true; // tonaj artırımı daha önce talep edildi
    return (
      <>
        <Modal
          open={open}
          onClose={() => this.props.onClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFadeOrder in={open}>
            <div>
              <div className="header">
                <h1>{tra('Order_Detail')}</h1>
                <CustomIconButton onClick={() => this.props.onClose()}><Close /></CustomIconButton>
              </div>
              <div className="detailContent">
                <OrderCard detail>
                  <div className="cardHeader">
                    <div>
                      <p>{tra('Order_Number')}</p>
                      <span>{orderDetail?.sap_order_no || '-'}</span>
                    </div>
                    <div>
                      <p>{tra('Order_Creator')}</p>
                      <span>{orderDetail?.created_user_name || '-'}</span>
                    </div>
                    <div>
                      <p>{tra('Shipping_Date')}</p>
                      <span>{orderDetail?.date ? new Date(orderDetail?.date).toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' }) : '-'}</span>
                    </div>
                    {/* <div>
                      <p>{tra('Order_Summary')}</p>
                      <span>{orderDetail?.Amounts?.length || '-'} {tra('Delivery_1_Product')}</span>
                    </div> */}
                    <div>
                      <p>{tra('Delivery_Type')}</p>
                      <span>{orderDetail?.delivery_point_name || '-'}</span>
                    </div>
                    <div>
                      <p>{tra('Building_Site')}</p>
                      <span>{orderDetail?.Delivery?.name || '-'}</span>
                    </div>
                    <div>
                      <p>{tra('Order_Date')}</p>
                      <span>{orderDetail?.createdAt ? new Date(orderDetail?.createdAt).toLocaleDateString('tr-TR', { day: 'numeric', month: 'long', year: 'numeric' }) : '-'}</span>
                    </div>
                    <div>
                      <p>{tra('Product_Name')}</p>
                      <span>{orderDetail?.product_name || '-'}</span>
                    </div>
                    <div>
                      <p>{tra('Payment_Condition')}</p>
                      <span>{orderDetail?.payment_condition_name || '-'}</span>
                    </div>
                    {/* <div>
                      <p>{tra('Building_Site')}</p>
                      <span>800567-Akdenizinsaat</span>
                    </div> */}
                  </div>
                  <div className="cardContent">
                    <SimpleTable detail>
                      <thead>
                        <th>{tra('Situation')}</th>
                        <th>{tra('Order_Ref_ID')}</th>
                        <th>{tra('Quanta')}</th>
                        {/* <th>{tra('Product')}</th> */}
                        <th>{tra('Delivery_Time')}</th>
                      </thead>
                      <tbody>
                        {
                          orderDetail && orderDetail.Amounts && orderDetail.Amounts.map((item) => (
                            <tr>
                              <td>
                                {
                                  orderItemStatus && orderItemStatus.map((a) => a.id === item.stage_id && (
                                    <Tooltip title={a.text}>
                                      <div>
                                        {a.icon}
                                      </div>
                                    </Tooltip>
                                  ))
                                }
                              </td>
                              <td>{item.order_id}</td>
                              <td>{item.amount} {tra('Ton')}</td>
                              {/* <td>
                                <Tooltip title={item.product}>
                                  <span>{item.product}</span>
                                </Tooltip>
                              </td> */}
                              <td>{item.hour}{item.hour !== '-' && ':00'}</td>
                              {/* <td>
                                <CustomButton
                                  lightBlue={!item.tonnage_update}
                                  grey={item.tonnage_update}
                                  disabled={item.tonnage_update}
                                  onClick={() => this.setState({ questionModal: true })}
                                >
                                  {item.tonnage_update ? 'Artırım Talep Edildi' : 'Tonaj Artışı Talep Et'}
                                </CustomButton>
                              </td> */}
                            </tr>
                          ))
                        }
                      </tbody>
                    </SimpleTable>
                  </div>
                </OrderCard>
                <div className="bottom">
                  {/* <div className="delivery">
                    <h2>Teslimat Durumu</h2>
                    <div className="status">
                      <div className="statusBox">
                        <div className="svgBox" style={{ backgroundColor: status >= 1 ? hexToRgbA(Colors.blue, 0.25) : Colors.lightGrey, transform: status === 1 ? 'scale(1.4)' : 'none' }}>
                          <CheckFilled color={status >= 1 ? Colors.blue : Colors.darkGrey} />
                        </div>
                        <p style={{ paddingTop: status === 1 ? '15px' : '10px', fontWeight: status === 1 ? 'bold' : 'normal', display: status >= 1 ? 'flex' : 'none' }}>{tra('Approved')}</p>
                      </div>
                      <span className="line1" />
                      <div className="statusBox">
                        <div className="svgBox" style={{ backgroundColor: status >= 2 ? hexToRgbA(Colors.blue, 0.25) : Colors.lightGrey, transform: status === 2 ? 'scale(1.4)' : 'none' }}>
                          <Truck color={status >= 2 ? Colors.blue : Colors.darkGrey} />
                        </div>
                        <p style={{ paddingTop: status === 2 ? '15px' : '10px', fontWeight: status === 2 ? 'bold' : 'normal', display: status >= 2 ? 'flex' : 'none' }}>{tra('On_Its_Way')}</p>
                      </div>
                      <span className="line2" />
                      <div className="statusBox">
                        <div className="svgBox" style={{ backgroundColor: status >= 3 ? hexToRgbA(Colors.blue, 0.25) : Colors.lightGrey, transform: status === 3 ? 'scale(1.4)' : 'none' }}>
                          <BoxConfirmed color={status >= 3 ? Colors.blue : Colors.darkGrey} />
                        </div>
                        <p style={{ paddingTop: status === 3 ? '15px' : '10px', fontWeight: status === 3 ? 'bold' : 'normal', display: status >= 3 ? 'flex' : 'none' }}>{tra('Was_Delivered')}</p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="address">
                    <p>{tra('Delivery_Address')}</p>
                    <span>Cevizlik, Kartopu Sk. No:12, 34142 Bakırköy/İstanbul</span>
                  </div> */}
                </div>
              </div>
            </div>
          </CustomFadeOrder>
        </Modal>
        {
          this.state.questionModal && (
            <GenerateModal
              open={this.state.questionModal}
              type="Question"
              title={tra('Tonnage_Increase_Will_Be_Requested')}
              text={tra('Do_you_confirm')}
              cancelButton={(<CustomButton grey onClick={() => this.setState({ questionModal: false })}>{tra('Cancel')}</CustomButton>)}
              confirmButton={(<CustomButton navy onClick={() => { this.setState({ questionModal: false, successModal: true }); }}>{tra('Accept')}</CustomButton>)}
            />
          )
        }
        {
          this.state.successModal && (
            <GenerateModal
              open={this.state.successModal}
              type="Success"
              title={tra('Tonnage_Increase_Request_Sent')}
              confirmButton={(<CustomButton green onClick={() => { this.setState({ successModal: false }); }}>{tra('Done')}</CustomButton>)}
            />
          )
        }
      </>
    );
  }
}

DetailOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderDetail: PropTypes.object.isRequired,
};

DetailOrder.defaultProps = {
};
